import React from 'react';

export const ChatColumnContext = React.createContext({
  chatColumnOpen: false,
  toggleChatColumn: () => {
  },
  chatSendOpen: false,
  toggleChatSend: () => {
  },
  chatMenuOpen: false,
  toggleChatMenu: () => {
  },
  chatFunctionsOpen: false,
  toggleChatFunctions: () => {
  },
  feld: {},
  setFeld: () => {
  },
  schritt: {
    key: 0
  },
  setSchritt: () => {
  },
  funktion: {
    key: 0
  },
  setFunktion: () => {
  },
  bereich: {
    name: "Businessplan"
  },
  setBereich: () => {
  },
  messages: [],
  postMessage: () => {
  },
  setPreviousSchritt: () => {
  },
});
