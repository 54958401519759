import Typography from "@material-ui/core/Typography/Typography";
import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CustomTextField from "../CustomTags/CustomTextField";
import Grid from "@material-ui/core/Grid";
import Flippy, {BackSide, FrontSide} from 'react-flippy';

import erklaerungsTexte from "../LogicData/erklaerungsTexte";
import erklaerungsUeberschriften from "../LogicData/erklaerungsUeberschriften";
import styles from "../styles";
import {AppContext} from "../AppContext";
import CustomMultilineTextField from "../CustomTags/CustomMultilineTextField";
import WeiterleitungsModal from "../CustomTags/Weiterleitungsmodal";
import clsx from "clsx";
import {CardActions} from "@mui/material";
import Button from "@material-ui/core/Button";
import TopAreaDual from "../MenuItems/TopAreaDual";


class SteckbriefBoardPublic extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.erklaerungen = {
      ...erklaerungsUeberschriften.Public.SteckbriefBoard,
      ...erklaerungsTexte,
    };

    this.state = {
      step: 1,
      tutorialEnabled: false,
      erklaerungHeader: this.erklaerungen["erklaerungHeader"],
      erklaerungText: this.erklaerungen["erklaerungText"],
      unternehmenName: '',
      publicSteckbriefUnternehmen: []
    };

  }

  componentDidMount() {
    this.getDataFromApi();
  }

  getDataFromApi = async () => {
    let response = await fetch(`${process.env.REACT_APP_NEWSAPI_URL}/collections/get/Unternehmen?fields[unternehmenName]=1&fields[pitchdeck]=1&fields[logo]=1&fields[branche]=1&fields[unternehmenData]=1&fields[image]=1`, {headers: {'Cockpit-Token': 'account-08a04d1fe5bbb21334f6a76c06b38e'}})
    console.log(response);
    let json = await response.json();
    console.log("getUnternehmenDataFromCockpitApi: json.entries");
    console.log(json.entries);
    this.setState({
      publicSteckbriefUnternehmen: json.entries,
      isLoaded: true,
    });
  }


  createField = (name, properties) => {
    const {classes} = this.props;
    const {items, vergleichsjahr} = this.state;
    if (properties.typ === "readOnly") {
      return (
        <CustomTextField
          id={"edit-textteil-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.textField}
          value={items[name]}
          onChange={this.handleChange(name, "input")}
          margin="normal"
          isCurrency={true}
          isReadOnly={true}
          unit={"€"}
          onFocus={() => this.handleFocus(name)}
        />
      );
    } else if (properties.typ === "input") {
      return (
        <CustomTextField
          id={"edit-textteil-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.textField}
          value={items[name]}
          onChange={this.handleChange(name, "input")}
          margin="normal"
          variant="outlined"
          isCurrency={true}
          isReadOnly={false}
          unit={"€"}
          onFocus={(event) => this.handleFocusEvent(name, event)}
        />
      );
    } else if (properties.typ === "multiline") {
      return (
        <CustomMultilineTextField
          id={"edit-textteil-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.multilineTextField}
          value={items[name]}
          margin="normal"
          variant="outlined"
          isReadOnly={true}
        />
      );
    } else if (properties.typ === "multiline-readonly") {
      return (
        <CustomMultilineTextField
          id={"edit-textteil-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.multilineTextField}
          showLabel={false}
          value={items[name]}
          margin="normal"
          variant="outlined"
          isReadOnly={true}
        />
      );
    }
  };

  createStepCard = () => {
    const {classes} = this.props;
    const {publicSteckbriefUnternehmen} = this.state;
    console.log(publicSteckbriefUnternehmen);

    let boardCards = [];
    let logoPath = '';
    let backSideText = '';
    let link = '';

    publicSteckbriefUnternehmen.forEach(function (unternehmen) {
      if ('logo' in unternehmen && unternehmen.logo !== null && 'path' in unternehmen.logo) {
        logoPath = 'https://api.beratungsheld.de/' + unternehmen.logo.path;
      } else if ('image' in unternehmen && unternehmen.image !== null && unternehmen.image.length > 0) {
        logoPath = unternehmen.image;
      } else {
        logoPath = 'https://app.beratungsheld.de/beratungsheld-logo-wasserzeichen.png';
      }
      if ('pitchdeck' in unternehmen && unternehmen.pitchdeck !== null && 'PitchDeckIntro' in unternehmen.pitchdeck) {
        backSideText = unternehmen.pitchdeck.PitchDeckIntro;
        if ('pitchDeckPublic' in unternehmen.pitchdeck && unternehmen.pitchdeck.pitchDeckPublic) {
          link = '/#/public/steckbrief/' + unternehmen._id;
        } else {
          link = false;
        }
      } else {
        backSideText = 'Es wurde leider noch keine Beschreibung hinterlegt...';
        link = false;
      }
      boardCards.push(
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <form className={classes.container} noValidate autoComplete="off">
            <Flippy
              // component={Card}
              // className={classes.card}
              flipOnHover={true} // default false
              flipOnClick={true} // default false
              flipDirection="horizontal" // horizontal or vertical
            >
              <FrontSide
                style={{
                  boxShadow: "none",
                }}>
                <Card className={classes.steckbriefFrontCard} style={{backgroundImage: 'url("' + logoPath + '")'}}>
                  <CardHeader
                    title={unternehmen.unternehmenName}
                    titleTypographyProps={{align: 'center'}}
                  />
                  <CardContent className={classes.steckbriefFrontCardContent}>
                    <Typography
                      align={"center"}>
                      {unternehmen.branche}
                    </Typography>
                  </CardContent>
                </Card>
              </FrontSide>
              <BackSide
                style={{
                  boxShadow: "none",
                }}>
                <Card className={classes.steckbriefBackCard}>
                  <CardHeader
                    title={unternehmen.unternehmenName}
                    titleTypographyProps={{align: 'center'}}
                  />
                  <CardContent className={classes.steckbriefBackCardContent}>
                    <Typography
                      style={{whiteSpace: 'pre-line'}}
                      align={"center"}>
                      {backSideText}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Grid container justifyContent='center'>
                      {link
                      ?<Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        href={link}
                      >
                        zum Steckbrief
                      </Button>
                      :<div></div>
                      }
                    </Grid>
                  </CardActions>
                </Card>
              </BackSide>
            </Flippy>
          </form>
        </Grid>
      )
    });

    return (
      <Fragment>
        {boardCards}
      </Fragment>
    );
  };

  render() {
    const {classes} = this.props;
    let {
      step,
      isLoaded,
      modalScript,
      weiterleitungsModalOpen,
      erklaerungHeader
    } = this.state;

    return (
      <Fragment>
        <TopAreaDual/>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: !this.state.chatColumnOpen,
          })}
        >
          <div className={classes.appBarSpacer}/>
          <WeiterleitungsModal
            userId={this.props.userId}
            unternehmenId={this.props.unternehmenId}
            weiterleitungsModalOpen={weiterleitungsModalOpen}
            modalScript={modalScript}
            continueAnywayPossible={false}
          />
          <div className={classes.appBarSpacer}/>
          <Typography variant="h4" gutterBottom component="h2">
            {erklaerungHeader}
          </Typography>
          <Grid container spacing={2}>
            {this.createStepCard(step)}
          </Grid>
        </main>
      </Fragment>
    );
  }
}

export default withStyles(styles)(SteckbriefBoardPublic);
