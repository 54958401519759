import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {AppContext} from "../../AppContext";

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class Login extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      user: "",
      pass: "",
      userId: "",
      unternehmenId: "",
    };
  }

  componentDidMount() {
    const apitoken = localStorage.getItem("Beratungsheld-Token");
    if (apitoken) {
      console.log("API-Token detected!")
      this.context.logInByToken(apitoken);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let {user, pass} = this.state;

    this.context.logIn(user, pass);


    // if (user === "beratung@oliverbrunn.de" && pass === "unserHeld") {
    //   this.setState({
    //     isLoggedIn: true,
    //     userId: "5bf7812a3452ca49b20abb53",
    //     unternehmenId: "5f047a347acaefafc2c27863",
    //   });
    // } else if (user === "vm@itfreund.de" && pass === "guterJunge") {
    //   this.setState({
    //     isLoggedIn: true,
    //     userId: "5f0478727acaefafc2c2785e",
    //     unternehmenId: "5f04797a7acaefafc2c27860",
    //   });
    // } else if (user === "mw@itfreund.de" && pass === "mostWorstCase") {
    //   this.setState({
    //     isLoggedIn: true,
    //     userId: "5f05bf57c403f809ec67e614",
    //     unternehmenId: "5f05bf87c403f809ec67e615",
    //   });
    // } else if (user === "lm@itfreund.de" && pass === "heldenhaft") {
    //   this.setState({
    //     isLoggedIn: true,
    //     userId: "5f0478df7acaefafc2c2785f",
    //     unternehmenId: "5f0479c77acaefafc2c27862",
    //   });
    // }
  };

  handleChange = (name) => ({target: {value, checked}}) => {
    console.log("handleChange aufgerufen für: " + name);
    this.setState({
      [name]: value,
    });
  };

  render() {
    let {isLoggedIn, user, pass, userId, unternehmenId} = this.state;

    // if (this.context.isLoggedIn === true) {
    //   console.log("Login Erfolgreich");
    //   return <Redirect to={`/dashboard/${this.context.userId}/${this.context.unternehmenId}`}/>;
    //   return <Redirect to={`/dashboard`}/>;
    // }

    // console.log(isLoggedIn);

    return (
      <Fragment>
        <Container component="main" maxWidth="xs">
          <CssBaseline/>
          <div className={this.props.classes.paper}>
            <Avatar className={this.props.classes.avatar}>
              <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
              Einloggen
            </Typography>
            <form
              className={this.props.classes.form}
              noValidate
              onSubmit={this.handleSubmit}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={user}
                onChange={this.handleChange("user")}
                id="email"
                label="Benutzername"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={pass}
                onChange={this.handleChange("pass")}
                name="password"
                label="Passwort"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {/*<FormControlLabel*/}
              {/*  control={<Checkbox value="remember" color="primary" />}*/}
              {/*  label="Benutzername merken"*/}
              {/*/>*/}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={this.props.classes.submit}
              >
                Einloggen
              </Button>
              {/*<Grid container>*/}
              {/*  <Grid item xs>*/}
              {/*    <Link href="#/forgotpassword" variant="body2">*/}
              {/*      Forgot password?*/}
              {/*    </Link>*/}
              {/*  </Grid>*/}
              {/*  <Grid item>*/}
              {/*    <Link href="#/signup" variant="body2">*/}
              {/*      {"Don't have an account? Sign Up"}*/}
              {/*    </Link>*/}
              {/*  </Grid>*/}
              {/*</Grid>*/}
            </form>
          </div>
        </Container>
      </Fragment>
    );
  }
}

export default withStyles(useStyles)(Login);
