import typischerMonatSchritte from "./TypischerMonatSchritte"
import React from "react";
import ChatFunktionen from "./ChatFunktionen";

const SpezifischerMonatSchritte = {
  1: {
    RentabilitaetMonatJahr: {
      typ: "monthYearSelect",
      teilDerSummen: [],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: false,
      inEingabe: true,
      key: "rentabilitaet-monthYear-select",
    },
  },
};

SpezifischerMonatSchritte[2] = typischerMonatSchritte[1];
SpezifischerMonatSchritte[3] = typischerMonatSchritte[2];

{
  Object.values(SpezifischerMonatSchritte[2]).map((funktion) => {
      funktion["funktionen"] = [ChatFunktionen.Prognose];
    }
  )
}

{
  Object.values(SpezifischerMonatSchritte[3]).map((funktion) => {
      funktion["funktionen"] = [ChatFunktionen.Prognose];
    }
  )
}

export default SpezifischerMonatSchritte;
