import React, {Fragment} from "react";
import Modal from "@material-ui/core/Modal";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import styles from "../styles";
import {withStyles} from "@material-ui/styles";

class EingabeModal extends React.Component {
  handleModalClose = () => {
    window.location.hash = `#/dashboard/${this.props.userId}/${this.props.unternehmenId}`;
  };

  handleModalSaveAndClose = () => {
    this.props.handleModalSaveAndClose();
  };

  render() {
    return (
      <Modal
        aria-labelledby="rumpfjahr-modal-title"
        aria-describedby="rumpfjahr-modal-description"
        open={this.props.eingabeModalOpen}
        onClose={this.handleModalClose}
      >
        <div className={this.props.classes.modal}>
          <h2 id="rumpfjahr-modal-title">Rumpfgeschäftsjahr festlegen</h2>
          <p id="rumpfjahr-modal-description">
            Für die weitere Planung ist es wichtig, welches Jahr dein
            Rumpfgeschäftsjahr ist. Sprich: In welchem Jahr ist die Gründung
            geplant bzw. erfolgt.
          </p>
          <FormControl
            className={this.props.classes.formControl}
            id={"edit-rentabiliteat-rumpfjahr-control"}
          >
            <Fragment>
              <InputLabel htmlFor="edit-rentabiliteat-rumpfjahr">
                Jahr
              </InputLabel>
              <Select
                value={this.props.rumpfjahr}
                onChange={this.props.handleChange("Rumpfjahr")}
                inputProps={{
                  name: "rumpfjahr",
                  id: "edit-rentabiliteat-rumpfjahr",
                }}
              >
                <MenuItem value="">
                  <em>Bitte wählen</em>
                </MenuItem>
                <MenuItem value={2016}>2016</MenuItem>
                <MenuItem value={2017}>2017</MenuItem>
                <MenuItem value={2018}>2018</MenuItem>
                <MenuItem value={2019}>2019</MenuItem>
                <MenuItem value={2020}>2020</MenuItem>
                <MenuItem value={2021}>2021</MenuItem>
              </Select>
            </Fragment>
          </FormControl>
          <FormControl
            className={this.props.classes.formControl}
            id={"edit-rentabiliteat-monat-control"}
          >
            <Fragment>
              {" "}
              <InputLabel htmlFor="edit-rentabiliteat-monat">Monat</InputLabel>
              <Select
                value={this.props.rumpfmonat}
                onChange={this.props.handleChange("Rumpfmonat")}
                inputProps={{
                  name: "monat",
                  id: "edit-rentabiliteat-monat",
                }}
              >
                <MenuItem value="">
                  <em>Bitte wählen</em>
                </MenuItem>
                <MenuItem value={1}>Januar</MenuItem>
                <MenuItem value={2}>Februar</MenuItem>
                <MenuItem value={3}>März</MenuItem>
                <MenuItem value={4}>April</MenuItem>
                <MenuItem value={5}>Mai</MenuItem>
                <MenuItem value={6}>Juni</MenuItem>
                <MenuItem value={7}>Juli</MenuItem>
                <MenuItem value={8}>August</MenuItem>
                <MenuItem value={9}>September</MenuItem>
                <MenuItem value={10}>Oktober</MenuItem>
                <MenuItem value={11}>November</MenuItem>
                <MenuItem value={12}>Dezember</MenuItem>
              </Select>
            </Fragment>
          </FormControl>
          <Button
            disabled={!this.props.continuePossible("Rumpfjahr")}
            variant="contained"
            color="primary"
            className={this.props.classes.button}
            onClick={this.handleModalSaveAndClose}
          >
            Speichern
          </Button>
          <Button
            disabled={false}
            variant="contained"
            color="secondary"
            className={this.props.classes.button}
            onClick={this.handleModalClose}
          >
            Abbrechen
          </Button>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(EingabeModal);
