import Typography from "@material-ui/core/Typography/Typography";
import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import erklaerungsTexte from "../LogicData/erklaerungsTexte";
import styles from "../styles";
import {AppContext} from "../AppContext";
import erklaerungsUeberschriften from "../LogicData/erklaerungsUeberschriften";


class BwaAusgabe extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.erklaerungen = {
      ...erklaerungsUeberschriften.Geschaeftsdaten.BwaAusgabe,
      ...erklaerungsTexte,
    };

    this.state = {
      step: 1,
      isLoaded: false,
      tutorialEnabled: true,
      jahr: "",
      ergebnisVorhanden: false,
      items: {},
      tables: <Fragment/>,
      datarows: {
        "Erloese7": "Umsätze 7% MwSt",
        "Erloese19": "Umsätze 19% MwSt",
        "Umsatzerloese": "Umsatzerlöse",
        "UnfertigeErzeugnisse": "Unfertige Erzeugnisse",
        "Bestandsveraenderung": "Bestandsveränderung",
        "Gesamtleistung": "Gesamtleistung",
        "Wareneinkauf": " Wareneinkauf",
        "Fremdleistungen": "Fremdleistungen",
        "SonstigeErloese": "Sonstige Umsätze",
        "Rohertrag": "Rohertrag",
        "Personalkosten": "Personalkosten",
        "Raumkosten": "Raum- und Bürokosten",
        "Steuern": "Steuern",
        "Versicherungen": "Versicherungen",
        "Kfz-Kosten": "Kfz Kosten",
        "Werbe-Reisekosten": "Werbe/Reisekosten",
        "Kosten der Warenabgabe": "Kosten der Warenabgabe",
        "Afa": "Afa",
        "Reparaturen": "Reparaturen",
        "Instandhaltung": "Instandhaltung",
        "Sonstige Kosten": "Sonstige Kosten",
        "Gesamtkosten": "Gesamtkosten",
        "Betriebsergebnis": "Betriebsergebnis",
        "Zinsaufwand": "Zinsaufwand",
        "So neutraler Aufwand": "Sonstiger neutraler Aufwand",
        "Neutraler Aufwand": "Summe Neutraler Aufwand",
        "Zinsertrag": "Zinserträge",
        "SoNeutralerErtrag": "Sonstige neutrale Erträge",
        "Neutraler Ertrag": "Summe Neutraler Ertrag",
        "Neutrales Ergebnis": "Neutrales Ergebnis",
        "Ergebnis": "Ergebnis"
      }
    };
    this.handleFocus = this.handleFocus.bind(this);
  }

  componentDidMount() {
    this.context.setFunktion({
      key: this.erklaerungen.key,
      name: this.erklaerungen.name,
      erklaerungHeader: this.erklaerungen.erklaerungHeader,
      erklaerungText: this.erklaerungen.erklaerungText,
      continuePossible: false,
      backPossible: false,
      totalSteps: Object.keys(this.erklaerungen["Schritte"]).length,
      handleSubmit: () => this.handleSubmit(),
      resetToStepOne: () => this.resetToStepOne(),
    });
    this.context.setBereich({
      name: "Geschaeftsdaten"
    });
    this.resetToStepOne();
  }

  createData = (name, calories, fat, carbs, protein) => {
    return {name, calories, fat, carbs, protein};
  }

  resetToStepOne = () => {
    this.context.setSchritt({
      key: 1,
      erklaerungHeader: this.erklaerungen["Schritte"][1]
        .erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][1].erklaerungText,
      continuePossible: false,
      backPossible: false,
    });
    this.context.setSavePossible(false);
  }

  componentDidUpdate() {
    if (this.state.step !== this.context.schritt.key) {
      this.handleStepChange();
    }
  }

  handleFocus = (name) => {
    this.context.setFeld({
      key: name,
    });
  };

  handleFocusEvent = (name, event) => {
    event.target.select();
    this.context.setFeld({
      key: name,
    });
  };

  handleStepChange = () => {
    const newStep = this.context.schritt.key;
    this.setState({
      step: newStep,
    });

    if (newStep > 1) {
      this.context.setSchritt({
        key: newStep,
        continuePossible: this.continuePossible(newStep),
        backPossible: true,
      });
    } else {
      this.context.setSchritt({
        key: newStep,
        continuePossible: this.continuePossible(newStep),
        backPossible: false,
      });
    }
  };

  getDataFromApi = async (jahr) => {
    let result = await this.context.getUnternehmenDataFromApi("bwa");
    this.dataImport(result, jahr);
  }

  getClearData = () => {
    return {}
  }

  dataImport = (result, jahr) => {
    if (jahr === "") {
      this.context.setSavePossible(false);
      this.handleStepChange();
      this.setState({
        isLoaded: false,
        jahr: "",
        items: this.getClearData(),
      });
    } else {
      console.log("MUH!!!")
      //this.context.setSavePossible(true);
      this.context.setSavePossible(false);
      this.handleStepChange();
      if (result.bwa) {
        if (result.bwa[jahr]) {
          this.setState({
            isLoaded: true,
            jahr: jahr,
            ergebnisVorhanden: true,
            items: {
              ...this.getClearData(),
              ...result.bwa[jahr],
            },
          }, this.generateTables);
          return;
        }
      }
      this.setState({
        isLoaded: false,
        jahr: jahr,
        ergebnisVorhanden: false,
        items: {
          ...this.getClearData(),
        },
      });
    }
  };

  generateTables = async () => {
    // const namerows = {};
    // Object.keys(this.state.datarows).map((key) => namerows[key] = this.context.returnFeldDataFromCockpitApi(key));
    // // const testrows = await Promise.all(namerows);
    // console.log(namerows);
    // // datarows.map((row) => {this.context.returnFeldDataFromCockpitApi(row)});

    const {classes} = this.props;
    const {
      jahr,
      ergebnisVorhanden,
      items,
    } = this.state;

    const table = await
      <Fragment>
        <Card className={classes.card}>
          <CardHeader title="IST"/>
          <CardContent>
            <TableContainer component={Paper}>
              <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>IST</TableCell>
                    <TableCell align="right">Jan</TableCell>
                    <TableCell align="right">Feb</TableCell>
                    <TableCell align="right">Mär</TableCell>
                    <TableCell align="right">Apr</TableCell>
                    <TableCell align="right">Mai</TableCell>
                    <TableCell align="right">Jun</TableCell>
                    <TableCell align="right">Jul</TableCell>
                    <TableCell align="right">Aug</TableCell>
                    <TableCell align="right">Sep</TableCell>
                    <TableCell align="right">Okt</TableCell>
                    <TableCell align="right">Nov</TableCell>
                    <TableCell align="right">Dez</TableCell>
                    <TableCell align="right">Summe</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(this.state.datarows).map((key) => (
                    <TableRow
                      key={key}
                      sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                      <TableCell component="th" scope="row">
                        {this.state.datarows[key]}
                      </TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[1] || {})["Ist"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[2] || {})["Ist"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[3] || {})["Ist"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[4] || {})["Ist"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[5] || {})["Ist"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[6] || {})["Ist"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[7] || {})["Ist"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[8] || {})["Ist"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[9] || {})["Ist"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[10] || {})["Ist"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[11] || {})["Ist"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[12] || {})["Ist"] || {})[key] || 0)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardHeader title="SOLL"/>
          <CardContent>
            <TableContainer component={Paper}>
              <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>SOLL</TableCell>
                    <TableCell align="right">Jan</TableCell>
                    <TableCell align="right">Feb</TableCell>
                    <TableCell align="right">Mär</TableCell>
                    <TableCell align="right">Apr</TableCell>
                    <TableCell align="right">Mai</TableCell>
                    <TableCell align="right">Jun</TableCell>
                    <TableCell align="right">Jul</TableCell>
                    <TableCell align="right">Aug</TableCell>
                    <TableCell align="right">Sep</TableCell>
                    <TableCell align="right">Okt</TableCell>
                    <TableCell align="right">Nov</TableCell>
                    <TableCell align="right">Dez</TableCell>
                    <TableCell align="right">Summe</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(this.state.datarows).map((key) => (
                    <TableRow
                      key={key}
                      sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                      <TableCell component="th" scope="row">
                        {this.state.datarows[key]}
                      </TableCell>
                      {/*this.context.returnFeldDataFromCockpitApi*/}
                      <TableCell
                        align="right">{((((this.state.items || {})[1] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[2] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[3] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[4] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[5] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[6] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[7] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[8] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[9] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[10] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[11] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[12] || {})["Soll"] || {})[key] || 0)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardHeader title="Differenz IST - SOLL"/>
          <CardContent>
            <TableContainer component={Paper}>
              <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Differenz</TableCell>
                    <TableCell align="right">Jan</TableCell>
                    <TableCell align="right">Feb</TableCell>
                    <TableCell align="right">Mär</TableCell>
                    <TableCell align="right">Apr</TableCell>
                    <TableCell align="right">Mai</TableCell>
                    <TableCell align="right">Jun</TableCell>
                    <TableCell align="right">Jul</TableCell>
                    <TableCell align="right">Aug</TableCell>
                    <TableCell align="right">Sep</TableCell>
                    <TableCell align="right">Okt</TableCell>
                    <TableCell align="right">Nov</TableCell>
                    <TableCell align="right">Dez</TableCell>
                    <TableCell align="right">Summe</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(this.state.datarows).map((key) => (
                    <TableRow
                      key={key}
                      sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                      <TableCell component="th" scope="row">
                        {this.state.datarows[key]}
                      </TableCell>
                      {/*this.context.returnFeldDataFromCockpitApi*/}
                      <TableCell
                        align="right">{((((this.state.items || {})[1] || {})["Ist"] || {})[key] || 0) - ((((this.state.items || {})[1] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[2] || {})["Ist"] || {})[key] || 0) - ((((this.state.items || {})[2] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[3] || {})["Ist"] || {})[key] || 0) - ((((this.state.items || {})[3] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[4] || {})["Ist"] || {})[key] || 0) - ((((this.state.items || {})[4] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[5] || {})["Ist"] || {})[key] || 0) - ((((this.state.items || {})[5] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[6] || {})["Ist"] || {})[key] || 0) - ((((this.state.items || {})[6] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[7] || {})["Ist"] || {})[key] || 0) - ((((this.state.items || {})[7] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[8] || {})["Ist"] || {})[key] || 0) - ((((this.state.items || {})[8] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[9] || {})["Ist"] || {})[key] || 0) - ((((this.state.items || {})[9] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[10] || {})["Ist"] || {})[key] || 0) - ((((this.state.items || {})[10] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[11] || {})["Ist"] || {})[key] || 0) - ((((this.state.items || {})[11] || {})["Soll"] || {})[key] || 0)}</TableCell>
                      <TableCell
                        align="right">{((((this.state.items || {})[12] || {})["Ist"] || {})[key] || 0) - ((((this.state.items || {})[12] || {})["Soll"] || {})[key] || 0)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Fragment>

    this.setState({
      tables: table
    });
  }

  continuePossible = (step) => {
    const {jahr} = this.state;

    switch (step) {
      case 1:
        console.log("yaeh!")
        // return (
        //   jahr &&
        //   0 !== jahr.toString().length &&
        //   monat &&
        //   0 !== monat.toString().length
        // );
        return true;
      default:
        // return (items["Ergebnis"] && 0 !== items["Ergebnis"].toString().length);
        return false;
    }
  };

  handleSnackbarClose = () => {
    this.setState({
      saveSuccessful: false,
    });
  };

  handleChange = (name) => ({target: {value, checked}}) => {
    console.log("handleChange aufgerufen für: " + name);
    const {jahr, monat, planung} = this.state;

    if (name === "Jahr") {
      console.log("handleChange is jahr!");
      if (value !== "" && jahr !== value) {
        console.log("jahr is changed!");
        this.getDataFromApi(value);
      } else {
        this.setState({
          jahr: value,
        });
      }
    }
  };

  handleContinue = () => {
    const {step} = this.state;
    console.log("continue to step " + (step + 1));
    this.setState({
      step: step + 1,
      erklaerungHeader: this.erklaerungen["Schritte"][step + 1].erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][step + 1].erklaerungText,
    });
  };

  handleBack = () => {
    const {step} = this.state;
    if (step > 1) {
      this.setState({
        step: step - 1,
        erklaerungHeader: this.erklaerungen["Schritte"][step - 1].erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][step - 1].erklaerungText,
      });
    }
  };

  onExit = () => {
    this.setState({
      tutorialEnabled: false,
    });
  };

  render() {
    const rows = [
      this.createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
      this.createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
      this.createData('Eclair', 262, 16.0, 24, 6.0),
      this.createData('Cupcake', 305, 3.7, 67, 4.3),
      this.createData('Gingerbread', 356, 16.0, 49, 3.9),
    ];

    const datarows = [
      "Erloese7",
      "Erloese19",
      "Umsatzerloese",
      "UnfertigeErzeugnisse",
      "Bestandsveraenderung",
      "Gesamtleistung",
      "Wareneinkauf",
      "Fremdleistungen",
      "SonstigeErloese",
      "Rohertrag",
      "Personalkosten",
      "Raumkosten",
      "Steuern",
      "Versicherungen",
      "Kfz-Kosten",
      "Werbe-Reisekosten",
      "Kosten der Warenabgabe",
      "Afa",
      "Reparaturen",
      "Instandhaltung",
      "Sonstige Kosten",
      "Gesamtkosten",
      "Betriebsergebnis",
      "Zinsaufwand",
      "So neutraler Aufwand",
      "Neutraler Aufwand",
      "Zinsertrag",
      "SoNeutralerErtrag",
      "Neutraler Ertrag",
      "Neutrales Ergebnis",
      "Ergebnis"
    ];

    const {classes} = this.props;
    const {
      jahr,
      ergebnisVorhanden,
      items,
    } = this.state;
    const STEPS = {
      1: (
        <Card className={classes.card}>
          <CardHeader title="Monat und Jahr"/>
          <CardContent>
            <FormControl
              className={classes.formControl}
              id={"edit-bwaausgabe-jahr-control"}
            >
              <InputLabel htmlFor="edit-bwaausgabe-jahr">Jahr</InputLabel>
              <Select
                value={jahr}
                onChange={this.handleChange("Jahr")}
                inputProps={{
                  name: "jahr",
                  id: "edit-bwaausgabe-jahr",
                }}
              >
                {/*<MenuItem value="">*/}
                {/*  <em>Bitte wählen</em>*/}
                {/*</MenuItem>*/}
                <MenuItem value={2016}>2016</MenuItem>
                <MenuItem value={2017}>2017</MenuItem>
                <MenuItem value={2018}>2018</MenuItem>
                <MenuItem value={2019}>2019</MenuItem>
                <MenuItem value={2020}>2020</MenuItem>
                <MenuItem value={2021}>2021</MenuItem>
                <MenuItem value={2022}>2022</MenuItem>
                <MenuItem value={2023}>2023</MenuItem>
                <MenuItem value={2024}>2024</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={ergebnisVorhanden}
                    value="ergebnisVorhanden"
                    color="primary"
                  />
                }
                label="bereits eingegeben"
              />
            </FormControl>
          </CardContent>
        </Card>
      ),
      2: (
        this.state.tables
      ),
    };

    const {
      step,
      erklaerungHeader,
      erklaerungText,
      visualisierungsdaten,
    } = this.state;

    return (
      <Fragment>
        <div className={classes.appBarSpacer}/>
        <Typography variant="h4" gutterBottom component="h2">
          BWA-Ausgabe
          {jahr !== "" && (
            <Fragment>
              &nbsp;für {jahr}
            </Fragment>
          )}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <form className={classes.container} noValidate autoComplete="off">
              {STEPS[step]}
            </form>
          </Grid>
          {/*<Grid item md={12}>*/}
          {/*  <Card className={classes.progressCard}>*/}
          {/*    <CardHeader title="Fortschritt"/>*/}
          {/*    <CardContent>*/}
          {/*      <LinearProgress*/}
          {/*        variant="determinate"*/}
          {/*        value={((step - 1) / (Object.keys(this.erklaerungen["Schritte"]).length - 1)) * 100}*/}
          {/*      />*/}
          {/*      <Typography variant="body1">*/}
          {/*        {this.erklaerungen.erklaerungHeader} &mdash; Schritt {step} von {(Object.keys(this.erklaerungen["Schritte"]).length)}: {this.context.schritt.erklaerungHeader}*/}
          {/*      </Typography>*/}
          {/*    </CardContent>*/}
          {/*  </Card>*/}
          {/*</Grid>*/}
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(BwaAusgabe);
