import Typography from "@material-ui/core/Typography/Typography";
import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import PhoneInput from "material-ui-phone-number";

import styles from "../styles";
import TopAreaBlank from "../MenuItems/TopAreaBlank";
import clsx from "clsx";

class Registrieren extends React.Component {
  state = {
    step: 1,
    error: "",
    items: {
      id: "",
      Vorname: "",
      Nachname: "",
      TelefonNummer: "",
      EmailAdresse: "",
      Passwort: "",
      Nutzername: "",
      Rolle: "",
      Unternehmen: "",
      Zahlweise: "",
    },
    result: {}
  };

  products = {
    "unternehmen-1": "869",
    "unternehmen-12": "877",
    "idee-1": "868",
    "idee-12": "878",
    "investoren-12": "870",
  }

  componentDidMount() {
    this.setState({
      items: {
        ...this.state.items,
        Rolle: this.props.match.params.role,
      }
    })
  }

  handleChange = (name) => ({target: {value}}) => {
    console.log("name: " + name + ", value: " + value)
    if (name === "Rolle" && value === "investoren") {
      console.log("Rolle Investor mit monatlicher Zahlung entdeckt!")
      this.setState({
        items: {
          ...this.state.items,
          Zahlweise: 12,
          Rolle: "investoren",
        },
      });
    } else {
      this.setState({
        items: {
          ...this.state.items,
          [name]: value,
        },
      });
    }
  };

  handlePhoneChange = (value) => {
    this.setState({
      items: {
        ...this.state.items,
        TelefonNummer: value,
      },
    });
  };

  handleContinue = () => {
    this.setState({
      step: 2,
    });
  }

  handleBack = () => {
    this.setState({
      step: 1,
    });
  }

  handleApiError = (error) => {
    var readableError = "";
    switch (error) {
      case "User password required":
        readableError = "Das Passwort darf nicht leer sein. Bitte  wähle ein Passwort und gebe es ein.";
        break;
      case "Valid email required":
        readableError = "Bitte gebe eine gültige E-Mail-Adresse ein.";
        break;
      case "Username required":
      case "Username cannot be empty!":
        readableError = "Der Nutzername darf nicht leer sein. Bitte  wähle einen Nutzernamen und gebe ihn ein.";
        break;
      case "Username is already used!":
        readableError = "Der Nutzername ist leider schon vergeben. Bitte wähle einen anderen Nutzernamen.";
        break;
      case "Email is already used!":
        readableError = "Die E-Mail-Adresse ist schon bei Beratungsheld registriert. Oben rechts kannst du dich einloggen.";
        break;
      default:
        readableError = error;
    }
    this.setState({
      isLoaded: false,
      error: readableError,
    });
  }

  handleSubmit = () => {
    // TODO: Validate

    const {items} = this.state;

    // if (items.Rolle === "" || items.Zahlweise === "") {
    //   this.setState({
    //     isLoaded: false,
    //     error: "Keine Rolle und/oder Zahlweise angegeben!",
    //   });
    //   return;
    // }
    //
    // var rolle = ""
    // switch (items.Rolle) {
    //   case "idee":
    //     rolle = "Gruender";
    //     break;
    //   case "unternehmen":
    //     rolle = "Unternehmer";
    //     break;
    //   case "investoren":
    //     rolle = "Investor";
    //     break;
    // }

    fetch(`${process.env.REACT_APP_NEWSAPI_URL}/cockpit/saveUser`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Cockpit-Token': '72dffa5b40b7b26271b103fb42eb76',
      },
      body: JSON.stringify({
        "user": {
          "user": items["Nutzername"],
          "password": items["Passwort"],
          "name": items["Vorname"] + " " + items["Nachname"],
          "firstname": items["Vorname"],
          "lastname": items["Nachname"],
          "email": items["EmailAdresse"],
          "phone": items["TelefonNummer"],
          "i18n": "de",
          "api_key": 1,
          "group": "Unternehmer",
          "active": true,
        },
      }),
    })
      .then((res) => res.json())
      // .then((res) => console.log(res))
      .then(
        (result) => {
          // console.log(result.entries)
          // var unternehmen = result.unternehmen.filter((value) => {
          //   return value._id == this.props.unternehmenId;
          // });

          if (result.error) {
            console.log(result.error);
            this.handleApiError(result.error);
          } else {
            this.setState({
              isLoaded: true,
              result: result,
              step: 2,
              error: ""
            });
            localStorage.setItem("Beratungsheld-Token", result.api_key);
          }
        },
        (error) => {
          console.log(error)
          this.setState({
            isLoaded: false,
            error: error,
          });
        }
        // )
        // .then(
        //   (result) => {
        //     // window.location = "https://beratungsheld.itf-demo.de/index.php/kasse/?add-to-cart=877";
        //     // alert("Benutzer \"" + this.state.result.name + "\" angelegt. Neue ID: \"" + this.state.result._id + "\". Hinweis: Einloggen für neue Nutzer noch deaktiviert. Daher erfolgt jetzt keine Weiterleitung zum Login bzw. kein Auto-Login.");
        //   }
      );

    // fetch(`${process.env.REACT_APP_API_URL}/v1/userdata/${this.props.userId}`, {
    //   method: "PUT",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     id: items["id"],
    //     Vorname: items["Vorname"],
    //     Nachname: items["Nachname"],
    //     TelefonNummer: items["TelefonNummer"],
    //     EmailAdresse: items["EmailAdresse"],
    //     "api_key":1
    //   }),
    // })
    //   .then((res) => res.json())
    //   .then(
    //     (result) => {
    //       this.setState({
    //         isLoaded: true,
    //         items: result,
    //       });
    //     },
    //     (error) => {
    //       this.setState({
    //         isLoaded: false,
    //         error: error,
    //       });
    //     }
    //   );
    // console.log(this.state);
  };

  getLabelForRolle = (zahlweise) => {
    const {items} = this.state;

    switch (items.Rolle) {
      case "idee":
      case "unternehmen":
        if (zahlweise === 1) {
          return "monatlich (19 €* im Monat)";
        } else if (zahlweise === 12) {
          return "jährlich (9 €* im Monat für jeweils 12 Monate im Voraus)";
        }
        break;
      case "investoren":
        if (zahlweise === 1) {
          return "keine monatlich Zahlung möglich...";
        } else if (zahlweise === 12) {
          return "jährlich (2000 €* für jeweils 12 Monate im Voraus)";
        }
        break;
    }
    if (zahlweise === 1) {
      return "für die Zahlweise bitte erst";
    } else if (zahlweise === 12) {
      return "eine Rolle auswählen!";
    }
  }

  monthlyPaymentImpossible = () => {
    const {items} = this.state;
    if (items.Rolle === "idee" || items.Rolle === "unternehmen") {
      return false;
    } else {
      return true;
    }
  }

  getMainFragment = (step) => {
    const {classes} = this.props;
    const {items} = this.state;
    if (step === 1) {
      return <Fragment>
        <Typography variant="body1" gutterBottom component="p">
          Bitte mache folgende Angaben, um dich bei Beratungsheld anzumelden:
        </Typography>
        {this.state.error.length > 0 &&
        <Typography variant="body1" gutterBottom component="p" color="error">
          Achtung! Folgender Fehler ist aufgetreten, bitte korrigiere deine Angaben: {this.state.error}
        </Typography>
        }
        <form className={classes.container} noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Nutzername"
                name="nutzername"
                autoComplete="username"
                id="edit-user-nutzername"
                // className={classes.textField}
                value={items["Nutzername"]}
                onChange={this.handleChange("Nutzername")}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="passwort"
                autoComplete="new-password"
                type="password"
                id="edit-user-passwort"
                label="Passwort"
                // className={classes.textField}
                value={items["Passwort"]}
                onChange={this.handleChange("Passwort")}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Vorname"
                name="vorname"
                autoComplete="fname"
                id="edit-user-vorname"
                // className={classes.textField}
                value={items["Vorname"]}
                onChange={this.handleChange("Vorname")}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="nachname"
                autoComplete="lname"
                id="edit-user-nachname"
                label="Nachname"
                // className={classes.textField}
                value={items["Nachname"]}
                onChange={this.handleChange("Nachname")}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <PhoneInput
                variant="outlined"
                fullWidth
                disableAreaCodes
                defaultCountry="de"
                id="phone"
                label="Telefonnummer"
                name="TelefonNummer"
                value={items["TelefonNummer"]}
                onChange={this.handlePhoneChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="E-Mail-Adresse"
                name="EmailAdresse"
                value={items["EmailAdresse"]}
                autoComplete="email"
                onChange={this.handleChange("EmailAdresse")}
                margin="normal"
              />
            </Grid>
            {/*<Grid item xs={6}>*/}
            {/*  <TextField*/}
            {/*    variant="outlined"*/}
            {/*    required*/}
            {/*    fullWidth*/}
            {/*    label="Name des ersten Unternehmens bzw. der Idee"*/}
            {/*    name="unternehmen"*/}
            {/*    id="edit-user-unternehmen"*/}
            {/*    // className={classes.textField}*/}
            {/*    value={items["Unternehmen"]}*/}
            {/*    onChange={this.handleChange("Unternehmen")}*/}
            {/*    margin="normal"*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid item xs={3}>*/}
            {/*  <FormControl className={classes.formControl} fullWidth={true}>*/}
            {/*    <InputLabel id="demo-simple-select-label">Rolle</InputLabel>*/}
            {/*    <Select*/}
            {/*      // labelId="demo-simple-select-label"*/}
            {/*      // id="demo-simple-select"*/}
            {/*      value={items["Rolle"]}*/}
            {/*      onChange={this.handleChange("Rolle")}*/}
            {/*      // onChange={handleChange}*/}
            {/*    >*/}
            {/*      <MenuItem value={"idee"}>Gründer</MenuItem>*/}
            {/*      <MenuItem value={"unternehmen"}>Unternehmer</MenuItem>*/}
            {/*      <MenuItem value={"investoren"}>Investor</MenuItem>*/}
            {/*    </Select>*/}
            {/*  </FormControl>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={3}>*/}
            {/*  <FormControl className={classes.formControl} fullWidth={true}>*/}
            {/*    /!*<InputLabel id="demo-simple-select-label" >Zahlweise</InputLabel>*!/*/}
            {/*    <RadioGroup*/}
            {/*      aria-label="zahlweise"*/}
            {/*      name="zahlweise"*/}
            {/*      value={items["Zahlweise"]}*/}
            {/*      onChange={this.handleChange("Zahlweise")}>*/}
            {/*      <FormControlLabel value="1" control={<Radio/>} label={this.getLabelForRolle(1)}*/}
            {/*                        disabled={this.monthlyPaymentImpossible()}/>*/}
            {/*      <FormControlLabel value="12" control={<Radio/>} label={this.getLabelForRolle(12)}/>*/}
            {/*    </RadioGroup>*/}
            {/*  </FormControl>*/}
            {/*</Grid>*/}
          </Grid>
          {/*<Typography variant="body2" gutterBottom component="p">*/}
          {/*  *Alle Preise zzgl. 19% MwSt.*/}
          {/*</Typography>*/}
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.handleSubmit}
          >
            Registrieren
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            href="https://beratungsheld.de"
          >
            Abbrechen
          </Button>
        </form>
      </Fragment>
    } else {
      // const nav = useNavigate();
      // nav("/login");
      // return <Navigate replace to="/login" />
      // this.props.history.replace("/");
      //return <Redirect push to={"/"}/>
      window.location.assign("/");
    }
  }

  render() {
    const {classes} = this.props;

    return (
      <Fragment>
        <TopAreaBlank/>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: !this.state.chatColumnOpen,
          })}
        >
          <div className={classes.appBarSpacer}/>
          <Typography variant="h4" gutterBottom component="h2">
            Bei Beratungsheld registrieren
          </Typography>
          {this.getMainFragment(this.state.step)}
        </main>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Registrieren);
