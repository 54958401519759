import React from "react";
import TableCell from "@material-ui/core/TableCell";
import NumberFormat from "react-number-format";
import {withStyles} from "@material-ui/core/styles";

const cellStyles = (theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 14,
  },
  body: {
    fontSize: 12,
  },
});

class CustomCell extends React.Component {
  render() {
    let {isCurrency, showCurrency, value, unit, ...other} = this.props;

    if (isCurrency) {
      if (showCurrency) {
        return (
          <TableCell {...other}>
            <NumberFormat
              value={value}
              displayType={"text"}
              decimalSeparator=","
              thousandSeparator="."
              allowNegative={true}
              fixedDecimalScale={true}
              suffix={" " + unit}
              decimalScale={2}
            />
          </TableCell>
        );
      } else {
        return (
          <TableCell {...other}>
            <NumberFormat
              value={value}
              displayType={"text"}
              decimalSeparator=","
              thousandSeparator="."
              allowNegative={true}
              fixedDecimalScale={true}
              decimalScale={2}
            />
          </TableCell>
        );
      }
    } else {
      return <TableCell {...other}>{value}</TableCell>;
    }
  }
}

export default withStyles(cellStyles)(CustomCell);
