const privaterFinanzBedarfSchritte = {
  1: {
    Input: [
      "PrivatGehaltLebenspartner",
      "PrivatKindergeld",
      "PrivatErziehungsgeld",
      "PrivatUnterhalt",
      "PrivatMietzins",
      "PrivateKapitalertraege",
      "PrivatSonstigeEinkuenfte"
    ],
    ReadOnly: ["PrivatEinnahmenInsgesamt"]
  },
  2: {
    Input: [
      "PrivatEinkommensteuerruecklage",
      "PrivatMiete",
      "PrivatKrankenversicherung",
      "PrivatAltersvorsorge",
      "PrivatUnfallversicherung",
      "PrivatArbeitslosenversicherung",
      "PrivatSonstigeVersicherungen",
      "PrivatKfz",
      "PrivatKommunikation",
      "PrivatBeitraege",
      "PrivaterTaeglicherBedarf",
      "PrivateRuecklagen",
      "PrivatKredite",
      "PrivatSonstiges"
    ],
    ReadOnly: [
      "PrivatAusgabenInsgesamt",
      "PrivatBenoetigtePrivatentnahme"
    ]
  },
};

export default privaterFinanzBedarfSchritte;
