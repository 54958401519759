import React from "react";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      label: {
        color: "#ffffff",
      },
      root: {
        borderRadius: 30,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 30,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 30,
      },
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          fontSize: "100%"
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#66A6AB',
      main: '#00656e',
      dark: '#014950',
      contrastText: "#ffffff"
      // contrastText: getContrastText(palette.primary[700]),
    },
    secondary: {
      light: '#E46590',
      main: '#d91b5b',
      dark: '#99284E',
      contrastText: "#ffffff",
      // contrastText: getContrastText(palette.secondary[700]),
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      "IBM Plex Sans",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif"
    ].join(",")
  }
});

// const theme = createMuiTheme({
//   palette: {
//     primary: {
//       // light: will be calculated from palette.primary.main,
//       main: '#007DE7',
//       // dark: will be calculated from palette.primary.main,
//       // contrastText: will be calculated to contrast with palette.primary.main
//     },
//     secondary: {
//       light: '#0066ff',
//       main: '#EC6E0A',
//       // dark: will be calculated from palette.secondary.main,
//       contrastText: '#ffcc00',
//     },
//     // Used by `getContrastText()` to maximize the contrast between
//     // the background and the text.
//     contrastThreshold: 3,
//     // Used by the functions below to shift a color's luminance by approximately
//     // two indexes within its tonal palette.
//     // E.g., shift from Red 500 to Red 300 or Red 700.
//     //tonalOffset: 0.2,
//   },
// });

function withRoot(Component) {
  function WithRoot(props) {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline/>
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
