import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import styles from "../styles";

import UnternehmensListe from "../Unternehmen/UnternehmenenListe";
import Funktionsleiste from "./Funktionsleiste";
import {AppContext} from "../AppContext";

class TopArea extends React.Component {
  static contextType = AppContext;

  state = {
    isLoaded: false,
    chatAssitentActivated: true,
    benutzerAnchorEl: null,
    activeStep: 0,
    items: {
      id: "",
      Vorname: "",
      Nachname: "",
      aktuellesUnternehmen: {unternehmenName: ""},
      alleUnternehmen: [],
    },
  };
  // static contextType = ChatColumnContext;

  handleBenutzerClick = (e) => {
    this.setState({benutzerAnchorEl: e.currentTarget});
  };

  handleBenutzerClose = () => {
    this.setState({benutzerAnchorEl: null});
  };

  toggleChatAssitent = () => {
    console.log("toggleChatAssitent aufgerufen")
    this.setState({
      chatAssitentActivated: !this.state.chatAssitentActivated
    });
  }

  logout = async () => {
    this.context.logOut().then(() => window.location.replace("/"));
    // this.context.logOut().then(() => window.location.reload());
  }

  componentDidMount() {
    // this.getDataFromApi();
    // this.getDataFromCockpitApi();
    this.context.refreshUnternehmenListe();
  }

  componentDidUpdate() {
    // this.getDataFromApi();
    // this.getDataFromCockpitApi();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.items.aktuellesUnternehmen._id !== nextProps.unternehmenId ||
      this.state.benutzerAnchorEl !== nextState.benutzerAnchorEl ||
      this.state.isLoaded !== nextState.isLoaded ||
      this.state.chatAssitentActivated !== nextState.chatAssitentActivated ||
      this.state.activeStep !== nextState.activeStep
    );
  }

  getDataFromCockpitApi = () => {
    //https://cockpit.beratungsheld.de/api:
    //Example-Query: /collections/get/Unternehmen?filter[userId]=60e85980e4788948b8291362&fields[unternehmenName]=1

    fetch(`${process.env.REACT_APP_NEWSAPI_URL}/collections/get/Unternehmen?filter[userId]=${this.context.userId}&fields[unternehmenName]=1`, {headers: {'Cockpit-Token': this.context.apiToken}})
      .then((res) => res.json())
      // .then((res) => console.log(res))
      .then(
        (result) => {
          console.log("RESULT:")
          console.log(result.entries)

          var unternehmen = result.entries.filter((value) => {
            return value._id === this.context.unternehmenId;
          });

          var unternehmensliste = [];
          result.entries.map((element, key) => {
            unternehmensliste = [
              ...unternehmensliste,
              {
                id: element._id,
                name: element.unternehmenName,
              },
            ];
          });


          this.setState({
            isLoaded: true,
            items: {
              aktuellesUnternehmen: unternehmen[0],
              alleUnternehmen: unternehmensliste,
            },
          });
        },
        (error) => {
          console.log(error)
          this.setState({
            isLoaded: false,
            error: error,
          });
        }
      );
  };

  getDataFromApi = async () => {
    // let result = await this.context.getUnternehmenDataFromApi("unternehmenName");
    let result = await this.context.getUnternehmenDataFromApi("base");

    this.setState(
      {
        items: result
      }
    );

    // fetch(`${process.env.REACT_APP_API_URL}/v1/userdata/${this.props.userId}`)
    //   .then((res) => res.json())
    //   .then(
    //     (result) => {
    //       var unternehmen = result.unternehmen.filter((value) => {
    //         return value._id == this.props.unternehmenId;
    //       });
    //
    //       var unternehmensliste = [];
    //       result.unternehmen.map((element, key) => {
    //         unternehmensliste = [
    //           ...unternehmensliste,
    //           {
    //             id: element._id,
    //             name: element.unternehmenName,
    //           },
    //         ];
    //       });
    //
    //       this.setState({
    //         isLoaded: true,
    //         items: {
    //           id: result._id,
    //           Vorname: result.Vorname,
    //           Nachname: result.Nachname,
    //           aktuellesUnternehmen: unternehmen[0],
    //           alleUnternehmen: unternehmensliste,
    //         },
    //       });
    //     },
    //     (error) => {
    //       this.setState({
    //         isLoaded: false,
    //         error: error,
    //       });
    //     }
    //   );
  };

  handleNext = () => {
    console.log("NEXT!")
    this.setState({
      activeStep: this.state.activeStep + 1
    })
  };

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1
    })
  };

  render() {
    const {classes} = this.props;
    const {benutzerAnchorEl} = this.state;

    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.appBar}>
          <div className={classes.logo}>
            <img src="/beratungsheld-logo.png" height={60}/>
          </div>

          <div className={classes.topMenu}>
            <Funktionsleiste
              userId={this.props.userId}
              unternehmenId={this.props.unternehmenId}
            />
          </div>

          <UnternehmensListe
            userId={this.props.userId}
            unternehmenId={this.props.unternehmenId}
            history={this.props.history}
          />
          {/* <Grid item>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              component={Link}
              to={"/"}
            >
              Abmelden
            </Button>

         </Grid> */}
          <ListItemIcon>
            <Fragment>
              <AccountCircleIcon
                fontSize="large"
                color="secondary"
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={this.handleBenutzerClick}
              />
              <Menu
                className={classes.profileMenu}
                elevation={2}
                getContentAnchorEl={null}
                anchorEl={benutzerAnchorEl}
                keepMounted
                open={Boolean(benutzerAnchorEl)}
                onClose={this.handleBenutzerClose}
              >
                <div>
                  {" "}
                  <MenuItem
                    className={classes.benutzerBereichInfo + " Mui-disabled"}
                  >
                    <ListItemIcon>
                      <PersonIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>
                      {this.context.firstname}{" "}
                      {this.context.lastname}
                    </ListItemText>
                  </MenuItem>
                  {/*<MenuItem*/}
                  {/*  className={classes.benutzerBereichInfo}*/}
                  {/*  component={Link}*/}
                  {/*  to={`/meinunternehmen`}*/}
                  {/*  onClick={this.handleBenutzerClose}*/}
                  {/*>*/}
                  {/*  <ListItemIcon>*/}
                  {/*    <BusinessIcon fontSize="small"/>*/}
                  {/*  </ListItemIcon>*/}
                  {/*  <ListItemText primary="Unternehmen verwalten"/>*/}
                  {/*</MenuItem>*/}
                  {/*<MenuItem*/}
                  {/*  className={classes.benutzerBereichInfo}*/}
                  {/*  component={Link}*/}
                  {/*  to={`/einstellungen`}*/}
                  {/*  onClick={this.handleBenutzerClose}*/}
                  {/*>*/}
                  {/*  <ListItemIcon>*/}
                  {/*    <SettingsIcon fontSize="small"/>*/}
                  {/*  </ListItemIcon>*/}
                  {/*  <ListItemText primary="Einstellungen"/>*/}
                  {/*</MenuItem>*/}
                  <MenuItem
                    className={classes.benutzerBereichInfo}
                    // component={Link}
                    onClick={this.logout}
                    to="/login"
                  >
                    <ListItemIcon>
                      <ExitToAppIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText primary="Abmelden"/>
                  </MenuItem>
                </div>
              </Menu>
            </Fragment>
          </ListItemIcon>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(TopArea);
