import React from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import styles from "../styles";
import {withStyles} from "@material-ui/styles";

class Weiterleitungsmodal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paragrapthText: "",
      topic: "",
      continueAnywayPossible: false,
      showModal: true,
    };
    // this.initializeModal();
  }

  handleModalClose = () => {
    if (this.props.modalScript === "Liquiditaet") {
      window.location.hash = `#/rentabilitaet`;
    } else if (this.props.modalScript === "KapitalUndFinanzierung") {
      window.location.hash = `#/kapitalundfinanzierung`;
    } else if (this.props.modalScript === "PrivaterFinanzbedarf") {
      window.location.hash = `#/privatfinanzen`;
    } else if (this.props.modalScript === "TypischerMonat") {
      window.location.hash = `#/typischermonat`;
    } else if (this.props.modalScript === "SepezifischerMonat") {
      window.location.hash = `#/spezifischermonat`;
    } else if (this.props.modalScript === "Textteil") {
      window.location.hash = `#/textteil`;
    } else if (this.props.modalScript === "BwaDaten") {
      window.location.hash = `#/finanzteil`;
    } else if (this.props.modalScript === "Steckbrief") {
      window.location.hash = `#/steckbrieftext`;
    } else if (this.props.modalScript === "SteckbriefUnpublished") {
      window.location.hash = `#/login`;
    }
  };

  handleModalCloseAndContinueAnyway = () => {
    this.setState({
      showModal: false,
    })
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.modalScript === "Liquiditaet" &&
      this.state.topic !== "Liquiditätsplanung"
    ) {
      this.setState({
        topic: "Liquiditätsplanung",
        paragrapthText:
          "Für die weitere Planung ist es wichtig, welches Jahr dein " +
          "Rumpfgeschäftsjahr ist. Dies kannst du in der Rentabilitätsplanung " +
          "festlegen.",
      });
    } else if (
      nextProps.modalScript === "KapitalUndFinanzierung" &&
      this.state.topic !== "Kapital & Finanzierung"
    ) {
      this.setState({
        topic: "Kapital & Finanzierung",
        paragrapthText:
          "Um einen grundlegenden Businessplan anzuzeigen, brauchen wir Angaben zum Kapitalbedarf und Finanzierung deines Unternehmens.",
      });
    } else if (
      nextProps.modalScript === "PrivaterFinanzbedarf" &&
      this.state.topic !== "privater Finanzbedarf"
    ) {
      this.setState({
        topic: "privater Finanzbedarf",
        paragrapthText:
          "Um einen grundlegenden Businessplan anzuzeigen, brauchen wir Angaben zu deinem privaten Finanzbedarf.",
      });
    } else if (
      nextProps.modalScript === "TypischerMonat" &&
      this.state.topic !== "typischer Monat"
    ) {
      this.setState({
        topic: "typischer Monat",
        paragrapthText:
          "Um einen grundlegenden Businessplan anzuzeigen, ist es wichtig die Daten für einen typischen Monat festzulegen.",
      });
    } else if (
      nextProps.modalScript === "SepezifischerMonat" &&
      this.state.topic !== "Grundungsmonat wählen"
    ) {
      this.setState({
        topic: "Grundungsmonat wählen",
        paragrapthText:
          "Um einen Businessplan zu generieren, ist es wichtig das Gründungsdatum (Monat und Jahr) zu bestimmen.",
      });
    } else if (
      nextProps.modalScript === "Textteil" &&
      this.state.topic !== "Textteil eingeben"
    ) {
      this.setState({
        topic: "Textteil eingeben",
        paragrapthText:
          "Um einen Businessplan zu generieren, fehlen noch die Eingaben für den Textteil.",
      });
    } else if (
      nextProps.modalScript === "BwaDaten" &&
      this.state.topic !== "Finanzteil berechnen"
    ) {
      this.setState({
        topic: "Finanzteil berechnen",
        paragrapthText:
          "Um einen Businessplan zu generieren, muss noch der Finanzteil berechnet werden oder die BWA für die entsprechenden Jahre unter Geschäftsdaten geplant werden (SOLL).",
        continueAnywayPossible: true,
      });
    } else if (
      nextProps.modalScript === "SteckbriefUnpublished" &&
      this.state.topic !== "Steckbrief Freigabe"
    ) {
      this.setState({
        topic: "Steckbrief Freigabe",
        paragrapthText:
          "Dieser Steckbrief ist nicht öffentlich. Bitte loggen Sie sich ein, wenn Sie eine Zugriffsberechtigung haben.",
      });
    } else if (
      nextProps.modalScript === "Steckbrief" &&
      this.state.topic !== "Steckbrief anlegen"
    ) {
      this.setState({
        topic: "Steckbrief anlegen",
        paragrapthText:
          "Bitte erst einen Steckbrief anlegen.",
      });
    }
  };

  render() {
    let {paragrapthText, topic, showModal} = this.state;
    let {continueAnywayPossible} = this.props;

    if (showModal) {
      return (
        <Modal
          aria-labelledby="rumpfjahr-modal-title"
          aria-describedby="rumpfjahr-modal-description"
          open={this.props.weiterleitungsModalOpen}
          onClose={this.handleModalClose}
        >
          <div className={this.props.classes.modal}>
            <h2 id="rumpfjahr-modal-title">{`Fehlende Angaben im Bereich "${topic}"`}</h2>
            <p id="rumpfjahr-modal-description">{paragrapthText}</p>
            <Button
              disabled={!(continueAnywayPossible)}
              variant="contained"
              color="secondary"
              className={this.props.classes.button}
              onClick={this.handleModalCloseAndContinueAnyway}
            >
              {`Trotzdem fortfahren`}
            </Button>
            <Button
              disabled={false}
              variant="contained"
              color="primary"
              className={this.props.classes.button}
              onClick={this.handleModalClose}
            >
              {`Gehe zum Bereich "${topic}"`}
            </Button>
          </div>
        </Modal>
      )
    } else {
      return (<div></div>)
    }
  }
}

export default withStyles(styles)(Weiterleitungsmodal);
