import Typography from "@material-ui/core/Typography/Typography";
import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import styles from "../styles";

class Einstellungen extends React.Component {
  state = {
    isLoaded: false,
    items: {
      id: "Test-ID",
      Vorname: "Test-Vorname",
      Nachname: "Test-Nachname",
      TelefonNummer: "+492222",
      EmailAdresse: "Test-Email",
      Test2: "Test2",
    },
  };

  componentDidMount() {
    this.getDataFromApi();
  }

  getDataFromApi = () => {
    fetch(`${process.env.REACT_APP_API_URL}/v1/userdata/${this.props.userId}`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error: error,
          });
        }
      );
  };

  handleChange = (name) => ({target: {value}}) => {
    this.setState({
      items: {
        ...this.state.items,
        [name]: value,
      },
    });
  };

  handleSubmit = () => {
    // TODO: Validate

    const {items} = this.state;

    fetch(`${process.env.REACT_APP_API_URL}/v1/userdata/${this.props.userId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: items["id"],
        Vorname: items["Vorname"],
        Nachname: items["Nachname"],
        TelefonNummer: "Hello",
        EmailAdresse: items["EmailAdresse"],
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error: error,
          });
        }
      );
    console.log(this.state);
  };

  render() {
    const {classes} = this.props;
    const {isLoaded, items} = this.state;

    return (
      <Fragment>
        <div className={classes.appBarSpacer}/>
        <Typography variant="h4" gutterBottom component="h2">
          Einstellungen
        </Typography>
        {isLoaded ? (
          <Fragment>
            <p>Huhu!</p>
          </Fragment>
        ) : (
          <Typography variant="h5" gutterBottom component="h2">
            <CircularProgress className={classes.progress}/>
          </Typography>
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(Einstellungen);
