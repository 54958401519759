import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import {withStyles} from "@material-ui/core/styles";
import {HashRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import withRoot from "../withRoot";

import ViewArea from "./ViewArea";
import styles from "./styles";
import Login from "./Authentifizierung/Login/Login";
import Registrieren from "./Benutzerbereich/Registrieren";
import {AppContext} from "./AppContext";
import ReactDOMServer from 'react-dom/server';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/ibm-plex-sans/300.css';
import '@fontsource/ibm-plex-sans/400.css';
import '@fontsource/ibm-plex-sans/500.css';
import '@fontsource/ibm-plex-sans/700.css';
import SteckbriefShowPublic from "./Public/SteckbriefShowPublic";
import SteckbriefBoardPublic from "./Public/SteckbriefBoardPublic";
import {MobileOnlyView} from "react-device-detect";


class Gui extends React.Component {
  constructor(props) {
    super(props);
    // this.chatScrollRef = React.createRef();

    this.logInByToken = (token) => {
      this.loginByToken(token);
    }
    this.logIn = (user, password) => {
      this.loginByUsernameAndPasswort(user, password);
    };
    this.logOut = async () => {
      await this.logout();
    };
    this.changeUnternehmen = (id, name) => {
      this.setState({
        unternehmenId: id,
        unternehmenName: name,
        feld: {
          "kurzName": "Gewähltes Feld",
        },
        schritt: {
          key: 0,
          "kurzName": "",
          continuePossible: false,
          backPossible: false,
          savePossible: true,
        },
        funktion: {
          key: 0,
          "kurzName": "Start",
          continuePossible: false,
          backPossible: false,
        },
        bereich: {
          name: "Businessplan"
        },
        messages: [],
        previousFunktion: 0,
        previousSchritt: 0,
      }, () => {
        this.getChatDataFromCockpitApi()
      });
    }

    this.getUnternehmenDataFromApi = async (domain) => {
      let result = await this.getUnternehmenDataFromCockpitApi(domain);
      // console.log( result);
      return result;

      // .then( (result) => console.log(result), (error) => console.log("error!"));
    }

    this.postUnternehmenDataToApi = async (domain, data) => {
      let result = await this.postUnternehmenDataToCockpitApi(domain, data);
      // console.log(await result);
      return result;

      // .then( (result) => console.log(result), (error) => console.log("error!"));
    }

    this.refreshUnternehmenListe = async () => {
      fetch(`${process.env.REACT_APP_NEWSAPI_URL}/collections/get/Unternehmen?filter[userId]=${this.state.userId}&fields[unternehmenName]=1`, {headers: {'Cockpit-Token': this.state.apiToken}})
        .then((res) => res.json())
        // .then((res) => console.log(res))
        .then(
          (result) => {
            if (result.total === 0) {
              this.setState({
                unternehmenName: "Noch nicht eingegeben...",
                unternehmenListe: [
                  // {
                  //   id: null,
                  //   name: "Noch nicht eingegeben...",
                  // }
                ],
              });
            } else {
              const unternehmen = result.entries.filter((value) => {
                return value._id === this.state.unternehmenId;
              });

              let unternehmensliste = [];
              result.entries.map((element, key) => {
                unternehmensliste = [
                  ...unternehmensliste,
                  {
                    id: element._id,
                    name: element.unternehmenName,
                  },
                ];
              });

              console.log(unternehmen);

              this.setState({
                unternehmenName: unternehmen[0].unternehmenName,
                unternehmenListe: unternehmensliste,
              });

            }
          },
          (error) => {
            console.log(error)
            this.setState({
              isLoaded: false,
              error: error,
            });
          }
        );
    };

    this.getOtherUnternehmenData = async (id) => {
      console.log("getOtherUnternehmenData: " + id);
      let response = await fetch(`${process.env.REACT_APP_NEWSAPI_URL}/collections/get/Unternehmen?filter[_id]=${id}&fields[unternehmenName]=1&fields[rumpfmonat]=1&fields[rumpfjahr]=1&fields[unternehmenData]=1&limit=1`, {headers: {'Cockpit-Token': this.state.apiToken}});
      console.log(response);
      let json = await response.json();
      console.log("getOtherUnternehmenData: json.entries[0]");
      console.log(json.entries[0]);
      // this.setState({
      //   unternehmenData: json.entries[0]
      // });
      console.log("getOtherUnternehmenData: returning");
      return json.entries[0];
    };

    this.postOtherUnternehmenData = async (id, name, data, rumpfjahr, rumpfmonat, image) => {
      console.log("postOtherUnternehmenData: " + id);
      var response = null;
      if (id === null) {
        response = await fetch(
          `${process.env.REACT_APP_NEWSAPI_URL}/collections/save/Unternehmen`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Cockpit-Token': this.state.apiToken,
            },
            body: JSON.stringify({
              data: {
                unternehmenName: name,
                unternehmenData: data,
                userId: [this.state.userId],
                rumpfjahr: rumpfjahr,
                rumpfmonat: rumpfmonat,
                image: image,
              },
              revision: true
            }),
          });
      } else {
        response = await fetch(
          `${process.env.REACT_APP_NEWSAPI_URL}/collections/save/Unternehmen`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Cockpit-Token': this.state.apiToken,
            },
            body: JSON.stringify({
              data: {
                unternehmenName: name,
                unternehmenData: data,
                _id: id,
                userId: [this.state.userId],
                rumpfjahr: rumpfjahr,
                rumpfmonat: rumpfmonat,
                image: image,
              },
              revision: true
            }),
          });
      }
      //   {headers: {'Cockpit-Token': this.state.apiToken}})
      console.log(response);
      let json = await response.json();
      console.log("postOtherUnternehmenData: json");
      console.log(json);
      console.log("postOtherUnternehmenData: returning");
      return json;
      //
      //
      //
      //
      // let response2 = await fetch(`${process.env.REACT_APP_NEWSAPI_URL}/collections/get/Unternehmen?filter[_id]=${id}&fields[unternehmenName]=1&fields[rumpfmonat]=1&fields[rumpfjahr]=1&fields[unternehmenData]=1&limit=1`, {headers: {'Cockpit-Token': this.state.apiToken}});
      // console.log(response);
      // let json2 = await response.json();
      // console.log("getOtherUnternehmenData: json.entries[0]");
      // console.log(json.entries[0]);
      // // this.setState({
      // //   unternehmenData: json.entries[0]
      // // });
      // console.log("getOtherUnternehmenData: returning");
      // return json.entries[0];
    };

    this.saveStandardunternehmen = async (unternehmenid, unternehmenname) => {

      fetch(`${process.env.REACT_APP_NEWSAPI_URL}/cockpit/saveUser`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Cockpit-Token': this.state.apiToken,
        },
        body: JSON.stringify({
          "user": {
            "_id": this.state.userId,
            "standardUnternehmen": {
              "_id": unternehmenid,
              "link": "Unternehmen",
              "display": unternehmenname
            }
          },
        }),
      })
      // .then((res) => res.json())
      // // .then((res) => console.log(res))
      // .then(
      //   (result) => {
      //     // console.log(result.entries)
      //     // var unternehmen = result.unternehmen.filter((value) => {
      //     //   return value._id == this.props.unternehmenId;
      //     // });
      //
      //     if (result.error) {
      //       console.log(result.error);
      //       this.handleApiError(result.error);
      //     } else {
      //       this.setState({
      //         isLoaded: true,
      //         result: result,
      //         step: 2,
      //         error: ""
      //       });
      //       localStorage.setItem("Beratungsheld-Token", result.api_key);
      //     }
      //   },
      //   (error) => {
      //     console.log(error)
      //     this.setState({
      //       isLoaded: false,
      //       error: error,
      //     });
      //   }
      // );
    };

    this.toggleChatColumn = () => {
      this.setState(state => ({
        chatColumnOpen: !this.state.chatColumnOpen
      }));
    };

    this.toggleChatSend = () => {
      this.setState(state => ({
        chatSendOpen: !this.state.chatSendOpen
      }));
    };

    this.toggleChatMenu = () => {
      this.setState(state => ({
        chatMenuOpen: !this.state.chatMenuOpen
      }));
    };

    this.toggleChatFunctions = () => {
      this.setState(state => ({
        chatFunctionsOpen: !this.state.chatFunctionsOpen
      }));
    };

    this.getFeldDataFromCockpitApi = (key) => {
      fetch(`${process.env.REACT_APP_NEWSAPI_URL}/collections/get/ErklaerungsTexte?filter[key]=${key}&sort[_modified]=-1`, {headers: {'Cockpit-Token': this.state.apiToken}})
        .then((res) => res.json())
        // .then((res) => console.log(res))
        .then(
          (result) => {
            // console.log(result.entries)
            // var unternehmen = result.unternehmen.filter((value) => {
            //   return value._id == this.props.unternehmenId;
            // });
            this.setState({
              isLoaded: true,
              feld: result.entries[0],
            });
          },
          (error) => {
            console.log(error)
            this.setState({
              isLoaded: false,
              error: error,
            });
          }
        );
    }

    this.returnFeldDataFromCockpitApi = async (key) => {
      fetch(`${process.env.REACT_APP_NEWSAPI_URL}/collections/get/ErklaerungsTexte?filter[key]=${key}&sort[_modified]=-1`, {headers: {'Cockpit-Token': this.state.apiToken}})
        .then((res) => res.json())
        // .then((res) => console.log(res))
        .then(
          (result) => {
            // console.log(result.entries)
            // var unternehmen = result.unternehmen.filter((value) => {
            //   return value._id == this.props.unternehmenId;
            // });
            // console.log(key);
            return "Test"
            // if(result.entries[0]) {
            //   return (result.entries[0]["kurzName"] || "Fehler")
            // } else {
            //   return "Fehler"
            // }

          },
          (error) => {
            console.log(error)
            return "Fehler"
            // return {key: "error", kurzName: "", erklaerungHeader: ""}
          }
        );
    }

    this.getChatDataFromCockpitApi = async () => {
      // fetch(`${process.env.REACT_APP_NEWSAPI_URL}/collections/get/Chats?filter[unternehmenId._id]=${this.state.unternehmenId}&sort[_modified]=-1&limit=20`, {headers: {'Cockpit-Token': this.state.apiToken}})
      // https://cockpit.beratungsheld.de/api/collections/get/Chats?filter[unternehmenId._id]=61041871e4788907334a4a73&filter[author]=bot&filter[message][$regex]=gespeichert&sort[_modified]=-1&limit=20
      let result = await fetch(`${process.env.REACT_APP_NEWSAPI_URL}/collections/get/Chats?filter[unternehmenId._id]=${this.state.unternehmenId}&filter[type]=functionCompleted&sort[_modified]=-1&limit=6`, {headers: {'Cockpit-Token': this.state.apiToken}})
        .then((res) => res.json())
        // .then((res) => console.log(res))
        .then(
          (result) => {
            // console.log("DINGS")
            // console.log(result.entries.reverse())
            // var unternehmen = result.unternehmen.filter((value) => {
            //   return value._id == this.props.unternehmenId;
            // });
            // result.map
            // message
            //   .replace('Alles klar, deine Eingaben für <em>','')
            //   .replace('</em> habe ich gespeichert! Lass uns mit vielleicht mit einem anderen Zeitraum weitermachen...', '')

            // this.setState({
            //   messages: result.entries//.reverse(),
            // }, () => this.scrollToBottom());
            return result.entries;
          },
          (error) => {
            console.log(error)
            // this.setState({
            //   error: error,
            // });
          }
        );
      return result;
    }

    this.getChatDoneDataFromCockpitApi = () => {
      fetch(`${process.env.REACT_APP_NEWSAPI_URL}/collections/get/Chats?filter[unternehmenId._id]=${this.state.unternehmenId}&sort[_modified]=-1&limit=20`, {headers: {'Cockpit-Token': this.state.apiToken}})
        .then((res) => res.json())
        // .then((res) => console.log(res))
        .then(
          (result) => {
            // console.log(result.entries)
            // var unternehmen = result.unternehmen.filter((value) => {
            //   return value._id == this.props.unternehmenId;
            // });
            this.setState({
              messages: result.entries//.reverse(),
            }, () => this.scrollToBottom());
          },
          (error) => {
            console.log(error)
            // this.setState({
            //   error: error,
            // });
          }
        );
    }

    this.setFeld = (feld) => {
      this.getFeldDataFromCockpitApi(feld.key);
      // this.setState(state => ({
      //   feld
      // }));
    };

    this.setSchritt = (schritt) => {
      this.setState(state => ({
        schritt
      }));
    };

    this.setFunktion = (funktion) => {
      this.setState(state => ({
        funktion
      }));
    };

    this.setBereich = (bereich) => {
      this.setState(state => ({
        bereich
      }));
    };

    this.setPreviousSchritt = (previousSchritt) => {
      this.setState(state => ({
        previousSchritt: previousSchritt,
      }));
    };

    this.setPreviousFunktion = (previousFunktion) => {
      this.setState(state => ({
        previousFunktion: previousFunktion
      }));
    };

    this.postMessage = async (message, author, field, type) => {
      // if (author === "user") {
      //   this.setState({
      //     messages: []
      //   })
      // }

      let result = this.postChatDataToCockpitApi(message, author, type)
      console.log(await result);

      if (author === "user") {
        this.answerMessage(field, type)
      }

      return result;
    }

    this.clearMessages = () => {
      this.setState({
        messages: []
      })
    }

    this.setSavePossible = (value) => {
      this.setState({
        savePossible: value
      })
    }

    // this.postMessage = (message, author, field, type) => {
    // fetch(
    //   `${process.env.REACT_APP_API_URL}/v1/chatdata/${this.props.match.params.userId}/${this.props.match.params.unternehmenId}`,
    //   {
    //     method: "POST",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //
    //     body: JSON.stringify(
    //       {
    //         message: message,
    //         author: author
    //       }
    //     ),
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((data) => {
    //     if (author === "user") {
    //       this.answerMessage(field, type)
    //     }
    //
    //     this.setState({
    //       messages: [
    //         ...this.state.messages,
    //         data
    //       ]
    //     }, () => this.scrollToBottom());
    //   })
    //   .catch((err) => console.log(err));
    // };

    this.state = {
      //AuthContext
      apiToken: false,
      isLoggedIn: false,
      userId: "",
      unternehmenId: "",
      unternehmenName: "",
      firstname: "",
      lastname: "",
      unternehmenListe: [],
      noUnternehmenRedirect: false,
      error: false,
      logIn: this.logIn,
      logOut: this.logOut,
      logInByToken: this.loginByToken,
      changeUnternehmen: this.changeUnternehmen,
      getUnternehmenDataFromApi: this.getUnternehmenDataFromApi,
      postUnternehmenDataToApi: this.postUnternehmenDataToApi,
      refreshUnternehmenListe: this.refreshUnternehmenListe,
      getOtherUnternehmenData: this.getOtherUnternehmenData,
      postOtherUnternehmenData: this.postOtherUnternehmenData,
      saveStandardunternehmen: this.saveStandardunternehmen,
      unternehmenData: {},

      //ChatContext
      chatColumnOpen: true,
      toggleChatColumn: this.toggleChatColumn,
      chatSendOpen: true,
      toggleChatSend: this.toggleChatSend,
      chatMenuOpen: false,
      toggleChatMenu: this.toggleChatMenu,
      chatFunctionsOpen: false,
      toggleChatFunctions: this.toggleChatFunctions,
      savePossible: true,
      setSavePossible: this.setSavePossible,
      feld: {
        "kurzName": "Gewähltes Feld",
      },
      setFeld: this.setFeld,
      schritt: {
        key: 0,
        "kurzName": "",
        continuePossible: false,
        backPossible: false,

      },
      setSchritt: this.setSchritt,
      funktion: {
        key: 0,
        "kurzName": "Start",
        continuePossible: false,
        backPossible: false,
      },
      setFunktion: this.setFunktion,
      bereich: {
        name: "Businessplan"
      },
      setBereich: this.setBereich,
      messages: [],
      postMessage: this.postMessage,
      clearMessages: this.clearMessages,
      previousFunktion: 0,
      setPreviousFunktion: this.setPreviousFunktion,
      previousSchritt: 0,
      setPreviousSchritt: this.setPreviousSchritt,
      getChatDataFromCockpitApi: this.getChatDataFromCockpitApi,
      returnFeldDataFromCockpitApi: this.returnFeldDataFromCockpitApi,
    }
  };

  logout = async () => {
    this.setState({
      apiToken: false,
      isLoggedIn: false,
      userId: "",
      unternehmenId: "",
      unternehmenName: "",
      firstname: "",
      lastname: "",
    }, () => localStorage.removeItem("Beratungsheld-Token"));
    // window.parent.location = "http://localhost:3000/";

  }

  scrollToBottom = () => {
    // this.chatScrollRef.current.scrollIntoView({behavior: 'smooth'})
  }

  postChatDataToCockpitApi = async (message, author, type) => {
    console.log("postChatDataToCockpitApi: " + author);
    let response = await fetch(
      `${process.env.REACT_APP_NEWSAPI_URL}/collections/save/Chats`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Cockpit-Token': this.state.apiToken,
        },
        body: JSON.stringify({
          data: {
            message: message,
            author: author,
            unternehmenId: {
              _id: this.state.unternehmenId,
              link: "Unternehmen"
            },
            type: type,
            target: {
              area: this.state.bereich.name,
              function: this.state.funktion.name,
              month: this.state.funktion.month,
              year: this.state.funktion.year
            }
          }
        }),
      });

    //   {headers: {'Cockpit-Token': this.state.apiToken}})
    console.log(response);
    let json = await response.json();
    console.log("postChatDataToCockpitApi: json");
    console.log(json);


    // fetch(`${process.env.REACT_APP_NEWSAPI_URL}/collections/get/Chats?filter[unternehmenId._id]=${this.state.unternehmenId}&sort[_modified]=1&limit=20`, {headers: {'Cockpit-Token': this.state.apiToken}})
    //   .then((res) => res.json())
    //   // .then((res) => console.log(res))
    //   .then(
    //     (result) => {
    //       // console.log(result.entries)
    //       // var unternehmen = result.unternehmen.filter((value) => {
    //       //   return value._id == this.props.unternehmenId;
    //       // });
    //       this.setState({
    //         messages: result.entries,
    //       }, () => this.scrollToBottom());
    //     },
    //     (error) => {
    //       console.log(error)
    //       // this.setState({
    //       //   error: error,
    //       // });
    //     }
    //   );


    this.setState({
      messages: [
        json,
        ...this.state.messages
      ]
    }, () => this.scrollToBottom());

    console.log("postChatDataToCockpitApi: returning");
    return json;
  }

  answerMessage = (field, type) => {
    // const icons = [
    //   <DateRangeIcon className={this.props.classes.chatIcon}/>,
    //   <FunctionsIcon className={this.props.classes.chatIcon}/>,
    //   <TrendingUpIcon className={this.props.classes.chatIcon}/>,
    //   <NoteAddIcon className={this.props.classes.chatIcon}/>,
    // ]
    if (field.kurzName === "Gewähltes Feld") {
      this.postMessage('Sobald du ein Eingabefeld anklickst, ändert sich unten das blaue Feld und zeigt den Namen des angeklickten Feldes an. Dann kann ich dir zu diesem Feld Informationen und Tipps geben.', "bot", null, "error");
    } else if (type === "info") {
      if (field.erklaerungText !== "" && field.erklaerungText !== null) {
        this.postMessage('Unter "' + field.erklaerungHeader + '" versteht man: '
          + ReactDOMServer.renderToString(field.erklaerungText), "bot", field, "info");
      } else {
        this.postMessage('Zu "' + field.erklaerungHeader + '" habe ich leider noch keine Informationen...', "bot");
      }
    } else if (type === "tipp") {
      if (field.erklaerungTipp !== "" && field.erklaerungTipp !== null) {
        this.postMessage(field.erklaerungTipp, "bot")
      } else {
        this.postMessage('Zu "' + field.erklaerungHeader + '" habe ich leider gerade keinen Tipp...', "bot")
      }
      // } else if (type === "functions") {
      //   this.postMessage('Für "' + field.erklaerungHeader + '" habe ich folgende Funktionen: ' + ReactDOMServer.renderToString(
      //     ['Zahlweise festlegen', 'Summen berechnen', 'Wachstum festlegen', 'Feld auf Merkzettel packen'].map((text, index) => (
      //       <ListItem button key={text}>
      //         <ListItemIcon>{icons[index]}</ListItemIcon>
      //         <ListItemText primary={text}/>
      //       </ListItem>
      //     ))
      //   ), "bot")
    }
  }

  postUnternehmenDataToCockpitApi = async (domain, data) => {
    console.log("postUnternehmenDataToCockpitApi: " + domain);
    let response = await fetch(
      `${process.env.REACT_APP_NEWSAPI_URL}/collections/save/Unternehmen`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Cockpit-Token': this.state.apiToken,
        },
        body: JSON.stringify({
          data: {
            [domain]: data,
            _id: this.state.unternehmenId
          },
          revision: true
        }),
      });

    //   {headers: {'Cockpit-Token': this.state.apiToken}})
    console.log(response);
    let json = await response.json();
    console.log("postUnternehmenDataToCockpitApi: json");
    console.log(json);
    console.log("postUnternehmenDataToCockpitApi: returning");
    return json;
  }

  getUnternehmenDataFromCockpitApi = async (domain) => {
    //https://cockpit.beratungsheld.de/api:
    //Example-Query: /collections/get/Unternehmen?filter[userId]=60e85980e4788948b8291362&fields[unternehmenName]=1
    if (domain === "all") {
      console.log("getUnternehmenDataFromCockpitApi: all");
      let response = await fetch(`${process.env.REACT_APP_NEWSAPI_URL}/collections/get/Unternehmen?filter[_id]=${this.state.unternehmenId}&limit=1`, {headers: {'Cockpit-Token': this.state.apiToken}})
      console.log(response);
      let json = await response.json();
      console.log("getUnternehmenDataFromCockpitApi: json.entries");
      console.log(json.entries);
      this.setState({
        unternehmenData: json.entries[0]
      });
      console.log("getUnternehmenDataFromCockpitApi: returning");
      return json.entries[0];
    } else if (domain === "base") {
      console.log("getUnternehmenDataFromCockpitApi2: base");
      let response = await fetch(`${process.env.REACT_APP_NEWSAPI_URL}/collections/get/Unternehmen?filter[_id]=${this.state.unternehmenId}&fields[unternehmenName]=1&fields[rumpfmonat]=1&fields[rumpfjahr]=1&limit=1`, {headers: {'Cockpit-Token': this.state.apiToken}});
      console.log(response);
      let json = await response.json();
      console.log("getUnternehmenDataFromCockpitApi: json.entries");
      console.log(json.entries);
      this.setState({
        unternehmenData: json.entries[0]
      });
      console.log("getUnternehmenDataFromCockpitApi: returning");
      return json.entries[0];
    } else if (domain === "unternehmenDataWithImage") {
      console.log("getUnternehmenDataFromCockpitApi2: unternehmenDataWithImage");
      let response = await fetch(`${process.env.REACT_APP_NEWSAPI_URL}/collections/get/Unternehmen?filter[_id]=${this.state.unternehmenId}&fields[unternehmenData]=1&fields[unternehmenName]=1&fields[rumpfmonat]=1&fields[rumpfjahr]=1&fields[image]=1&limit=1`, {headers: {'Cockpit-Token': this.state.apiToken}});
      console.log(response);
      let json = await response.json();
      console.log("getUnternehmenDataFromCockpitApi: json.entries");
      console.log(json.entries);
      this.setState({
        unternehmenData: json.entries[0]
      });
      console.log("getUnternehmenDataFromCockpitApi: returning");
      return json.entries[0];
    } else {
      console.log("getUnternehmenDataFromCockpitApi2: " + domain);
      let response = await fetch(`${process.env.REACT_APP_NEWSAPI_URL}/collections/get/Unternehmen?filter[_id]=${this.state.unternehmenId}&fields[${domain}]=1&fields[unternehmenName]=1&fields[rumpfmonat]=1&fields[rumpfjahr]=1&limit=1`, {headers: {'Cockpit-Token': this.state.apiToken}});
      console.log(response);
      let json = await response.json();
      console.log("getUnternehmenDataFromCockpitApi: json.entries");
      console.log(json.entries);
      this.setState({
        unternehmenData: json.entries[0]
      });
      console.log("getUnternehmenDataFromCockpitApi: returning");
      return json.entries[0];

      // .then((res) => res.json())
      // // .then((res) => console.log(res))
      // .then(
      //   (result) => {
      //     console.log("getUnternehmenDataFromCockpitApi2:")
      //     console.log(result.entries)
      //
      //     this.setState({
      //       [domain]: result.entries[0][domain]
      //     });
      //
      //     console.log("getUnternehmenDataFromCockpitApi2: returning")
      //     return Promise.resolve(result.entries[0]);
      //   },
      //   (error) => {
      //     console.log(error)
      //     // this.setState({
      //     //   apiToken: false,
      //     //   isLoggedIn: false,
      //     //   userId: "",
      //     //   unternehmenId: "",
      //     //   firstname: "",
      //     //   lastname: "",
      //     //   error: "Fehler beim Login. Bitte an den technischen Support (it@beratungsheld.de) wenden.",
      //     // });
      //     throw error;
      //   }
      // );
    }
  };

  getDataFromCockpitApi = (api_key) => {
    //https://cockpit.beratungsheld.de/api:
    //Example-Query: /collections/get/Unternehmen?filter[userId]=60e85980e4788948b8291362&fields[unternehmenName]=1

    fetch(`${process.env.REACT_APP_NEWSAPI_URL}/collections/get/Unternehmen?filter[userId]=${this.state.userId}&fields[unternehmenName]=1&limit=1`, {headers: {'Cockpit-Token': this.state.apiToken}})
      .then((res) => res.json())
      // .then((res) => console.log(res))
      .then(
        (result) => {
          console.log("RESULT:")
          console.log(result.entries)

          this.setState({
            isLoggedIn: true,
            unternehmenId: result.entries[0]._id,
            unternehmenName: result.entries[0].unternehmenName,
          });
          localStorage.setItem("Beratungsheld-Token", api_key);
        },
        (error) => {
          console.log(error)
          this.setState({
            apiToken: false,
            isLoggedIn: false,
            userId: "",
            unternehmenId: "",
            unternehmenName: "",
            firstname: "",
            lastname: "",
            error: "Fehler beim Login. Bitte an den technischen Support (it@beratungsheld.de) wenden.",
          });
        }
      );
  };

  loginByToken = (token) => {
    fetch(`${process.env.REACT_APP_NEWSAPI_URL}/cockpit/listUsers?filter[api_key]=${token}&limit=1`, {headers: {'Cockpit-Token': "7b17fe54540f9fee3aba74504e23c3"}})
      .then((res) => res.json())
      // .then((res) => console.log(res))
      .then(
        (result) => {
          // console.log("loginByToken:")
          // console.log(result)

          if (result.length > 0) {
            if (result[0].standardUnternehmen) {
              this.setState({
                apiToken: token,
                isLoggedIn: true,
                userId: result[0]._id,
                firstname: result[0].firstname,
                lastname: result[0].lastname,
                unternehmenId: result[0].standardUnternehmen._id,
                unternehmenName: result[0].standardUnternehmen.display,
                error: false,
              });
              localStorage.setItem("Beratungsheld-Token", token);
            } else {
              // console.log("DA!")
              // console.log(result)
              this.setState({
                  apiToken: token,
                  isLoggedIn: true,
                  userId: result[0]._id,
                  firstname: result[0].firstname,
                  lastname: result[0].lastname,
                  unternehmenId: null,
                  // noUnternehmenRedirect: true,
                  error: false,
                  // }, () => {
                  //   this.getDataFromCockpitApi(token);
                  // }//, () => { this.props.history.replace("/ersteschritte") }
                }, () => {
                  window.location.assign("/#/ersteschritte");
                }
              );
            }
          }
        },
        (error) => {
          console.log(error)
          this.setState({
            apiToken: false,
            isLoggedIn: false,
            userId: "",
            unternehmenId: "",
            firstname: "",
            lastname: "",
            error: "Fehler beim Login. Bitte an den technischen Support (it@beratungsheld.de) wenden.",
          });
        }
      );
  };

  loginByUsernameAndPasswort = (user, password) => {
    fetch(`${process.env.REACT_APP_NEWSAPI_URL}/cockpit/authUser`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Cockpit-Token': 'f0dce0096d516d74a5f33499b30f49',
      },
      body: JSON.stringify({
        "user": user,
        "password": password,
      }),
    })
      .then((res) => res.json())
      // .then((res) => console.log(res))
      .then(
        (result) => {
          // console.log(result.entries)
          // var unternehmen = result.unternehmen.filter((value) => {
          //   return value._id == this.props.unternehmenId;
          // });

          if (result.error) {
            console.log(result.error);
            this.setState({
              apiToken: false,
              isLoggedIn: false,
              userId: "",
              unternehmenId: "",
              firstname: "",
              lastname: "",
              error: "Fehler beim Login. Sind der Benutzername und das Passwort korrekt?",
            });
            localStorage.removeItem("Beratungsheld-Token");
          } else {
            localStorage.setItem("Beratungsheld-Token", result.api_key);
            if (result.standardUnternehmen) {
              // console.log("HIER!")
              // console.log(result)
              this.setState({
                apiToken: result.api_key,
                isLoggedIn: true,
                userId: result._id,
                firstname: result.firstname,
                lastname: result.lastname,
                unternehmenId: result.standardUnternehmen._id,
                unternehmenName: result.standardUnternehmen.display,
                error: false,
              });
              // localStorage.setItem("Beratungsheld-Token", result.api_key);
            } else {
              // console.log("DA!")
              // console.log(result)
              this.setState({
                  apiToken: result.api_key,
                  isLoggedIn: true,
                  userId: result._id,
                  firstname: result.firstname,
                  lastname: result.lastname,
                  unternehmenId: null,
                  // noUnternehmenRedirect: true,
                  unternehmenName: "Noch nicht eingegeben...",
                  unternehmenListe: [
                    // {
                    //   id: null,
                    //   name: "Noch nicht eingegeben...",
                    // }
                  ],
                  error: false,
                  // }, () => {
                  //   this.getDataFromCockpitApi(result.api_key);
                  // }//, () => { this.props.history.replace("/ersteschritte") }
                }, () => {
                  window.location.assign("/#/ersteschritte")
                }
              );
              // localStorage.setItem("Beratungsheld-Token", result.api_key);
              // window.location.assign("/#/ersteschritte");
            }
          }
        },
        (error) => {
          console.log(error)
          this.setState({
            apiToken: false,
            isLoggedIn: false,
            userId: "",
            unternehmenId: "",
            firstname: "",
            lastname: "",
            error: "Fehler beim Verbinden mit dem Login-Server. Bitte Internetverbindung überprüfen und ggf. später noch einmal probieren.",
          });
          localStorage.removeItem("Beratungsheld-Token");
        }
        // )
        // .then(
        //   (result) => {
        //     // window.location = "https://beratungsheld.itf-demo.de/index.php/kasse/?add-to-cart=877";
        //     // alert("Benutzer \"" + this.state.result.name + "\" angelegt. Neue ID: \"" + this.state.result._id + "\". Hinweis: Einloggen für neue Nutzer noch deaktiviert. Daher erfolgt jetzt keine Weiterleitung zum Login bzw. kein Auto-Login.");
        //   }
      );
  }
  //
  // logout = () => {
  //   this.state = {
  //     apiToken: false,
  //     isLoggedIn: false,
  //     userId: "",
  //     unternehmenId: "",
  //   }
  //   localStorage.removeItem("Beratungsheld-Token");
  //   return <Redirect to="/login"/>
  // }

  render() {
    const {classes} = this.props;

    return (
      <Router>
        <AppContext.Provider value={this.state}>
          <CssBaseline/>
          <MobileOnlyView>
            <div class={classes.mobilWarningBanner}>Achtung: Beratungsheld ist (noch) nicht für Handys optimiert. Bitte benutze ein Tablet oder PC!</div>
          </MobileOnlyView>
          {/*<Route exact path="/" render={() => <Redirect to="/login"/>}/>*/}
          <div className={classes.root}>
            <Switch>
              <Route exact path="/"
                     render={() => <Redirect to={`/dashboard`}/>}/>
              <Route path="/register/:role" component={Registrieren}/>
              <Route exact path="/register" component={Registrieren}/>

              <Route path="/public/steckbrief/:id" component={SteckbriefShowPublic}/>
              <Route exact path="/public/businessforum" component={SteckbriefBoardPublic}/>
              <Route exact path="/:functionName">
                {this.state.isLoggedIn
                  ? <ViewArea/>
                  : <Login/>
                }
              </Route>
              {/*<Route exact path="/"*/}
              {/*       render={() => <Redirect to={`/login`}/>}/>*/}
              {/*<Route path="/login" component={Login}/>*/}
            </Switch>
          </div>
        </AppContext.Provider>
      </Router>
    );
  }
}

Gui.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(Gui));
