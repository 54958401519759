import React from "react";
import WorkIcon from "@material-ui/icons/Work";
import {withStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import styles from "../styles";
import {Link, Redirect} from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import {AppContext} from "../AppContext";
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';

class UnternehmensListe extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      listOpen: false,
      unternehmenAnchorEl: null,
      neuesUnternehmenOpen: false,
      unternehmenName: null,
      redirect: false,
      redirectTarget: "",
    };
  }

  // componentDidMount() {
  //   if(this.context.noUnternehmenRedirect) {
  //     window.location.assign("/#/ersteschritte");
  //     // this.props.history.push("/ersteschritte");
  //     // this.setState({
  //     //   redirect: true,
  //     //   redirectTarget:"/ersteschritte",
  //     // })
  //     this.context.noUnternehmenRedirect = false;
  //   };
  // }

  handleUnternehmenClick = (e) => {
    this.setState({unternehmenAnchorEl: e.currentTarget});
    // this.context.unternehmenChange()
  };

  handleUnternehmenChange = (id, name) => {
    // if(this.context.unternehmenId === null) {
    //   this.setState({
    //     redirect: true,
    //     redirectTarget: "/erste",
    //   });
    // } else {
    this.context.changeUnternehmen(id, name);
    this.handleUnternehmenClose();
    this.setState({
      isLoading: false,
      listOpen: false,
      unternehmenAnchorEl: null,
      neuesUnternehmenOpen: false,
      unternehmenName: null,
      redirect: true,
      redirectTarget: "/",
    });
    // }
  };

  handleUnternehmenClose = () => {
    this.setState({unternehmenAnchorEl: null});
  };

  neuesUnternehmenOpen = (e) => {
    this.setState({
      neuesUnternehmenOpen: true,
    });
  };

  neuesUnternehmenClose = () => {
    this.setState({
      neuesUnternehmenOpen: false,
      listOpen: false,
    });
  };

  neuesUnternehmenAnlegen = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/v1/unternehmendata/${this.props.userId}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },

        body: JSON.stringify({unternehmenName: this.state.unternehmenName}),
        //TODO: nur den Namen senden
      }
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          neuesUnternehmenOpen: false,
          listOpen: false,
          redirect: true,
          redirectTarget: `/dashboard/${this.props.userId}/${data._id}`,
        });
      })
      .catch((err) => console.log(err));
  };

  unternehmenChange = (e) => {
    this.setState({
      unternehmenName: e.target.value,
    });
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      this.setState({
        isLoading: false,
        listOpen: false,
        unternehmenAnchorEl: null,
        neuesUnternehmenOpen: false,
        unternehmenName: null,
        redirect: false,
        redirectTarget: "",
      });
      return <Redirect to={this.state.redirectTarget}/>;
    }
  };

  render() {
    const {classes} = this.props;
    const {unternehmenListe} = this.context;
    const {neuesUnternehmenOpen, unternehmenAnchorEl} = this.state;
    const alleUnternehmen = unternehmenListe.map((unternehmen, index) => {
      return (
        <ListItem
          key={unternehmen.id}
          button
          component={Link}
          to={`/dashboard`}
          onClick={() => this.handleUnternehmenChange(unternehmen.id, unternehmen.name)}
        >
          <ListItemIcon>
            <WorkIcon/>
          </ListItemIcon>
          <ListItemText primary={unternehmen.name}/>
        </ListItem>
      );
    });

    return (
      <div>
        {this.renderRedirect()}
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={this.handleUnternehmenClick}
        >
          {this.context.unternehmenName}
        </Button>

        <Menu
          id="simple-menu"
          anchorEl={unternehmenAnchorEl}
          keepMounted
          open={Boolean(unternehmenAnchorEl)}
          onClose={this.handleUnternehmenClose}
        >
          <Grid item>
            {alleUnternehmen}
            <ListItem
              key={"new-unternehmen-link-unternehmensliste"}
              button
              component={Link}
              to={`/ersteschritte`}
              // onClick={() => this.handleUnternehmenChange(unternehmen.id, unternehmen.name)}
            >
              <ListItemIcon>
                <AddBoxIcon/>
              </ListItemIcon>
              <ListItemText primary={"neues Unternehmen anlegen"}/>
            </ListItem>
            <ListItem
              key={"unternehmen-bearbeiten-link-unternehmensliste"}
              button
              component={Link}
              to={`/unternehmenbearbeiten`}
              // onClick={() => this.handleUnternehmenChange(unternehmen.id, unternehmen.name)}
            >
              <ListItemIcon>
                <EditIcon/>
              </ListItemIcon>
              <ListItemText primary={"aktuelles Unternehmen bearbeiten"}/>
            </ListItem>
            {/*<ListItem>*/}
            {/*  {neuesUnternehmenOpen ? (*/}
            {/*    <div>*/}
            {/*      <Grid container>*/}
            {/*        <Grid item xs={9}>*/}
            {/*          <TextField*/}
            {/*            id="standard-basic"*/}
            {/*            placeholder="Unternehmensname"*/}
            {/*            name="unternehmenName"*/}
            {/*            onChange={this.unternehmenChange}*/}
            {/*          />*/}
            {/*        </Grid>*/}
            {/*        <Grid item xs={1}>*/}
            {/*          <IconButton onClick={this.neuesUnternehmenAnlegen}>*/}
            {/*            <CheckIcon fontSize="small" color="primary"/>*/}
            {/*          </IconButton>*/}
            {/*        </Grid>*/}
            {/*        <Grid item xs={2}>*/}
            {/*          <IconButton*/}
            {/*            onClick={this.neuesUnternehmenClose}*/}
            {/*            className={classes.unternehmenClose}*/}
            {/*          >*/}
            {/*            <CloseIcon fontSize="small" color="secondary"/>*/}
            {/*          </IconButton>*/}
            {/*        </Grid>*/}
            {/*      </Grid>*/}
            {/*    </div>*/}
            {/*  ) : (*/}
            {/*    <IconButton*/}
            {/*      onClick={this.neuesUnternehmenOpen}*/}
            {/*      className={classes.margin}*/}
            {/*    >*/}
            {/*      <AddCircleIcon color="primary" fontSize="large"/>*/}
            {/*    </IconButton>*/}
            {/*  )}*/}
            {/*</ListItem>*/}
          </Grid>
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles)(UnternehmensListe);
