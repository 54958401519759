const liquiditaetsSchritte = {
  1: {
    LiquiditaetMonatJahr: {
      typ: "monthYearSelect",
      teilDerSummen: [],
      key: "liquiditaet-monthYear-select",
      datenQuelle: "bwa",
      inAusgabe: false,
      inEingabe: true,
    },
  },
  2: {
    LiquiditaetVormonat: {
      class: "zwischenSumme",
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetVerfuegbar", "LiquiditaetMonatsende"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
    Erloese7: {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetEinzahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
    Erloese19: {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetEinzahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
    // SonstigeErloese: {
    //   typ: "input",
    //   teilDerSummen: ["LiquiditaetEinzahlungen"],
    //   datenQuelle: "bwa",
    //   datenTyp: "monthly",
    //   inAusgabe: true,
    //   inEingabe: true,
    // },
    // Zinsertrag: {
    //   typ: "input",
    //   teilDerSummen: ["LiquiditaetEinzahlungen"],
    //   datenQuelle: "bwa",
    //   datenTyp: "monthly",
    //   inAusgabe: true,
    //   inEingabe: true,
    // },
    // SoNeutralerErtrag: {
    //   typ: "input",
    //   teilDerSummen: ["LiquiditaetEinzahlungen"],
    //   datenQuelle: "bwa",
    //   datenTyp: "monthly",
    //   inAusgabe: true,
    //   inEingabe: true,
    // },
    LiquiditaetSonstigeEinzahlungen: {
      typ: "input",
      teilDerSummen: ["LiquiditaetEinzahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
    LiquiditaetEinzahlungen: {
      class: "zwischenSumme",
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetVerfuegbar"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
    LiquiditaetVerfuegbar: {
      class: "summe",
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetRoherloes"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
  },
  3: {
    Wareneinkauf: {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetProjektbezogeneAuszahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
    Fremdleistungen: {
      typ: "input",
      teilDerSummen: ["LiquiditaetProjektbezogeneAuszahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
    LiquiditaetProjektbezogeneAuszahlungen: {
      class: "zwischenSumme",
      typ: "readOnly",
      teilDerSummen: [],
      subtrahendVon: ["LiquiditaetRoherloes"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
    LiquiditaetRoherloes: {
      class: "summe",
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetUeberdeckung"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
  },
  4: {
    Personalkosten: {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
    Raumkosten: {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
    Steuern: {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
      inAusgabe: true,
      inEingabe: true,
    },
    Versicherungen: {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
      inAusgabe: true,
      inEingabe: true,
    },
    "Kfz-Kosten": {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
    "Werbe-Reisekosten": {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
    "Kosten der Warenabgabe": {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
    Reparaturen: {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
    // Instandhaltung: {
    //   typ: "readOnly",
    //   teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
    //   datenQuelle: "bwa",
    //   datenTyp: "monthly",
    //   inAusgabe: true,
    //   inEingabe: true,
    // },
    "Sonstige Kosten": {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
    Zinsaufwand: {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
    "So neutraler Aufwand": {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
    LiquiditaetTilgungen: {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
    // LiquiditaetSteuernAbgaben: {
    //   typ: "input",
    //   teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
    //   datenQuelle: "bwa",
    //   datenTyp: "monthly",
    //   inAusgabe: true,
    //   inEingabe: true,
    // },
    LiquiditaetInvestitionen: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
    },
    LiquiditaetUmsatzsteuersaldo: {
      // class: "summe",
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
      prozentualeAnrechnungDerSumme: true,
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: false,
    },
    LiquiditaetLaufendeAuszahlungen: {
      class: "zwischenSumme",
      typ: "readOnly",
      teilDerSummen: [],
      subtrahendVon: ["LiquiditaetUeberdeckung"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
    LiquiditaetUeberdeckung: {
      class: "summe",
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetUeberdeckungNachEntnahme"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: false,
    },
    PrivatBenoetigtePrivatentnahme: {
      typ: "readOnly",
      teilDerSummen: [],
      subtrahendVon: ["LiquiditaetMonatsende"],
      datenQuelle: "PrivatFinanzen",
      datenTyp: "static",
      inAusgabe: true,
      inEingabe: false,
    },
    LiquiditaetUeberdeckungNachEntnahme: {
      class: "summe",
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetMonatsende"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: false,
    },
    LiquiditaetMonatsende: {
      class: "summe",
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetMonatsendeMitKontokorrentrahmen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: false,
    },
    FinanzierungKontokorrrentkredit: {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetMonatsendeMitKontokorrentrahmen"],
      datenQuelle: "kapitalUndFinanzierung",
      datenTyp: "static",
      inAusgabe: true,
      inEingabe: false,
    },
    LiquiditaetMonatsendeMitKontokorrentrahmen: {
      class: "summe",
      typ: "readOnly",
      teilDerSummen: [],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: false,
    },
    "Neutrales Ergebnis": {
      class: "hidden",
      typ: "readOnly",
      teilDerSummen: [],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: false,
    },
    Betriebsergebnis: {
      class: "summe",
      typ: "readOnly",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: [],
    },
    Ergebnis: {
      class: "hidden",
      typ: "readOnly",
      teilDerSummen: [],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: false,
    },
  },
};

export default liquiditaetsSchritte;
