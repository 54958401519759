import React, {Fragment} from "react";
import {TextField, Typography} from "@mui/material";
import {withStyles} from "@material-ui/styles";
import styles from "../styles";

class CustomMultilineTextField extends React.Component {
  state = {
    step: 1,
    isLoaded: false,
    tutorialEnabled: true,
    jahr: "",
  };

  render() {
    var {classes, isReadOnly, showLabel, ...other} = this.props;

    if (isReadOnly) {
      if (showLabel) {
        return (
          // <TextField
          //   {...other}
          //   multiline
          //   InputProps={{
          //     readOnly: true,
          //   }}
          // />
          <Fragment>
            <Typography variant="h6">
              {this.props.label}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontSize: '1.2rem',
                whiteSpace: 'pre-line'
              }}
            >
              {this.props.value}
            </Typography>
          </Fragment>
        );
      } else {
        return (
          // <TextField
          //   {...other}
          //   multiline
          //   InputProps={{
          //     readOnly: true,
          //   }}
          // />
          <Fragment>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                whiteSpace: 'pre-line',
                fontSize: '1.2rem',
              }}
            >
              {this.props.value}
            </Typography>
          </Fragment>
        );
      }
    } else {
      return (
        <TextField
          {...other}
          multiline
        />
      );
    }
  }
}

export default withStyles(styles)(CustomMultilineTextField);
