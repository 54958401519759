import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import liquiditaetsSchritte from "../LogicData/Liquiditaetsschritte";
import rentabilitaetsSchritte from "../LogicData/RentabilitaetsSchritte";
import kapitalUndFinanzierungsSchritte from "../LogicData/KapitalUndFinanzierungsSchritte";
import privaterFinanzBedarfSchritte from "../LogicData/PrivaterFinanzbedarfSchritte2";
import typischerMonatSchritte from "../LogicData/TypischerMonatSchritte";
import erklaerungsTexte from "../LogicData/erklaerungsTexte";
import erklaerungsUeberschriften from "../LogicData/erklaerungsUeberschriften";

import CustomCell from "../CustomTags/CustomCell";
import WeiterleitungsModal from "../CustomTags/Weiterleitungsmodal";
import styles from "../styles";
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid";
import {AppContext} from "../AppContext";
import timpaniRollSound from "../Assistent/resources/timpani-roll.mp3";

const Row = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
    "&.summe": {
      backgroundColor: "#1976d2",
      paddingBottom: "80px",
      //fontWeight: "800",
    },
    "&.zwischenSumme": {
      backgroundColor: "#9e9e9e",
      paddingBottom: "80px",
      //fontWeight: "800",
    },
  },
}))(TableRow);

class Endergebnis extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.timpaniRollAudio = new Audio(timpaniRollSound);

    this.erklaerungen = {
      ...erklaerungsUeberschriften.Businessplan.Endergebnis,
      ...erklaerungsTexte,
    };

    this.state = {
      kapitalUndFinanzierungsdaten: null,
      privaterFinanzBedarfdaten: null,
      //umsatzsteuersaldo: null,
      ueber_unterDeckung: null,
      ueber_unterDeckung_Privatentnahme: null,
      liquideMittelAmMonatsende: [],
      liquiditaetVormonat: [],
      liquideMittelAmMonatsendeInklKontokorrentRahmen: [],
      typischerMonatData: null,
      spezifischerMonatData: null,
      bwaData: null,
      liquiditaetrows: [],
      bauinverstition: null,
      kapitalUndFinanzierungsrows: [],
      rentabilitaetrows: [],
      privaterFinanzBedarfrows: [],
      rumpfjahr: null,
      rumpfmonat: null,
      isLoaded: false,
      weiterleitungsModalOpen: false,
      modalScript: "",
      showTable: false,
      step: 1,
    };

    let LiquiditaetsSchrittName = [];
    let KapitalUndFinanzierungsSchrittName = [];
    let RentabilitaetsSchrittName = [];
    let PrivaterFinanzbedarfSchrittName = [];

    //LiquiditaetsSchritte

    Object.keys(liquiditaetsSchritte).forEach(function (outerKey) {
      Object.keys(liquiditaetsSchritte[outerKey]).forEach(function (innerKey) {
        if (liquiditaetsSchritte[outerKey][innerKey]["inAusgabe"] === true) {
          if ("class" in liquiditaetsSchritte[outerKey][innerKey]) {
            LiquiditaetsSchrittName = {
              ...LiquiditaetsSchrittName,

              [innerKey]: {
                class: liquiditaetsSchritte[outerKey][innerKey]["class"],
                step: outerKey,
              },
            };
          } else {
            LiquiditaetsSchrittName = {
              ...LiquiditaetsSchrittName,
              [innerKey]: {
                class: "",
                step: outerKey,
              },
            };
          }
        } else {
          delete liquiditaetsSchritte[outerKey][innerKey];
        }
      });
    });
    this.state.liquiditaetrows = LiquiditaetsSchrittName;

    //Rentabiliaetsschritte
    Object.keys(rentabilitaetsSchritte).forEach(function (firstKey) {
      Object.keys(rentabilitaetsSchritte[firstKey]).forEach(function (
        secondKey
      ) {
        if (rentabilitaetsSchritte[firstKey][secondKey]["inAusgabe"] === true) {
          if ("class" in rentabilitaetsSchritte[firstKey][secondKey]) {
            RentabilitaetsSchrittName = {
              ...RentabilitaetsSchrittName,

              [secondKey]: {
                class: rentabilitaetsSchritte[firstKey][secondKey]["class"],
                step: firstKey,
              },
            };
          } else {
            RentabilitaetsSchrittName = {
              ...RentabilitaetsSchrittName,
              [secondKey]: {
                class: "",
                step: firstKey,
              },
            };
          }
        } else {
          delete rentabilitaetsSchritte[firstKey][secondKey];
        }
      });
    });
    this.state.rentabilitaetrows = RentabilitaetsSchrittName;

    //Kapital- und FinanzierungsSchritte
    Object.keys(kapitalUndFinanzierungsSchritte).forEach(function (outkey) {
      Object.keys(kapitalUndFinanzierungsSchritte[outkey]).forEach(function (
        inKey
      ) {
        if (
          kapitalUndFinanzierungsSchritte[outkey][inKey]["inAusgabe"] === true
        ) {
          if ("class" in kapitalUndFinanzierungsSchritte[outkey][inKey]) {
            KapitalUndFinanzierungsSchrittName = {
              ...KapitalUndFinanzierungsSchrittName,
              //              fourthKey,
              [inKey]: {
                class: kapitalUndFinanzierungsSchritte[outkey][inKey]["class"],
                step: outkey,
              },
            };
          } else {
            KapitalUndFinanzierungsSchrittName = {
              ...KapitalUndFinanzierungsSchrittName,
              //              fourthKey,
              [inKey]: {
                class: "",
                step: outkey,
              },
            };
          }
        } else {
          delete kapitalUndFinanzierungsSchritte[outkey][inKey];
        }
      });
    });

    this.state.kapitalUndFinanzierungsrows = KapitalUndFinanzierungsSchrittName;

    //Privater FinanzbedarfSchritte
    Object.keys(privaterFinanzBedarfSchritte).forEach(function (thirdKey) {
      Object.keys(privaterFinanzBedarfSchritte[thirdKey]).forEach(function (
        fourthKey
      ) {
        if (
          privaterFinanzBedarfSchritte[thirdKey][fourthKey]["inAusgabe"] ===
          true
        ) {
          if ("class" in privaterFinanzBedarfSchritte[thirdKey][fourthKey]) {
            PrivaterFinanzbedarfSchrittName = {
              ...PrivaterFinanzbedarfSchrittName,
              //              fourthKey,
              [fourthKey]: {
                class:
                  privaterFinanzBedarfSchritte[thirdKey][fourthKey]["class"],
                step: thirdKey,
              },
            };
          } else {
            PrivaterFinanzbedarfSchrittName = {
              ...PrivaterFinanzbedarfSchrittName,
              //              fourthKey,
              [fourthKey]: {
                class: "",
                step: thirdKey,
              },
            };
          }
        } else {
          delete privaterFinanzBedarfSchritte[thirdKey][fourthKey];
        }
      });
    });

    this.state.privaterFinanzBedarfrows = PrivaterFinanzbedarfSchrittName;

    this.state.typischerMonatReversed = this.getClearData("typischerMonatSchritte");
  }

  componentDidMount() {
    this.context.setFunktion({
      key: this.erklaerungen.key,
      name: this.erklaerungen.name,
      erklaerungHeader: this.erklaerungen.erklaerungHeader,
      erklaerungText: this.erklaerungen.erklaerungText,
      continuePossible: true,
      backPossible: true,
      totalSteps: 2,
      handleSubmit: () => this.bwaDataErstellen(),
    });
    this.context.setSchritt({
      key: 1,
      erklaerungHeader: this.erklaerungen["Schritte"][1]
        .erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][1].erklaerungText,
      continuePossible: true,
      backPossible: false,
    });
    this.context.setBereich({
      name: "Businessplan"
    });
    this.context.setSavePossible(true);

    this.getDataFromApi();
  }

  componentDidUpdate() {
    if (this.state.step !== this.context.schritt.key) {
      this.handleStepChange();
    }
  }

  handleStepChange = () => {
    const newStep = this.context.schritt.key;
    this.setState({
      step: newStep,
      erklaerungHeader: this.erklaerungen["Schritte"][newStep]
        .erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
      showTable: false,
    });

    if (newStep > 1) {
      this.bwaDataErstellen();
      this.context.setSchritt({
        key: newStep,
        erklaerungHeader: this.erklaerungen["Schritte"][newStep]
          .erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
        continuePossible: this.continuePossible(newStep),
        backPossible: true,
      });
    } else {
      this.context.setSchritt({
        key: newStep,
        erklaerungHeader: this.erklaerungen["Schritte"][newStep]
          .erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
        continuePossible: this.continuePossible(newStep),
        backPossible: false,
      });
    }
  };

  continuePossible = (step) => {
    switch (step) {
      case 1:
        return true;
      case 2:
        return false;
      default:
        return false;
    }
  };

  getClearData = (name) => {
    switch (name) {
      case "liquiditaetsSchritte":
        return (this.reloadSchrittData(this.state.liquiditaetrows));
      case "rentabilitaetsSchritte":
        return (this.reloadSchrittData(this.state.rentabilitaetrows));
      case "kapitalUndFinanzierungsSchritte":
        return (this.reloadSchrittData(this.state.kapitalUndFinanzierungsrows));
      case "privaterFinanzBedarfSchritte":
        return (this.reloadSchrittData(this.state.privaterFinanzBedarfrows));
      case "typischerMonatSchritte":
        return (this.reloadSchrittData(typischerMonatSchritte));
      default:
        return ({});
    }
  };

  reloadSchrittData = (SchrittFile) => {
    let allItems = [];

    Object.keys(SchrittFile).forEach(function (outerKey) {
      Object.keys(SchrittFile[outerKey]).forEach(function (innerKey) {
        if (SchrittFile[outerKey][innerKey]["inAusgabe"] === true) {
          allItems = [...allItems, innerKey];
        } else {
          delete SchrittFile[outerKey][innerKey];
        }
      });
    });

    let allItemsReversed = {};

    allItems.map((element, key) => {
      allItemsReversed[element] = 0;
    });

    return (allItemsReversed);
  }

  getDataFromApi = async () => {
    let result = await this.context.getUnternehmenDataFromApi("all");

    let typischerMonatData;
    let spezifischerMonatData;
    let privaterFinanzBedarfdaten;
    let kapitalUndFinanzierungsdaten;
    let rumpfjahr = result.rumpfjahr;
    let rumpfmonat = result.rumpfmonat;

    if (
      result.privatFinanzen &&
      "PrivatBenoetigtePrivatentnahme" in result.privatFinanzen &&
      0 !==
      result.privatFinanzen["PrivatBenoetigtePrivatentnahme"].toString()
        .length
    ) {
      privaterFinanzBedarfdaten = result.privatFinanzen;
    } else {
      this.setState({
        weiterleitungsModalOpen: true,
        modalScript: "PrivaterFinanzbedarf",
      });
      return;
    }

    if (
      result.kapitalUndFinanzierung &&
      "FinanzierungFinanzierungslueckeReserve" in
      result.kapitalUndFinanzierung &&
      0 !==
      result.kapitalUndFinanzierung[
        "FinanzierungFinanzierungslueckeReserve"
        ].toString().length
    ) {
      kapitalUndFinanzierungsdaten = result.kapitalUndFinanzierung;
    } else {
      this.setState({
        weiterleitungsModalOpen: true,
        modalScript: "KapitalUndFinanzierung",
      });
      return;
    }

    let bauinverstition =
      result.kapitalUndFinanzierung.FinanzierungBauinvestitionen;

    if (
      result.typischerMonat &&
      "LiquiditaetEinzahlungen" in result.typischerMonat &&
      0 !== result.typischerMonat["LiquiditaetEinzahlungen"].toString().length
    ) {
      typischerMonatData = result.typischerMonat;
    } else {
      this.setState({
        weiterleitungsModalOpen: true,
        modalScript: "TypischerMonat",
      });
      return;
    }

    if (isNaN(rumpfjahr) || isNaN(rumpfmonat)) {
      this.setState({
        weiterleitungsModalOpen: true,
        modalScript: "ErsteSchritte",
      });
      return;
    }

    if (result.spezifischerMonat) {
      spezifischerMonatData = result.spezifischerMonat;
    }

    this.setState({
      privaterFinanzBedarfdaten: privaterFinanzBedarfdaten,
      typischerMonatData: typischerMonatData,
      bauinverstition: bauinverstition,
      kapitalUndFinanzierungsdaten: kapitalUndFinanzierungsdaten,
      spezifischerMonatData: spezifischerMonatData,
      // bwaData: bwaData,
      rumpfjahr: rumpfjahr,
      rumpfmonat: rumpfmonat,
      isLoaded: true,
    });
  }

  tableHeadMonate = () => {
    return (
      <Fragment>
        <CustomCell align="right" value={"Januar"}/>
        <CustomCell align="right" value={"Februar"}/>
        <CustomCell align="right" value={"März"}/>
        <CustomCell align="right" value={"April"}/>
        <CustomCell align="right" value={"Mai"}/>
        <CustomCell align="right" value={"Juni"}/>
        <CustomCell align="right" value={"Juli"}/>
        <CustomCell align="right" value={"August"}/>
        <CustomCell align="right" value={"September"}/>
        <CustomCell align="right" value={"Oktober"}/>
        <CustomCell align="right" value={"November"}/>
        <CustomCell align="right" value={"Dezember"}/>
        <CustomCell align="right" value={"Summe"}/>
      </Fragment>
    );
  };

  playSound = (audioFile) => {
    audioFile.play();
  };

  bwaDataErstellen = async () => {
    const {
      typischerMonatData,
      spezifischerMonatData,
      rumpfjahr,
      rumpfmonat,
      privaterFinanzBedarfdaten,
      liquiditaetrows,
      rentabilitaetrows,
    } = this.state;

    this.playSound(this.timpaniRollAudio);

    var bwa = {};

    //const felder = typischerMonatData;
    const felder = {...rentabilitaetrows, ...liquiditaetrows};
    let bwaSollVormonat = {};
    let bwaSollThisMonat = this.state.typischerMonatReversed;

    for (let i = 0; i <= 3; i++) {
      var jahr = parseInt(rumpfjahr) + i;
      bwa[jahr] = {};
      for (let j = 1; j <= 12; j++) {
        bwa[jahr][j] = {};
        bwa[jahr][j]["Soll"] = {};
        bwaSollVormonat = bwaSollThisMonat;
        bwaSollThisMonat = bwa[jahr][j].Soll;

        for (const feld in felder) {
          if (i === 0 && j < rumpfmonat) {
            //Vor Grundungsmonat alles nullen
            bwa[jahr][j]["Soll"][feld] = 0;
          } else if (i === 0 && j === rumpfmonat &&
            feld !== "RentabilitaetRohertrag" &&
            feld !== "RentabilitaetAufwand" &&
            feld !== "Betriebsergebnis" &&
            feld !== "LiquiditaetLaufendeAuszahlungen" &&
            feld !== "LiquiditaetUmsatzsteuersaldo" &&
            feld !== "LiquiditaetRoherloes" &&
            feld !== "LiquiditaetUeberdeckung" &&
            feld !== "PrivatBenoetigtePrivatentnahme" &&
            feld !== "LiquiditaetUeberdeckungNachEntnahme" &&
            feld !== "LiquiditaetMonatsende" &&
            feld !== "FinanzierungKontokorrrentkredit" &&
            feld !== "LiquiditaetMonatsendeMitKontokorrentrahmen" ) {
            if (
              spezifischerMonatData &&
              spezifischerMonatData[jahr] &&
              spezifischerMonatData[jahr][j] &&
              spezifischerMonatData[jahr][j][feld]
            ) {
              //Vorrang für spezifische Monatsdaten im Gründungsmonat
              bwa[jahr][j]["Soll"][feld] =
                spezifischerMonatData[jahr][j][feld];
            } else {
              switch (feld) {
                //Rentabilität Gründungsmonat:
                case "RentabilitaetUmsatzerloese":
                case "Erloese7":
                case "Erloese19":
                case "Fremdleistungen":
                  bwa[jahr][j]["Soll"][feld] = 0
                  break;
                case "Wareneinkauf":
                  bwa[jahr][j]["Soll"][feld] = this.state.kapitalUndFinanzierungsdaten.FinanzierungWarenMateriallager;
                  break;
                case "Werbe-Reisekosten":
                  bwa[jahr][j]["Soll"][feld] = this.state.kapitalUndFinanzierungsdaten.FinanzierungMarkteinfuehrungWerbung;
                  break;
                case "RentabilitaetAbschreibungen":
                  if (
                    spezifischerMonatData &&
                    spezifischerMonatData[jahr] &&
                    spezifischerMonatData[jahr][j] &&
                    spezifischerMonatData[jahr][j]["GWG"]
                  ) {
                    bwa[jahr][j]["Soll"][feld] = spezifischerMonatData[jahr][j]["GWG"];
                  } else {
                    bwa[jahr][j]["Soll"][feld] = 0;
                  }
                  break;
                case "Sonstige Kosten":
                  bwa[jahr][j]["Soll"][feld] =
                    this.state.kapitalUndFinanzierungsdaten.FinanzierungBeratungen +
                    //this.state.kapitalUndFinanzierungsdaten.FinanzierungKautionen +
                    this.state.kapitalUndFinanzierungsdaten.FinanzierungBueroerstausstattung //+
                  // typischerMonatData[feld];
                  break;

                case "LiquiditaetUmsatzsteuersaldo":
                  if (
                    spezifischerMonatData &&
                    spezifischerMonatData[jahr] &&
                    spezifischerMonatData[jahr][j] &&
                    spezifischerMonatData[jahr][j][feld]
                  ) {
                    bwa[jahr][j]["Soll"][feld] =
                      spezifischerMonatData[jahr][j][feld];
                  } else {
                    bwa[jahr][j]["Soll"][feld] = typischerMonatData[feld];
                  }


                  //Umsatzsteuersaldo
                  let umsatzsteuersaldoData =
                    (this.state.kapitalUndFinanzierungsdaten.FinanzierungWarenMateriallager +
                      typischerMonatData.Raumkosten +
                      typischerMonatData["Kfz-Kosten"] +
                      this.state.kapitalUndFinanzierungsdaten.FinanzierungMarkteinfuehrungWerbung +
                      typischerMonatData["Kosten der Warenabgabe"] +
                      typischerMonatData.Reparaturen +
                      this.state.kapitalUndFinanzierungsdaten.FinanzierungBeratungen +
                      this.state.kapitalUndFinanzierungsdaten.FinanzierungBueroerstausstattung +
                      this.state.kapitalUndFinanzierungsdaten.FinanzierungBauinvestitionen +
                      this.state.kapitalUndFinanzierungsdaten.FinanzierungGeschaeftsausstattung +
                      this.state.kapitalUndFinanzierungsdaten.FinanzierungMaschinen +
                      this.state.kapitalUndFinanzierungsdaten.FinanzierungComputerSoftware +
                      this.state.kapitalUndFinanzierungsdaten.FinanzierungFahrzeuge +
                      this.state.kapitalUndFinanzierungsdaten.FinanzierungSonstigeInvestitionen) *
                    0.19;

                  bwa[jahr][j]["Soll"][feld] = umsatzsteuersaldoData;
                  break;

                //Liquidität Gründungsmonat:
                case "LiquiditaetVormonat":
                  bwa[jahr][j]["Soll"][feld] = 0;
                  break;
                case "LiquiditaetEinzahlungen":
                  bwa[jahr][j]["Soll"][feld] =
                    this.state.kapitalUndFinanzierungsdaten.FinanzierungGesamtkapitalAvalkredite;
                  break;
                case "LiquiditaetSonstigeEinzahlungen":
                  bwa[jahr][j]["Soll"][feld] =
                    this.state.kapitalUndFinanzierungsdaten.FinanzierungGesamtkapitalAvalkredite;
                  break;
                case "LiquiditaetVerfuegbar":
                  bwa[jahr][j]["Soll"][feld] =
                    this.state.kapitalUndFinanzierungsdaten.FinanzierungGesamtkapitalAvalkredite;
                  break;
                case "LiquiditaetProjektbezogeneAuszahlungen":
                  bwa[jahr][j]["Soll"][feld] =
                    bwaSollThisMonat.Wareneinkauf +
                    bwaSollThisMonat.Fremdleistungen;
                  break;
                case "LiquiditaetInvestitionen":
                  bwa[jahr][j]["Soll"][feld] =
                    this.state.kapitalUndFinanzierungsdaten.FinanzierungBauinvestitionen +
                    this.state.kapitalUndFinanzierungsdaten.FinanzierungGeschaeftsausstattung +
                    this.state.kapitalUndFinanzierungsdaten.FinanzierungMaschinen +
                    this.state.kapitalUndFinanzierungsdaten.FinanzierungComputerSoftware +
                    this.state.kapitalUndFinanzierungsdaten.FinanzierungFahrzeuge +
                    this.state.kapitalUndFinanzierungsdaten.FinanzierungSonstigeInvestitionen;
                  break;
                default:
                  bwa[jahr][j]["Soll"][feld] = typischerMonatData[feld];
              }
            }
          } else {

            switch (feld) {
              //Rentabilität nach Gründungsmonat:
              case "Afa":
                let calculatedAbschreibungen =
                  this.state.kapitalUndFinanzierungsdaten.FinanzierungBauinvestitionen * 0.0025 +
                  this.state.kapitalUndFinanzierungsdaten.FinanzierungGeschaeftsausstattung * 0.0166667 +
                  this.state.kapitalUndFinanzierungsdaten.FinanzierungMaschinen * 0.0083333 +
                  this.state.kapitalUndFinanzierungsdaten.FinanzierungComputerSoftware * 0.0277778 +
                  this.state.kapitalUndFinanzierungsdaten.FinanzierungFahrzeuge * 0.0138889 +
                  this.state.kapitalUndFinanzierungsdaten.FinanzierungSonstigeInvestitionen * 0.0104167;

                if (
                  spezifischerMonatData &&
                  spezifischerMonatData[jahr] &&
                  spezifischerMonatData[jahr][j] &&
                  spezifischerMonatData[jahr][j]["GWG"]
                ) {
                  bwa[jahr][j]["Soll"][feld] = calculatedAbschreibungen + spezifischerMonatData[jahr][j]["RentabilitaetGWG"];
                } else {
                  bwa[jahr][j]["Soll"][feld] = calculatedAbschreibungen + typischerMonatData["RentabilitaetGWG"];
                }
                break;
              case "RentabilitaetUmsatzerloese":
                bwa[jahr][j]["Soll"][feld] =
                  bwaSollThisMonat.Erloese7 +
                  bwaSollThisMonat.Erloese19;
                break;
              case "RentabilitaetRohertrag":
                bwa[jahr][j]["Soll"][feld] =
                  bwaSollThisMonat.Erloese7 +
                  bwaSollThisMonat.Erloese19 -
                  bwaSollThisMonat.Fremdleistungen -
                  bwaSollThisMonat.Wareneinkauf;
                break;
              case "RentabilitaetAufwand":
                 bwa[jahr][j]["Soll"][feld] =
                   bwaSollThisMonat.Personalkosten +
                   bwaSollThisMonat.Raumkosten +
                   bwaSollThisMonat.Steuern +
                   bwaSollThisMonat.Versicherungen +
                   bwaSollThisMonat["Kfz-Kosten"] +
                   bwaSollThisMonat["Werbe-Reisekosten"] +
                   bwaSollThisMonat["Kosten der Warenabgabe"] +
                   bwaSollThisMonat["Sonstige Kosten"] +
                   bwaSollThisMonat.Afa;
                break;
              case "Betriebsergebnis":
                bwa[jahr][j]["Soll"][feld] =
                  bwaSollThisMonat.RentabilitaetRohertrag -
                  bwaSollThisMonat.RentabilitaetAufwand;
                break;

              //Liquidität nach Gründungsmonat:
              case "LiquiditaetVerfuegbar":
                bwa[jahr][j]["Soll"][feld] =
                  bwaSollThisMonat.LiquiditaetVormonat +
                  bwaSollThisMonat.LiquiditaetEinzahlungen;
                break;
              case "LiquiditaetInvestitionen":
                bwa[jahr][j]["Soll"][feld] = 0;
                break;
              case "LiquiditaetLaufendeAuszahlungen":
                bwa[jahr][j]["Soll"][feld] =
                  bwaSollThisMonat.Personalkosten +
                  bwaSollThisMonat.Raumkosten  +
                  bwaSollThisMonat.Steuern +
                  bwaSollThisMonat.Versicherungen +
                  bwaSollThisMonat["Kfz-Kosten"] +
                  bwaSollThisMonat["Werbe-Reisekosten"] +
                  bwaSollThisMonat["Kosten der Warenabgabe"] +
                  bwaSollThisMonat.Reparaturen +
                  // bwaSollThisMonat.Instandhaltung +
                  bwaSollThisMonat["Sonstige Kosten"] +
                  bwaSollThisMonat.Zinsaufwand +
                  bwaSollThisMonat["So neutraler Aufwand"] +
                  bwaSollThisMonat.LiquiditaetTilgungen +
                  // bwaSollThisMonat.LiquiditaetSteuernAbgaben +
                  bwaSollThisMonat.LiquiditaetInvestitionen +
                  bwaSollThisMonat.LiquiditaetUmsatzsteuersaldo
                break;
              case "LiquiditaetEinzahlungen":
                bwa[jahr][j]["Soll"][feld] =
                  bwaSollThisMonat.Erloese7 +
                  bwaSollThisMonat.Erloese19 //+
                  // bwaSollThisMonat.LiquiditaetSonstigeEinzahlungen;
                break;
              case "LiquiditaetRoherloes":
                bwa[jahr][j]["Soll"][feld] =
                  bwaSollThisMonat.LiquiditaetEinzahlungen -
                  bwaSollThisMonat.LiquiditaetProjektbezogeneAuszahlungen;
                break;
              case "PrivatBenoetigtePrivatentnahme":
                bwa[jahr][j]["Soll"][feld] = this.state.privaterFinanzBedarfdaten.PrivatBenoetigtePrivatentnahme;
                break;
              case "Neutrales Ergebnis":
                bwa[jahr][j]["Soll"][feld] =
                  bwaSollThisMonat["So neutraler Aufwand"] +
                  bwaSollThisMonat.SoNeutralerErtrag;
                break;
              case "Ergebnis":
                bwa[jahr][j]["Soll"][feld] =
                  bwaSollThisMonat["Neutrales Ergebnis"] +
                  bwaSollThisMonat.Betriebsergebnis;
                break;
              case "FinanzierungKontokorrrentkredit":
                bwa[jahr][j]["Soll"][feld] = this.state.kapitalUndFinanzierungsdaten.FinanzierungKontokorrrentkredit;
                break;
              case "LiquiditaetMonatsendeMitKontokorrentrahmen":
                bwa[jahr][j]["Soll"][feld] =
                  bwa[jahr][j]["Soll"]["LiquiditaetMonatsende"] +
                  this.state.kapitalUndFinanzierungsdaten
                    .FinanzierungKontokorrrentkredit;
                break;

              case "LiquiditaetUmsatzsteuersaldo":
                //Umsatzsteuersaldo
                let umsatzsteuersaldoData =
                  bwaSollThisMonat.Erloese19 * 0.19 +
                  bwaSollThisMonat.Erloese7 * 0.07 -
                  (bwaSollThisMonat.Wareneinkauf +
                    bwaSollThisMonat.Raumkosten +
                    bwaSollThisMonat["Kfz-Kosten"] +
                    bwaSollThisMonat["Werbe-Reisekosten"] +
                    bwaSollThisMonat["Kosten der Warenabgabe"] +
                    bwaSollThisMonat.Reparaturen +
                    bwaSollThisMonat["Sonstige Kosten"]) *
                  0.19;

                bwa[jahr][j]["Soll"][feld] = umsatzsteuersaldoData;
                break;
              case "LiquiditaetUeberdeckung":
                bwa[jahr][j]["Soll"][feld] =
                  bwaSollThisMonat.LiquiditaetRoherloes -
                  bwaSollThisMonat.LiquiditaetLaufendeAuszahlungen;
                break;
              case "LiquiditaetUeberdeckungNachEntnahme":
                //Ueber - Unterdeckung nach Privatentnahme
                bwa[jahr][j]["Soll"]["LiquiditaetUeberdeckungNachEntnahme"] = bwa[jahr][j]["Soll"]["LiquiditaetUeberdeckung"] - privaterFinanzBedarfdaten.PrivatBenoetigtePrivatentnahme;
                break;
              case "LiquiditaetVormonat":
                bwa[jahr][j]["Soll"][feld] = bwaSollVormonat["LiquiditaetMonatsende"];
                break;
              case "LiquiditaetMonatsende":
                bwa[jahr][j]["Soll"][feld] =
                  bwaSollThisMonat["LiquiditaetVormonat"] +
                  bwaSollThisMonat["LiquiditaetUeberdeckungNachEntnahme"];
                break;
              default:
                if (
                  spezifischerMonatData &&
                  spezifischerMonatData[jahr] &&
                  spezifischerMonatData[jahr][j] &&
                  spezifischerMonatData[jahr][j][feld]
                ) {
                  bwa[jahr][j]["Soll"][feld] =
                    spezifischerMonatData[jahr][j][feld];
                } else {
                  bwa[jahr][j]["Soll"][feld] = typischerMonatData[feld];
                }
                break;
            }
          }
        }
      }
    }

    this.setState({
      showTable: true,
      bwaData: bwa,
    });

    let result = await this.context.postUnternehmenDataToApi("bwa", bwa);

    // fetch(
    //   `${process.env.REACT_APP_API_URL}/v1/unternehmendata/${this.props.userId}/${this.props.unternehmenId}`,
    //   {
    //     method: "PUT",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       bwa: bwa,
    //     }),
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((result) => {
    //   });
  };

  loopThroughBwaData = (row, jahr) => {
    let {bwaData} = this.state;
    let ausgabe = [];
    let summe = 0;

    for (let j = 1; j <= 12; j++) {
      ausgabe.push(
        <CustomCell
          align="right"
          isCurrency={true}
          unit={"€"}
          value={bwaData[jahr][j]["Soll"][row] || 0}
        />
      );
      summe += bwaData[jahr][j]["Soll"][row] || 0;
    }

    ausgabe.push(
      <CustomCell align="right" isCurrency={true} unit={"€"} value={summe}/>
    );
    return ausgabe;
  };

  handletabellenErstellen = (row, jahr) => {
    return <Fragment>{this.loopThroughBwaData(row, jahr)}</Fragment>;
  };

  render() {
    const {classes} = this.props;
    let {
      kapitalUndFinanzierungsdaten,
      kapitalUndFinanzierungsrows,
      privaterFinanzBedarfdaten,
      privaterFinanzBedarfrows,
      liquiditaetrows,
      rentabilitaetrows,
      bwaData,
      isLoaded,
      modalScript,
      weiterleitungsModalOpen,
      showTable,
    } = this.state;

    console.log("render");
    console.log(this.state);

    let erklaerungstexte = erklaerungsTexte;
    let handletabellenErstellen = this.handletabellenErstellen;
    let tableHeadMonate = this.tableHeadMonate;

    const renderItems = [];
    if (showTable) {
      Object.keys(bwaData).forEach(function (jahr) {
        renderItems.push(
          <TableContainer component={Paper} key={jahr} className={classes.contentItem}>
            <Table aria-label="customized table">
              <TableHead>
                <Row>
                  <CustomCell value={"Rentabilität im Jahr " + jahr}/>
                  {tableHeadMonate()}
                </Row>
              </TableHead>
              <TableBody>
                {Object.keys(rentabilitaetrows).map(function (row) {
                  return (
                    <Row
                      key={row}
                      className={
                        rentabilitaetrows[row].class +
                        " step-" +
                        rentabilitaetrows[row].step
                      }
                    >
                      <CustomCell
                        value={erklaerungstexte[row]["kurzName"]}
                        className={rentabilitaetrows[row].class}
                      />
                      {isLoaded ? (
                        handletabellenErstellen(row, jahr)
                      ) : (
                        <CustomCell/>
                      )}
                    </Row>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        );
      });

      Object.keys(bwaData).forEach(function (jahr) {
        renderItems.push(
          <TableContainer component={Paper} key={jahr} className={classes.contentItem}>
            <Table aria-label="customized table">
              <TableHead>
                <Row>
                  <CustomCell value={"Liquiditätstabelle im Jahr " + jahr}/>
                  {tableHeadMonate()}
                </Row>
              </TableHead>
              <TableBody>
                {Object.keys(liquiditaetrows).map(function (row) {
                  return (
                    <Row
                      key={row}
                      className={
                        liquiditaetrows[row].class +
                        " step-" +
                        liquiditaetrows[row].step
                      }
                    >
                      <CustomCell
                        value={erklaerungstexte[row]["kurzName"]}
                        className={liquiditaetrows[row].class}
                      />
                      {isLoaded ? (
                        handletabellenErstellen(row, jahr)
                      ) : (
                        <CustomCell/>
                      )}
                    </Row>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        );
      });
    }

    return (
      <Fragment>
        <WeiterleitungsModal
          userId={this.props.userId}
          unternehmenId={this.props.unternehmenId}
          weiterleitungsModalOpen={weiterleitungsModalOpen}
          modalScript={modalScript}
          continueAnywayPossible={false}
        />
        <div className={classes.appBarSpacer}/>
        <Typography variant="h4" gutterBottom component="h2">
          {this.erklaerungen.erklaerungHeader}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={12} lg={12}>
            {showTable ? (
              <div className={classes.contentItem}>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <Row>
                        <CustomCell value={"Privater Finanzbedarf"}/>
                        <CustomCell align="right" value={"Wert"}/>
                      </Row>
                    </TableHead>
                    <TableBody>
                      {Object.keys(privaterFinanzBedarfrows).map(function (
                        row,
                        index
                      ) {
                        return (
                          <Row
                            key={row}
                            className={
                              privaterFinanzBedarfrows[row].class +
                              " step-" +
                              privaterFinanzBedarfrows[row].step
                            }
                          >
                            <CustomCell
                              value={erklaerungstexte[row]["kurzName"]}
                              className={privaterFinanzBedarfrows[row].class}
                            />
                            {isLoaded ? (
                              <CustomCell
                                align="right"
                                isCurrency={true}
                                unit={"€"}
                                value={privaterFinanzBedarfdaten[row]}
                                className={privaterFinanzBedarfrows[row].class}
                              />
                            ) : (
                              <CustomCell/>
                            )}
                          </Row>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <Row>
                        <CustomCell value={"Kapital- und Finanzbedarf"}/>
                        <CustomCell align="right" value={"Wert"}/>
                      </Row>
                    </TableHead>
                    <TableBody>
                      {Object.keys(kapitalUndFinanzierungsrows).map(function (
                        row,
                        index
                      ) {
                        return (
                          <Row
                            key={row}
                            className={
                              kapitalUndFinanzierungsrows[row].class +
                              " step-" +
                              kapitalUndFinanzierungsrows[row].step
                            }
                          >
                            <CustomCell
                              value={erklaerungstexte[row]["kurzName"]}
                              className={kapitalUndFinanzierungsrows[row].class}
                            />
                            {isLoaded ? (
                              <CustomCell
                                align="right"
                                isCurrency={true}
                                unit={"€"}
                                value={kapitalUndFinanzierungsdaten[row]}
                                className={kapitalUndFinanzierungsrows[row].class}
                              />
                            ) : (
                              <CustomCell/>
                            )}
                          </Row>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                {renderItems}
              </div>
            ) : <Typography>
              Die Businessplanberechnung kann durch einen Klick unten auf weiter gestartet werden. Achtung: Dadurch
              werden die vorhandenen BWA-Daten gelöscht!
            </Typography>}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Endergebnis);
