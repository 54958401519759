const TextteilSchritte = {
  1: {
    TextteilIntro: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
    PitchDeckIntro: {
      typ: "multiline-readonly",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  },
  2: {
    TextteilTeam: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
    PitchDeckTeam: {
      typ: "multiline-readonly",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  },
  3: {
    TextteilProblem: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
    PitchDeckProblem: {
      typ: "multiline-readonly",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  },
  4: {
    TextteilLoesung: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
    PitchDeckLoesung: {
      typ: "multiline-readonly",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  },
  5: {
    TextteilProdukt: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
    PitchDeckProdukt: {
      typ: "multiline-readonly",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  },
  6: {
    TextteilMarkt: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
    PitchDeckMarkt: {
      typ: "multiline-readonly",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  },
  7: {
    TextteilUSP: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
    PitchDeckUSP: {
      typ: "multiline-readonly",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  },
  8: {
    TextteilMachbarkeit: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
    PitchDeckMachbarkeit: {
      typ: "multiline-readonly",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  },
};

export default TextteilSchritte;
