const rentabilitaetsSchritte = {
  1: {
    RentabilitaetMonatJahr: {
      typ: "monthYearSelect",
      teilDerSummen: [],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: false,
      inEingabe: true,
      key: "rentabilitaet-monthYear-select",
    },
  },
  2: {
    Erloese7: {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["RentabilitaetRohertrag"],
    },
    Erloese19: {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["RentabilitaetRohertrag"],
    },
    RentabilitaetUmsatzerloese: {
      class: "zwischenSumme",
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["RentabilitaetRohertrag"],
    },
    Wareneinkauf: {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: [],
      subtrahendVon: ["RentabilitaetRohertrag"],
    },
    Fremdleistungen: {
      typ: "input",
      teilDerSummen: [],
      subtrahendVon: ["RentabilitaetRohertrag"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
    // SonstigeErloese: {
    //   typ: "input",
    //   datenQuelle: "bwa",
    //   datenTyp: "monthly",
    //   inAusgabe: true,
    //   inEingabe: true,
    //   teilDerSummen: [],
    //   subtrahendVon: ["RentabilitaetRohertrag"],
    // },
    RentabilitaetRohertrag: {
      class: "zwischenSumme",
      typ: "readOnly",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["Betriebsergebnis"],
    },
  },
  3: {
    Personalkosten: {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["RentabilitaetAufwand"],
    },
    Raumkosten: {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["RentabilitaetAufwand"],
    },
    Steuern: {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["RentabilitaetAufwand"],
    },
    // RentabilitaetAbschreibungen: {
    //   typ: "readOnly",
    //   datenQuelle: "bwa",
    //   datenTyp: "monthly",
    //   inAusgabe: true,
    //   inEingabe: true,
    //   teilDerSummen: ["RentabilitaetAufwand"],
    // },
    RentabilitaetGWG: {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["RentabilitaetAbschreibungen"],
    },
    Afa: {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["RentabilitaetAbschreibungen"],
    },
    Versicherungen: {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["RentabilitaetAufwand"],
    },
    "Kfz-Kosten": {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["RentabilitaetAufwand"],
    },
    "Werbe-Reisekosten": {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["RentabilitaetAufwand"],
    },
    "Kosten der Warenabgabe": {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["RentabilitaetAufwand"],
    },
    Zinsaufwand: {
      typ: "input",
      teilDerSummen: ["RentabilitaetAufwand"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
    },
    "Sonstige Kosten": {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["RentabilitaetAufwand"],
    },
    // PrivatBenoetigtePrivatentnahme: {
    //   typ: "readOnly",
    //   teilDerSummen: [],
    //   subtrahendVon: ["LiquiditaetMonatsende"],
    //   datenQuelle: "PrivatFinanzen",
    //   datenTyp: "static",
    //   inAusgabe: true,
    //   inEingabe: false,
    // },
    RentabilitaetAufwand: {
      class: "zwischenSumme",
      typ: "readOnly",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: [],
      subtrahendVon: ["Betriebsergebnis"],
    },
    Betriebsergebnis: {
      class: "summe",
      typ: "readOnly",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: [],
    },
  },
};

export default rentabilitaetsSchritte;
