import React, {Fragment} from 'react';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import InfoIcon from '@mui/icons-material/Info';
import HelpIcon from '@mui/icons-material/Help';

import {Box, Snackbar, withStyles} from "@material-ui/core";
// import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import styles from "../styles";
import erklaerungsUeberschriften from "../LogicData/erklaerungsUeberschriften";
import Button from "@material-ui/core/Button";

import {AppContext} from "../AppContext";
import levelUpSound from "./resources/level-up.wav";
import typeWriterSound from "./resources/type-writing.mp3";
import AppBar from "@material-ui/core/AppBar/AppBar";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import {SnackbarContent} from "@mui/material";

class ChatColumn extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    // this.lastMessage = React.createRef();
    this.ChatSendCard = React.createRef();
    this.state = {
      monat: 1,
      message: "",
      funktion: 0,
      heightOfChatSendCard: 300,
      heightOfChatSendCardChanged: false,
      intervalId: 0,
    }
    this.handleChange = this.handleChange.bind(this);
    this.levelUpAudio = new Audio(levelUpSound);
    this.typeWriterAudio = new Audio(typeWriterSound);
  }

  playSound = (audioFile) => {
    audioFile.play();
  };

// export default function ChatColumn() {
//   const classes = useStyles();
//   const theme = useTheme();
//   const [open, setOpen] = React.useState(false);

  componentDidMount() {
    // const {classes} = this.props;
    // const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    // window.addEventListener('resize', this.calcHeight());
    // enqueueSnackbar('That was easy sadfas f!', {className: classes.bottomRightMessage});
    // enqueueSnackbar('That was easy!', {className: classes.bottomRightMessage});
  }

  componentDidUpdate() {
    // this.getDataFromApi();
    // const rect = this.ChatSendCard.current.getBoundingClientRect();
    // const newHeightOfChatSendCard = this.ChatSendCard.current.getBoundingClientRect().height
    // if (newHeightOfChatSendCard !== this.state.heightOfChatSendCard) {
    //   this.setState({
    //     heightOfChatSendCard: newHeightOfChatSendCard,
    //     heightOfChatSendCardChanged: true,
    //   });
    // } else if (this.state.heightOfChatSendCardChanged) {
    //   this.scrollToBottom()
    //   this.setState({
    //     heightOfChatSendCardChanged: false,
    //   });
    // }
  }

  calcHeight = () => {
    const newHeightOfChatSendCard = this.ChatSendCard.current.getBoundingClientRect().height
    if (newHeightOfChatSendCard !== this.state.heightOfChatSendCard) {
      this.setState({
        heightOfChatSendCard: newHeightOfChatSendCard,
        heightOfChatSendCardChanged: true,
      });
    }
  }

  handleChange = (name) => ({target: {value, checked}}) => {
    console.log("handleChange aufgerufen für: " + name);
    this.setState({
        message: value
      }
    )
  }

  // sendChat = () => {
  //   this.context.postMessage(this.state.message, "user")
  // }

  scrollToBottom = () => {
    // this.props.innerLastMessageRef.current.scrollIntoView({behavior: 'smooth'})
  }

  getInfo = (feld) => {
    //TODO: Make switchable in Profile
    // this.playSound(this.typeWriterAudio);
    this.context.postMessage('Gib mir Informationen zu "' + feld.kurzName + '".', "user", feld, "info")
  }

  getTipp = (feld) => {
    //TODO: Make switchable in Profile
    // this.playSound(this.typeWriterAudio);
    this.context.postMessage('Gib mir Praxistipps zu "' + feld.kurzName + '".', "user", feld, "tipp")
  }

  getFunctions = (feld) => {
    this.context.postMessage('Welche Funktionen kannst du mir zu "' + feld.kurzName + '" anbieten?', "user", feld, "functions")
  }

  continue = () => {
    if (this.context.schritt.continuePossible) {
      this.context.setSchritt({
        key: this.context.schritt.key + 1,
      });
    } else if (this.context.funktion.continuePossible) {
      console.log("Next Funktion!");
      console.log(erklaerungsUeberschriften);
      console.log(this.context.bereich.name);
      console.log(Object.keys(erklaerungsUeberschriften[this.context.bereich.name])[this.context.funktion.key]);
      let nextFunktion = Object.keys(erklaerungsUeberschriften[this.context.bereich.name])[this.context.funktion.key];
      this.context.chatColumnOpen = true;
      if (this.context.savePossible) {
        this.context.funktion.handleSubmit();
        this.context.postMessage("Alles klar, deine Eingaben für " + this.context.funktion.erklaerungHeader + " habe ich gespeichert! Lass uns mit " + erklaerungsUeberschriften[this.context.bereich.name][nextFunktion].erklaerungHeader + " weitermachen...", "bot", null, "functionCompleted");
        this.playSound(this.levelUpAudio);
      } else {
        this.context.postMessage("Alles klar, lass uns " + this.context.funktion.erklaerungHeader + " überspringen und mit " + erklaerungsUeberschriften[this.context.bereich.name][nextFunktion].erklaerungHeader + " weitermachen...", "bot", null, "todo");
      }
      this.context.setPreviousSchritt(0);
      this.props.history.push(`/${erklaerungsUeberschriften[this.context.bereich.name][nextFunktion].url}`);
    } else {
      console.log("No next Schritt or Funktion!");
      if (this.context.savePossible) {
        console.log("Save possible");
        console.log(erklaerungsUeberschriften);
        console.log(this.context.bereich.name);
        console.log(this.context.funktion.key);
        console.log(Object.keys(erklaerungsUeberschriften[this.context.bereich.name])[(this.context.funktion.key) - 1]);
        let nextFunktion = Object.keys(erklaerungsUeberschriften[this.context.bereich.name])[(this.context.funktion.key) - 1];
        this.context.funktion.handleSubmit();
        this.context.setPreviousSchritt(0);
        this.context.chatColumnOpen = true;
        this.context.postMessage("Alles klar, deine Eingaben für " + this.context.funktion.erklaerungHeader + " habe ich gespeichert! Lass uns mit vielleicht mit einem anderen Zeitraum weitermachen...", "bot", null, "functionCompleted");
        this.playSound(this.levelUpAudio);
        this.context.funktion.resetToStepOne();
        // this.props.history.push(`/${erklaerungsUeberschriften[this.context.bereich.name][nextFunktion].url}`);
      } else {
        console.log("Save not possible");
        this.context.chatColumnOpen = true;
        this.context.postMessage("Du kannst hier nicht weitegehen. Bitte fülle alle Daten in diesem Schritt aus oder wähle eine andere Funktion oben im Menü...", "bot", null, "error");
      }
    }
  }

  back = () => {
    if (this.context.schritt.backPossible) {
      this.context.setSchritt({
        key: this.context.schritt.key - 1,
      });
    } else if (this.context.funktion.backPossible) {
      let previousFunktion = Object.keys(erklaerungsUeberschriften[this.context.bereich.name])[this.context.funktion.key - 2];
      this.context.setPreviousSchritt(0);
      this.props.history.push(`/${erklaerungsUeberschriften[this.context.bereich.name][previousFunktion].url}`);
    }
  }

  render() {
    const actions = [
      {icon: <FileCopyIcon/>, name: 'Copy'},
      {icon: <SaveIcon/>, name: 'Save'},
      {icon: <PrintIcon/>, name: 'Print'},
      {icon: <ShareIcon/>, name: 'Share'},
    ];
    const {classes} = this.props;

    return (
      <AppContext.Consumer>
        {({
            chatColumnOpen,
            toggleChatColumn,
            chatSendOpen,
            toggleChatSend,
            chatMenuOpen,
            toggleChatMenu,
            chatFunctionsOpen,
            toggleChatFunctions,
            feld,
            schritt,
            messages
          }) => (
          <Fragment>
            <SpeedDial
              ariaLabel={"Teste"}
              hidden={true}
              icon={<SpeedDialIcon/>}
              direction={"down"}
              color={"secondary"}
              open={true}>
              {/*{actions.map((action) => (*/}
              {/*  <SpeedDialAction*/}
              {/*    key={action.name}*/}
              {/*    icon={action.icon}*/}
              {/*    tooltipTitle={action.name}*/}
              {/*    classes={{*/}
              {/*      fab: classes.floatingButtons*/}
              {/*    }}*/}
              {/*  />*/}
              {/*))}*/}
              <SpeedDialAction
                key={"infofab"}
                icon={<InfoIcon/>}
                tooltipTitle={"Info"}
                classes={{
                  fab: classes.floatingButtons
                }}
                onClick={() => {
                  this.getInfo(feld);
                  this.context.chatColumnOpen = true;
                }}
              />
              <SpeedDialAction
                key={"helpfab"}
                icon={<HelpIcon/>}
                tooltipTitle={"Tipp"}
                classes={{
                  fab: classes.floatingButtons
                }}
                onClick={() => {
                  this.getTipp(feld);
                  this.context.chatColumnOpen = true;
                }}
              />
              {/*<SpeedDialAction*/}
              {/*  key={"buildfab"}*/}
              {/*  icon={chatFunctionsOpen*/}
              {/*    ? <BuildIcon className={classes.fieldActiveIcon}/>*/}
              {/*    : <BuildIcon className={classes.fieldIcon}/>*/}
              {/*  }*/}
              {/*  tooltipTitle={"Funktionen"}*/}
              {/*  classes={{*/}
              {/*    fab: classes.floatingButtons*/}
              {/*  }}*/}
              {/*  onClick={() => {*/}
              {/*    toggleChatFunctions();*/}
              {/*  }}>*/}
              {/*</SpeedDialAction>*/}
            </SpeedDial>
            {this.context.funktion.totalSteps > 0
              ? <AppBar position="fixed" color="transparent" className={classes.progressBar}>
                <MobileStepper
                  variant="dots"
                  classes={{
                    root: classes.progressBarStepper,
                    dots: classes.progressBarDots,
                    dot: classes.progressBarDot,
                    dotActive: classes.progressBarDotActive,
                  }}
                  steps={this.context.funktion.totalSteps}
                  position="static"
                  activeStep={this.context.schritt.key - 1}
                  nextButton={
                    <Button variant="contained" size="small" color="secondary" onClick={this.continue}
                            disabled={this.state.activeStep === 4} className={classes.progressButton}>
                      <KeyboardArrowRight/>
                    </Button>
                  }
                  backButton={
                    <Button variant="contained" size="small" color="secondary" onClick={this.back}
                            disabled={this.state.activeStep === 0} className={classes.progressButton}>
                      <KeyboardArrowLeft/>
                    </Button>
                  }
                />
              </AppBar>
              : <div/>
            }
            {/*<SnackbarProvider*/}
            {/*  maxSnack={2}*/}
            {/*  autoHideDuration={null}*/}
            {/*  anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}*/}
            {/*  open={true}*/}
            {/*  // onClose={handleClose}*/}
            {/*  classes={{*/}
            {/*    root: classes.bottomRightMessageContent,*/}
            {/*  }}*/}
            {/*/>*/}
            <Box className={classes.bottomRightMessageWrapper}>
              {this.context.messages.length > 2
                ? <Snackbar
                  anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                  open={true}
                  // onClose={handleClose}
                  key={'bottomrightmessage3'}
                  classes={{
                    root: classes.bottomRightMessage,
                  }}
                >
                  <SnackbarContent
                    message={this.context.messages[2].message}
                    classes={{
                      root: classes.bottomRightMessageContent,
                    }}
                  />
                </Snackbar>
                : <div/>
              }
              {this.context.messages.length > 1
                ? <Snackbar
                  anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                  open={true}
                  // onClose={handleClose}
                  key={'bottomrightmessage2'}
                  classes={{
                    root: classes.bottomRightMessage,
                  }}
                >
                  <SnackbarContent
                    message={this.context.messages[1].message}
                    classes={{
                      root: classes.bottomRightMessageContent,
                    }}
                  />
                </Snackbar>
                : <div/>
              }
              {this.context.messages.length > 0
                ? <Snackbar
                  anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                  open={true}
                  // onClose={handleClose}
                  key={'bottomrightmessage'}
                  classes={{
                    root: classes.bottomRightMessage,
                  }}
                >
                  <SnackbarContent
                    message={this.context.messages[0].message}
                    classes={{
                      root: classes.bottomRightMessageContent,
                    }}
                  />
                </Snackbar>
                : <div/>
              }
            </Box>
          </Fragment>
        )}
      </AppContext.Consumer>
    );
  }
}

export default withStyles(styles)(React.forwardRef((props, ref) => <ChatColumn
  innerLastMessageRef={ref} {...props}
/>));


//export default withStyles(styles)(ChatColumn);

//     ReactDOM.createPortal(
//       <IconButton
//         color="inherit"
//         aria-label="open drawer"
//         edge="end"
//         onClick={this.handleDrawerOpen}
//         className={clsx(chatColumnOpen && classes.hide)}
//       >
//         <MenuIcon/>
//       </IconButton>,
//       chatAssistentButtonContainer
//     );
//   }
// }


// import Grid from "@material-ui/core/Grid";
// import Card from "@material-ui/core/Card";
// import CardHeader from "@material-ui/core/CardHeader";
// import CardContent from "@material-ui/core/CardContent";
// import Typography from "@material-ui/core/Typography/Typography";
// import React from "react";
// import ZingChart from "zingchart-react";
//
// <Grid item md={4}>
//   <Card className={classes.rightcard}>
//     <CardHeader title={erklaerungHeader} />
//     <CardContent>
//       <Typography variant={"body1"}>{erklaerungText}</Typography>
//     </CardContent>
//   </Card>
//   {step !== 1 ? (
//     {visualisierungsdaten}
//   ) : (
//     <Card className={classes.card}>
//       <CardHeader title="grafische Auswertung"/>
//       <CardContent>
//       </CardContent>
//     </Card>
//   )}
//   {/*</Grid>*/}
//   {/*<Grid item md={4}>*/}
//
//   renderChartMessage = () => {
//   return(
//   <Card className={classes.card}>
//   <CardHeader
//   title="grafische Auswertung"
//   id="grafische-auswertung-header"
//   />
//   <CardContent>
//   <ZingChart
//   id="bwa-visualisierung"
//   data={visualisierungsdaten}
//   className={classes.chart}
//   width={"100%"}
//   />
//   </CardContent>
//   </Card>
//   );
// };


// <Drawer
//   className={classes.drawer}
//   variant="persistent"
//   anchor="right"
//   open={chatColumnOpen}
//   elevation={0}
//   PaperProps={{elevation: 0}}
//   classes={{
//     paper: classes.drawerOuterPaper,
//   }}
// >
//   <Paper
//     className={classes.drawerInnerPaper}
//     // style={{height: `calc(100% - ${this.state.heightOfChatSendCard}px - 64px - 2px)`}}
//     elevation={0}
//   >
//     {messages.map((message, i) => {
//       return (
//         <Card
//           className={`${message.author === "bot" ? classes.chatCardAssistent : classes.chatCardUser}`}
//           key={i}
//         >
//           <CardContent>
//             <Typography dangerouslySetInnerHTML={{__html: message.message}}>
//               {/*{message.message}*/}
//             </Typography>
//           </CardContent>
//         </Card>
//       )
//     })}
//     <div ref={this.props.innerLastMessageRef}/>
//   </Paper>
//
// </Drawer>
//
// <Container
//   className={classes.drawerFooter}
//   ref={this.ChatSendCard}
// >
//   {!chatMenuOpen && chatSendOpen
//     ? <Card
//       className={classes.chatCardSend}
//     >
//       <ChatFunctions/>
//       <Typography
//         className={classes.chatButtonTypography}
//       >
//         <Button
//           size="large"
//           variant="contained"
//           color="primary"
//           className={classes.fieldButton}
//           disabled
//         >
//           {feld.kurzName}
//         </Button>
//         <span className={classes.fieldIconSpan}>
//                         <IconButton onClick={() => {
//                           this.getInfo(feld);
//                           this.context.chatColumnOpen = true;
//                         }}>
//                         <InfoIcon className={classes.fieldIcon}
//                         />
//                         </IconButton>
//                         <IconButton onClick={() => {
//                           this.getTipp(feld);
//                           this.context.chatColumnOpen = true;
//                         }}>
//                         <HelpIcon className={classes.fieldIcon}
//                         />
//                         </IconButton>
//                         <IconButton onClick={() => {
//                           toggleChatFunctions();
//                         }}>
//                           {chatFunctionsOpen
//                             ? <BuildIcon className={classes.fieldActiveIcon}/>
//                             : <BuildIcon className={classes.fieldIcon}/>
//                           }
//                         </IconButton>
//                       </span>
//       </Typography>
//     </Card>
//     : <Fragment/>
//   }
//
//   {chatMenuOpen
//     ? <Card
//       className={classes.chatCardMenu}
//     >
//       <ChatMenu userId={this.props.userId} unternehmenId={this.props.unternehmenId}/>
//     </Card>
//     : <Fragment/>
//   }
//
//   <BottomNavigation className={classes.chatCardSendNavigation}>
//     <IconButton onClick={this.back}>
//       <ChevronLeftIcon/>
//     </IconButton>
//     {/*<IconButton onClick={toggleChatMenu}>*/}
//     {/*  {chatMenuOpen*/}
//     {/*    ? <AppsIcon color="primary"/>*/}
//     {/*    : <AppsIcon/>*/}
//     {/*  }*/}
//     {/*</IconButton>*/}
//     <IconButton onClick={toggleChatSend}>
//       {chatSendOpen
//         ? <ExpandMoreIcon/>
//         : <ExpandLessIcon/>
//       }
//     </IconButton>
//     <IconButton onClick={toggleChatColumn}>
//       {chatColumnOpen
//         ? <SpeakerNotesIcon/>
//         : <SpeakerNotesOffIcon/>
//       }
//     </IconButton>
//     <IconButton onClick={this.continue}>
//       <ChevronRightIcon/>
//     </IconButton>
//   </BottomNavigation>
// </Container>
