import DateRangeIcon from "@material-ui/icons/DateRange";
import FunctionsIcon from "@material-ui/icons/Functions";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import SportsIcon from '@material-ui/icons/Sports';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import BarChartIcon from '@material-ui/icons/BarChart';
import React from "react";

const ChatFunktionen = {
  immerVorhanden: {
    Merkzettel: {
      key: 1000,
      erklaerungHeader: 'Feld auf Merkzettel packen',
      erklaerungText: 'Dieses Feld für später merken.',
      icon: <NoteAddIcon/>,
    },
    SummenTabelle: {
      key: 1100,
      erklaerungHeader: 'Summen berechnen',
      erklaerungText: 'Feld aus mehreren Unterposten zusammenaddieren.',
      icon: <FunctionsIcon/>,
    },
    SponsoredLinks: {
      key: 2000,
      erklaerungHeader: 'Sponsored Link',
      erklaerungText: 'Auch wir brauchen Kohle...',
      icon: <SportsIcon/>,
    },
  },
  Zahlweise: {
    key: 3100,
    erklaerungHeader: 'Zahlweise festlegen',
    erklaerungText: 'Monatsdurchschnitt für Ein- und Auszahlungen, die quartalsweise, halbjährlich oder jährlich getätigt werden, berechnen.',
    icon: <DateRangeIcon/>,
  },
  Prognose: {
    key: 3500,
    erklaerungHeader: 'Änderungen fortschreiben',
    erklaerungText: 'Prognose für die zukünftige Entwicklung dieses Wertes.',
    icon: <TrendingUpIcon/>,
  },
  Abschreibungsassistent: {
    key: 3200,
    erklaerungHeader: 'Abschreibungsassistent',
    erklaerungText: 'Zur genauen Bestimmtung der Abschreibungsdauer.',
    icon: <HourglassEmptyIcon/>,
  },
  Bewertung: {
    key: 3201,
    erklaerungHeader: 'Bewertung der Eingabe',
    erklaerungText: 'Eingabe grafisch auswerten.',
    icon: <BarChartIcon/>,
  }
};

export default (ChatFunktionen);
