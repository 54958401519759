import Typography from "@material-ui/core/Typography/Typography";
import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import styles from "../styles";

class Stammdaten extends React.Component {
  state = {
    isLoaded: false,
    tutorialEnabled: true,
    jahr: 2016,
    items: {
      "Personalaufwand Gesamt": "",
      Gemeinkosten: "",
    },
  };

  componentDidMount() {
    this.getDataFromApi(2016);
  }

  setSampleData = (jahr) => {
    console.log("setSampleData for " + jahr);

    this.setState({
      isLoaded: true,
      jahr: jahr,
      items: this.getClearData(),
    });
  };

  getClearData = () => {
    console.log("getClearData");

    return {
      "Personalaufwand Gesamt": "",
      Gemeinkosten: "",
    };
  };

  getDataFromApi = (jahr) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/v1/unternehmendata/${this.props.userId}/${this.props.unternehmenId}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (jahr === false) {
            this.setState({
              isLoaded: false,
              jahr: "",
              items: this.getClearData(),
            });
          } else {
            if (result.stammdaten) {
              if (result.stammdaten[jahr]) {
                console.log("Setting State from API Readings for " + jahr);
                this.setState({
                  isLoaded: true,
                  jahr: jahr,
                  items: {
                    ...this.getClearData(),
                    ...result.stammdaten[jahr],
                  },
                });
              } else {
                console.log("Setting State from Sample Data (1) for " + jahr);
                this.setSampleData(jahr);
              }
            } else {
              console.log("Setting State from Sample Data (2) for " + jahr);
              this.setSampleData(jahr);
            }
          }
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error: error,
          });
        }
      );
  };

  handleChange = (name) => ({target: {value}}) => {
    const {jahr} = this.state;
    console.log("handleChange aufgerufen für: " + name);
    const {items} = this.state;

    if (name === "Jahr") {
      console.log("handleChange is jahr!");
      if (value !== "" && jahr !== value) {
        console.log("jahr is set!");
        this.getDataFromApi(value);
      }
    } else {
      value = value.replace(/,/g, ".");
      this.setState({
        items: {
          ...items,
          [name]: value,
        },
      });
    }
  };

  handleSubmit = () => {
    // TODO: Validate

    const {items, jahr} = this.state;

    fetch(
      `${process.env.REACT_APP_API_URL}/v1/unternehmendata/${this.props.userId}/${this.props.unternehmenId}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          stammdaten: {
            [jahr]: {
              ...items,
            },
          },
        }),
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            jahr: jahr,
            items: result.stammdaten[jahr],
          });
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error: error,
          });
        }
      );
  };

  onExit = () => {
    this.setState({
      tutorialEnabled: false,
    });
  };

  render() {
    const {classes} = this.props;
    const {isLoaded, items, jahr} = this.state;

    return (
      <Fragment>
        <div className={classes.appBarSpacer}/>
        <Typography variant="h4" gutterBottom component="h2">
          Stammdaten eingeben
        </Typography>
        <form className={classes.container} noValidate autoComplete="off">
          <Card className={classes.card}>
            <CardHeader title="Jahr"/>
            <CardContent>
              <FormControl
                className={classes.formControl}
                id={"edit-stammdaten-jahr-control"}
              >
                <InputLabel htmlFor="edit-stammdaten-bundesland">
                  Jahr
                </InputLabel>
                <Select
                  value={jahr}
                  onChange={this.handleChange("Jahr")}
                  inputProps={{
                    name: "jahr",
                    id: "edit-stammdaten-jahr",
                  }}
                >
                  <MenuItem value="">
                    <em>Bitte wählen</em>
                  </MenuItem>
                  <MenuItem value={2016}>2016</MenuItem>
                  <MenuItem value={2017}>2017</MenuItem>
                  <MenuItem value={2018}>2018</MenuItem>
                  <MenuItem value={2019}>2019</MenuItem>
                  <MenuItem value={2020}>2020</MenuItem>
                </Select>
              </FormControl>
            </CardContent>
          </Card>
          {isLoaded ? (
            <Fragment>
              <Card className={classes.card}>
                <CardHeader title="Stammdaten"/>
                <CardContent>
                  <TextField
                    id="edit-stammdaten-personalaufwand"
                    label="Personalaufwand Gesamt"
                    className={classes.textField}
                    value={items["Personalaufwand Gesamt"]}
                    onChange={this.handleChange("Personalaufwand Gesamt")}
                    margin="normal"
                  />
                  <TextField
                    id="edit-stammdaten-gemeinkosten"
                    label="Gemeinkosten"
                    className={classes.textField}
                    value={items["Gemeinkosten"]}
                    onChange={this.handleChange("Gemeinkosten")}
                    margin="normal"
                  />
                </CardContent>
              </Card>

              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.handleSubmit}
              >
                Speichern
              </Button>
            </Fragment>
          ) : (
            <Typography variant="h5" gutterBottom component="h2">
              <CircularProgress className={classes.progress}/>
            </Typography>
          )}
        </form>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Stammdaten);
