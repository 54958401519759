import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography/Typography";
import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CustomTextField from "../CustomTags/CustomTextField";
import Grid from "@material-ui/core/Grid";
import erklaerungsTexte from "../LogicData/erklaerungsTexte";
import erklaerungsUeberschriften from "../LogicData/erklaerungsUeberschriften";
import EingabeModal from "../CustomTags/EingabeModal";
import styles from "../styles";
import SpezifischerMonatSchritte from "../LogicData/SpezifischerMonatSchritte";
import WeiterleitungsModal from "../CustomTags/Weiterleitungsmodal";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import {AppContext} from "../AppContext";

class SpezifischerMonat extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.erklaerungen = {
      ...erklaerungsUeberschriften.Businessplan.SpezifischerMonat,
      ...erklaerungsTexte,
    };

    var allEingabeItems = [];
    var readOnlyFields = [];

    Object.keys(SpezifischerMonatSchritte).forEach(function (outerKey) {
      Object.keys(SpezifischerMonatSchritte[outerKey]).forEach(function (
        innerKey
      ) {
        if (
          SpezifischerMonatSchritte[outerKey][innerKey]["inEingabe"] === true
        ) {
          allEingabeItems = [...allEingabeItems, innerKey];
          if (
            SpezifischerMonatSchritte[outerKey][innerKey]["typ"] === "readOnly"
          ) {
            readOnlyFields = [...readOnlyFields, innerKey];
          }
        } else {
          delete SpezifischerMonatSchritte[outerKey][innerKey];
        }
      });
    });

    this.readOnlyFields = readOnlyFields;

    console.log(this.readOnlyFields);

    this.schritte = SpezifischerMonatSchritte;

    this.allItems = allEingabeItems;

    this.allItemsReversed = {};

    this.allItems.map((element, key) => {
      this.allItemsReversed[element] = 0;
    });
    // this.getRumpfjahrFromApi();

    this.state = {
      step: 1,
      isLoaded: false,
      showNextMonthButton: false,
      saveSuccessful: false,
      tutorialEnabled: true,
      ergebnisVorhanden: false,
      eingabeModalOpen: false,
      weiterleitungsModalOpen: false,
      modalScript: "",
      typischerMonatData: null,
      monat: "",
      jahr: "",
      rumpfjahr: "",
      rumpfmonat: "",
      erklaerungHeader: this.erklaerungen["erklaerungHeader"],
      erklaerungText: this.erklaerungen["erklaerungText"],
      items: this.allItemsReversed,
      editList: [],

      vergleichsmonat: {
        Erloese7: "",
        Erloese19: "",
        Bestandsveraenderung: "",
        Gesamtleistung: "",
        Wareneinkauf: "",
        Fremdleistungen: "",
        SonstigeErloese: "",
        Rohertrag: "",
        Personalkosten: "",
        Raumkosten: "",
        Steuern: "",
        Versicherungen: "",
        "Kfz-Kosten": "",
        "Werbe/Reisekosten": "",
        "Kosten der Warenabgabe": "",
        Afa: "",
        Reparaturen: "",
        "So Kosten": "",
        Gesamtkosten: "",
        Betriebsergebnis: "",
        Zinsaufwand: "",
        "So neutraler Aufwand": "",
        "Neutraler Aufwand": "",
        Zinsertrag: "",
        SoNeutralerErtrag: "",
        "Neutraler Ertrag": "",
        "Neutrales Ergebnis": "",
        Ergebnis: "",
      },
      vergleichstext: "",
    };
  }

  componentDidMount() {
    this.context.setFunktion({
      key: this.erklaerungen.key,
      name: this.erklaerungen.name,
      erklaerungHeader: this.erklaerungen.erklaerungHeader,
      erklaerungText: this.erklaerungen.erklaerungText,
      continuePossible: true,
      backPossible: true,
      totalSteps: Object.keys(this.schritte).length,
      handleSubmit: () => this.handleSubmit(),
      resetToStepOne: () => this.resetToStepOne(),
    });
    // this.context.setSchritt({
    //   key: 1,
    //   erklaerungHeader: this.erklaerungen["Schritte"][1]
    //     .erklaerungHeader,
    //   erklaerungText: this.erklaerungen["Schritte"][1].erklaerungText,
    //   continuePossible: false,
    //   backPossible: false,
    // });
    this.context.setBereich({
      name: "Businessplan"
    });
    this.getRumpfjahrFromApi();
    this.resetToStepOne();
  }

  resetToStepOne = () => {
    this.context.setSchritt({
      key: 1,
      erklaerungHeader: this.erklaerungen["Schritte"][1]
        .erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][1].erklaerungText,
      continuePossible: false,
      backPossible: false,
    });
    this.context.setSavePossible(false);
  }

  componentDidUpdate() {
    if (this.state.step !== this.context.schritt.key) {
      this.handleStepChange();
    }
  }

  handleStepChange = () => {
    const newStep = this.context.schritt.key;
    this.setState({
      step: newStep,
      erklaerungHeader: this.erklaerungen["Schritte"][newStep]
        .erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
    });

    if (newStep > 1) {
      this.context.setSchritt({
        key: newStep,
        erklaerungHeader: this.erklaerungen["Schritte"][newStep]
          .erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
        continuePossible: this.continuePossible(newStep),
        backPossible: true,
      });
    } else {
      this.context.setSchritt({
        key: newStep,
        erklaerungHeader: this.erklaerungen["Schritte"][newStep]
          .erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
        continuePossible: this.continuePossible(newStep),
        backPossible: false,
      });
    }
  };

  getClearData = () => {
    return this.allItemsReversed;
  };

  getTypischerMonatData = (typischerMonat) => {
    return {
      ...this.getClearData(),
      ...typischerMonat,
    };
  };

  //Errorhandlung
  error = (error) => {
    this.setState({
      isLoaded: false,
      error: error,
    }, () => this.handleStepChange());
  };

  getRumpfjahrFromApi = async () => {
    let result = await this.context.getUnternehmenDataFromApi("all");
    //Typischer Monat testen
    let typischerMonatData;
    if (
      result.typischerMonat &&
      "LiquiditaetEinzahlungen" in result.typischerMonat &&
      0 !== result.typischerMonat["LiquiditaetEinzahlungen"].toString().length
    ) {
      typischerMonatData = result.typischerMonat;
      this.setState({
        typischerMonatData: typischerMonatData,
        kapitalUndFinanzierungData: result.kapitalUndFinanzierung
      });
    } else {
      this.setState({
        weiterleitungsModalOpen: true,
        modalScript: "TypischerMonat",
      });
      return;
    }
    if (result.rumpfjahr && parseInt(result.rumpfjahr) > 2015) {
      this.setState({
        rumpfjahr: parseInt(result.rumpfjahr),
        rumpfmonat: parseInt(result.rumpfmonat),
        eingabeModalOpen: false,
      });
    } else {
      this.setState({
        rumpfjahr: "",
        rumpfmonat: "",
        eingabeModalOpen: true,
      });
    }
  };

  dataImport = (result, jahr, monat) => {
    let typischerMonatData;
    if (
      result.typischerMonat &&
      "LiquiditaetEinzahlungen" in result.typischerMonat &&
      0 !== result.typischerMonat["LiquiditaetEinzahlungen"].toString().length
    ) {
      typischerMonatData = result.typischerMonat;
      this.setState({
        typischerMonatData: typischerMonatData,
        kapitalUndFinanzierungData: result.kapitalUndFinanzierung
      });
    } else {
      this.setState({
        weiterleitungsModalOpen: true,
        modalScript: "TypischerMonat",
      });
      return;
    }

    if (result.rumpfjahr && parseInt(result.rumpfjahr) > 2015) {
      this.setState({
        rumpfjahr: parseInt(result.rumpfjahr),
        rumpfmonat: parseInt(result.rumpfmonat),
        eingabeModalOpen: false,
      });
    } else {
      this.setState({
        rumpfjahr: "",
        rumpfmonat: "",
        eingabeModalOpen: true,
      });
    }

    var typischerMonat = this.getTypischerMonatData(result.typischerMonat);

    if (jahr === "" || monat === "") {
      this.setState({
        isLoaded: false,
        jahr: "",
        monat: "",
        items: typischerMonat,
      }, () => this.handleStepChange());
      this.context.setSavePossible(false);
    } else {
      this.context.setSavePossible(true);
      if (result.spezifischerMonat) {
        if (result.spezifischerMonat[jahr]) {
          if (result.spezifischerMonat[jahr][monat]) {
            // if (
            //   result.spezifischerMonat[jahr][monat]["Betriebsergebnis"] &&
            //   0 !==
            //   result.spezifischerMonat[jahr][monat][
            //     "Betriebsergebnis"
            //     ].toString().length
            // ) {
            this.setState({
              isLoaded: true,
              jahr: jahr,
              monat: monat,
              ergebnisVorhanden: true,
              items: {
                ...typischerMonat,
                ...result.spezifischerMonat[jahr][monat],
              },
              editList: [
                ...Object.keys(result.spezifischerMonat[jahr][monat]),
              ],
            }, () => this.handleStepChange());
            return;
            // } else {
            //   this.setState({
            //     isLoaded: true,
            //     jahr: jahr,
            //     monat: monat,
            //     ergebnisVorhanden: false,
            //     items: {
            //       ...typischerMonat,
            //       ...result.spezifischerMonat[jahr][monat],
            //     },
            //     editList: [
            //       ...Object.keys(result.spezifischerMonat[jahr][monat]),
            //     ],
            //   });
            // }
            // return;
          }
        }
      }
    }
    this.setState({
      isLoaded: false,
      jahr: jahr,
      monat: monat,
      ergebnisVorhanden: false,
      items: typischerMonat,
    }, () => this.handleStepChange());
  };

  // getDataFromApi = (jahr, monat) => {
  //   fetch(
  //     `${process.env.REACT_APP_API_URL}/v1/unternehmendata/${this.props.userId}/${this.props.unternehmenId}`
  //   )
  //     .then((res) => res.json())
  //     .then((result) => {
  //       this.dataImport(result, jahr, monat);
  //     }, this.error());
  // };

  getDataFromApi = async (jahr, monat) => {
    let result = await this.context.getUnternehmenDataFromApi("all");
    this.dataImport(result, jahr, monat);
    this.context.setFunktion({
      key: this.erklaerungen.key,
      name: this.erklaerungen.name,
      erklaerungHeader: this.erklaerungen.erklaerungHeader,
      erklaerungText: this.erklaerungen.erklaerungText,
      continuePossible: true,
      backPossible: true,
      totalSteps: Object.keys(this.schritte).length,
      month: monat,
      year: jahr,
      handleSubmit: () => this.handleSubmit(),
      resetToStepOne: () => this.resetToStepOne(),
    });
  }

  continuePossible = (step) => {
    const {jahr, monat, rumpfjahr, rumpfmonat} = this.state;

    switch (step) {
      case "Rumpfjahr":
        return rumpfjahr > 2015 && rumpfmonat > 0;
      case 1:
        // return this.state.isLoaded;
        return (
          jahr !== undefined &&
          0 !== jahr.toString().length &&
          monat !== undefined &&
          0 !== monat.toString().length
        );
      case 2:
        return true;
      case 3:
        return false;
      default:
        return false;
    }
  };

  handleSnackbarClose = (event, reason) => {
    this.setState({
      saveSuccessful: false,
    });
  };

  handleChange = (name) => ({target: {value, checked}}) => {
    const {items, jahr, monat} = this.state;

    if (name === "Rumpfjahr") {
      this.setState({
        rumpfjahr: value,
      });
    } else if (name === "Rumpfmonat") {
      this.setState({
        rumpfmonat: value,
      });
    } else if (name === "Jahr") {
      if (monat !== "" && value !== "" && this.state.jahr !== value) {
        this.getDataFromApi(value, monat);
      } else {
        this.setState({
          jahr: value,
        }, () => this.handleStepChange());
      }
    } else if (name === "Monat") {
      if (jahr !== "" && value !== "" && this.state.jahr !== value) {
        this.getDataFromApi(jahr, value);
      } else {
        this.setState({
          monat: value,
        }, () => this.handleStepChange());
      }
    } else {
      if (value === "") {
        value = 0;
      } else {
        value = parseFloat(value);
      }

      let summen = {};
      const steps = Object.keys(this.schritte);

      this.allItems.map((item) => {
        steps.map((schritt) => {
          let itemsOfCurrentStep = Object.keys(this.schritte[schritt]);
          itemsOfCurrentStep.map((feld) => {
            if (this.schritte[schritt][feld]["typ"] === "monthYearSelect") {
            } else {
              if (
                this.schritte[schritt][feld]["teilDerSummen"].includes(item)
              ) {
                if (typeof summen[item] === "undefined") {
                  summen[item] = 0;
                }
                if (feld === name) {
                  summen[item] += value;
                } else {
                  summen[item] += items[feld];
                }
              }
              if (
                typeof this.schritte[schritt][feld]["subtrahendVon"] !==
                "undefined" &&
                this.schritte[schritt][feld]["subtrahendVon"].includes(item)
              ) {
                if (typeof summen[item] === "undefined") {
                  summen[item] = 0;
                }
                if (feld === name) {
                  summen[item] -= value;
                } else {
                  summen[item] -= items[feld];
                }
              }
            }
          });
        });
      });

      this.setState({
        items: {
          ...items,
          ...summen,
          [name]: value,
        },
      });
    }
  };

  handleModalSaveAndClose = () => {
    if (this.continuePossible("Rumpfjahr")) {
      this.setState({
        eingabeModalOpen: false,
      });
      this.handleRumpfjahrSubmit(this.state.rumpfjahr, this.state.rumpfmonat);
    }
  };

  handleFocus = (name) => {
    this.setState({
      erklaerungHeader: this.erklaerungen[name].erklaerungHeader,
      erklaerungText: this.erklaerungen[name].erklaerungText,
    });
    this.context.setFeld({
      key: name,
      // ...this.erklaerungen[name],
      // ...this.schritte[this.state.step][name],
    });
  };

  handleFocusEvent = (name, event) => {
    this.setState({
      erklaerungHeader: this.erklaerungen[name].erklaerungHeader,
      erklaerungText: this.erklaerungen[name].erklaerungText,
    });
    event.target.select();
    this.context.setFeld({
      key: name,
      // ...this.erklaerungen[name],
      // ...this.schritte[this.state.step][name],
    });
  };

  handleContinue = () => {
    this.setState({
      step: this.state.step + 1,
      erklaerungHeader: this.erklaerungen["Schritte"][this.state.step + 1]
        .erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][this.state.step + 1]
        .erklaerungText,
    });
  };

  handleBack = () => {
    const {step} = this.state;
    if (step > 1) {
      this.setState({
        step: step - 1,
        erklaerungHeader: this.erklaerungen["Schritte"][this.state.step - 1]
          .erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][this.state.step - 1]
          .erklaerungText,
      });
    }
  };

  handleNextMonth = () => {
    const {jahr, monat} = this.state;

    let monatneu = (monat + 1) % 12;
    let jahrneu = jahr;
    if (monatneu === 0) {
      monatneu = 12;
    } else if (monatneu === 1) {
      jahrneu = jahr + 1;
    }

    this.setState({
      step: 2,
      ergebnisVorhanden: false,
      erklaerungHeader: this.erklaerungen["Schritte"][2].erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][2].erklaerungText,
      isLoaded: false,
      jahr: jahrneu,
      monat: monatneu,
      saveSuccessful: false,
      showNextMonthButton: false,
    });

    this.getDataFromApi(jahrneu, monatneu);
  };

  handleRumpfjahrSubmit = (rumpfjahr, rumpfmonat) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/v1/unternehmendata/${this.props.userId}/${this.props.unternehmenId}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          rumpfjahr: rumpfjahr,
          rumpfmonat: rumpfmonat,
        }),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          saveSuccessful: true,
        });
      }, this.error());
  };

  handleSubmit = async () => {
    const {items, jahr, monat, editList} = this.state;

    var submitItems = {};

    editList.map((feld) => {
      submitItems[feld] = items[feld];
    });

    this.readOnlyFields.map((feld) => {
      submitItems[feld] = items[feld];
    });

    var deleteItems = [];

    this.allItems.map((feld) => {
      if (!submitItems.hasOwnProperty(feld)) {
        deleteItems[feld] = 0;
      }
    });

    var submitdata = {
      [jahr]: {
        [monat]: {
          ...submitItems,
        },
      },
    }

    let result = await this.context.postUnternehmenDataToApi("spezifischerMonat", submitdata);

    this.setState({
      isLoaded: true,
      items: {
//TODO:              ...this.getTypischerMonatData(result.typischerMonat),
        ...result.spezifischerMonat[jahr][monat],
      },
      saveSuccessful: true,
      showNextMonthButton: true,
    });
  }

  // handleSubmit = () => {
  //   // TODO: Validate
  //
  //   const {items, jahr, monat, editList} = this.state;
  //
  //   var submitItems = {};
  //
  //   editList.map((feld) => {
  //     submitItems[feld] = items[feld];
  //   });
  //
  //   this.readOnlyFields.map((feld) => {
  //     submitItems[feld] = items[feld];
  //   });
  //
  //   var deleteItems = [];
  //
  //   this.allItems.map((feld) => {
  //     if (!submitItems.hasOwnProperty(feld)) {
  //       deleteItems[feld] = 0;
  //     }
  //   });
  //
  //
  //
  //   fetch(
  //     `${process.env.REACT_APP_API_URL}/v1/unternehmendata/${this.props.userId}/${this.props.unternehmenId}`,
  //     {
  //       method: "PUT",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         spezifischerMonat: {
  //           [jahr]: {
  //             [monat]: {
  //               ...submitItems,
  //             },
  //           },
  //         },
  //       }),
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then((result) => {
  //       this.setState({
  //         isLoaded: true,
  //         items: {
  //           ...this.getTypischerMonatData(result.typischerMonat),
  //           ...result.spezifischerMonat[jahr][monat],
  //         },
  //         saveSuccessful: true,
  //         showNextMonthButton: true,
  //       }, () => this.handleStepChange());
  //     }, this.error())
  //     .then((success) => {
  //       fetch(
  //         `${process.env.REACT_APP_API_URL}/v1/unternehmendata/${this.props.userId}/${this.props.unternehmenId}`,
  //         {
  //           method: "DELETE",
  //           headers: {
  //             Accept: "application/json",
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             spezifischerMonat: {
  //               [jahr]: {
  //                 [monat]: {
  //                   ...deleteItems,
  //                 },
  //               },
  //             },
  //           }),
  //         }
  //       );
  //     });
  // };

  onExit = () => {
    this.setState({
      tutorialEnabled: false,
    });
  };

  editField = (name) => {
    const {items, editList, typischerMonatData} = this.state;
    if (!editList.includes(name)) {
      this.setState({
        editList: [...editList, name],
      });
    } else {
      var editListLocal = editList;
      var index = editListLocal.indexOf(name);
      if (index > -1) {
        editListLocal.splice(index, 1);
      }

      this.setState({
        items: {
          ...items,
          [name]: typischerMonatData[name],
        },
        editList: [...editListLocal],
      });
    }
  };

  createField = (name, properties) => {
    const {classes} = this.props;
    const {items, editList} = this.state;
    if (properties.typ === "readOnly") {
      return (
        <Fragment>
          <Grid container>
            <Grid item>
              <CustomTextField
                id={"edit-rentabiliteat-" + name}
                label={this.erklaerungen[name]["kurzName"]}
                className={classes.textField}
                value={items[name]}
                onChange={this.handleChange(name)}
                margin="normal"
                isCurrency={true}
                isReadOnly={false}
                unit={"€"}
                helperText={
                  "Typischer Monat: " +
                  this.state.typischerMonatData[name] +
                  " €"
                }
                onFocus={() => this.handleFocus(name)}
              />
            </Grid>
          </Grid>
        </Fragment>
      );
    } else if (properties.typ === "input") {
      return (
        <Fragment>
          <Grid container>
            <Grid item>
              <CustomTextField
                id={"edit-rentabiliteat-" + name}
                label={this.erklaerungen[name]["kurzName"]}
                className={this.props.classes.textField}
                value={this.state.items[name]}
                onChange={this.handleChange(name)}
                margin="normal"
                disabled={!editList.includes(name)}
                variant="outlined"
                isCurrency={true}
                isReadOnly={false}
                unit={"€"}
                helperText={
                  "Typischer Monat: " +
                  this.state.typischerMonatData[name] +
                  " €"
                }
                onFocus={(event) => this.handleFocusEvent(name, event)}
              />
            </Grid>
            <Grid item>
              <IconButton onClick={() => this.editField(name)}>
                <EditIcon fontSize="small"/>
              </IconButton>
            </Grid>
          </Grid>
        </Fragment>
      );
    } else {
      return (
        <Fragment key={properties.key}>
          <FormControl
            className={this.props.classes.formControl}
            id={"edit-rentabiliteat-jahr-control"}
          >
            <InputLabel htmlFor="edit-rentabiliteat-jahr">Jahr</InputLabel>
            <Select
              value={this.state.jahr}
              onChange={this.handleChange("Jahr")}
              inputProps={{
                name: "jahr",
                id: "edit-rentabiliteat-jahr",
              }}
            >
              <MenuItem value="">
                <em>Bitte wählen</em>
              </MenuItem>
              <MenuItem value={this.state.rumpfjahr}>
                Rumpfgeschäftsjahr
              </MenuItem>
              <MenuItem value={this.state.rumpfjahr + 1}>erstes Jahr</MenuItem>
              <MenuItem value={this.state.rumpfjahr + 2}>zweites Jahr</MenuItem>
              <MenuItem value={this.state.rumpfjahr + 3}>drittes Jahr</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            className={this.props.classes.formControl}
            id={"edit-rentabiliteat-monat-control"}
          >
            <InputLabel htmlFor="edit-rentabiliteat-monat">Monat</InputLabel>
            <Select
              value={this.state.monat}
              onChange={this.handleChange("Monat")}
              inputProps={{
                name: "monat",
                id: "edit-rentabiliteat-monat",
              }}
            >
              <MenuItem value="">
                <em>Bitte wählen</em>
              </MenuItem>
              <MenuItem value={1}>Januar</MenuItem>
              <MenuItem value={2}>Februar</MenuItem>
              <MenuItem value={3}>März</MenuItem>
              <MenuItem value={4}>April</MenuItem>
              <MenuItem value={5}>Mai</MenuItem>
              <MenuItem value={6}>Juni</MenuItem>
              <MenuItem value={7}>Juli</MenuItem>
              <MenuItem value={8}>August</MenuItem>
              <MenuItem value={9}>September</MenuItem>
              <MenuItem value={10}>Oktober</MenuItem>
              <MenuItem value={11}>November</MenuItem>
              <MenuItem value={12}>Dezember</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            className={this.props.classes.formControl}
            id={"edit-rentabiliteat-ergebnisVorhanden-control"}
          >
            <FormControlLabel
              control={
                <Checkbox
                  disabled
                  checked={this.state.ergebnisVorhanden}
                  onChange={this.handleChange("ergebnisVorhanden")}
                  value="ergebnisVorhanden"
                  color="primary"
                />
              }
              label="bereits eingegeben"
            />
          </FormControl>
        </Fragment>
      );
    }
  };

  createInputField = (name) => {
    return (
      <CustomTextField
        id={"edit-rentabiliteat-" + name}
        label={this.erklaerungen[name]["kurzName"]}
        className={this.props.classes.textField}
        value={this.state.items[name]}
        onChange={this.handleChange(name)}
        margin="normal"
        isCurrency={true}
        isReadOnly={false}
        unit={"€"}
        helperText={
          "Vergleichswert: " +
          this.state.vergleichsmonat["Gesamtleistung"] +
          this.state.vergleichstext
        }
        onFocus={(event) => this.handleFocusEvent(name, event)}
      />
    );
  };

  createReadOnlyField = (name) => {
    return (
      <CustomTextField
        id={"edit-rentabiliteat-" + name}
        label={this.erklaerungen[name]["kurzName"]}
        className={this.props.classes.textField}
        value={this.state.items[name]}
        onChange={this.handleChange(name)}
        margin="normal"
        isCurrency={true}
        isReadOnly={true}
        unit={"€"}
        helperText={
          "Vergleichswert: " +
          this.state.vergleichsmonat["Gesamtleistung"] +
          this.state.vergleichstext
        }
        onFocus={() => this.handleFocus(name)}
      />
    );
  };

  createStepCard = (step) => {
    return (
      <Card className={this.props.classes.card}>
        <CardHeader
          title={this.erklaerungen["Schritte"][step]["erklaerungHeader"]}
        />
        <CardContent>
          {Object.keys(this.schritte[step]).map((feld) => {
            return this.createField(feld, this.schritte[step][feld]);
          })}
        </CardContent>
      </Card>
    );
  };

  render() {
    const {classes, userId, unternehmenId} = this.props;
    const {
      step,
      rumpfmonat,
      rumpfjahr,
      eingabeModalOpen,
      weiterleitungsModalOpen,
      modalScript,
    } = this.state;

    return (
      <Fragment>
        <div className={classes.appBarSpacer}/>
        <div>
          <WeiterleitungsModal
            userId={this.props.userId}
            unternehmenId={this.props.unternehmenId}
            weiterleitungsModalOpen={weiterleitungsModalOpen}
            modalScript={modalScript}
          />
          <EingabeModal
            handleModalSaveAndClose={this.handleModalSaveAndClose}
            continuePossible={this.continuePossible}
            handleChange={this.handleChange}
            eingabeModalOpen={eingabeModalOpen}
            rumpfjahr={rumpfjahr}
            rumpfmonat={rumpfmonat}
            userId={userId}
            unternehmenId={unternehmenId}
          />
        </div>
        <Typography variant="h4" gutterBottom component="h2">
          {this.erklaerungen.erklaerungHeader}
          {this.state.jahr && this.state.monat && (
            <Fragment>
              &nbsp;{this.state.monat}/{this.state.jahr}
            </Fragment>
          )}
        </Typography>
        {/*<Typography variant="h4" gutterBottom component="h2">*/}
        {/*  {this.erklaerungen.erklaerungHeader}*/}
        {/*  {this.state.jahr !== "" &&*/}
        {/*  this.state.monat !== "" &&*/}
        {/*  ` ${this.state.monat}/${this.state.jahr}`}*/}
        {/*</Typography>*/}
        <Grid container spacing={2}>
          <Grid item md={8}>
            <form className={classes.container} noValidate autoComplete="off">
              {this.createStepCard(step)}

              {/*{step === Object.keys(this.schritte).length ? (*/}
              {/*  <Button*/}
              {/*    disabled={!this.continuePossible(step)}*/}
              {/*    variant="contained"*/}
              {/*    color="primary"*/}
              {/*    className={classes.button}*/}
              {/*    onClick={this.handleSubmit}*/}
              {/*  >*/}
              {/*    Speichern*/}
              {/*  </Button>*/}
              {/*) : (*/}
              {/*  <Button*/}
              {/*    disabled={true}*/}
              {/*    variant="contained"*/}
              {/*    color="primary"*/}
              {/*    className={classes.button}*/}
              {/*    onClick={this.handleSubmit}*/}
              {/*  >*/}
              {/*    Speichern*/}
              {/*  </Button>*/}
              {/*)}*/}
              {/*{this.state.showNextMonthButton ? (*/}
              {/*  <Button*/}
              {/*    disabled={false}*/}
              {/*    variant="contained"*/}
              {/*    color="primary"*/}
              {/*    className={classes.button}*/}
              {/*    onClick={this.handleNextMonth}*/}
              {/*  >*/}
              {/*    nächster Monat*/}
              {/*  </Button>*/}
              {/*) : (*/}
              {/*  <Button*/}
              {/*    disabled*/}
              {/*    variant="contained"*/}
              {/*    color="primary"*/}
              {/*    className={classes.button}*/}
              {/*  >*/}
              {/*    nächster Monat*/}
              {/*  </Button>*/}
              {/*)}*/}
            </form>
          </Grid>
          <Grid item md={4}>
            <Card className={this.props.classes.rightcard}>
              <CardContent>
                <Typography variant={"body2"}>
                  Als Rumpfgeschäftsjahr wurde folgendes Jahr festgelegt:{" "}
                  {this.state.rumpfjahr}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/*<Snackbar*/}
        {/*  anchorOrigin={{*/}
        {/*    vertical: "bottom",*/}
        {/*    horizontal: "left",*/}
        {/*  }}*/}
        {/*  open={this.state.saveSuccessful}*/}
        {/*  autoHideDuration={6000}*/}
        {/*  onClose={this.handleSnackbarClose}*/}
        {/*  ContentProps={{*/}
        {/*    "aria-describedby": "message-id",*/}
        {/*  }}*/}
        {/*  message={<span id="message-id">Daten erfolgreich gespeichert!</span>}*/}
        {/*/>*/}

        {/*<Card className={classes.progressCard}>*/}
        {/*  <CardHeader title="Fortschritt"/>*/}
        {/*  <CardContent>*/}
        {/*    <LinearProgress*/}
        {/*      variant="determinate"*/}
        {/*      value={((step - 1) / (Object.keys(this.erklaerungen["Schritte"]).length - 1)) * 100}*/}
        {/*    />*/}
        {/*    <Typography variant="body1">*/}
        {/*      {this.erklaerungen.erklaerungHeader} &mdash; Schritt {step} von {(Object.keys(this.erklaerungen["Schritte"]).length)}: {this.context.schritt.erklaerungHeader}*/}
        {/*    </Typography>*/}
        {/*  </CardContent>*/}
        {/*</Card>*/}
      </Fragment>
    );
  }
}

export default withStyles(styles)(SpezifischerMonat);
