import React, {Fragment} from "react";
import {withStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import StoreIcon from "@material-ui/icons/Store";
import styles from "../styles";
import {AppContext} from "../AppContext";
import Typography from "@material-ui/core/Typography/Typography";
import {Card} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import AvatarImagePath from '../CustomTags/beratungsheld-logo.png'

const plzRegex = RegExp(/^[0-9]{1,6}$/);

class MeinUnternehmen extends React.Component {
  static contextType = AppContext;
  editor = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isNewUnternehmen: false,
      currentUnternehmenEntry: null,
      isLoading: false,
      unternehmenAnchorEl: null,
      redirect: false,
      redirectTarget: "",
      unternehmenName: "",
      unternehmenData: {
        unternehemenRechtsform: "",
        unternehmenZusatz: "",
        umsatzSteuerID: "",
        steuerNummer: "",
        adresse: "",
        plz: "",
        ort: ""
      },
      formValidierung: {
        unternehemenRechtsform: "",
        unternehmenName: "",
        unternehmenZusatz: "",
        umsatzSteuerID: "",
        steuerNummer: "",
        adresse: "",
        plz: "",
        ort: "",
      },
      image: AvatarImagePath,
      position: {x: 0.5, y: 0.5},
      scale: 1,
      rotate: 0,
      borderRadius: 0,
      preview: undefined,
      width: 200,
      height: 200,
      disableCanvasRotation: false,
      isTransparent: false,
      backgroundColor: undefined,
      color: [0, 0, 0, 0.5],
      showGrid: false,
    };
  }


  handleNewImage = (e) => {
    if (e.target.files?.[0]) {
      this.setState({image: e.target.files[0]})
    }
  }

  handleSave = () => {
    const img = this.editor.current?.getImageScaledToCanvas().toDataURL()
    const rect = this.editor.current?.getCroppingRect()

    console.log("Preview!");
    console.log(img);
    console.log(rect);

    if (!img || !rect) return

    console.log("Preview can be done!");

    this.setState({
      preview: {
        img,
        rect,
        scale: this.state.scale,
        width: this.state.width,
        height: this.state.height,
        borderRadius: this.state.borderRadius,
      },
    })
  }

  handleScale = (e) => {
    const scale = parseFloat(e.target.value)
    this.setState({scale})
  }

  handleDisableCanvasRotation = (e) => {
    this.setState({disableCanvasRotation: e.target.checked})
  }

  rotateScale = (e) => {
    e.preventDefault()
    this.setState({rotate: parseFloat(e.target.value)})
  }

  rotateLeft = (e) => {
    e.preventDefault()
    this.setState({rotate: (this.state.rotate - 90) % 360})
  }

  rotateRight = (e) => {
    e.preventDefault()
    this.setState({rotate: (this.state.rotate + 90) % 360})
  }

  handleBorderRadius = (e) => {
    this.setState({borderRadius: parseInt(e.target.value)})
  }

  handleXPosition = (e) => {
    this.setState({
      position: {...this.state.position, x: parseFloat(e.target.value)},
    })
  }

  handleYPosition = (e) => {
    this.setState({
      position: {...this.state.position, y: parseFloat(e.target.value)},
    })
  }

  handleWidth = (e) => {
    this.setState({width: parseInt(e.target.value)})
  }

  handleHeight = (e) => {
    this.setState({height: parseInt(e.target.value)})
  }

  logCallback(e) {
    console.log('callback', e)
  }

  handlePositionChange = (position) => {
    this.setState({position})
  }

  setBackgroundColor = (e) => {
    this.setState({backgroundColor: e.target.value})
  }

  //create full hex
  fullHex = (hex) => {
    let r = hex.slice(1, 2);
    let g = hex.slice(2, 3);
    let b = hex.slice(3, 4);

    r = parseInt(r + r, 16);
    g = parseInt(g + g, 16);
    b = parseInt(b + b, 16);

    // return {r, g, b}
    return [r, g, b];
  }

//convert hex to rgb
  hex2rgb = (hex) => {
    if (hex.length === 4) {
      return this.fullHex(hex);
    }

    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return [r, g, b];
  }

  setColor = (e) => {
    let color = this.hex2rgb(e.target.value);
    this.setState({color: color})
  }

  setTransparent = (e) => {
    const isTransparent = e.target.checked
    // set color to white initially
    const backgroundColor = isTransparent ? '#fff' : undefined

    this.setState({backgroundColor, isTransparent})
  }

  handleShowGrid = (e) =>
    this.setState({showGrid: e.target.checked})

  handleSubmit = async () => {
    // if(this.state.isNewUnternehmen) {
    //
    // } else {
    let response = await this.context.postOtherUnternehmenData(this.state.currentUnternehmenEntry,
      this.state.unternehmenName, this.state.unternehmenData);
    this.context.refreshUnternehmenListe();
    // }
  }

  // handleChange = (e) => {
  //   e.preventDefault();
  //
  //   const {name, value} = e.target;
  //   let formValidierung = {...this.state.formValidierung};
  //
  //   switch (name) {
  //     case "firmenName":
  //       formValidierung.firmenName =
  //         value.length < 50 ? "" : "Bitte geben Sie weniger als 50 Zeichen ein";
  //       break;
  //     case "firmenZusatz":
  //       formValidierung.firmenZusatz =
  //         value.length < 50 ? "Bitte geben Sie weniger als 50 Zeichen ein" : "";
  //       break;
  //     case "adresse":
  //       formValidierung.adresse =
  //         value.length < 50 ? "" : "Bitte geben Sie weniger als 50 Zeichen ein";
  //       break;
  //     case "plz":
  //       formValidierung.plz = plzRegex.test(value)
  //         ? ""
  //         : "Bite geben Sie eine valide Postleitzahl ein";
  //       break;
  //     case "ort":
  //       formValidierung.ort =
  //         value.length < 30 ? "" : "Bitte geben Sie weniger als 30 Zeichen ein";
  //       break;
  //     default:
  //       break;
  //   }
  //
  //   this.setState({formValidierung, [name]: value});
  // };

  handleClick = async (key) => {
    console.log("secondList clicked for key: " + key)
    const result = await this.context.getOtherUnternehmenData(key);
    if (result.unternehmenData) {
      this.setState({
        currentUnternehmenEntry: key,
        unternehmenName: result.unternehmenName,
        unternehmenData: {
          unternehemenRechtsform: result.unternehmenData.unternehemenRechtsform || "",
          unternehmenZusatz: result.unternehmenData.unternehmenZusatz || "",
          umsatzSteuerID: result.unternehmenData.umsatzSteuerID || "",
          steuerNummer: result.unternehmenData.steuerNummer || "",
          adresse: result.unternehmenData.adresse || "",
          plz: result.unternehmenData.plz || "",
          ort: result.unternehmenData.ort || ""
        }
      })
    } else {
      this.setState({
        currentUnternehmenEntry: key,
        unternehmenName: result.unternehmenName,
        unternehmenData: {
          unternehemenRechtsform: "",
          unternehmenZusatz: "",
          umsatzSteuerID: "",
          steuerNummer: "",
          adresse: "",
          plz: "",
          ort: ""
        },
      })
    }
  }

  handleNeuesUnternehmen = () => {
    this.props.history.push('/ersteschritte');

    // this.setState({
    //     currentUnternehmenEntry: null,
    //     unternehmenName: "",
    //     unternehmenData: {
    //       unternehemenRechtsform: "",
    //       unternehmenZusatz: "",
    //       umsatzSteuerID: "",
    //       steuerNummer: "",
    //       adresse: "",
    //       plz: "",
    //       ort: ""
    //     }
    //   }
    // )
  }

  createUnternehmenList = () => {
    return (
      <List>
        {this.context.unternehmenListe.map((unternehmen, index) => {
          return (
            <ListItem
              key={unternehmen.id}
              button
              onClick={() => this.handleClick(unternehmen.id)}
            >
              <ListItemIcon>
                <StoreIcon/>
              </ListItemIcon>
              <ListItemText primary={unternehmen.name}/>
            </ListItem>
          );
        })
        }
        <ListItem
          button
          onClick={this.handleNeuesUnternehmen}
        >
          <ListItemIcon>
            <AddBusinessIcon color="primary"/>
          </ListItemIcon>
          <ListItemText primary="Neues Unternehmen hinzufügen" color="primary"/>
        </ListItem>
      </List>
    )
  }

  handleChange = (name) => ({target: {value, checked}}) => {
    if (name === "unternehmenName") {
      this.setState({
        [name]: value,
      });
    } else {
      this.setState({
        unternehmenData: {
          ...this.state.unternehmenData,
          [name]: value,
        }
      })
    }
  };

  render() {
    const {formValidierung, neuesUnternehmenOpen, unternehmenAnchorEl} = this.state;
    const {classes} = this.props;

    return (
      <Fragment>
        <div className={classes.appBarSpacer}/>
        <Typography variant="h4" gutterBottom component="h2">
          Unternehmen verwalten
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="stretch"
          spacing={2}
          className={classes.fullheightGrid}>
          <Grid item md={12} lg={12}>
            <Card className={classes.card}>
              {/*<CardHeader*/}
              {/*  title="Unternehmen verwalten"*/}
              {/*/>*/}
              <CardContent>
                {this.createUnternehmenList()}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(MeinUnternehmen);
