import ChatFunktionen from "./ChatFunktionen";

const kapitalUndFinanzierungsSchritte = {
  1: {
    FinanzierungBauinvestitionen: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungSummeSachinvestitionen"],
      funktionen: [ChatFunktionen.Abschreibungsassistent, ChatFunktionen.Bewertung],
    },
    FinanzierungGeschaeftsausstattung: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungBGA"],
      funktionen: [ChatFunktionen.Abschreibungsassistent, ChatFunktionen.Bewertung],
    },
    FinanzierungMaschinen: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungBGA"],
      funktionen: [ChatFunktionen.Abschreibungsassistent, ChatFunktionen.Bewertung],
    },
    FinanzierungComputerSoftware: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungBGA"],
      funktionen: [ChatFunktionen.Abschreibungsassistent, ChatFunktionen.Bewertung],
    },
    FinanzierungBGA: {
      typ: "readOnly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungSummeSachinvestitionen"],
    },
    FinanzierungFahrzeuge: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungSummeSachinvestitionen"],
      funktionen: [ChatFunktionen.Abschreibungsassistent, ChatFunktionen.Bewertung],
    },
    FinanzierungSonstigeInvestitionen: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungSummeSachinvestitionen"],
      funktionen: [ChatFunktionen.Abschreibungsassistent, ChatFunktionen.Bewertung],
    },
    FinanzierungWarenMateriallager: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungSummeSachinvestitionen"],
      funktionen: [ChatFunktionen.Abschreibungsassistent, ChatFunktionen.Bewertung],
    },
    FinanzierungKaufpreis: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungSummeSachinvestitionen"],
    },
    FinanzierungSummeSachinvestitionen: {
      class: "summe",
      typ: "readOnly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungKapitalbedarf"],
      funktionen: [ChatFunktionen.Bewertung],
    },
  },
  2: {
    FinanzierungBeratungen: {
      typ: "readOnly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungSummeGruendungsnebenkosten"],
    },
    FinanzierungUnternehmensberater: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungBeratungen"],
    },
    FinanzierungRechtsanwalt: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungBeratungen"],
    },
    FinanzierungFortbildungen: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungBeratungen"],
    },
    FinanzierungKautionen: {
      typ: "readOnly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungSummeGruendungsnebenkosten"],
    },
    FinanzierungMietkautionen: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungKautionen"],
    },
    FinanzierungGebuehrenBehoerden: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungKautionen"],
    },
    FinanzierungNotargebuehren: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungKautionen"],
    },
    FinanzierungMaklerprovisionen: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungKautionen"],
    },
    FinanzierungKonzessionen: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungKautionen"],
    },
    FinanzierungBueroerstausstattung: {
      typ: "readOnly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungSummeGruendungsnebenkosten"],
    },
    FinanzierungBueromaterial: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungBueroerstausstattung"],
    },
    FinanzierungBriefpapierVistenkarten: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungBueroerstausstattung"],
    },
    FinanzierungMarkteinfuehrungWerbung: {
      typ: "readOnly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungSummeGruendungsnebenkosten"],
    },
    FinanzierungWerbematerialien: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungMarkteinfuehrungWerbung"],
    },
    FinanzierungInternetpraesenz: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungMarkteinfuehrungWerbung"],
    },
    FinanzierungAnzeigen: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungMarkteinfuehrungWerbung"],
    },
    FinanzierungEroeffnungsfeier: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungMarkteinfuehrungWerbung"],
    },
    FinanzierungSummeGruendungsnebenkosten: {
      class: "summe",
      typ: "readOnly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungKapitalbedarf"],
    },
  },
  3: {
    FinanzierungSummeBuergschaften: {
      class: "summe",
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      // teilDerSummen: ["FinanzierungGesamtkapitalAvalkredite"],
      teilDerSummen: ["FinanzierungKapitalbedarf", "FinanzierungGesamtkapitalAvalkredite"],
      // subtrahendVon: ["FinanzierungGesamtkapitalAvalkredite"],
    },
    FinanzierungLiquiditaetsbedarf: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungKapitalbedarf"],
    },
    FinanzierungLiquiditaetsreserve: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungKapitalbedarf"],
    },
    // FinanzierungSummeBetriebsmittel: {
    //   class: "summe",
    //   typ: "readOnly",
    //   inAusgabe: true,
    //   inEingabe: true,
    //   teilDerSummen: ["FinanzierungKapitalbedarf"],
    // },
    FinanzierungKapitalbedarf: {
      class: "summe",
      typ: "readOnly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: [],
      subtrahendVon: ["FinanzierungFinanzierungslueckeReserve"],
    },
    FinanzierungEigenmittel: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungSummeEigenkapital"],
    },
    FinanzierungSachwerteEigenleistungen: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungSummeEigenkapital"],
    },
    FinanzierungSonstiges: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungSummeEigenkapital"],
    },
    FinanzierungSummeEigenkapital: {
      class: "summe",
      typ: "readOnly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungGesamtkapital"],
    },
    FinanzierungFoerderdarlehen: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungSummeFremdkapital"],
    },
    FinanzierungHausbankdarlehen: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungSummeFremdkapital"],
    },
    FinanzierungKontokorrrentkredit: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungSummeFremdkapital"],
    },
    FinanzierungPrivateDarlehen: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungSummeFremdkapital"],
    },
    FinanzierungSonstigeFinanzierungsmittel: {
      typ: "input",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungSummeFremdkapital"],
    },
    FinanzierungSummeFremdkapital: {
      class: "summe",
      typ: "readOnly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungGesamtkapital"],
    },
    FinanzierungGesamtkapital: {
      class: "summe",
      typ: "readOnly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungGesamtkapitalAvalkredite"],
    },
    // FinanzierungAvalkredite: {
    //   typ: "readOnly",
    //   inAusgabe: true,
    //   inEingabe: true,
    //   teilDerSummen: ["FinanzierungGesamtkapitalAvalkredite"],
    // },
    FinanzierungGesamtkapitalAvalkredite: {
      class: "summe",
      typ: "readOnly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["FinanzierungFinanzierungslueckeReserve"],
    },
    FinanzierungFinanzierungslueckeReserve: {
      typ: "readOnly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: [],
    },
  },
};

export default kapitalUndFinanzierungsSchritte;
