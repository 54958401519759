import Typography from "@material-ui/core/Typography/Typography";
import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CustomTextField from "../CustomTags/CustomTextField";
import Grid from "@material-ui/core/Grid";

import erklaerungsTexte from "../LogicData/erklaerungsTexte";
import erklaerungsUeberschriften from "../LogicData/erklaerungsUeberschriften";
import kapitalUndFinanzierungsSchritte from "../LogicData/KapitalUndFinanzierungsSchritte";
import styles from "../styles";
import {AppContext} from "../AppContext";


class KaptialUndFinanzierung extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.erklaerungen = {
      ...erklaerungsUeberschriften.Businessplan.KaptialUndFinanzierung,
      ...erklaerungsTexte,
    };

    this.schritte = kapitalUndFinanzierungsSchritte;

    var allEingabeItems = [];

    Object.keys(kapitalUndFinanzierungsSchritte).forEach(function (outerKey) {
      Object.keys(kapitalUndFinanzierungsSchritte[outerKey]).forEach(function (
        innerKey
      ) {
        if (kapitalUndFinanzierungsSchritte[outerKey][innerKey]["inEingabe"] === true) {
          allEingabeItems = [...allEingabeItems, innerKey];
        } else {
          delete kapitalUndFinanzierungsSchritte[outerKey][innerKey];
        }
      });
    });

    this.allItems = allEingabeItems;

    this.allItemsReversed = {};

    this.allItems.map((element, key) => {
      this.allItemsReversed[element] = 0;
    });


    this.state = {
      step: 1,
      isLoaded: false,
      tutorialEnabled: true,
      erklaerungHeader: this.erklaerungen["erklaerungHeader"],
      erklaerungText: this.erklaerungen["erklaerungText"],
      kurzName: "",
      items: this.allItemsReversed,
    };
  }

  componentDidMount() {
    this.context.setFunktion({
      key: this.erklaerungen.key,
      name: this.erklaerungen.name,
      erklaerungHeader: this.erklaerungen.erklaerungHeader,
      erklaerungText: this.erklaerungen.erklaerungText,
      continuePossible: true,
      backPossible: true,
      totalSteps: Object.keys(this.schritte).length,
      handleSubmit: () => this.handleSubmit(),
    });
    this.context.setSchritt({
      key: 1,
      erklaerungHeader: this.erklaerungen["Schritte"][1]
        .erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][1].erklaerungText,
      continuePossible: true,
      backPossible: false,
      totalSteps: Object.keys(this.erklaerungen["Schritte"]).length,
    });
    this.context.setBereich({
      name: "Businessplan"
    });
    this.context.setSavePossible(true);

    this.getDataFromApi();
  }

  getDataFromApi = async () => {
    let result = await this.context.getUnternehmenDataFromApi("kapitalUndFinanzierung"); //.then((result) => {console.log(result)});

    if (result.kapitalUndFinanzierung) {
      console.log("Setting State from API Readings");
      if (
        result.kapitalUndFinanzierung["FinanzierungFinanzierungslueckeReserve"] &&
        result.kapitalUndFinanzierung["FinanzierungFinanzierungslueckeReserve"] !== 0
      ) {
        this.setState({
          isLoaded: true,
          ergebnisVorhanden: true,
          items: {
            ...this.getClearData(),
            ...result.kapitalUndFinanzierung,
          },
        });
      } else {
        this.setState({
          isLoaded: true,
          ergebnisVorhanden: false,
          items: {
            ...this.getClearData(),
            ...result.kapitalUndFinanzierung,
          },
        });
      }
    } else {
      console.log("Setting State from Sample Data");
      this.setState({
        isLoaded: false,
        ergebnisVorhanden: false,
        items: this.getClearData(),
      });
    }
  }

  async componentDidUpdate() {
    if (this.state.step !== this.context.schritt.key) {
      this.handleStepChange();
    }
  }

  handleStepChange = () => {
    const newStep = this.context.schritt.key;
    this.setState({
      step: newStep,
      erklaerungHeader: this.erklaerungen["Schritte"][newStep]
        .erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
    });

    if (newStep > 1) {
      this.context.setSchritt({
        key: newStep,
        erklaerungHeader: this.erklaerungen["Schritte"][newStep]
          .erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
        continuePossible: this.continuePossible(newStep),
        backPossible: true,
      });
    } else {
      this.context.setSchritt({
        key: newStep,
        erklaerungHeader: this.erklaerungen["Schritte"][newStep]
          .erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
        continuePossible: this.continuePossible(newStep),
        backPossible: false,
      });
    }
  };

  getClearData = () => {
    console.log("getClearData");

    return this.allItemsReversed;
  };
  error = (error) => {
    this.setState({
      isLoaded: false,
      error: error,
    });
  };

  continuePossible = (step) => {
    console.log("continuePossible für Step " + step + " aufgerufen.");
    return(step < this.context.funktion.anzahlSchritte)
  };

  handleSnackbarClose = (event, reason) => {
    this.setState({
      saveSuccessful: false,
    });
  };

  handleChange = (name) => ({target: {value, checked}}) => {
    console.log("handleChange aufgerufen für: " + name);
    const {items} = this.state;

    if (value === "") {
      value = 0;
    } else {
      value = parseFloat(value);
    }

    let summen = {};

    // const keys = [ ...(this.schritte[step]) ];
    // let itemsOfCurrentStep = Object.keys(this.schritte[step]);
    const steps = Object.keys(this.schritte);

    this.allItems.map((item) => {
      steps.map((schritt) => {
        let itemsOfCurrentStep = Object.keys(this.schritte[schritt]);
        itemsOfCurrentStep.map((feld) => {
          if (this.schritte[schritt][feld]["teilDerSummen"].includes(item)) {
            if (typeof summen[item] === "undefined") {
              console.log("Reset of Summe of " + item);
              summen[item] = 0;
            }
            if (feld === name) {
              summen[item] += value;
            } else {
              summen[item] += items[feld];
            }
          }
          if (
            typeof this.schritte[schritt][feld]["subtrahendVon"] !==
            "undefined" &&
            this.schritte[schritt][feld]["subtrahendVon"].includes(item)
          ) {
            if (typeof summen[item] === "undefined") {
              console.log("Reset of Summe of " + item);
              summen[item] = 0;
            }
            if (feld === name) {
              summen[item] -= value;
            } else {
              summen[item] -= items[feld];
            }
          }
        });
      });
    });

    console.log("Final setState...");
    console.log(this.summen);

    this.setState({
      items: {
        ...items,
        ...summen,
        [name]: value,
      },
    });
  };

  handleFocus = (name) => {
    this.setState({
      erklaerungHeader: this.erklaerungen[name].erklaerungHeader,
      erklaerungText: this.erklaerungen[name].erklaerungText,
      kurzName: this.erklaerungen[name].kurzName,
    });
    this.context.setFeld({
      key: name,
      // ...this.erklaerungen[name],
      // ...this.schritte[this.state.step][name],
    });
  };

  handleFocusEvent = (name, event) => {
    this.setState({
      erklaerungHeader: this.erklaerungen[name].erklaerungHeader,
      erklaerungText: this.erklaerungen[name].erklaerungText,
      kurzName: this.erklaerungen[name].kurzName,
    });
    event.target.select();
    this.context.setFeld({
      key: name,
      // ...this.erklaerungen[name],
      // ...this.schritte[this.state.step][name],
    });
  };

  handleContinue = () => {
    const {step} = this.state;
    this.setState({
      step: step + 1,
      erklaerungHeader: this.erklaerungen["Schritte"][step + 1].erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][step + 1].erklaerungText,
    });
  };

  handleBack = () => {
    const {step} = this.state;
    if (step > 1) {
      this.setState({
        step: step - 1,
        erklaerungHeader: this.erklaerungen["Schritte"][step - 1].erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][step - 1].erklaerungText,
      });
    }
  };

  handleSubmit = async () => {
    const {items} = this.state;

    let result = await this.context.postUnternehmenDataToApi("kapitalUndFinanzierung", items);

    this.setState({
      isLoaded: true,
      items: result.kapitalUndFinanzierung,
      saveSuccessful: true,
    });
  }

  onExit = () => {
    this.setState({
      tutorialEnabled: false,
    });
  };

  createField = (name, properties) => {
    const {classes} = this.props;
    const {items} = this.state;
    if (properties.typ === "readOnly") {
      console.log("generating Field " + name);

      return (
        <CustomTextField
          id={"edit-kapitalundfinanzierung-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.textField}
          value={items[name]}
          onChange={this.handleChange(name)}
          margin="normal"
          isCurrency={true}
          isReadOnly={true}
          unit={"€"}
          onFocus={() => this.handleFocus(name)}
        />
      );
    } else {
      return (
        <CustomTextField
          id={"edit-kapitalundfinanzierung-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.textField}
          value={items[name]}
          onChange={this.handleChange(name)}
          margin="normal"
          variant="outlined"
          isCurrency={true}
          isReadOnly={false}
          unit={"€"}
          onFocus={(event) => this.handleFocusEvent(name, event)}
        />
      );
    }
  };

  createInputField = (name) => {
    const {classes} = this.props;
    const {items} = this.state;
    return (
      <CustomTextField
        id={"edit-kapitalundfinanzierung-" + name}
        label={this.erklaerungen[name]["kurzName"]}
        className={classes.textField}
        value={items[name]}
        onChange={this.handleChange(name)}
        margin="normal"
        isCurrency={true}
        isReadOnly={false}
        unit={"€"}
        onFocus={(event) => this.handleFocusEvent(name, event)}
      />
    );
  };

  createReadOnlyField = (name) => {
    const {classes} = this.props;
    const {items} = this.state;
    return (
      <CustomTextField
        id={"edit-kapitalundfinanzierung-" + name}
        label={this.erklaerungen[name]["kurzName"]}
        className={classes.textField}
        value={items[name]}
        onChange={this.handleChange(name)}
        margin="normal"
        isCurrency={true}
        isReadOnly={true}
        unit={"€"}
        onFocus={() => this.handleFocus(name)}
      />
    );
  };

  createStepCard = (step) => {
    const {classes} = this.props;
    return (
      <Card className={classes.card}>
        <CardHeader title={this.erklaerungen["Schritte"][step]["erklaerungHeader"]}/>
        <CardContent>
          {Object.keys(this.schritte[step]).map((feld) => {
            return this.createField(feld, this.schritte[step][feld]);
          })}
        </CardContent>
      </Card>
    );
  };

  render() {
    const {classes} = this.props;
    const {
      step,
      kurzName,
      erklaerungHeader,
      erklaerungText,
      saveSuccessful,
    } = this.state;

    return (
      <Fragment>
        <div className={classes.appBarSpacer}/>
        <Typography variant="h4" gutterBottom component="h2">
          {this.erklaerungen.erklaerungHeader}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={12} lg={12}>
            <form className={classes.container} noValidate autoComplete="off">
              {this.createStepCard(step)}
            </form>
          </Grid>
        </Grid>

        {/*<Card className={classes.progressCard}>*/}
        {/*  <CardHeader title="Fortschritt"/>*/}
        {/*  <CardContent>*/}
        {/*    <MobileStepper*/}
        {/*      variant="dots"*/}
        {/*      steps={6}*/}
        {/*      position="static"*/}
        {/*      activeStep={this.state.activeStep}*/}
        {/*      sx={{ maxWidth: 400, flexGrow: 1 }}*/}
        {/*      nextButton={*/}
        {/*        <Button size="small" onClick={this.handleNext} disabled={this.state.activeStep === 5}>*/}
        {/*          Next*/}
        {/*          <KeyboardArrowRight />*/}
        {/*        </Button>*/}
        {/*      }*/}
        {/*      backButton={*/}
        {/*        <Button size="small" onClick={this.handleBack} disabled={this.state.activeStep === 0}>*/}
        {/*          <KeyboardArrowLeft />*/}
        {/*          Back*/}
        {/*        </Button>*/}
        {/*      }*/}
        {/*    />*/}
        {/*    <LinearProgress*/}
        {/*      variant="determinate"*/}
        {/*      value={((step - 1) / (Object.keys(this.erklaerungen["Schritte"]).length - 1)) * 100}*/}
        {/*    />*/}
        {/*    <Typography variant="body1">*/}
        {/*      {this.erklaerungen.erklaerungHeader} &mdash; Schritt {step} von {(Object.keys(this.erklaerungen["Schritte"]).length)}: {this.context.schritt.erklaerungHeader}*/}
        {/*    </Typography>*/}
        {/*  </CardContent>*/}
        {/*</Card>*/}
      </Fragment>
    );
  }
}

export default withStyles(styles)(KaptialUndFinanzierung);
