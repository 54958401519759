import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CustomTextField from "../CustomTags/CustomTextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import erklaerungsTexte from "../LogicData/erklaerungsTexte";
import erklaerungsUeberschriften from "../LogicData/erklaerungsUeberschriften";
import privaterFinanzBedarfSchritte from "../LogicData/PrivaterFinanzbedarfSchritte";
import styles from "../styles";
import {AppContext} from "../AppContext";

const erklaerungen = {
  ...erklaerungsUeberschriften.Businessplan.PrivaterFinanzbedarf,
  ...erklaerungsTexte,
};

const Schritte = privaterFinanzBedarfSchritte;

const AllItems = [
  ...Schritte[1]["Input"],
  ...Schritte[2]["Input"],
  ...Schritte[1]["ReadOnly"],
  ...Schritte[2]["ReadOnly"],
];

let AllItemsReversed = {PrivatGehaltLebenspartner: 0};

AllItems.map((element) => {
  AllItemsReversed[element] = 0;
});

class PrivaterFinanzbedarf extends React.Component {
  static contextType = AppContext;

  state = {
    step: 1,
    isLoaded: false,
    tutorialEnabled: true,
    erklaerungHeader: erklaerungen["erklaerungHeader"],
    erklaerungText: erklaerungen["erklaerungText"],
    items: AllItemsReversed,
    checked: true,
  };

  componentDidMount() {
    this.context.setFunktion({
      key: erklaerungen.key,
      name: erklaerungen.name,
      erklaerungHeader: erklaerungen.erklaerungHeader,
      erklaerungText: erklaerungen.erklaerungText,
      continuePossible: true,
      backPossible: false,
      totalSteps: Object.keys(erklaerungen["Schritte"]).length,
      handleSubmit: () => this.handleSubmit(),
    });
    this.context.setSchritt({
      key: 1,
      erklaerungHeader: erklaerungen["Schritte"][1]
        .erklaerungHeader,
      erklaerungText: erklaerungen["Schritte"][1].erklaerungText,
      continuePossible: true,
      backPossible: false,
    });
    this.context.setBereich({
      name: "Businessplan"
    });
    this.context.setSavePossible(true);

    this.getDataFromApi();
  }

  componentDidUpdate() {
    if (this.state.step !== this.context.schritt.key) {
      this.handleStepChange();
    }
  }

  handleStepChange = () => {
    const newStep = this.context.schritt.key;
    this.setState({
      step: newStep,
      erklaerungHeader: erklaerungen["Schritte"][newStep]
        .erklaerungHeader,
      erklaerungText: erklaerungen["Schritte"][newStep].erklaerungText,
    });

    if (newStep > 1) {
      this.context.setSchritt({
        key: newStep,
        erklaerungHeader: erklaerungen["Schritte"][newStep]
          .erklaerungHeader,
        erklaerungText: erklaerungen["Schritte"][newStep].erklaerungText,
        continuePossible: this.continuePossible(newStep),
        backPossible: true,
      });
    } else {
      this.context.setSchritt({
        key: newStep,
        erklaerungHeader: erklaerungen["Schritte"][newStep]
          .erklaerungHeader,
        erklaerungText: erklaerungen["Schritte"][newStep].erklaerungText,
        continuePossible: this.continuePossible(newStep),
        backPossible: false,
      });
    }
  };

  getClearData = () => {
    console.log("getClearData");

    return AllItemsReversed;
  };

  getDataFromApi = async () => {
    let result = await this.context.getUnternehmenDataFromApi("privatFinanzen"); //.then((result) => {console.log(result)});

    if (result.privatFinanzen) {
      console.log("Setting State from API Readings");
      if (
        result.privatFinanzen["PrivatBenoetigtePrivatentnahme"] &&
        result.privatFinanzen["PrivatBenoetigtePrivatentnahme"] !== 0
      ) {
        this.setState({
          isLoaded: true,
          ergebnisVorhanden: true,
          items: {
            ...this.getClearData(),
            ...result.privatFinanzen,
          },
        });
      } else {
        this.setState({
          isLoaded: true,
          ergebnisVorhanden: false,
          items: {
            ...this.getClearData(),
            ...result.privatFinanzen,
          },
        });
      }
    } else {
      console.log("Setting State from Sample Data");
      this.setState({
        isLoaded: false,
        ergebnisVorhanden: false,
        items: this.getClearData(),
      });
    }
  }

  continuePossible = (step) => {
    console.log("continuePossible für Step " + step + " aufgerufen.");

    switch (step) {
      case 1:
        return true;
      case 2:
        return false;
      case 3:
        return false;
      default:
        return false;
    }
  };

  handleSnackbarClose = (event, reason) => {
    this.setState({
      saveSuccessful: false,
    });
  };

  handleChange = (name) => ({target: {value}}) => {
    console.log("handleChange aufgerufen für: " + name);
    const {step, items} = this.state;

    if (value === "") {
      value = 0;
    } else {
      value = parseFloat(value);
    }

    let einnahmengesamt = 0;
    let ausgabengesamt = 0;
    let privatentnahme = 0;
    const keys = [...Schritte[step]["Input"]];
    //First Step
    switch (step) {
      case 1:
        for (const key of keys) {
          if (key === name) {
            einnahmengesamt += value;
          } else {
            einnahmengesamt += items[key];
          }
        }

        privatentnahme = items["PrivatAusgabenInsgesamt"] - einnahmengesamt;
        if (privatentnahme < 0) {
          privatentnahme = 0;
        }

        console.log("Final setState Step 1...");

        this.setState({
          items: {
            ...items,
            [name]: value,
            PrivatEinnahmenInsgesamt: einnahmengesamt,
            PrivatBenoetigtePrivatentnahme: privatentnahme,
          },
        });
        break;
      case 2:
        for (const key of keys) {
          if (key === name) {
            ausgabengesamt += value;
          } else {
            ausgabengesamt += items[key];
          }
        }

        privatentnahme = ausgabengesamt - items["PrivatEinnahmenInsgesamt"];
        if (privatentnahme < 0) {
          privatentnahme = 0;
        }

        console.log("Final setState Step 2...");

        this.setState({
          items: {
            ...items,
            [name]: value,
            PrivatAusgabenInsgesamt: ausgabengesamt,
            PrivatBenoetigtePrivatentnahme: privatentnahme,
          },
        });
        break;
      default:
        privatentnahme =
          items["PrivatAusgabenInsgesamt"] - items["PrivatEinnahmenInsgesamt"];
        if (privatentnahme < 0) {
          privatentnahme = 0;
        }

        this.setState({
          items: {
            ...items,
            PrivatBenoetigtePrivatentnahme: privatentnahme,
          },
        });
        break;
    }
  };

  handleFocus = (name) => {
    this.setState({
      erklaerungHeader: erklaerungen[name].erklaerungHeader,
      erklaerungText: erklaerungen[name].erklaerungText,
    });
    this.context.setFeld({
      key: name,
      // ...erklaerungen[name],
      // ...privaterFinanzBedarfSchritte2[this.state.step][name],
    });
  };

  handleFocusEvent = (name, event) => {
    this.setState({
      erklaerungHeader: erklaerungen[name].erklaerungHeader,
      erklaerungText: erklaerungen[name].erklaerungText,
    });
    event.target.select();
    this.context.setFeld({
      key: name,
      // ...erklaerungen[name],
      // ...privaterFinanzBedarfSchritte2[this.state.step][name],
    });
  };

  handleContinue = () => {
    const {step} = this.state;
    this.setState({
      step: step + 1,
      erklaerungHeader: erklaerungen["Schritte"][step + 1].erklaerungHeader,
      erklaerungText: erklaerungen["Schritte"][step + 1].erklaerungText,
    });
  };

  handleBack = () => {
    const {step} = this.state;
    if (step > 1) {
      this.setState({
        step: step - 1,
        erklaerungHeader: erklaerungen["Schritte"][step - 1].erklaerungHeader,
        erklaerungText: erklaerungen["Schritte"][step - 1].erklaerungText,
      });
    }
  };

  handleSubmit = async () => {
    const {items} = this.state;

    let result = await this.context.postUnternehmenDataToApi("privatFinanzen", items);

    this.setState({
      isLoaded: true,
      items: result.privatFinanzen,
      saveSuccessful: true,
    });
  };

  onExit = () => {
    this.setState({
      tutorialEnabled: false,
    });
  };

  createInputField = (name, index) => {
    const {classes} = this.props;
    const {items, checked} = this.state;
    return (
      <Fragment>
        <CustomTextField
          id={"edit-privatfinanzen-" + name}
          label={erklaerungen[name]["kurzName"]}
          className={classes.textField}
          value={items[name]}
          onChange={this.handleChange(name)}
          margin="normal"
          variant="outlined"
          isCurrency={true}
          isReadOnly={false}
          unit={"€"}
          onFocus={(event) => this.handleFocusEvent(name, event)}
        />
      </Fragment>
    );
  };

  switchChange = (e) => {
    this.setState({checked: e.target.checked});
  };

  createReadOnlyField = (name) => {
    const {items} = this.state;
    const {classes} = this.props;
    return (
      <CustomTextField
        id={"edit-privatfinanzen-" + name}
        label={erklaerungen[name]["kurzName"]}
        className={classes.textField}
        value={items[name]}
        onChange={this.handleChange(name)}
        margin="normal"
        isCurrency={true}
        isReadOnly={true}
        unit={"€"}
        onFocus={() => this.handleFocus(name)}
      />
    );
  };

  createStepCard = (step) => {
    const {classes} = this.props;
    return (
      <Card className={classes.card}>
        <CardHeader
          title={erklaerungen["Schritte"][step]["erklaerungHeader"]}
        />
        <CardContent>
          {Schritte[step]["Input"].map((feld) => {
            return this.createInputField(feld);
          })}
          {Schritte[step]["ReadOnly"].map((feld) => {
            return this.createReadOnlyField(feld);
          })}
        </CardContent>
      </Card>
    );
  };

  render() {
    const {classes} = this.props;
    const {
      step,
      saveSuccessful,
    } = this.state;

    return (
      <Fragment>
        <div className={classes.appBarSpacer}/>
        <Typography variant="h4" gutterBottom component="h2">
          {erklaerungen.erklaerungHeader}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={12} lg={12}>
            <form className={classes.container} noValidate autoComplete="off">
              {this.createStepCard(step)}
            </form>
          </Grid>
        </Grid>

        {/*<Card className={classes.progressCard}>*/}
        {/*  <CardHeader title="Fortschritt"/>*/}
        {/*  <CardContent>*/}
        {/*    <LinearProgress*/}
        {/*      variant="determinate"*/}
        {/*      value={((step - 1) / (Object.keys(erklaerungen["Schritte"]).length - 1)) * 100}*/}
        {/*    />*/}
        {/*    <Typography variant="body1">*/}
        {/*      {erklaerungen.erklaerungHeader} &mdash; Schritt {step} von {(Object.keys(erklaerungen["Schritte"]).length)}: {this.context.schritt.erklaerungHeader}*/}
        {/*    </Typography>*/}
        {/*  </CardContent>*/}
        {/*</Card>*/}
      </Fragment>
    );
  }
}

export default withStyles(styles)(PrivaterFinanzbedarf);
