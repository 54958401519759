import Typography from "@material-ui/core/Typography/Typography";
import React, {Fragment} from "react";
import {Box, withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CustomTextField from "../CustomTags/CustomTextField";
import Grid from "@material-ui/core/Grid";

import erklaerungsTexte from "../LogicData/erklaerungsTexte";
import erklaerungsUeberschriften from "../LogicData/erklaerungsUeberschriften";
import TextteilSchritte from "../LogicData/TextteilSchritte";
import SteckbriefSchritte from "../LogicData/SteckbriefSchritte";
import styles from "../styles";
import {AppContext} from "../AppContext";
import CustomMultilineTextField from "../CustomTags/CustomMultilineTextField";


class Textteil extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.erklaerungen = {
      ...erklaerungsUeberschriften.Businessplan.Textteil,
      ...erklaerungsUeberschriften.Businessplan.Steckbrief,
      ...erklaerungsTexte,
    };

    var allEingabeItems = [];

    Object.keys(TextteilSchritte).forEach(function (outerKey) {
      Object.keys(TextteilSchritte[outerKey]).forEach(function (
        innerKey
      ) {
        if (TextteilSchritte[outerKey][innerKey]["inEingabe"] === true) {
          allEingabeItems = [...allEingabeItems, innerKey];
        } else {
          delete TextteilSchritte[outerKey][innerKey];
        }
      });
    });

    this.schritte = TextteilSchritte;

    Object.keys(SteckbriefSchritte).forEach(function (outerKey) {
      Object.keys(SteckbriefSchritte[outerKey]).forEach(function (
        innerKey
      ) {
        if (SteckbriefSchritte[outerKey][innerKey]["inEingabe"] === true) {
          allEingabeItems = [...allEingabeItems, innerKey];
        }
      });
    });

    this.allItems = allEingabeItems;

    this.allItemsReversed = {};

    this.allItems.map((element, key) => {
      this.allItemsReversed[element] = "";
    });

    this.state = {
      step: 1,
      isLoaded: false,
      tutorialEnabled: true,
      ergebnisVorhanden: false,
      erklaerungHeader: this.erklaerungen["erklaerungHeader"],
      erklaerungText: this.erklaerungen["erklaerungText"],
      Steckbrief: [],
      items: this.allItemsReversed,
    };
  }

  componentDidMount() {
    this.context.setFunktion({
      key: this.erklaerungen.key,
      name: this.erklaerungen.name,
      erklaerungHeader: this.erklaerungen.erklaerungHeader,
      erklaerungText: this.erklaerungen.erklaerungText,
      continuePossible: true,
      backPossible: true,
      totalSteps: Object.keys(this.schritte).length,
      handleSubmit: () => this.handleSubmit(),
    });
    this.context.setSchritt({
      key: 1,
      erklaerungHeader: this.erklaerungen["Schritte"][1]
        .erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][1].erklaerungText,
      continuePossible: true,
      backPossible: false,
    });
    this.context.setBereich({
      name: "Businessplan"
    });
    this.getDataFromApi();
    this.context.setSavePossible(true);
  }

  componentDidUpdate() {
    if (this.state.step !== this.context.schritt.key) {
      this.handleStepChange();
    }
  }

  getClearData = () => {
    return this.allItemsReversed;
  };

  getDataFromApi = async () => {
    let result = await this.context.getUnternehmenDataFromApi("textteil");
    let resultPitchdeck = await this.context.getUnternehmenDataFromApi("pitchdeck");

    if (result.textteil) {
      if (
        result.textteil["TextteilIntro"] &&
        0 !== result.textteil["TextteilIntro"].toString().length
      ) {
        this.setState({
          isLoaded: true,
          ergebnisVorhanden: true,
          Steckbrief: resultPitchdeck.pitchdeck,
          items: {
            ...this.getClearData(),
            ...result.textteil,
            ...resultPitchdeck.pitchdeck,
          },
        });
      } else {
        this.setState({
          isLoaded: true,
          ergebnisVorhanden: false,
          Steckbrief: resultPitchdeck.pitchdeck,
          items: {
            ...this.getClearData(),
            ...result.textteil,
            ...resultPitchdeck.pitchdeck,
          },
        });
      }
    } else {
      this.setState({
        isLoaded: false,
        ergebnisVorhanden: false,
        items: {
          ...this.getClearData(),
          ...resultPitchdeck.pitchdeck
        },
      });
    }
  }

  continuePossible = (step) => {
    switch (step) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        return true;
      case 8:
        return false;
      default:
        return false;
    }
  };

  handleSnackbarClose = (event, reason) => {
    this.setState({
      saveSuccessful: false,
    });
  };

  handleChange = (name, typ) => ({target: {value, checked}}) => {
    const {items} = this.state;

    this.setState({
      items: {
        ...items,
        [name]: value,
      },
    });
  };

  handleFocus = (name) => {
    this.setState({
      erklaerungHeader: this.erklaerungen[name].erklaerungHeader,
      erklaerungText: this.erklaerungen[name].erklaerungText,
    });
    this.context.setFeld({
      key: name,
    });
  };

  handleFocusEvent = (name, event) => {
    this.setState({
      erklaerungHeader: this.erklaerungen[name].erklaerungHeader,
      erklaerungText: this.erklaerungen[name].erklaerungText,
    });
    event.target.select();
    this.context.setFeld({
      key: name,
    });
  };

  handleStepChange = () => {
    const newStep = this.context.schritt.key;
    this.setState({
      step: newStep,
      erklaerungHeader: this.erklaerungen["Schritte"][newStep]
        .erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
    });

    if (newStep > 1) {
      this.context.setSchritt({
        key: newStep,
        erklaerungHeader: this.erklaerungen["Schritte"][newStep]
          .erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
        continuePossible: this.continuePossible(newStep),
        backPossible: true,
      });
    } else {
      this.context.setSchritt({
        key: newStep,
        erklaerungHeader: this.erklaerungen["Schritte"][newStep]
          .erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
        continuePossible: this.continuePossible(newStep),
        backPossible: false,
      });
    }
  };

  handleBack = () => {
    const {step} = this.state;
    if (step > 1) {
      this.setState({
        step: step - 1,
        erklaerungHeader: this.erklaerungen["Schritte"][step - 1]
          .erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][step - 1].erklaerungText,
      });
    }
  };

  handleSubmit = async () => {
    const {items} = this.state;

    let result = await this.context.postUnternehmenDataToApi("textteil", items);

    this.setState({
      isLoaded: true,
      items: result.textteil,
      saveSuccessful: true,
    });
  }

  createField = (name, properties) => {
    const {classes} = this.props;
    const {items, Steckbrief} = this.state;
    if (properties.typ === "readOnly") {
      return (
        <CustomTextField
          id={"edit-textteil-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.textField}
          value={items[name]}
          onChange={this.handleChange(name, "input")}
          margin="normal"
          isCurrency={true}
          isReadOnly={true}
          unit={"€"}
          onFocus={() => this.handleFocus(name)}
        />
      );
    } else if (properties.typ === "input") {
      return (
        <CustomTextField
          id={"edit-textteil-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.textField}
          value={items[name]}
          onChange={this.handleChange(name, "input")}
          margin="normal"
          variant="outlined"
          isCurrency={true}
          isReadOnly={false}
          unit={"€"}
          onFocus={(event) => this.handleFocusEvent(name, event)}
        />
      );
    } else if (properties.typ === "multiline") {
      return (
        <CustomMultilineTextField
          id={"edit-textteil-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.multilineTextField}
          value={items[name]}
          onChange={this.handleChange(name, "multiline")}
          margin="normal"
          variant="outlined"
          isReadOnly={false}
          onFocus={(event) => this.handleFocusEvent(name, event)}
        />
      );
    } else if (properties.typ === "multiline-readonly") {
      return (
        <Box sx={{ mt: 4 }} >
        <CustomMultilineTextField
          id={"show-textteil-" + name}
          label={"Zur Erinnerung, das hast du bei den Stichpunkten im Steckbrief geschrieben:"}
          className={classes.multilineTextField}
          value={items[name]}
          margin="normal"
          variant="outlined"
          isReadOnly={true}
          showLabel={true}
        />
        </Box>
      );
    }
  };

  createStepCard = (step) => {
    const {classes} = this.props;
    return (
      <Card className={classes.card}>
        <CardHeader
          title={this.erklaerungen["Schritte"][step]["erklaerungHeader"]}
        />
        <CardContent>
          {Object.keys(this.schritte[step]).map((feld) => {
            return this.createField(feld, this.schritte[step][feld]);
          })}
        </CardContent>
      </Card>
    );
  };

  render() {
    const {classes} = this.props;
    const {step} = this.state;

    return (
      <Fragment>
        <div className={classes.appBarSpacer}/>
        <Typography variant="h4" gutterBottom component="h2">
          {this.erklaerungen.erklaerungHeader}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={12} lg={12}>
            <form className={classes.container} noValidate autoComplete="off">
              {this.createStepCard(step)}
            </form>
          </Grid>
        </Grid>

        {/*<Card className={classes.progressCard}>*/}
        {/*  <CardHeader title="Fortschritt"/>*/}
        {/*  <CardContent>*/}
        {/*    <LinearProgress*/}
        {/*      variant="determinate"*/}
        {/*      value={((step - 1) / (Object.keys(this.erklaerungen["Schritte"]).length - 1)) * 100}*/}
        {/*    />*/}
        {/*    <Typography variant="body1">*/}
        {/*      {this.erklaerungen.erklaerungHeader} &mdash; Schritt {step} von {(Object.keys(this.erklaerungen["Schritte"]).length)}: {this.context.schritt.erklaerungHeader}*/}
        {/*    </Typography>*/}
        {/*  </CardContent>*/}
        {/*</Card>*/}
      </Fragment>
    );
  }
}

export default withStyles(styles)(Textteil);
