import Typography from "@material-ui/core/Typography/Typography";
import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CustomTextField from "../CustomTags/CustomTextField";
import Grid from "@material-ui/core/Grid";

import erklaerungsTexte from "../LogicData/erklaerungsTexte";
import erklaerungsUeberschriften from "../LogicData/erklaerungsUeberschriften";
import TypischerMonatSchritte from "../LogicData/TypischerMonatSchritte";
import styles from "../styles";
import {AppContext} from "../AppContext";


class TypischerMonat extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.erklaerungen = {
      ...erklaerungsUeberschriften.Businessplan.TypischerMonat,
      ...erklaerungsTexte,
    };

    var allEingabeItems = [];

    Object.keys(TypischerMonatSchritte).forEach(function (outerKey) {
      Object.keys(TypischerMonatSchritte[outerKey]).forEach(function (
        innerKey
      ) {
        if (TypischerMonatSchritte[outerKey][innerKey]["inEingabe"] === true) {
          allEingabeItems = [...allEingabeItems, innerKey];
        } else {
          delete TypischerMonatSchritte[outerKey][innerKey];
        }
      });
    });

    this.schritte = TypischerMonatSchritte;

    this.allItems = allEingabeItems;

    this.allItemsReversed = {};

    this.allItems.map((element, key) => {
      this.allItemsReversed[element] = 0;
    });

    this.state = {
      step: 1,
      isLoaded: false,
      tutorialEnabled: true,
      ergebnisVorhanden: false,
      erklaerungHeader: this.erklaerungen["erklaerungHeader"],
      erklaerungText: this.erklaerungen["erklaerungText"],
      items: this.allItemsReversed,
      ueber_unterDeckung: null,
      ueber_unterDeckung_Privatentnahme: null,
      liquideMittelAmMonatsende: [],
      liquiditaetVormonat: [],
      liquiditaetsbedarf: null,
      monatsData: null,
      kapitalUndFinanzierungsdaten: null,
      privaterFinanzBedarfdaten: null,
      vergleichsjahr: {
        vergleichsmonat: {
          Erloese7: "",
          Erloese19: "",
          Bestandsveraenderung: "",
          Gesamtleistung: "",
          Wareneinkauf: "",
          Fremdleistungen: "",
          SonstigeErloese: "",
          Rohertrag: "",
          Personalkosten: "",
          Raumkosten: "",
          Steuern: "",
          Versicherungen: "",
          "Kfz-Kosten": "",
          "Werbe/Reisekosten": "",
          "Kosten der Warenabgabe": "",
          Afa: "",
          Reparaturen: "",
          "So Kosten": "",
          Gesamtkosten: "",
          Betriebsergebnis: "",
          Zinsaufwand: "",
          "So neutraler Aufwand": "",
          "Neutraler Aufwand": "",
          Zinsertrag: "",
          SoNeutralerErtrag: "",
          "Neutraler Ertrag": "",
          "Neutrales Ergebnis": "",
          Ergebnis: "",
        },
        vergleichstext: "",
      },
    };
  }

  componentDidMount() {
    this.context.setFunktion({
      key: this.erklaerungen.key,
      name: this.erklaerungen.name,
      erklaerungHeader: this.erklaerungen.erklaerungHeader,
      erklaerungText: this.erklaerungen.erklaerungText,
      continuePossible: true,
      backPossible: true,
      totalSteps: Object.keys(this.schritte).length,
      handleSubmit: () => this.handleSubmit(),
    });
    this.context.setSchritt({
      key: 1,
      erklaerungHeader: this.erklaerungen["Schritte"][1]
        .erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][1].erklaerungText,
      continuePossible: true,
      backPossible: false,
    });
    this.context.setBereich({
      name: "Businessplan"
    });
    this.getDataFromApi();
    this.context.setSavePossible(true);
  }

  componentDidUpdate() {
    if (this.state.step !== this.context.schritt.key) {
      this.handleStepChange();
    }
  }

  getClearData = () => {
    return this.allItemsReversed;
  };

  getDataFromApi = async () => {
    let result = await this.context.getUnternehmenDataFromApi("typischerMonat");

    if (result.typischerMonat) {
      if (
        result.typischerMonat["Betriebsergebnis"] &&
        0 !== result.typischerMonat["Betriebsergebnis"].toString().length
      ) {
        this.setState({
          isLoaded: true,
          ergebnisVorhanden: true,
          items: {
            ...this.getClearData(),
            ...result.typischerMonat,
          },
        });

        let monatsData;
        let privaterFinanzBedarfdaten;
        let kapitalUndFinanzierungsdaten;

        if (
          result.privatFinanzen &&
          "PrivatBenoetigtePrivatentnahme" in result.privatFinanzen &&
          0 !==
          result.privatFinanzen[
            "PrivatBenoetigtePrivatentnahme"
            ].toString().length
        ) {
          privaterFinanzBedarfdaten = result.privatFinanzen;
        } else {
          this.setState({
            weiterleitungsModalOpen: true,
            modalScript: "PrivaterFinanzbedarf",
          });
          return;
        }

        if (
          result.kapitalUndFinanzierung &&
          "FinanzierungFinanzierungslueckeReserve" in
          result.kapitalUndFinanzierung &&
          0 !==
          result.kapitalUndFinanzierung[
            "FinanzierungFinanzierungslueckeReserve"
            ].toString().length
        ) {
          kapitalUndFinanzierungsdaten = result.kapitalUndFinanzierung;
        } else {
          this.setState({
            weiterleitungsModalOpen: true,
            modalScript: "KapitalUndFinanzierung",
          });
          return;
        }

        if (
          result.typischerMonat &&
          "Betriebsergebnis" in result.typischerMonat &&
          0 !==
          result.typischerMonat["Betriebsergebnis"].toString().length
        ) {
          monatsData = result.typischerMonat;
        } else {
          this.setState({
            weiterleitungsModalOpen: true,
            modalScript: "TypischerMonat",
          });
          return;
        }

        var Bauinverstition =
          result.kapitalUndFinanzierung.FinanzierungBauinvestitionen;

        let ueber_unterDeckung =
          monatsData.Erloese7 +
          monatsData.Erloese19 +
          monatsData.Zinsertrag +
          monatsData.SoNeutralerErtrag +
          monatsData.LiquiditaetSonstigeEinzahlungen -
          (monatsData.Wareneinkauf + monatsData.Fremdleistungen) -
          Bauinverstition -
          (monatsData.Personalkosten +
            monatsData.Raumkosten +
            monatsData.Steuern +
            monatsData.Versicherungen +
            monatsData["Kfz-Kosten"] +
            monatsData["Werbe-Reisekosten"] +
            monatsData["Kosten der Warenabgabe"] +
            monatsData.Reparaturen +
            monatsData["Sonstige Kosten"] +
            monatsData.Zinsaufwand +
            monatsData["So neutraler Aufwand"] +
            monatsData.LiquiditaetTilgungen +
            monatsData.LiquiditaetSteuernAbgaben);

        let ueber_unterDeckung_Privatentnahme =
          ueber_unterDeckung -
          privaterFinanzBedarfdaten.PrivatBenoetigtePrivatentnahme;

        let liquiditaetVormonat = [];
        liquiditaetVormonat[1] = 0;
        let liquideMittelAmMonatsende = [];
        let liquideMittelAmMonatsendeInklKontokorrentRahmen = [];

        for (let i = 1; i < 49; i++) {
          liquideMittelAmMonatsende[i] =
            liquiditaetVormonat[i] + ueber_unterDeckung_Privatentnahme;
          liquiditaetVormonat[i + 1] = liquideMittelAmMonatsende[i];
        }

        let kontokorrentkredit =
          kapitalUndFinanzierungsdaten.FinanzierungKontokorrrentkredit;

        for (let i = 1; i < 49; i++) {
          liquideMittelAmMonatsendeInklKontokorrentRahmen[i] =
            liquideMittelAmMonatsende[i] + kontokorrentkredit;
        }

        this.setState({
          ueber_unterDeckung: ueber_unterDeckung,
          ueber_unterDeckung_Privatentnahme: ueber_unterDeckung_Privatentnahme,
          liquideMittelAmMonatsende: liquideMittelAmMonatsende,
          liquiditaetVormonat: liquiditaetVormonat,
          monatsData: monatsData,
          kapitalUndFinanzierungsdaten: kapitalUndFinanzierungsdaten,
          isLoaded: true,
        });
      } else {
        this.setState({
          isLoaded: true,
          ergebnisVorhanden: false,
          items: {
            ...this.getClearData(),
            ...result.typischerMonat,
          },
        });
      }
    } else {
      this.setState({
        isLoaded: false,
        ergebnisVorhanden: false,
        items: this.getClearData(),
      });
    }
  }

  calculateLiquiditaetsbedarf = () => {
    const {liquideMittelAmMonatsende} = this.state;
    let newArray = liquideMittelAmMonatsende.slice(1, 49);

    let liquiditaetsbedarf = Math.min.apply(null, newArray);

    if (liquiditaetsbedarf > 0) {
      return 0;
    } else {
      return Math.abs(liquiditaetsbedarf);
    }
  };

  continuePossible = (step) => {
    switch (step) {
      case 1:
        return true;
      case 2:
        return false;
      default:
        return false;
    }
  };

  handleSnackbarClose = (event, reason) => {
    this.setState({
      saveSuccessful: false,
    });
  };

  handleChange = (name) => ({target: {value, checked}}) => {
    const {items} = this.state;

    if (value === "") {
      value = 0;
    } else {
      value = parseFloat(value);
    }

    let summen = {};

    const steps = Object.keys(this.schritte);

    this.allItems.map((item) => {
      steps.map((schritt) => {
        let itemsOfCurrentStep = Object.keys(this.schritte[schritt]);
        itemsOfCurrentStep.map((feld) => {
          if (this.schritte[schritt][feld]["typ"] === "monthYearSelect") {
          } else {
            if (this.schritte[schritt][feld]["teilDerSummen"].includes(item)) {
              if (typeof summen[item] === "undefined") {
                summen[item] = 0;
              }
              if (feld === name) {
                summen[item] += value;
              } else {
                summen[item] += items[feld];
              }
            }
            if (
              typeof this.schritte[schritt][feld]["subtrahendVon"] !==
              "undefined" &&
              this.schritte[schritt][feld]["subtrahendVon"].includes(item)
            ) {
              if (typeof summen[item] === "undefined") {
                summen[item] = 0;
              }
              if (feld === name) {
                summen[item] -= value;
              } else {
                summen[item] -= items[feld];
              }
            }
          }
        });
      });
    });

    this.setState({
      items: {
        ...items,
        ...summen,
        [name]: value,
      },
    });
  };

  handleFocus = (name) => {
    this.setState({
      erklaerungHeader: this.erklaerungen[name].erklaerungHeader,
      erklaerungText: this.erklaerungen[name].erklaerungText,
    });
    this.context.setFeld({
      key: name,
      // ...this.erklaerungen[name],
      // ...this.schritte[this.state.step][name],
    });
  };

  handleFocusEvent = (name, event) => {
    this.setState({
      erklaerungHeader: this.erklaerungen[name].erklaerungHeader,
      erklaerungText: this.erklaerungen[name].erklaerungText,
    });
    event.target.select();
    this.context.setFeld({
      key: name,
      // ...this.erklaerungen[name],
      // ...this.schritte[this.state.step][name],
    });
  };

  handleStepChange = () => {
    const newStep = this.context.schritt.key;
    this.setState({
      step: newStep,
      erklaerungHeader: this.erklaerungen["Schritte"][newStep]
        .erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
    });

    if (newStep > 1) {
      this.context.setSchritt({
        key: newStep,
        erklaerungHeader: this.erklaerungen["Schritte"][newStep]
          .erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
        continuePossible: this.continuePossible(newStep),
        backPossible: true,
      });
    } else {
      this.context.setSchritt({
        key: newStep,
        erklaerungHeader: this.erklaerungen["Schritte"][newStep]
          .erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
        continuePossible: this.continuePossible(newStep),
        backPossible: false,
      });
    }
  };

  handleBack = () => {
    const {step} = this.state;
    if (step > 1) {
      this.setState({
        step: step - 1,
        erklaerungHeader: this.erklaerungen["Schritte"][step - 1]
          .erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][step - 1].erklaerungText,
      });
    }
  };

  handleSubmit = async () => {
    const {items} = this.state;

    let result = await this.context.postUnternehmenDataToApi("typischerMonat", items);

    this.setState({
      isLoaded: true,
      items: result.typischerMonat,
      saveSuccessful: true,
    });

    var lb = this.calculateLiquiditaetsbedarf()
    //TODO: save FinanzierungLiquiditaetsbedarf
    //
    // kapitalUndFinanzierung: {
    //   FinanzierungLiquiditaetsbedarf: lb,
    // },
  }

  createField = (name, properties) => {
    const {classes} = this.props;
    const {items, vergleichsjahr} = this.state;
    if (properties.typ === "readOnly") {
      return (
        <CustomTextField
          id={"edit-liquiditaet-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.textField}
          value={items[name]}
          onChange={this.handleChange(name)}
          margin="normal"
          isCurrency={true}
          isReadOnly={true}
          unit={"€"}
          onFocus={() => this.handleFocus(name)}
        />
      );
    } else if (properties.typ === "input") {
      return (
        <CustomTextField
          id={"edit-liquiditaet-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.textField}
          value={items[name]}
          onChange={this.handleChange(name)}
          margin="normal"
          variant="outlined"
          isCurrency={true}
          isReadOnly={false}
          unit={"€"}
          onFocus={(event) => this.handleFocusEvent(name, event)}
        />
      );
    }
  };

  createStepCard = (step) => {
    const {classes} = this.props;
    return (
      <Card className={classes.card}>
        <CardHeader
          title={this.erklaerungen["Schritte"][step]["erklaerungHeader"]}
        />
        <CardContent>
          {Object.keys(this.schritte[step]).map((feld) => {
            return this.createField(feld, this.schritte[step][feld]);
          })}
        </CardContent>
      </Card>
    );
  };

  render() {
    const {classes} = this.props;
    const {step} = this.state;

    return (
      <Fragment>
        <div className={classes.appBarSpacer}/>
        <Typography variant="h4" gutterBottom component="h2">
          {this.erklaerungen.erklaerungHeader}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={12} lg={12}>
            <form className={classes.container} noValidate autoComplete="off">
              {this.createStepCard(step)}
            </form>
          </Grid>
        </Grid>

        {/*<Card className={classes.progressCard}>*/}
        {/*  <CardHeader title="Fortschritt"/>*/}
        {/*  <CardContent>*/}
        {/*    <LinearProgress*/}
        {/*      variant="determinate"*/}
        {/*      value={((step - 1) / (Object.keys(this.erklaerungen["Schritte"]).length - 1)) * 100}*/}
        {/*    />*/}
        {/*    <Typography variant="body1">*/}
        {/*      {this.erklaerungen.erklaerungHeader} &mdash; Schritt {step} von {(Object.keys(this.erklaerungen["Schritte"]).length)}: {this.context.schritt.erklaerungHeader}*/}
        {/*    </Typography>*/}
        {/*  </CardContent>*/}
        {/*</Card>*/}
      </Fragment>
    );
  }
}

export default withStyles(styles)(TypischerMonat);
