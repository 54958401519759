import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import liquiditaetsSchritte from "../LogicData/Liquiditaetsschritte";
import rentabilitaetsSchritte from "../LogicData/RentabilitaetsSchritte";
import kapitalUndFinanzierungsSchritte from "../LogicData/KapitalUndFinanzierungsSchritte";
import privaterFinanzBedarfSchritte from "../LogicData/PrivaterFinanzbedarfSchritte2";
import erklaerungsTexte from "../LogicData/erklaerungsTexte";

import CustomCell from "../CustomTags/CustomCell";
import WeiterleitungsModal from "../CustomTags/Weiterleitungsmodal";
import {ChatColumnContext} from "../Assistent/ChatColumnContext";
import erklaerungsUeberschriften from "../LogicData/erklaerungsUeberschriften";

const Row = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
    "&.summe": {
      backgroundColor: "#1976d2",
      paddingBottom: "80px",
      //fontWeight: "800",
    },
    "&.zwischenSumme": {
      backgroundColor: "#9e9e9e",
      paddingBottom: "80px",
      //fontWeight: "800",
    },
  },
}))(TableRow);

export default class Zwischenzustand extends React.Component {
  static contextType = ChatColumnContext;

  constructor(props) {
    super(props);

    this.erklaerungen = {
      ...erklaerungsUeberschriften.Businessplan.Zwischenzustand,
      ...erklaerungsTexte,
    };

    this.state = {
      kapitalUndFinanzierungsdaten: null,
      privaterFinanzBedarfdaten: null,
      umsatzsteuersaldo: null,
      ueber_unterDeckung: null,
      ueber_unterDeckung_Privatentnahme: null,
      liquideMittelAmMonatsende: [],
      kontokorrentkredit: null,
      liquiditaetVormonat: [],
      liquideMittelAmMonatsendeInklKontokorrentRahmen: [],
      monatsData: null,
      liquiditaetrows: [],
      kapitalUndFinanzierungsrows: [],
      rentabiliataetrows: [],
      privaterFinanzBedarfrows: [],
      isLoaded: false,
      weiterleitungsModalOpen: false,
      modalScript: "",
    };

    let LiquiditaetsSchrittName = [];
    let KapitalUndFinanzierungsSchrittName = [];
    let RentabilitaetsSchrittName = [];
    let PrivaterFinanzbedarfSchrittName = [];

    //LiquiditaetsSchritte

    Object.keys(liquiditaetsSchritte).forEach(function (outerKey) {
      Object.keys(liquiditaetsSchritte[outerKey]).forEach(function (innerKey) {
        if (liquiditaetsSchritte[outerKey][innerKey]["inAusgabe"] === true) {
          if ("class" in liquiditaetsSchritte[outerKey][innerKey]) {
            LiquiditaetsSchrittName = {
              ...LiquiditaetsSchrittName,

              [innerKey]: {
                class: liquiditaetsSchritte[outerKey][innerKey]["class"],
                step: outerKey,
              },
            };
          } else {
            LiquiditaetsSchrittName = {
              ...LiquiditaetsSchrittName,
              [innerKey]: {
                class: "",
                step: outerKey,
              },
            };
          }
        } else {
          delete liquiditaetsSchritte[outerKey][innerKey];
        }
      });
    });
    this.state.liquiditaetrows = LiquiditaetsSchrittName;

    //Rentabiliaetsschritte
    Object.keys(rentabilitaetsSchritte).forEach(function (firstKey) {
      Object.keys(rentabilitaetsSchritte[firstKey]).forEach(function (
        secondKey
      ) {
        if (rentabilitaetsSchritte[firstKey][secondKey]["inAusgabe"] === true) {
          if ("class" in rentabilitaetsSchritte[firstKey][secondKey]) {
            RentabilitaetsSchrittName = {
              ...RentabilitaetsSchrittName,

              [secondKey]: {
                class: rentabilitaetsSchritte[firstKey][secondKey]["class"],
                step: firstKey,
              },
            };
          } else {
            RentabilitaetsSchrittName = {
              ...RentabilitaetsSchrittName,
              [secondKey]: {
                class: "",
                step: firstKey,
              },
            };
          }
        } else {
          delete rentabilitaetsSchritte[firstKey][secondKey];
        }
      });
    });
    this.state.rentabiliataetrows = RentabilitaetsSchrittName;

    //Kapital- und FinanzierungsSchritte
    Object.keys(kapitalUndFinanzierungsSchritte).forEach(function (outkey) {
      Object.keys(kapitalUndFinanzierungsSchritte[outkey]).forEach(function (
        inKey
      ) {
        if (
          kapitalUndFinanzierungsSchritte[outkey][inKey]["inAusgabe"] === true
        ) {
          if ("class" in kapitalUndFinanzierungsSchritte[outkey][inKey]) {
            KapitalUndFinanzierungsSchrittName = {
              ...KapitalUndFinanzierungsSchrittName,
              //              fourthKey,
              [inKey]: {
                class: kapitalUndFinanzierungsSchritte[outkey][inKey]["class"],
                step: outkey,
              },
            };
          } else {
            KapitalUndFinanzierungsSchrittName = {
              ...KapitalUndFinanzierungsSchrittName,
              //              fourthKey,
              [inKey]: {
                class: "",
                step: outkey,
              },
            };
          }
        } else {
          delete kapitalUndFinanzierungsSchritte[outkey][inKey];
        }
      });
    });

    this.state.kapitalUndFinanzierungsrows = KapitalUndFinanzierungsSchrittName;

    //Privater FinanzbedarfSchritte
    Object.keys(privaterFinanzBedarfSchritte).forEach(function (thirdKey) {
      Object.keys(privaterFinanzBedarfSchritte[thirdKey]).forEach(function (
        fourthKey
      ) {
        if (
          privaterFinanzBedarfSchritte[thirdKey][fourthKey]["inAusgabe"] ===
          true
        ) {
          if ("class" in privaterFinanzBedarfSchritte[thirdKey][fourthKey]) {
            PrivaterFinanzbedarfSchrittName = {
              ...PrivaterFinanzbedarfSchrittName,
              //              fourthKey,
              [fourthKey]: {
                class:
                  privaterFinanzBedarfSchritte[thirdKey][fourthKey]["class"],
                step: thirdKey,
              },
            };
          } else {
            PrivaterFinanzbedarfSchrittName = {
              ...PrivaterFinanzbedarfSchrittName,
              //              fourthKey,
              [fourthKey]: {
                class: "",
                step: thirdKey,
              },
            };
          }
        } else {
          delete privaterFinanzBedarfSchritte[thirdKey][fourthKey];
        }
      });
    });

    this.state.privaterFinanzBedarfrows = PrivaterFinanzbedarfSchrittName;
  }

  componentDidMount() {
    this.context.setFunktion({
      key: this.erklaerungen.key,
      name: this.erklaerungen.name,
      erklaerungHeader: this.erklaerungen.erklaerungHeader,
      erklaerungText: this.erklaerungen.erklaerungText,
      continuePossible: false,
      backPossible: true,
      handleSubmit: () => this.handleSubmit(),
    });
    this.context.setSchritt({
      key: 0,
      continuePossible: false,
      backPossible: false,
    });

    this.getDataFromApi();
  }

  getDataFromApi = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/v1/unternehmendata/${this.props.userId}/${this.props.unternehmenId}`
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        let monatsData;
        let privaterFinanzBedarfdaten;
        let kapitalUndFinanzierungsdaten;

        if (
          result.privatFinanzen &&
          "PrivatBenoetigtePrivatentnahme" in result.privatFinanzen &&
          0 !==
          result.privatFinanzen["PrivatBenoetigtePrivatentnahme"].toString()
            .length
        ) {
          privaterFinanzBedarfdaten = result.privatFinanzen;
        } else {
          this.setState({
            weiterleitungsModalOpen: true,
            modalScript: "PrivaterFinanzbedarf",
          });
          return;
        }

        if (
          result.kapitalUndFinanzierung &&
          "FinanzierungFinanzierungslueckeReserve" in
          result.kapitalUndFinanzierung &&
          0 !==
          result.kapitalUndFinanzierung[
            "FinanzierungFinanzierungslueckeReserve"
            ].toString().length
        ) {
          kapitalUndFinanzierungsdaten = result.kapitalUndFinanzierung;
        } else {
          this.setState({
            weiterleitungsModalOpen: true,
            modalScript: "KapitalUndFinanzierung",
          });
          return;
        }

        if (
          result.typischerMonat &&
          "Betriebsergebnis" in result.typischerMonat &&
          0 !== result.typischerMonat["Betriebsergebnis"].toString().length
        ) {
          monatsData = result.typischerMonat;
        } else {
          this.setState({
            weiterleitungsModalOpen: true,
            modalScript: "TypischerMonat",
          });
          return;
        }

        let umsatzsteuersaldo =
          monatsData.Erloese19 * 0.19 +
          monatsData.Erloese7 * 0.07 -
          (monatsData.Wareneinkauf +
            monatsData.Raumkosten +
            monatsData["Kfz-Kosten"] +
            monatsData["Werbe-Reisekosten"] +
            monatsData["Kosten der Warenabgabe"] +
            monatsData.Reparaturen +
            monatsData["Sonstige Kosten"]) *
          0.19;

        let Bauinverstition =
          result.kapitalUndFinanzierung.FinanzierungBauinvestitionen;

        let ueber_unterDeckung =
          monatsData.Erloese7 +
          monatsData.Erloese19 +
          monatsData.Zinsertrag +
          monatsData.SoNeutralerErtrag +
          monatsData.LiquiditaetSonstigeEinzahlungen -
          (monatsData.Wareneinkauf + monatsData.Fremdleistungen) -
          Bauinverstition -
          (monatsData.Personalkosten +
            monatsData.Raumkosten +
            monatsData.Steuern +
            monatsData.Versicherungen +
            monatsData["Kfz-Kosten"] +
            monatsData["Werbe-Reisekosten"] +
            monatsData["Kosten der Warenabgabe"] +
            monatsData.Reparaturen +
            monatsData["Sonstige Kosten"] +
            monatsData.Zinsaufwand +
            monatsData["So neutraler Aufwand"] +
            monatsData.LiquiditaetTilgungen +
            monatsData.LiquiditaetSteuernAbgaben);

        let ueber_unterDeckung_Privatentnahme =
          ueber_unterDeckung -
          privaterFinanzBedarfdaten.PrivatBenoetigtePrivatentnahme;

        let liquiditaetVormonat = [];
        liquiditaetVormonat[1] = 0;
        let liquideMittelAmMonatsende = [];
        let liquideMittelAmMonatsendeInklKontokorrentRahmen = [];

        for (let i = 1; i < 49; i++) {
          liquideMittelAmMonatsende[i] =
            liquiditaetVormonat[i] + ueber_unterDeckung_Privatentnahme;
          liquiditaetVormonat[i + 1] = liquideMittelAmMonatsende[i];
        }

        let kontokorrentkredit =
          kapitalUndFinanzierungsdaten.FinanzierungKontokorrrentkredit;

        for (let i = 1; i < 49; i++) {
          liquideMittelAmMonatsendeInklKontokorrentRahmen[i] =
            liquideMittelAmMonatsende[i] + kontokorrentkredit;
        }

        this.setState({
          umsatzsteuersaldo: umsatzsteuersaldo,
          ueber_unterDeckung: ueber_unterDeckung,
          ueber_unterDeckung_Privatentnahme: ueber_unterDeckung_Privatentnahme,
          liquideMittelAmMonatsende: liquideMittelAmMonatsende,
          liquiditaetVormonat: liquiditaetVormonat,
          kontokorrentkredit: kontokorrentkredit,
          liquideMittelAmMonatsendeInklKontokorrentRahmen: liquideMittelAmMonatsendeInklKontokorrentRahmen,
          privaterFinanzBedarfdaten: privaterFinanzBedarfdaten,
          monatsData: monatsData,
          kapitalUndFinanzierungsdaten: kapitalUndFinanzierungsdaten,
          isLoaded: true,
        });
      });
  };

  tableHeadMonate = () => {
    return (
      <Fragment>
        <CustomCell align="right" value={"Januar"}/>
        <CustomCell align="right" value={"Februar"}/>
        <CustomCell align="right" value={"März"}/>
        <CustomCell align="right" value={"April"}/>
        <CustomCell align="right" value={"Mai"}/>
        <CustomCell align="right" value={"Juni"}/>
        <CustomCell align="right" value={"Juli"}/>
        <CustomCell align="right" value={"August"}/>
        <CustomCell align="right" value={"September"}/>
        <CustomCell align="right" value={"Oktober"}/>
        <CustomCell align="right" value={"November"}/>
        <CustomCell align="right" value={"Dezember"}/>
        <CustomCell align="right" value={"Summen"}/>
      </Fragment>
    );
  };

  //Tabelle für das Rumpfjahr
  loopThroughLiquiditaetTableRowRJ(row) {
    let {
      monatsData,
      umsatzsteuersaldo,
      privaterFinanzBedarfdaten,
      liquideMittelAmMonatsende,
      liquiditaetVormonat,
      ueber_unterDeckung,
      ueber_unterDeckung_Privatentnahme,
      kontokorrentkredit,
      liquideMittelAmMonatsendeInklKontokorrentRahmen,
    } = this.state;
    var i = 0;
    let ausgabe = [];
    let summe = 0;
    for (i = 1; i < 13; i++) {
      switch (row) {
        case "LiquiditaetMonatsende":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={liquideMittelAmMonatsende[i]}
            />
          );
          summe += liquideMittelAmMonatsende[i];
          break;

        case "PrivatBenoetigtePrivatentnahme":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={privaterFinanzBedarfdaten[row]}
            />
          );
          summe += privaterFinanzBedarfdaten[row];
          break;
        case "LiquiditaetVormonat":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={liquiditaetVormonat[i]}
            />
          );
          summe += liquiditaetVormonat[i];
          break;
        case "LiquiditaetUeberdeckung":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={ueber_unterDeckung}
            />
          );
          summe += ueber_unterDeckung;
          break;
        case "LiquiditaetUeberdeckungNachEntnahme":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={ueber_unterDeckung_Privatentnahme}
            />
          );
          summe += ueber_unterDeckung_Privatentnahme;
          break;
        case "FinanzierungKontokorrrentkredit":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={kontokorrentkredit}
            />
          );
          summe += kontokorrentkredit;
          break;
        case "LiquiditaetUmsatzsteuersaldo":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={umsatzsteuersaldo}
            />
          );
          summe += umsatzsteuersaldo;
          break;
        case "LiquiditaetMonatsendeMitKontokorrentrahmen":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={liquideMittelAmMonatsendeInklKontokorrentRahmen[i]}
            />
          );
          summe += liquideMittelAmMonatsendeInklKontokorrentRahmen[i];
          break;
        default:
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={monatsData[row] || 0}
            />
          );
          summe += monatsData[row] || 0;
      }
    }
    ausgabe.push(
      <CustomCell align="right" isCurrency={true} unit={"€"} value={summe}/>
    );
    return ausgabe;
  }

  handleLiquiditaetTableRowRJ = (row) => {
    return <Fragment>{this.loopThroughLiquiditaetTableRowRJ(row)}</Fragment>;
  };

  //Tabelle für das erste Jahr
  loopThroughLiquiditaetTableRowEJ(row) {
    let {
      monatsData,
      umsatzsteuersaldo,
      privaterFinanzBedarfdaten,
      liquideMittelAmMonatsende,
      liquiditaetVormonat,
      ueber_unterDeckung,
      ueber_unterDeckung_Privatentnahme,
      kontokorrentkredit,
      liquideMittelAmMonatsendeInklKontokorrentRahmen,
    } = this.state;

    var i = 0;
    let ausgabe = [];
    let summe = 0;
    for (i = 13; i < 25; i++) {
      switch (row) {
        case "LiquiditaetMonatsende":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={liquideMittelAmMonatsende[i]}
            />
          );
          summe += liquideMittelAmMonatsende[i];
          break;

        case "PrivatBenoetigtePrivatentnahme":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={privaterFinanzBedarfdaten[row]}
            />
          );
          summe += privaterFinanzBedarfdaten[row];
          break;
        case "LiquiditaetVormonat":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={liquiditaetVormonat[i]}
            />
          );
          summe += liquiditaetVormonat[i];
          break;
        case "LiquiditaetUeberdeckung":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={ueber_unterDeckung}
            />
          );
          summe += ueber_unterDeckung;
          break;
        case "LiquiditaetUeberdeckungNachEntnahme":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={ueber_unterDeckung_Privatentnahme}
            />
          );
          summe += ueber_unterDeckung_Privatentnahme;
          break;
        case "FinanzierungKontokorrrentkredit":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={kontokorrentkredit}
            />
          );
          summe += kontokorrentkredit;
          break;
        case "LiquiditaetUmsatzsteuersaldo":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={this.state.umsatzsteuersaldo}
            />
          );
          summe += umsatzsteuersaldo;
          break;
        case "LiquiditaetMonatsendeMitKontokorrentrahmen":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={liquideMittelAmMonatsendeInklKontokorrentRahmen[i]}
            />
          );
          summe += liquideMittelAmMonatsendeInklKontokorrentRahmen[i];
          break;
        default:
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={monatsData[row] || 0}
            />
          );
          summe += monatsData[row] || 0;
      }
    }
    ausgabe.push(
      <CustomCell align="right" isCurrency={true} unit={"€"} value={summe}/>
    );
    return ausgabe;
  }

  handleLiquiditaetTableRowEJ = (row) => {
    return <Fragment>{this.loopThroughLiquiditaetTableRowEJ(row)}</Fragment>;
  };

  //Tabelle für das zweite Jahr
  loopThroughLiquiditaetTableRowZJ(row) {
    let {
      monatsData,
      umsatzsteuersaldo,
      privaterFinanzBedarfdaten,
      liquideMittelAmMonatsende,
      liquiditaetVormonat,
      ueber_unterDeckung,
      ueber_unterDeckung_Privatentnahme,
      kontokorrentkredit,
      liquideMittelAmMonatsendeInklKontokorrentRahmen,
    } = this.state;

    var i = 0;
    let ausgabe = [];
    let summe = 0;
    for (i = 25; i < 37; i++) {
      switch (row) {
        case "LiquiditaetMonatsende":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={liquideMittelAmMonatsende[i]}
            />
          );
          summe += liquideMittelAmMonatsende[i];
          break;

        case "PrivatBenoetigtePrivatentnahme":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={privaterFinanzBedarfdaten[row]}
            />
          );
          summe += privaterFinanzBedarfdaten[row];
          break;
        case "LiquiditaetVormonat":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={liquiditaetVormonat[i]}
            />
          );
          summe += liquiditaetVormonat[i];
          break;
        case "LiquiditaetUeberdeckung":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={ueber_unterDeckung}
            />
          );
          summe += ueber_unterDeckung;
          break;
        case "LiquiditaetUeberdeckungNachEntnahme":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={ueber_unterDeckung_Privatentnahme}
            />
          );
          summe += ueber_unterDeckung_Privatentnahme;
          break;
        case "FinanzierungKontokorrrentkredit":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={kontokorrentkredit}
            />
          );
          summe += kontokorrentkredit;
          break;
        case "LiquiditaetUmsatzsteuersaldo":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={umsatzsteuersaldo}
            />
          );
          summe += umsatzsteuersaldo;
          break;
        case "LiquiditaetMonatsendeMitKontokorrentrahmen":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={liquideMittelAmMonatsendeInklKontokorrentRahmen[i]}
            />
          );
          summe += liquideMittelAmMonatsendeInklKontokorrentRahmen[i];
          break;
        default:
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={monatsData[row] || 0}
            />
          );
          summe += monatsData[row] || 0;
      }
    }
    ausgabe.push(
      <CustomCell align="right" isCurrency={true} unit={"€"} value={summe}/>
    );
    return ausgabe;
  }

  handleLiquiditaetTableRowZJ = (row) => {
    return <Fragment>{this.loopThroughLiquiditaetTableRowZJ(row)}</Fragment>;
  };

  //Tabelle für das dritte Jahr
  loopThroughLiquiditaetTableRowDJ(row) {
    let {
      monatsData,
      umsatzsteuersaldo,
      privaterFinanzBedarfdaten,
      liquideMittelAmMonatsende,
      liquiditaetVormonat,
      ueber_unterDeckung,
      ueber_unterDeckung_Privatentnahme,
      kontokorrentkredit,
      liquideMittelAmMonatsendeInklKontokorrentRahmen,
    } = this.state;

    var i = 0;
    let ausgabe = [];
    let summe = 0;
    for (i = 37; i < 49; i++) {
      switch (row) {
        case "LiquiditaetMonatsende":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={liquideMittelAmMonatsende[i]}
            />
          );
          summe += liquideMittelAmMonatsende[i];
          break;

        case "PrivatBenoetigtePrivatentnahme":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={privaterFinanzBedarfdaten[row]}
            />
          );
          summe += privaterFinanzBedarfdaten[row];
          break;
        case "LiquiditaetVormonat":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={liquiditaetVormonat[i]}
            />
          );
          summe += liquiditaetVormonat[i];
          break;
        case "LiquiditaetUeberdeckung":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={ueber_unterDeckung}
            />
          );
          summe += ueber_unterDeckung;
          break;
        case "LiquiditaetUeberdeckungNachEntnahme":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={ueber_unterDeckung_Privatentnahme}
            />
          );
          summe += ueber_unterDeckung_Privatentnahme;
          break;
        case "FinanzierungKontokorrrentkredit":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={kontokorrentkredit}
            />
          );
          summe += kontokorrentkredit;
          break;
        case "LiquiditaetUmsatzsteuersaldo":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={umsatzsteuersaldo}
            />
          );
          summe += umsatzsteuersaldo;
          break;
        case "LiquiditaetMonatsendeMitKontokorrentrahmen":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={liquideMittelAmMonatsendeInklKontokorrentRahmen[i]}
            />
          );
          summe += liquideMittelAmMonatsendeInklKontokorrentRahmen[i];
          break;
        default:
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={monatsData[row] || 0}
            />
          );
          summe += monatsData[row] || 0;
      }
    }
    ausgabe.push(
      <CustomCell align="right" isCurrency={true} unit={"€"} value={summe}/>
    );
    return ausgabe;
  }

  handleLiquiditaetTableRowDJ = (row) => {
    return <Fragment>{this.loopThroughLiquiditaetTableRowDJ(row)}</Fragment>;
  };

  loopThroughRentaibilitaetTableRow(row) {
    let {monatsData, privaterFinanzBedarfdaten} = this.state;

    var i = 0;
    let ausgabe = [];
    let summe = 0;
    for (i = 1; i < 13; i++) {
      switch (row) {
        case "PrivatBenoetigtePrivatentnahme":
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={privaterFinanzBedarfdaten[row]}
            />
          );
          summe += privaterFinanzBedarfdaten[row];
          break;
        default:
          ausgabe.push(
            <CustomCell
              align="right"
              isCurrency={true}
              unit={"€"}
              value={monatsData[row] || 0}
            />
          );
          summe += monatsData[row] || 0;
      }
    }

    ausgabe.push(
      <CustomCell align="right" isCurrency={true} unit={"€"} value={summe}/>
    );
    return ausgabe;
  }

  handleRentabilitaetTableRow = (row) => {
    return <Fragment>{this.loopThroughRentaibilitaetTableRow(row)}</Fragment>;
  };

  render() {
    let {
      kapitalUndFinanzierungsdaten,
      kapitalUndFinanzierungsrows,
      privaterFinanzBedarfdaten,
      privaterFinanzBedarfrows,
      liquiditaetrows,
      rentabiliataetrows,
      isLoaded,
      modalScript,
      weiterleitungsModalOpen,
    } = this.state;

    let erklaerungstexte = erklaerungsTexte;
    let handleLiquiditaetTableRowRJ = this.handleLiquiditaetTableRowRJ; //Rumpfjahr
    let handleLiquiditaetTableRowEJ = this.handleLiquiditaetTableRowEJ; //Erste Jahr
    let handleLiquiditaetTableRowZJ = this.handleLiquiditaetTableRowZJ; //Zweite Jahr
    let handleLiquiditaetTableRowDJ = this.handleLiquiditaetTableRowDJ; //Dritte Jahr
    let tableHeadMonate = this.tableHeadMonate;
    let handleRentabilitaetTableRow = this.handleRentabilitaetTableRow;

    return (
      <Fragment>
        <WeiterleitungsModal
          userId={this.props.userId}
          unternehmenId={this.props.unternehmenId}
          weiterleitungsModalOpen={weiterleitungsModalOpen}
          modalScript={modalScript}
        />
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <Row>
                <CustomCell value={"Privater Finanzbedarf"}/>
                <CustomCell align="right" value={"Wert"}/>
              </Row>
            </TableHead>
            <TableBody>
              {Object.keys(privaterFinanzBedarfrows).map(function (row, index) {
                return (
                  <Row
                    key={row}
                    className={
                      privaterFinanzBedarfrows[row].class +
                      " step-" +
                      privaterFinanzBedarfrows[row].step
                    }
                  >
                    <CustomCell
                      value={erklaerungstexte[row]["kurzName"]}
                      className={privaterFinanzBedarfrows[row].class}
                    />
                    {isLoaded ? (
                      <CustomCell
                        align="right"
                        isCurrency={true}
                        unit={"€"}
                        value={privaterFinanzBedarfdaten[row]}
                        className={privaterFinanzBedarfrows[row].class}
                      />
                    ) : (
                      <CustomCell/>
                    )}
                  </Row>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <Row>
                <CustomCell value={"Kapital- und Finanzbedarf"}/>
                <CustomCell align="right" value={"Wert"}/>
              </Row>
            </TableHead>
            <TableBody>
              {Object.keys(kapitalUndFinanzierungsrows).map(function (
                row,
                index
              ) {
                return (
                  <Row
                    key={row}
                    className={
                      kapitalUndFinanzierungsrows[row].class +
                      " step-" +
                      kapitalUndFinanzierungsrows[row].step
                    }
                  >
                    <CustomCell
                      value={erklaerungstexte[row]["kurzName"]}
                      className={kapitalUndFinanzierungsrows[row].class}
                    />
                    {isLoaded ? (
                      <CustomCell
                        align="right"
                        isCurrency={true}
                        unit={"€"}
                        value={kapitalUndFinanzierungsdaten[row]}
                        className={kapitalUndFinanzierungsrows[row].class}
                      />
                    ) : (
                      <CustomCell/>
                    )}
                  </Row>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <Row>
                <CustomCell
                  value={"Liquiditätstabelle im Rumpfgeschäftsjahr"}
                />
                {tableHeadMonate()}
              </Row>
            </TableHead>
            <TableBody>
              {Object.keys(liquiditaetrows).map(function (row, index) {
                return (
                  <Row
                    key={row}
                    className={
                      liquiditaetrows[row].class +
                      " step-" +
                      liquiditaetrows[row].step
                    }
                  >
                    <CustomCell
                      value={erklaerungstexte[row]["kurzName"]}
                      className={liquiditaetrows[row].class}
                    />
                    {isLoaded ? (
                      handleLiquiditaetTableRowRJ(row)
                    ) : (
                      <CustomCell/>
                    )}
                    <CustomCell/>
                  </Row>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <Row>
                <CustomCell value={"Liquiditätstabelle im ersten Jahr"}/>
                {tableHeadMonate()}
              </Row>
            </TableHead>
            <TableBody>
              {Object.keys(liquiditaetrows).map(function (row, index) {
                return (
                  <Row
                    key={row}
                    className={
                      liquiditaetrows[row].class +
                      " step-" +
                      liquiditaetrows[row].step
                    }
                  >
                    <CustomCell
                      value={erklaerungstexte[row]["kurzName"]}
                      className={liquiditaetrows[row].class}
                    />
                    {isLoaded ? (
                      handleLiquiditaetTableRowEJ(row)
                    ) : (
                      <CustomCell/>
                    )}
                    <CustomCell/>
                  </Row>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <Row>
                {" "}
                <CustomCell value={"Liquiditätstabelle im zweiten Jahr"}/>
                {tableHeadMonate()}
              </Row>
            </TableHead>
            <TableBody>
              {Object.keys(liquiditaetrows).map(function (row, index) {
                return (
                  <Row
                    key={row}
                    className={
                      liquiditaetrows[row].class +
                      " step-" +
                      liquiditaetrows[row].step
                    }
                  >
                    <CustomCell
                      value={erklaerungstexte[row]["kurzName"]}
                      className={liquiditaetrows[row].class}
                    />
                    {isLoaded ? (
                      handleLiquiditaetTableRowZJ(row)
                    ) : (
                      <CustomCell/>
                    )}
                    <CustomCell/>
                  </Row>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <Row>
                <CustomCell value={"Liquiditätstabelle im dritten Jahr"}/>
                {tableHeadMonate()}
              </Row>
            </TableHead>
            <TableBody>
              {Object.keys(liquiditaetrows).map(function (row, index) {
                return (
                  <Row
                    key={row}
                    className={
                      liquiditaetrows[row].class +
                      " step-" +
                      liquiditaetrows[row].step
                    }
                  >
                    <CustomCell
                      value={erklaerungstexte[row]["kurzName"]}
                      className={liquiditaetrows[row].class}
                    />
                    {isLoaded ? (
                      handleLiquiditaetTableRowDJ(row)
                    ) : (
                      <CustomCell/>
                    )}
                    <CustomCell/>
                  </Row>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <Row>
                <CustomCell
                  value={"Rentabilitätstabelle im Rumpfgeschäftsjahr"}
                />
                {tableHeadMonate()}
              </Row>
            </TableHead>
            <TableBody>
              {Object.keys(rentabiliataetrows).map(function (row, index) {
                return (
                  <Row
                    key={row}
                    className={
                      rentabiliataetrows[row].class +
                      " step-" +
                      rentabiliataetrows[row].step
                    }
                  >
                    <CustomCell
                      value={erklaerungstexte[row]["kurzName"]}
                      className={rentabiliataetrows[row].class}
                    />
                    {isLoaded ? (
                      handleRentabilitaetTableRow(row)
                    ) : (
                      <CustomCell/>
                    )}
                    <CustomCell/>
                  </Row>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <Row>
                <CustomCell value={"Rentabilitätstabelle im ersten Jahr"}/>
                {tableHeadMonate()}
              </Row>
            </TableHead>
            <TableBody>
              {Object.keys(rentabiliataetrows).map(function (row, index) {
                return (
                  <Row
                    key={row}
                    className={
                      rentabiliataetrows[row].class +
                      " step-" +
                      rentabiliataetrows[row].step
                    }
                  >
                    <CustomCell
                      value={erklaerungstexte[row]["kurzName"]}
                      className={rentabiliataetrows[row].class}
                    />
                    {isLoaded ? (
                      handleRentabilitaetTableRow(row)
                    ) : (
                      <CustomCell/>
                    )}
                    <CustomCell/>
                  </Row>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <Row>
                <CustomCell value={"Rentabilitätstabelle im zweiten Jahr"}/>
                {tableHeadMonate()}
              </Row>
            </TableHead>
            <TableBody>
              {Object.keys(rentabiliataetrows).map(function (row, index) {
                return (
                  <Row
                    key={row}
                    className={
                      rentabiliataetrows[row].class +
                      " step-" +
                      rentabiliataetrows[row].step
                    }
                  >
                    <CustomCell
                      value={erklaerungstexte[row]["kurzName"]}
                      className={rentabiliataetrows[row].class}
                    />
                    {isLoaded ? (
                      handleRentabilitaetTableRow(row)
                    ) : (
                      <CustomCell/>
                    )}
                    <CustomCell/>
                  </Row>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <Row>
                <CustomCell value={"Rentabilitätstabelle im dritten Jahr"}/>
                {tableHeadMonate()}
              </Row>
            </TableHead>
            <TableBody>
              {Object.keys(rentabiliataetrows).map(function (row, index) {
                return (
                  <Row
                    key={row}
                    className={
                      rentabiliataetrows[row].class +
                      " step-" +
                      rentabiliataetrows[row].step
                    }
                  >
                    <CustomCell
                      value={erklaerungstexte[row]["kurzName"]}
                      className={rentabiliataetrows[row].class}
                    />
                    {isLoaded ? (
                      handleRentabilitaetTableRow(row)
                    ) : (
                      <CustomCell/>
                    )}
                    <CustomCell/>
                  </Row>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    );
  }
}
