import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core";
import {withRouter} from 'react-router-dom'

import MainArea from "./MainArea";
import TopArea from "./MenuItems/TopArea";
import ChatColumn from "./Assistent/ChatColumn";
import styles from "./styles";
import clsx from "clsx";

import Stundensatz from "./Basisfunktionen/Stundensatz";
import Stammdaten from "./Basisfunktionen/Stammdaten";
import Bwa from "./Basisfunktionen/Bwa";
import PrivaterFinanzbedarf from "./Businessplan/PrivaterFinanzbedarf";
import Rentabilitaet from "./Businessplan/Rentabilitaet";
import Liquiditaet from "./Businessplan/Liquiditaet";
import KapitalUndFinanzierung from "./Businessplan/KapitalUndFinanzierung";
import Zwischenzustand from "./Businessplan/Zwischenzustand";
import TypischerMonat from "./Businessplan/TypischerMonat";
import SpezifischerMonat from "./Businessplan/SpezifischerMonat";
import Textteil from "./Businessplan/Textteil";
import MeinProfil from "./Benutzerbereich/MeinProfil";
import Einstellungen from "./Benutzerbereich/Einstellungen";
import Endergebnis from "./Businessplan/Endergebnis";
import {AppContext} from "./AppContext";
import MeinUnternehmen from "./Unternehmen/MeinUnternehmen";
import BwaAusgabe from "./Basisfunktionen/BwaAusgabe";
import Businessplan from "./Businessplan/Businessplan";
import Steckbrief from "./Businessplan/SteckbriefTextteil";
import SteckbriefShow from "./Businessplan/SteckbriefShow";
import ErsteSchritte from "./Unternehmen/ErsteSchritte";
import UnternehmenBearbeiten from "./Unternehmen/UnternehmenBearbeiten";

class ViewArea extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      intervalId: null,
      activeStep: 1,
    }

    this.generateMessages = this.generateMessages.bind(this);
    // this.chatScrollRef = React.createRef();
  }

  componentDidMount() {
    // this.getDataFromApi();
    // this.context.getChatDataFromCockpitApi();
    const intervalId = setInterval(this.generateMessages, 500);
    // store intervalId in the state so it can be accessed later:
    this.setState({intervalId: intervalId});
  }

  generateMessages() {
    // generates Messages if Schritt or Funktion has changed in context
    if (this.context.funktion.key !== 0 && this.context.previousFunktion !== this.context.funktion.key && this.context.funktion.erklaerungHeader) {
      this.context.clearMessages();
      this.context.postMessage(
        this.context.funktion.erklaerungHeader + ": " + this.context.funktion.erklaerungText, "bot", "functionStarted"
      );
      this.context.setPreviousFunktion(this.context.funktion.key);

      this.context.postMessage(
        this.context.funktion.erklaerungHeader + " - Schritt " + this.context.schritt.key + ": " + this.context.schritt.erklaerungHeader + ": " + this.context.schritt.erklaerungText, "bot", "stepStarted"
      );
      this.context.setPreviousSchritt(this.context.schritt.key);

    } else if (this.context.schritt.key !== 0 && this.context.previousSchritt !== this.context.schritt.key && this.context.schritt.erklaerungHeader) {
      if (this.context.schritt.key !== 1) {
        this.context.clearMessages();
      }
      // console.log("Doof2!");
      this.context.postMessage(
        this.context.funktion.erklaerungHeader + " - Schritt " + this.context.schritt.key + ": " + this.context.schritt.erklaerungHeader + ": " + this.context.schritt.erklaerungText, "bot", "stepStarted"
      );
      this.context.setPreviousSchritt(this.context.schritt.key);
    }
  }

  // componentDidUpdate() {
  //   if (this.context.unternehmenId !== this.props.match.params.unternehmenId) {
  //     this.setState({
  //       unternehmenId: this.props.match.params.unternehmenId,
  //     });
  //     this.context.getChatDataFromCockpitApi() //.then(this.scrollToBottom());
  //     // this.getDataFromApi();
  //   }
  // }

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.context.intervalId);
  }

  scrollToBottom = () => {
    // this.chatScrollRef.current.scrollIntoView({behavior: 'smooth'})
    // this.props.outerLastMessageRef.current.scrollIntoView({behavior: 'smooth'})
    // this.props.ref.current.scrollIntoView({behavior: 'smooth'})
  }

  getDataFromApi = () => {
    fetch(`${process.env.REACT_APP_API_URL}/v1/chatdata/${this.props.match.params.userId}/${this.props.match.params.unternehmenId}`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            messages: result,
          }, () => this.scrollToBottom());
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error: error,
          });
        }
      )
  };

  handleNext = () => {
    this.setState({
      activeStep: this.state.activeStep + 1
    })
  };

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1
    })
  };

  render() {
    const {classes} = this.props;
    const {params} = this.props.match;

    // console.log(feld)

    return (
      <Fragment>
        {/*<ChatColumnContext.Provider value={this.state}>*/}
        <TopArea userId={params.userId} unternehmenId={params.unternehmenId} history={this.props.history}/>

        <main
          className={clsx(classes.content, {
            [classes.contentShift]: !this.context.chatColumnOpen,
          })}
        >
          {(function () {
            switch (params.functionName) {
                case "dashboard":
                  return (
                    <MainArea
                      userId={params.userId}
                      unternehmenId={params.unternehmenId}
                    />
                  );
                case "stundensatz":
                  return (
                    <Stundensatz
                      userId={params.userId}
                      unternehmenId={params.unternehmenId}
                    />
                  );
                case "stammdaten":
                  return (
                    <Stammdaten
                      userId={params.userId}
                      unternehmenId={params.unternehmenId}
                    />
                  );
              case "bwa":
                return (
                  <Bwa
                    userId={params.userId}
                    unternehmenId={params.unternehmenId}
                  />
                );
              case "bwaausgabe":
                return (
                  <BwaAusgabe/>
                );
              case "privatfinanzen":
                return (
                  <PrivaterFinanzbedarf/>
                );
              case "rentabilitaet":
                return (
                  <Rentabilitaet/>
                );
              case "liquiditaet":
                return (
                    <Liquiditaet
                      userId={params.userId}
                      unternehmenId={params.unternehmenId}
                    />
                  );
                case "kapitalundfinanzierung":
                  return (
                    <KapitalUndFinanzierung
                      userId={params.userId}
                      unternehmenId={params.unternehmenId}
                    />
                  );
                case "typischermonat":
                  return (
                    <TypischerMonat
                      userId={params.userId}
                      unternehmenId={params.unternehmenId}
                    />
                  );
                case "zwischenzustand":
                  return (
                    <Zwischenzustand
                      userId={params.userId}
                      unternehmenId={params.unternehmenId}
                    />
                  );
              case "spezifischermonat":
                return (
                  <SpezifischerMonat
                    userId={params.userId}
                    unternehmenId={params.unternehmenId}
                  />
                );
              case "steckbrieftext":
                return (
                  <Steckbrief
                    userId={params.userId}
                    unternehmenId={params.unternehmenId}
                  />
                );
              case "steckbriefshare":
                return (
                  <SteckbriefShow
                    userId={params.userId}
                    unternehmenId={params.unternehmenId}
                  />
                );
              case "textteil":
                return (
                  <Textteil
                    userId={params.userId}
                    unternehmenId={params.unternehmenId}
                  />
                );
              case "finanzteil":
                return (
                  <Endergebnis
                    userId={params.userId}
                    unternehmenId={params.unternehmenId}
                  />
                );
              case "endergebnis":
                return (
                  <Businessplan
                    userId={params.userId}
                    unternehmenId={params.unternehmenId}
                  />
                );
              case "ersteschritte":
                return (
                  <ErsteSchritte history={this.props.history}/>
                );
              case "unternehmenbearbeiten":
                return (
                  <UnternehmenBearbeiten
                    unternehmenId={params.unternehmenId}
                  />
                );
              case "meinunternehmen":
                return (
                  <MeinUnternehmen history={this.props.history}/>
                );
              case "meinprofil":
                return (
                  <MeinProfil
                    userId={params.userId}
                    unternehmenId={params.unternehmenId}
                  />
                );
              case "einstellungen":
                return (
                  <Einstellungen
                    userId={params.userId}
                    unternehmenId={params.unternehmenId}
                  />
                );
              default:
                return null;
            }
          }).call(this)}
          {/*<Pagination count={5} classes={{*/}
          {/*  button: classes.mainPagination*/}
          {/*}}/>*/}

          {/*<MobileStepper*/}
          {/*  variant="dots"*/}
          {/*  steps={6}*/}
          {/*  position="static"*/}
          {/*  activeStep={this.state.activeStep}*/}
          {/*  sx={{ maxWidth: 400, flexGrow: 1 }}*/}
          {/*  nextButton={*/}
          {/*    <Button size="small" onClick={this.handleNext} disabled={this.state.activeStep === 5}>*/}
          {/*      Next*/}
          {/*      <KeyboardArrowRight />*/}
          {/*    </Button>*/}
          {/*  }*/}
          {/*  backButton={*/}
          {/*    <Button size="small" onClick={this.handleBack} disabled={this.state.activeStep === 0}>*/}
          {/*      <KeyboardArrowLeft />*/}
          {/*      Back*/}
          {/*    </Button>*/}
          {/*  }*/}
          {/*/>*/}
          {this.context.funktion.totalSteps > 0
            ? <div className={classes.appBarBottomSpacer}/>
            : <div/>}
        </main>
        <ChatColumn userId={params.userId}
                    unternehmenId={params.unternehmenId}
                    ref={this.props.chatRef}
                    history={this.props.history}/>
        {/*<div ref={this.props.chatRef} />*/}
        {/*</ChatColumnContext.Provider>*/}
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(ViewArea));
// export default withRouter(withStyles(styles)(React.forwardRef((props, ref) => <ViewArea
//   outerLastMessageRef={ref} {...props}
// />)));
// export default withStyles(styles)(React.forwardRef((props, ref) => <ChatColumn
//   lastMessageRef={ref} {...props}
// />));

// export default withStyles(styles)(React.forwardRef((props, ref) => <ViewArea
//   outerLastMessageRef={ref} {...props}
// />));
