const SteckbriefSchritte = {
  1: {
    PitchDeckIntro: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  },
  2: {
    PitchDeckTeam: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  },
  3: {
    PitchDeckProblem: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  },
  4: {
    PitchDeckLoesung: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  },
  5: {
    PitchDeckProdukt: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  },
  6: {
    PitchDeckMarkt: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  },
  7: {
    PitchDeckUSP: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  },
  8: {
    PitchDeckMachbarkeit: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  }
};

export default SteckbriefSchritte;
