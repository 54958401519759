import React, {Fragment} from 'react';

const erklaerungsTexte = {
  Erloese7: {
    kurzName: "Umsätze 7% MwSt",
    erklaerungHeader: "Umsätze 7% MwSt",
    erklaerungTipp:
      "Praxistipp: Wenn Du ein Unternehmen hast, in dem verschiedene Mehrwertsteuersätze zur Anwendung kommen, solltest Du diese von Anfang an in unterschiedlichen Konten buchen.",
    erklaerungText:
      "Erlöse, die mit 7% Mehrwertsteuersatz gebucht werden, z.B. Lebensmittelverkäufe",
  },
  Erloese19: {
    kurzName: "Umsätze 19% MwSt",
    erklaerungHeader: "Umsätze 19% MwSt",
    erklaerungTipp:
      "Wenn Du ein Unternehmen hast, in dem verschiedene Mehrwertsteuersätze zur Anwendung kommen, solltest Du diese von Anfang an in unterschiedlichen Konten buchen",
    erklaerungText:
      "Erlöse, die mit dem \"normalen\" 19% Mehrwertsteuersatz gebucht werden, z.B.  Dienstleistungen, Waren aller Art",
  },
  SonstigeErloese: {
    kurzName: "Sonstige Umsätze",
    erklaerungHeader: "Sonstige Umsätze",
    erklaerungTipp:
      "Sonstige Umsätze kommen in der Praxis gerade bei Startups eher selten vor.",
    erklaerungText:
      "Sonstige betriebliche Erträge, wie z.B. Privatverbrauch Unternehmer, oder Mietzahlungen einer nicht betriebsnotwendigen Immobilie",
  },
  UnfertigeErzeugnisse: {
    kurzName: "Unfertige Erzeugnisse",
    erklaerungHeader: "Unfertige Erzeugnisse",
    erklaerungTipp:
      "Meistens werden unfertige Erzeugnisse unterjährig nicht gebucht, da keine unterjährige Inventur stattfindet. Man könnte dies aber machen, und unfertige Erzeugnisse bewerten, wenn man das Ergebnis in der monatlichen BWA mit einem Inventurwert \"verbessern\" möchte",
    erklaerungText: "Hier können Werte für in der Produktion angefangene, und bei einer Inventur erfasste Werte berücksichtigt werden",
  },
  Bestandsveraenderung: {
    kurzName: "Bestandsveränderung",
    erklaerungHeader: "Bestandsveränderung",
    erklaerungTipp:
      "Meistens werden fertige Erzeugnisse bzw. deren Bewertung unterjährig nicht gebucht, da keine unterjährige Inventur stattfindet. Man könnte dies aber machen, und unfertige Erzeugnisse bewerten, wenn man das Ergebnis in der monatlichen BWA mit einem Inventurwert \"verbessern\" möchte",
    erklaerungText:
      "Wert der Bestandsveränderung; wird meist unterjährig nicht erfasst, aber zum Jahreswechsel wird die Wertänderung nach der Inventur hier eingetragen; bei einzelnen Unternehmen kann eine häufigere Inventur Sinn machen, und hier eingetragen werden",
  },
  Wareneinkauf: {
    kurzName: "Wareneinkauf",
    erklaerungHeader: "Wareneinkauf",
    erklaerungTipp:
      "Beim Wareneinkauf kommt es darauf an, ob das eigene Unternehmen ein Ist- oder Soll-Versteuerer ist. Bei einem Ist-Versteuerer müssten die Rechnungen nur bei erfolgter Zahlung gebucht werden. Beim Sollversteurer werden diese in der Regel gleich nach Erhalt eingebucht. Somit kann ggf. Über den Erhalt und das Zahlen von Rechnungen eine gewisse Bilanzpolitik betrieben werden. Auch zum Jahresende kann durch das Vorziehen von Rechnungen oder deren Zahlung ein gutes Jahr etwas \"verschlechtert\" werden, und damit eine Reduzierung der Steuerlast erzielt werden. Verbindliche Auskünfte kann jedoch am besten Dein Steuerbüro mit Dir besprechen.",
    erklaerungText:
      "Wert der Aufwendungen für Grundmaterialien im Unternehmen, wie z.B. Einkauf von Rohstoffen",
  },
  Fremdleistungen: {
    kurzName: "Fremdleistungen",
    erklaerungHeader: "Projektbezogen einzukaufende Fremdleistungen",
    erklaerungTipp:
      "Beim Einkauf von Fremdleistungen kommt es darauf an, ob das eigene Unternehmen ein Ist- oder Soll-Versteuerer ist. Bei einem Ist-Versteuerer müssten die Rechnungen nur bei erfolgter Zahlung gebucht werden. Beim Sollversteurer werden diese in der Regel gleich nach Erhalt eingebucht. Somit kann ggf. Über den Erhalt und das Zahlen von Rechnungen eine gewisse Bilanzpolitik betrieben werden. Auch zum Jahresende kann durch das Vorziehen von Rechnungen oder deren Zahlung ein gutes Jahr etwas \"verschlechtert\" werden, und damit eine Reduzierung der Steuerlast erzielt werden. Verbindliche Auskünfte kann jedoch am besten Dein Steuerbüro mit Dir besprechen.",
    erklaerungText: "Hier werden die Aufwendungen für Dienstleister berücksichtigt",
  },
  Personalkosten: {
    kurzName: "Personalkosten",
    erklaerungHeader: "Personalkosten",
    erklaerungTipp:
      "Bei den Personalkosten sollte unbedingt beachtet werden, dass z.B, einzelne Monate deutlich höhere Personalkosten haben könnten, da z:b. Im November ein Weihnachtsgeld zu zahlen ist. In der Buchhaltung wird dieses Weihnachtsgeld eher selten auf die anderen Monate umgelegt, so dass es hier zu Personalkostenschwankungen kommen kann. Weiterhin ist wichtig, dass bei Personengesellschaften kein Geschäftsführergehalt gezahlt wird, und der Gewinn des Unternehmens die Lebenshaltungskosten mit abdecken sollte. Kalkulatorisch sollte somit ein jedem Fall das gewünschte Einkommen des Unternehmens mit einkalkuliert werden.",
    erklaerungText:
      "Alle Personalkosten des Unternehmens; bei Einzelunternehmen muss hier gedanklich noch der Unternehmerlohn des Gründers mit eingerechnet werden, wird häufig vergessen; bei einer GmbH bezieht der Unternehmer oft ein Geschäftsführergehalt, das in den Personalkosten eingebucht ist",
  },
  Raumkosten: {
    kurzName: "Raum- und Bürokosten",
    erklaerungHeader: "Raum- und Bürokosten",
    erklaerungTipp:
      "Wenn für das eigene Unternehmen eigene Privaträume genutzt werden, für die keine Miete zu zahlen ist, können aber z.B. Renovierungskosten ggf. steuerlich wirksam eingebucht werden.",
    erklaerungText:
      "Alle Kosten für die Räumlichkeit des Unternehmens, wie Miete,  Leasing, Reparaturen im Gebäude, Energiekosten, Reinigung und ähnliches.",
  },
  Steuern: {
    kurzName: "Steuern",
    erklaerungHeader: "Steuern",
    erklaerungTipp:
      "Für die Gewerbesteuervorauszahlungen sollte immer eine Rücklage gebildet werden. Bei einem deutlich erfolgreicheren Geschäftsjahr als im Vorjahr muss für dieses Jahr bei einem Gewerbebetrieb oder bei einem Freiberufler Einkommenssteuer nachgezahlt werden, und die Vorauszahlung für das laufende Jahr erhöht sich. Dies sollte durch Bildung von Liquiditätsreserven planerisch berücksichtigt werden, um nicht in Liquiditätsschwierigkeiten zu geraten",
    erklaerungText:
      "Betriebliche Steuern, wie Kraftfahrzeugsteuer oder Gewerbesteuervorauszahlung",
  },
  Versicherungen: {
    kurzName: "Versicherungen",
    erklaerungHeader: "Versicherungen",
    erklaerungTipp:
      "Nicht für jedes Risiko ist wirklich eine Versicherung zur Absicherung notwendig, Elementare Risiken, wie Feuer, oder je nach Produkt Produkthaftungsrisiken sollten abgeschlossen werden vor Betriebsbeginn. Viele kleinere Risiken können aber z.B. mit passender Vertragsgestaltung oder AGBs ausgeschlossen oder reduziert werden. Fragen Sie im Zweifelsfalle einen unabhängigen Fachmann.",
    erklaerungText:
      "Kosten für Versicherungen, Beiträge für Verbände, sonstige Abgaben und ähnliches",
  },
  "Kfz-Kosten": {
    kurzName: "Kfz Kosten",
    erklaerungHeader: "Kfz Kosten",
    erklaerungTipp:
      "Bei Betriebsfahrzeugen können bei betrieblichen Fahrten in der Regel alle KFZ-Kosten als Betriebskosten abgesetzt werden. Diese müssen im Zweifel durch ein Fahrtenbuch belegt werden können. Nähere Auskünfte dazu erteilt Dein Steuerberater.",
    erklaerungText:
      "laufende Kraftfahrzeug-Betriebskosten, Kraftfahrzeugversicherungen, Mietleasing-Raten für Kraftfahrzeuge, Reparaturkosten für Kraftfahrzeuge",
  },
  "Werbe-Reisekosten": {
    kurzName: "Werbe/Reisekosten",
    erklaerungHeader: "Werbe/Reisekosten",
    erklaerungTipp:
      "Bereits im Vorfeld einer Unternehmensgründung können z.B. Fahrtkosten entstehen, z.B. für Fahrten mit dem eigenen privaten PKW zur Kundensuche, oder zur Gewinnung von Kooperationspartner im Vorfeld. Diese Fahrten können als Gründungskosten steuerlich im Regelfall mit angesetzt werden, daher macht eine Sammlung und Aufstellung Sinn. Auch im betrieblichen Alltag kann es immer wieder Gründe geben, Fahrten mit dem eignen PKW betrieblich abzusetzen. Bewirtungskosten für ein Essen mit einem Kunden oder Geschäftspartner kann ebenfalls als Betriebsausgabe abgesetzt werden. Auch ein Bild im Büro, ein Imagefilm, Mitgliedsbeiträge in Unternehmensverbänden, etc. können Teil der Werbungskosten sein.",
    erklaerungText:
      "Kosten für Geschäftsreisen des Unternehmers, Werbung, Dekoration, Geschenke, Repräsentationskosten, Übernachtungskosten, Bewirtungskosten, Reisekosten Arbeitnehmer",
  },
  "Kosten der Warenabgabe": {
    kurzName: "Kosten der Warenabgabe",
    erklaerungHeader: "Kosten der Warenabgabe",
    erklaerungTipp:
      "Leider habe ich dazu noch keinen Praxistipp...",
    erklaerungText:
      "Verkaufsprovisionen, Ausgangsfrachten, Garantieleistungen, Fremdkosten für Vertriebsunterstützung",
  },
  Afa: {
    kurzName: "Afa",
    erklaerungHeader: "Abschreibungen (AfA)",
    erklaerungTipp:
      "Abschreibungen bis 800 EUR pro Wirtschaftsgut können sofort im vollen Umfang steuerlich als Betriebsausgabe abgesetzt werden, und mindern somit den Gewinn. Bei Wirtschaftsgütern mit höheren Werten müssen diese über eine steuerlich vom Finanzamt meist vorgegebene steuerliche Nutzungsdauer auf mehrere Jahre verteilt werden. Mit einer geschickten Investitionspolitik können ungewollte Schwankungen bei Steuerzahlungen etwas \"geglättet\" werden. Die Erfahrung zeigt, dass es sich lohnt, bei guter Gewinnlage bereits Investitionen in die wirtschaftliche Zukunft Deines Unternehmens zu tätigen, statt zuviel Steuern zu bezahlen",
    erklaerungText:
      "Alle Abschreibungen des Unternehmens, wie z.B. Abgang aus Sachanlagen, Forderungsverluste bei Entfall von Forderungen, laufende Abschreibung von Wirtschaftsgütern, also Wertverluste durch Nutzung derselben. Afa steht dabei für Absetzungen für Abnutzung. Da es hierbei steuerliche Gestaltungsspielräume gibt, kann mit dieser Position ggf. Bilanzpolitik gemacht werden",
  },
  Reparaturen: {
    kurzName: "Reparaturen",
    erklaerungHeader: "Reparaturen",
    erklaerungTipp:
      "Die zeitnahe oder vorbeugende Instandhaltung bzw. Reparatur von Betriebsmitteln, die zur Leistungserstellung dienen, ist wichtiger, als das Hinauszögern derselben, da häufig im unpassenden Moment ansonsten ein Ausfall befürchtet werden muss.",
    erklaerungText:
      "Wartung und Reparaturen von Maschinen und Anlagen des Betriebsvermögens",
  },
  Instandhaltung: {
    kurzName: "Instandhaltung",
    erklaerungHeader: "Instandhaltung",
    erklaerungTipp:
      "Die zeitnahe oder vorbeugende Instandhaltung bzw. Reparatur von Betriebsmitteln, die zur Leistungserstellung dienen, ist wichtiger, als das Hinauszögern derselben, da häufig im unpassenden Moment ansonsten ein Ausfall befürchtet werden muss.",
    erklaerungText: "Kosten der betrieblichen Instandhaltung, wie z.B. kleinere Instandsetzungen; bei größeren Positionen könnten diese ggf. aktiviert, und damit als Wertzuwachs dargestellt werden",
  },
  "Sonstige Kosten": {
    kurzName: "Sonstige Kosten",
    erklaerungHeader: "Sonstige Kosten",
    erklaerungTipp:
      "Aus der Erfahrung heraus zeigt sich, dass unter sonstigen Kosten eine Vielzahl von Kosten gebucht werden. Hier lohnt es sich, nach Ablauf je eines Jahres diese im Details auszuwerten, und hier nach Einsparungen gezielt zu suchen.",
    erklaerungText:
      "sonstige betriebliche Aufwendungen, Kosten für Betriebsbedarf und Ähnliches",
  },
  Zinsaufwand: {
    kurzName: "Zinsaufwand",
    erklaerungHeader: "Zinsaufwand",
    erklaerungTipp:
      "Versuche zu zahlende Zinsen so gering, wie möglich zu halten, denn das erhöht Deine Flexibilität für die Zukunft. Zinsen für das Girokonto sind häufig sehr teuer, und ein Darlehen mit einer Absicherung ist häufig eine günstigere Variante, wenn dies möglich ist",
    erklaerungText:
      "Aufwand für Zinsen, wie sie für Kredite und Darlehen bezahlt werden müssen",
  },
  "So neutraler Aufwand": {
    kurzName: "Sonstiger neutraler Aufwand",
    erklaerungHeader: "Sonstiger neutraler Aufwand",
    erklaerungTipp:
      "Während der Corona-Pandemie erhaltene Hilfszahlungen, die zurückzuzahlen sind, werden hier beispielsweise eingebucht.",
    erklaerungText:
      "Aufwand, der unabhängig vom Unternehmenszweck anfällt, wie z.B. Entschädigungen Dritter",
  },
  Zinsertrag: {
    kurzName: "Zinserträge",
    erklaerungHeader: "Zinserträge",
    erklaerungTipp:
      "Zinserträge sind besser, als Zinskosten!",
    erklaerungText: "Zinserträge für z.B. Geldanlagen des Unternehmens",
  },
  SoNeutralerErtrag: {
    kurzName: "Sonstige neutrale Erträge",
    erklaerungHeader: "Sonstige neutrale Erträge",
    erklaerungTipp:
      "Während der Corona-Pandemie erhaltene Hilfszahlungen vom Staat werden bei neutralen Erträgen in der Regel eingebucht.",
    erklaerungText:
      <Fragment>
        <p>Ertrag, der im Normalfall nichts mit dem Unternehmenszweck zu tun hat, wie z.B. Entschädigungszahlungen
          Dritter.</p>
        <p><i>Für den gewählten Gründungsmonat wurden hier automatisch die Eigenmittel und Darlehen eingetragen. Sollten
          gestaffelte Einzahlungen erfolgen, ist dies in der detaillierten Monatsplanung anzupassen.</i></p>
      </Fragment>,
  },
  FinanzierungBauinvestitionen: {
    kurzName: "Bauinvestitionen",
    erklaerungHeader: "Bauinvestitionen",
    erklaerungTipp:
      "Bauinvestitionen werden meist über einen längeren Zeitraum abgeschrieben und erhöhen die Abschreibungen, und mindern somit in den Jahren der Abschreibung den steuerlichen Gewinn anteilig.",
    erklaerungText:
      "Investitionen in ein Bauvorhaben oder ein Immobilienprojekt des Unternehmens",
  },
  FinanzierungBGA: {
    kurzName: "BGA",
    erklaerungHeader: "Betriebs- und Geschäftsausstattung",
    erklaerungTipp:
      "Investitionen in BGA werden meist über einen längeren Zeitraum abgeschrieben und erhöhen die Abschreibungen, und mindern somit in den Jahren der Abschreibung den steuerlichen Gewinn anteilig.",
    erklaerungText:
      "Investitionen und Ausgaben für die Betriebsausstattung, wie z.B. Möbel",
  },
  FinanzierungGeschaeftsausstattung: {
    kurzName: "Geschäftsausstattung",
    erklaerungHeader: "Geschäftsausstattung",
    erklaerungTipp:
      "Investitionen in Geschäftsausstattung werden meist über einen längeren Zeitraum abgeschrieben und erhöhen die Abschreibungen, und mindern somit in den Jahren der Abschreibung den steuerlichen Gewinn anteilig.",
    erklaerungText:
      "Investitionen und Ausgaben in die Geschäftsausstattung des Unternehmens, z.B. Bürotisch, Verkaufstresen, Telefonanlage",
  },
  FinanzierungMaschinen: {
    kurzName: "Maschinen",
    erklaerungHeader: "Maschinen",
    erklaerungTipp:
      "Investitionen in Maschinen werden meist über einen längeren Zeitraum abgeschrieben und erhöhen die Abschreibungen, und mindern somit in den Jahren der Abschreibung den steuerlichen Gewinn anteilig. Gleichzeitig wird bei rentabler Produktion Wert für Dein Unternehmen geschaffen.",
    erklaerungText:
      "Investitionen und Ausgaben in Maschinen für einen Produktionsbetrieb",
  },
  FinanzierungComputerSoftware: {
    kurzName: "Computer/Software",
    erklaerungHeader: "Computer/Software",
    erklaerungTipp:
      "Bis 800 EUR können Computer und Software sofort abgeschrieben werden, und mindern damit im Anschaffungsjahr den Gewinn in Höhe der Anschaffungskosten.",
    erklaerungText:
      "Investitionen und Ausgaben in Computer und Software, wie z.B. Server, PC",
  },
  FinanzierungFahrzeuge: {
    kurzName: "Fahrzeuge",
    erklaerungHeader: "Fahrzeuge und Transportmittel",
    erklaerungTipp:
      "Für Investitionen in Digitalisierung gibt es immer wieder Förderprogramme in einzelnen Bundesländern. Damit könnten solche Investitionen verbilligt werden. Frage einen Frdermittelberater.",
    erklaerungText:
      "Investitionen und Ausgaben für Fahrzeuge aller Art, oder Hubwagen, etc.",
  },
  FinanzierungSonstigeInvestitionen: {
    kurzName: "Sonstige Investitionen",
    erklaerungHeader: "Sonstige Investitionen & Reserve",
    erklaerungTipp:
      "Versuche Investitionen zu finden, die Deiner Produktivität oder Deiner Sichtbarkeit zu Gute kommen.",
    erklaerungText:
      "Hier macht bei der Planung der Ansatz eines pauschalen Betrages Sinn, um z.B. für ungeplante Ausfälle Ersatz eingeplant zu haben",
  },
  FinanzierungWarenMateriallager: {
    kurzName: "Waren- und Materiallager",
    erklaerungHeader: "Erstes Waren- und Materiallager",
    erklaerungTipp:
      "Lege Dir nicht zuviel Sachen auf Lager, wenn Du Dir nicht sicher bist, diese auch zeitnah wieder verkaufen kannst, denn Trends und Warenalterung sind nicht zu unterschätzen und stellen ein Risiko für Warenbestände dar. ",
    erklaerungText:
      "Zum Verkauf oder Produktion wird ein erster Bestand an Waren/ Rohstoffen benötigt, der hier vom Wert her angesetzt wird, damit er berücksichtigt wird",
  },
  FinanzierungKaufpreis: {
    kurzName: "Kaufpreis",
    erklaerungHeader: "Kaufpreis (Betriebsübernahme)",
    erklaerungTipp:
      "Der Kaufpreis von Unternehmen sollte dem Wert entsprechen, und nicht zuviel Aufschlag für Goodwill aufweisen.",
    erklaerungText: "Kaufpreis eines Unternehmens oder einer Beteiligung",
  },
  FinanzierungSummeSachinvestitionen: {
    kurzName: "Summe Sachinvestitionen",
    erklaerungHeader: "Summe Sachinvestitionen",
    erklaerungTipp:
      "Investitionen in gebräuchliche Wirtschaftsgüter stellen häufig einen Wert in der Bilanz dar. Dies kann ggf. für Kredite als Sicherheit genutzt werden.",
    erklaerungText: "Hier werden alle Sachinvestitionen aufsummiert",
  },
  FinanzierungBeratungen: {
    kurzName: "Beratungen",
    erklaerungHeader: "Beratungen",
    erklaerungTipp:
      "Unterschätze nicht den Input erfahrender Dritter....und kaufe Expertise im geeignetem Maße zu, wo Du sie selbst nicht hast. Weiterbildung ist manchmal ebenfalls förderfähig, oder kann günstig eingekauft werden.",
    erklaerungText:
      "Summe aller Kosten für Unternehmens- und Steuerberater, Rechtsanwälte und Fortbildungen",
  },
  FinanzierungUnternehmensberater: {
    kurzName: "Unternehmens-/Steuerberater",
    erklaerungHeader: "Unternehmens-/Steuerberater",
    erklaerungTipp:
      "Vermeide fatale Fehler durch Einkauf von Erfahrung für Dein Unternehmen. Das lohnt sich sehr oft!",
    erklaerungText:
      "geschätzte Kosten für einen Steuerberater und/oder einen Unternehmensberater zum Unternehmensstart; bitte unterschätze nicht den Erfahrungswert solcher Beratungen. Wenn klassische Anfängerfehler vermieden werden können, ist dies viel Geld wert",
  },
  FinanzierungRechtsanwalt: {
    kurzName: "Rechtsanwalt",
    erklaerungHeader: "Rechtsanwalt",
    erklaerungTipp:
      "Besser bei einem erfahrenen Fachanwalt eine Problemstellung beheben lassen, als bei einem unerfahrenen Allrounder.",
    erklaerungText:
      "Kosten für einen Rechtsanwalt, z.B. für die Erstellung eines Gesellschaftsvertrages bei der GmbH",
  },
  FinanzierungFortbildungen: {
    kurzName: "Fortbildungen",
    erklaerungHeader: "Seminare, Kurse etc.",
    erklaerungTipp:
      "In einigen Bundesländern ist Fortbildung förderfähig. Versuche Lücken in Deinem Wissen zu schließen. Dies kann auch im Austausch mit anderen Unternehmern erfolgen.",
    erklaerungText: "Kosten für mögliche Fortbildungen im Betrieb",
  },
  FinanzierungKautionen: {
    kurzName: "Kautionen",
    erklaerungHeader: "Kautionen / Gebühren / Provisionen",
    erklaerungTipp:
      "Versuche mit möglichst wenig Gebühren und Kautionen zurecht zu kommen.",
    erklaerungText: "Mögliche Kosten für Kautionen oder sonstige Gebühren",
  },
  FinanzierungMietkautionen: {
    kurzName: "Mietkautionen",
    erklaerungHeader: "Mietkautionen in bar",
    erklaerungTipp:
      "LBarzahlungen solltest Du nach Möglichkeit vermeiden, da diese oft nicht ausreichend dokumentiert sind.",
    erklaerungText: "Betrag für Kautionszahlungen für Miete",
  },
  FinanzierungGebuehrenBehoerden: {
    kurzName: "Gebühren von Behörden",
    erklaerungHeader: "Gebühren von Behörden",
    erklaerungTipp:
      "Manchmal kann man im Dialog mit Behörden auch eine Reduzierung der Gebühren erreichen. Oftmals hilft ein persönliches Gespräch, weil man selbst die genauen Ursachen für Gebühren nicht einschätzen kann....",
    erklaerungText:
      "Mögliche Gebühren von Behörden für z.B. Eintragungen, Ausschreibungen, etc.",
  },
  FinanzierungNotargebuehren: {
    kurzName: "Notargebühren",
    erklaerungHeader: "Notargebühren",
    erklaerungTipp:
      "Notagrbeühren für betriebliche Belange können auch gewinnmindernd und damit Steuermindernd eingebucht werden.",
    erklaerungText:
      "Mögliche Notarkosten, z.B. für die Ernennung und Eintragung zum Geschäftsführer",
  },
  FinanzierungMaklerprovisionen: {
    kurzName: "Maklerprovisionen",
    erklaerungHeader: "Maklerprovisionen",
    erklaerungTipp:
      "Bei Maklern immer das Verhältnis von Kosten und Nutzen im Auge behalten.",
    erklaerungText:
      "Mögliche Maklerprovisionen, z.B. für die Vermittlung von Geschäftsräumen",
  },
  FinanzierungKonzessionen: {
    kurzName: "Konzessionen",
    erklaerungHeader: "Konzessionen",
    erklaerungTipp:
      "Konzessionen sind Verhandlungssache, und somit können diese oft beeinflusst werden.",
    erklaerungText:
      "Kosten für mögliche Genehmigung einer Behörde oder eines anderen Unternehmens",
  },
  FinanzierungBueroerstausstattung: {
    kurzName: "Büroerstausstattung",
    erklaerungHeader: "Büroerstausstattung",
    erklaerungTipp:
      "Auch ein Bild oder ein Teppich kann ein Teil der Büroausstattung sein.",
    erklaerungText: "Kosten für die Ausstattung des Büros",
  },
  FinanzierungBueromaterial: {
    kurzName: "Büromaterial",
    erklaerungHeader: "Büromaterial",
    erklaerungTipp:
      "Es gibt viele gute Online- und Offlineshops für Bürobedarf. Ladeninhaber kennen oft andere Betriebe, mit denen eine Kooperation möglich sein könnte. Es loht sich öfters, mit anderen Unternehmern ins Gespräch zu kommen.",
    erklaerungText: "Kosten für die Anschaffung von Büromaterial",
  },
  FinanzierungBriefpapierVistenkarten: {
    kurzName: "Briefpapier/Vistenkarten",
    erklaerungHeader: "Briefpapier/Vistenkarten, etc.",
    erklaerungTipp:
      "Ein gutes und geschmackvolles Design von Visitenkarten gibt Kunden und Interessenten einen ersten Eindruck Deines Unternehmens, daher ist das wichtig!",
    erklaerungText: "Kosten für Briefpapier, Visitenkarten, und ähnliches ",
  },
  FinanzierungMarkteinfuehrungWerbung: {
    kurzName: "Markteinführung/Werbung",
    erklaerungHeader: "Markteinführung / Werbung",
    erklaerungTipp:
      "Werbung ist für die Sichtbarkeit Deines Unternehmens sehr wichtig, Man spricht von Tocupoints zum Kunden, die können überall dort sein, wo es Berührungspunkte zu Kunden gibt, also z.B auch am Telefon, die Ladenansicht, die Homepage, Werbung auf Deinem Auto, usw. Versuche dies ganzheitlich zu betrachten",
    erklaerungText:
      "Kosten für Werbemassnahmen; bitte bereits zeitlich vor oder zum eigentlichen Unternehmensstart einplanen, denn der Effekt sollte gleich zum Unternehmensstart vorliegen, damit die geplanten Umsätze erreicht werden können",
  },
  FinanzierungWerbematerialien: {
    kurzName: "Werbematerialien",
    erklaerungHeader: "Werbematerialien",
    erklaerungTipp:
      "Versuche Werbemassnahmen zu verstetigen, und nicht nur am Anfang durchzuführen.",
    erklaerungText: "Kosten für sonstige Werbematerialien",
  },
  FinanzierungInternetpraesenz: {
    kurzName: "Internet-Präsenz",
    erklaerungHeader: "Internet-Präsenz",
    erklaerungTipp:
      "Eine digitale Präsenz ist für fast alle Unternehmen im Zeitalter der Digitalisierung sehr wichtig, daher sollte auf eine übersichtliche und gut fundbare Homepage geachtet werden.",
    erklaerungText:
      "Kosten für Internetpräsenz, wer ein Geschäft eröffnet, muss auch virtuell gleich auffindbar sein",
  },
  FinanzierungAnzeigen: {
    kurzName: "Anzeigen",
    erklaerungHeader: "Anzeigen",
    erklaerungTipp:
      "Viele Unternehmen machen keine Anzeigen mehr, daher können hier oft sehr gute Preise verhandelt werden.",
    erklaerungText: "Kosten für Anzeigen",
  },
  FinanzierungEroeffnungsfeier: {
    kurzName: "Eröffnungsfeier",
    erklaerungHeader: "Eröffnungsfeier",
    erklaerungTipp:
      "Eine gute Eröffnungsfeier bringt eine sehr gute Sichtbarkeit, wenn sie gut gemacht ist. Zudem kann häufig die Presse eingeladen werden, so dass im Nachgang eine weitere Verbesserung der Bekanntheit damit erreicht werden kann.",
    erklaerungText:
      "Eine gute Möglichkeit ist eine Eröffnungsfeier, um gleich bekannt zu werden; die Kosten hierfür sind hier einzuplanen",
  },
  FinanzierungSummeGruendungsnebenkosten: {
    kurzName: "Summe Gründungsnebenkosten",
    erklaerungHeader: "Summe Gründungsnebenkosten",
    erklaerungTipp:
      "Gründungsnebenkosten können in der Regel steuerlich berücksichtigt werden, auch bereits vor der Gründung selbst.",
    erklaerungText: "Summe aller Kosten auf dieser Seite.",
  },
  FinanzierungLiquiditaetsbedarf: {
    kurzName: "Liquiditätsbedarf",
    erklaerungHeader: "Liquiditätsbedarf (geschätzt)",
    erklaerungTipp:
      "Bitte plane und arbeite immer mit einem Puffer bei der Liquidität, das wird häufig unterschätzt.",
    erklaerungText: "Für eine grobe Finanzierungsplanung sollte hier geschätzt werden, welcher Liquiditätsbedarf sich aus dem geplanten Vorhaben ergibt. Anhand eines später erstellten Liquiditätsplans kann dieser Wert präzisiert werden.",
  },
  FinanzierungLiquiditaetsreserve: {
    kurzName: "Liquiditätsreserve",
    erklaerungHeader: "Zusätzliche Liquiditätsreserve",
    erklaerungTipp:
      "Bitte plane und arbeite immer mit einem Puffer bei der Liquidität, das wird häufig unterschätzt.",
    erklaerungText:
      "Ein Puffer sollte immer eingeplant werden, denn eine Finanzierung oder ein Finanzplan sollte nie zu eng geplant sein, denn es können unvorhersehbare Probleme entstehen",
  },
  FinanzierungSummeBetriebsmittel: {
    kurzName: "Summe Betriebsmittel",
    erklaerungHeader: "Summe Betriebsmittel",
    erklaerungTipp:
      "Betriebsmittel sollten nie zuviel, aber auch nicht zuwenig vorhanden sein. Hier sollten die Erfahrungen im Verlauf der Zeit dokumentiert und ausgewertet werden. Stillstände sind zu vermeiden...",
    erklaerungText:
      "Summe der Aufwendungen für den Betriebsstart und die laufenden Ausgaben",
  },
  FinanzierungSummeBuergschaften: {
    kurzName: "Summe Bürgschaften/Garantien",
    erklaerungHeader: "Summe Bürgschaften/Garantien",
    erklaerungTipp:
      "Behalte über die Zeit Garantien und Bürgschaften im Auge...die zugrundeliegenden Sachverhalte ändern sich, und dann sollten die Garantien und Bürgschaften ebenfalls angepasst werden.",
    erklaerungText: "Dies ist die Summe der Bürgschaften, die notwendig sind",
  },
  FinanzierungKapitalbedarf: {
    kurzName: "Kapitalbedarf",
    erklaerungHeader: "Kapitalbedarf",
    erklaerungTipp:
      "Oft ist der tatsächliche Kapitalbedarf anders, als zunächst angenommen. Bitte im Auge behalten...",
    erklaerungText: "Das ist der Kapitalbedarf in einer Summe, der für das Vorhaben notwendig ist",
  },
  FinanzierungEigenmittel: {
    kurzName: "Eigenmittel",
    erklaerungHeader: "Eigenmittel",
    erklaerungTipp:
      "Ein gewisses Maß an Eigenmitteln ist wichtig, um nicht zu sehr von Fremdkapitalgebern abhängig zu sein.",
    erklaerungText: "Das sind die ohne Kredite zur Verfügung stehenden eigenen Finanzmittel",
  },
  FinanzierungSachwerteEigenleistungen: {
    kurzName: "Sachwerte/Eigenleistungen",
    erklaerungHeader: "Sachwerte/Eigenleistungen",
    erklaerungTipp:
      "Eigene Arbeiten z.B. für den Bau eines Regales oder Gebäudeteiles kann als Wert aktiviert werden, und wird damit in der Bilanz sichtbar. Damit kann ggf. eine besseres Eigenkapitalposition erreicht werden,. ",
    erklaerungText: "Das sind die z.B. durch eigene Arbeiten eingebrachten Werte in das Unternehmen",
  },
  FinanzierungSonstiges: {
    kurzName: "Sonstiges Eigenkapital",
    erklaerungHeader: "Sonstiges Eigenkapital",
    erklaerungTipp:
      "Ein Mindestmaß an Eigenkapital ist für den Betrieb eines Unternehmens in der Regel notwendig",
    erklaerungText: "Das sind sonstige, nicht unter die anderen Positionen passenden Eigenmittel",
  },
  FinanzierungSummeEigenkapital: {
    kurzName: "Summe Eigenkapital",
    erklaerungHeader: "Summe Eigenkapital",
    erklaerungTipp:
      "Ein Mindestmaß an Eigenkapital ist für den störungsfreien Betrieb Deines Unternehmens in der Regel notwendig.",
    erklaerungText: "Das ist die Summe des Eigenkapitals des Vorhabens",
  },
  FinanzierungFoerderdarlehen: {
    kurzName: "Förderdarlehen",
    erklaerungHeader: "Förderdarlehen",
    erklaerungTipp:
      "Oft gibt es in den Bundesländern günstige staatliche Kredite für Unternehmer. Erkundige Dich bei Deinem Beratungsheld oder z.B.bei der Kfw!",
    erklaerungText: "Das ist die Summe der Förderdarlehen, die z.B. zinsverbilligt sind, oder bei denen der Staat teilweise bürgt",
  },
  FinanzierungHausbankdarlehen: {
    kurzName: "Hausbankdarlehen",
    erklaerungHeader: "Hausbankdarlehen",
    erklaerungTipp:
      "Eine gute Hausbank mit einer vertrauensvollen Zusammenarbeit ist viel Wert für jeden Unternehmer.",
    erklaerungText: "Das sind die über die Hausbank erhaltenen Finanzmittel für das Vorhaben",
  },
  FinanzierungKontokorrrentkredit: {
    kurzName: "Kontokorrrentkredit",
    erklaerungHeader: "Kontokorrentkredit der Hausbank",
    erklaerungTipp:
      "Zinsen bei Kontokorrentdarlehen sind häufig recht teuer, und Du solltest günstigere Finanzierungsalternativen prüfen!",
    erklaerungText: "Das ist der Finanzrahmen, der von der Hausbank über das Girokonto flexibel zur Verfügung gestellt wird",
  },
  FinanzierungPrivateDarlehen: {
    kurzName: "Private Darlehen",
    erklaerungHeader: "Private Darlehen",
    erklaerungTipp:
      "Darlehen von privaten Kontakten können eine Alternative zu Banken sein im Einzelfall.",
    erklaerungText: "Das sind von privaten Investoren oder von z.B. der Familie gestellten Darlehen",
  },
  FinanzierungSonstigeFinanzierungsmittel: {
    kurzName: "Sonstige Finanzierungsmittel",
    erklaerungHeader: "Sonstige Finanzierungsmittel",
    erklaerungTipp:
      "Manchmal gibt es auch günstige Kredite von Versicherungen oder Bausparkassen. Einfach mal prüfen!",
    erklaerungText: "Das sind Finanzmittel, die unter sonst keine andere Rubrik passen",
  },
  FinanzierungSummeFremdkapital: {
    kurzName: "Summe Fremdkapital",
    erklaerungHeader: "Summe Fremdkapital",
    erklaerungTipp:
      "Zuviel Fremdkapital in der Bilanz macht alle Fremdkaptalgeber nervös...",
    erklaerungText: "Das ist die Summe des Fremdkapitals des Vorhabens",
  },
  FinanzierungGesamtkapital: {
    kurzName: "Gesamtkapital",
    erklaerungHeader: "Gesamtkapital",
    erklaerungTipp:
      "In der Regel sollten mindestens 20% Eigenkapital vorhanden sein",
    erklaerungText: "Das ist die Summe des gesamten Kapitals des Vorhabens, also Eigen- und Fremdkapital addiert",
  },
  FinanzierungAvalkredite: {
    kurzName: "Avalkredite",
    erklaerungHeader: "Avalkredite (Bürgschaften/Garantien)",
    erklaerungTipp:
      "Bürgschaften und Garantien sollten immer wieder hinterfragt und angepasst werden",
    erklaerungText: "Das ist die Summe aller Bürgschaften und Garantien des Vorhabens",
  },
  FinanzierungGesamtkapitalAvalkredite: {
    kurzName: "Gesamtkapital und Avalkredite",
    erklaerungHeader: "Gesamtkapital und Avalkredite",
    erklaerungTipp:
      "Auch Avalkredite sollten immer wieder mal überprüft und angepasst werden",
    erklaerungText: "Das ist die Summe aus Gesamtkapital und der Avalkredite",
  },
  FinanzierungFinanzierungslueckeReserve: {
    kurzName: "Finanzierungslücke/-reserve",
    erklaerungHeader: "Finanzierungslücke/-reserve",
    erklaerungTipp:
      "Bei auftretenden oder bevorstehenden Finanzierungslücken sollten frühzeitig mögliche weitere Finanzpartner oder die Hausbank kontaktiert werden.",
    erklaerungText: "Das ist die Finanzlücke des Vorhabens, oder die Finanzreserve",
  },
  LiquiditaetVormonat: {
    kurzName: "Mittel am Monatsanfang",
    erklaerungHeader: "Liquide Mittel am Monatsanfang (Bank + Kasse)",
    erklaerungTipp:
      "Bei auftretenden oder bevorstehenden Finanzierungslücken sollten frühzeitig mögliche weitere Finanzpartner oder die Hausbank kontaktiert werden",
    erklaerungText: "Das sind die flüssigen Mittel am Monatsanfang",
  },
  LiquiditaetEinzahlungen: {
    kurzName: "Einzahlungen",
    erklaerungHeader: "Summe der Einzahlungen",
    erklaerungTipp:
      "Bei auftretenden oder bevorstehenden Finanzierungslücken sollten frühzeitig mögliche weitere Finanzpartner oder die Hausbank kontaktiert werden",
    erklaerungText: "Das sind die Summe der Einzahlungen",
  },
  LiquiditaetSonstigeEinzahlungen: {
    kurzName: "Sonstige Einzahlungen",
    erklaerungHeader: "Sonstige Einzahlungen",
    erklaerungTipp:
      "Bei auftretenden oder bevorstehenden Finanzierungslücken sollten frühzeitig mögliche weitere Finanzpartner oder die Hausbank kontaktiert werden",
    erklaerungText:
      "Summe der Einzahlungen, die somit liquiditätswirksam werden",
  },
  LiquiditaetVerfuegbar: {
    kurzName: "Verfügbare Liquidität",
    erklaerungHeader: "Verfügbare Liquidität im Monat",
    erklaerungTipp:
      "Bei auftretenden oder bevorstehenden Finanzierungslücken sollten frühzeitig mögliche weitere Finanzpartner oder die Hausbank kontaktiert werden",
    erklaerungText: "Das sind die für Ausgaben zur Verfügung stehenden Mittel im Betrachtungsmonat",
  },
  LiquiditaetProjektbezogeneAuszahlungen: {
    kurzName: "Projektbezogene Auszahlungen",
    erklaerungHeader: 'Projektbezogene Auszahlungen ("Fremdkosten"), brutto',
    erklaerungTipp:
      "ei auftretenden oder bevorstehenden Finanzierungslücken sollten frühzeitig mögliche weitere Finanzpartner oder die Hausbank kontaktiert werden",
    erklaerungText: "Das sind die auf das Projekt bezogenen Ausgaben",
  },
  LiquiditaetRoherloes: {
    kurzName: "Roherlös",
    erklaerungHeader: "Roherlös",
    erklaerungTipp:
      "Der Roherlös sollte im Laufe der Jahre idealerweise immer etwas steigen, um von der Einnahmenreihe her das Erbebnis zu verbessern.",
    erklaerungText: "Roherlös = Einzahlungen - Projektbezogene Auszahlungen",
  },
  LiquiditaetLaufendeAuszahlungen: {
    kurzName: "Laufende Auszahlungen",
    erklaerungHeader: 'Laufende Auszahlungen ("Fixkosten"), brutto',
    erklaerungTipp:
      "Behalte immer Deine Abflüsse von Liquidität im Auge. Arbeite mit Liquiditätsplanung, so dass Du hier vor negativen Überraschungen geschützt bleibst.",
    erklaerungText: "Das sind laufende Auszahlungen, die die Liquidität reduzieren",
  },
  LiquiditaetTilgungen: {
    kurzName: "Tilgungen",
    erklaerungHeader: "Tilgungen",
    erklaerungTipp:
      "Tilgungen müssen in der Praxis von den laufenden Ergebnissen bezahlt werden, also von der Ergebnissen plus Abschreibungen in der BWA.",
    erklaerungText:
      "Summe der Tilgungen für Kredite, also Rückzahlung von Krediten; für neue Unternehmen gibt es oft bei öffentlichen Krediten die Möglichkeit von bis zu 2 tilgungsfreien Jahren",
  },
  LiquiditaetInvestitionen: {
    kurzName: "Investitionen",
    erklaerungHeader: "Auszahlungen für Investitionen",
    erklaerungTipp:
      "Größere Investitionen sollten separat finanziert werden, um die allgemeine Liquiditätssituation des Unternehmens nicht zu sehr zu belasten.",
    erklaerungText:
      <Fragment>
        <p>Investitionen sind werthaltige Ausgaben für Anlagen. Diese werden zwar über die steuerlich festgelegte
          Nutzungsdauer abgeschrieben. Bei der Liquiditätsberechnung ist jede getätigte Zahlung für Investitionen aber
          sofort abzuziehen.</p>
        <p><i>Für den gewählten Gründungsmonat wurden hier automatisch alle Sachinvestitionen aus der Kapital- und
          Bedarfsplanung eingetragen. Sollten die Investitions-Auszahlungen gestaffelt erfolgen, ist dies in der
          detaillierten Monatsplanung anzupassen.</i></p>
      </Fragment>,
  },
  LiquiditaetSteuernAbgaben: {
    kurzName: "Steuern und Abgaben",
    erklaerungHeader: "Steuern und Abgaben",
    erklaerungTipp:
      "Steuern werden meist im Nachhinein fällig. Bei stärker Ergebnisverbesserung wird somit im Nachhinein im folgenden Jahr eine Steuernachzahlung fällig. Diese muss rechtzeitig eingeplant werden, und meist erfolgt dann auch eine entsprechende Vorauszahlung von Steuern für das neue laufende Jahr.",
    erklaerungText: "Staatliche Steuern und Abgaben",
  },
  LiquiditaetUmsatzsteuersaldo: {
    kurzName: "Umsatzsteuersaldo",
    erklaerungHeader: "Umsatzsteuersaldo (Netto-Umsatz abzgl. Vorsteuer)",
    erklaerungTipp:
      "Umsatzsteuer muss meist monatlich bezahlt werden, und zwar aus der Differenz der erhaltenen Vorsteuer und der gezahlten Mehrwertsteuer. Durch entsprechenden Rechnungserhalt oder Zahlung kann somit der Monat und der Umsatzsteuersaldo auch ggf. etwas geplant werden.",
    erklaerungText: "Umsatzsteuer ist zu zahlen, Vorsteuer für bezogene Einkäufe und Dienstleistungen kann davon abgezogen werden, der Saldo ist an das Finanzamt zu zahlen, oder wird erstattet",
  },
  LiquiditaetUeberdeckung: {
    kurzName: "Über-/Unterdeckung",
    erklaerungHeader: "Umsatzsteuersaldo (Netto-Umsatz abzgl. Vorsteuer)",
    erklaerungTipp:
      "Umsatzsteuer muss meist monatlich bezahlt werden, und zwar aus der Differenz der erhaltenen Vorsteuer und der gezahlten Mehrwertsteuer. Durch entsprechenden Rechnungserhalt oder Zahlung kann somit der Monat und der Umsatzsteuersaldo auch ggf. etwas geplant werden.",
    erklaerungText: "Das ist die errechnete Liquiditätssituation",
  },
  LiquiditaetUeberdeckungNachEntnahme: {
    kurzName: "Über-/Unterdeckung (nach Privatentnahme)",
    erklaerungHeader: "Überdeckung (+) Unterdeckung (-) (nach Privatentnahme))",
    erklaerungTipp:
      "Entnahmen sollte der Unternehmer regelmäßig vornehmen, um seinen Lebensunterhalt und die laufenden privaten Kosten sicher abdecken zu können.",
    erklaerungText: "Das ist die errechnete Liquiditätssituation nach einer Privatentnahme, besonders dann notwendig bei einer Einzelfirma, bei der der Unternehmer kein Geschäftsführergehalt bezieht",
  },
  LiquiditaetMonatsende: {
    kurzName: "Liquide Mittel am Monatsende",
    erklaerungHeader: "Liquide Mittel am Monatsende",
    erklaerungTipp:
      "Umsatzsteuer muss meist monatlich bezahlt werden, und zwar aus der Differenz der erhaltenen Vorsteuer und der gezahlten Mehrwertsteuer. Durch entsprechenden Rechnungserhalt oder Zahlung kann somit der Monat und der Umsatzsteuersaldo auch ggf. etwas geplant werden.",
    erklaerungText: "Das ist der Stand der liquiden Mittel am Monatsende",
  },
  LiquiditaetMonatsendeMitKontokorrentrahmen: {
    kurzName: "Liquide Mittel inkl. Kontokorrentrahmen",
    erklaerungHeader: "Liquide Mittel inkl. Kontokorrentrahmen",
    erklaerungTipp:
      "Umsatzsteuer muss meist monatlich bezahlt werden, und zwar aus der Differenz der erhaltenen Vorsteuer und der gezahlten Mehrwertsteuer. Durch entsprechenden Rechnungserhalt oder Zahlung kann somit der Monat und der Umsatzsteuersaldo auch ggf. etwas geplant werden. Der verfügbare Kontokorrentrahmen sollte für Notfälle vorgehalten werden.",
    erklaerungText: "Das ist der Stand der liquiden Mittel am Monatsende unter Berücksichtigung des noch verfügbaren Rahmens der Kontokorrents",
  },
  PrivatGehaltLebenspartner: {
    kurzName: "Gehalt Lebenspartner/in",
    erklaerungHeader: "Nettogehalt Lebenspartner/in",
    erklaerungTipp:
      "Bei gemeinsamer Haushaltführung sollte eine gemeinsame Familieneingaben- und Ausgabenplanung gemacht werden, um den finanziellen Überblick jederzeit zu haben.",
    erklaerungText:
      "Um von der Unternehmung leben zu können, ist es hilfreich, wenn man zunächst bis zum erfolgreichen Anlauf des Unternehmens ein anderes Einkommen z.B. in der Familie hat, daher hier anzugeben",
  },
  PrivatKindergeld: {
    kurzName: "Kindergeld",
    erklaerungHeader: "Kindergeld",
    erklaerungTipp:
      "Kindergeld oder Kinderfreibetrag bei Steuererklärung: das Finanzamt bzw. der Steuerberater ermittelt das günstigere für Dich!",
    erklaerungText: "Auch erhaltenes Kindergeld zählt zum Familieneinkommen",
  },
  PrivatErziehungsgeld: {
    kurzName: "Erziehungsgeld",
    erklaerungHeader: "Erziehungsgeld",
    erklaerungTipp:
      "Das Erziehungsgeld wird nur für einen bestimmten Zeitraum, also nicht auf Dauer gezahlt.",
    erklaerungText: "Erziehungsgeld, das z.B. vom Staat gezahlt wird",
  },
  PrivatUnterhalt: {
    kurzName: "Unterhalt",
    erklaerungHeader: "Unterhalt",
    erklaerungTipp:
      "Unterhaltszahlungen können regelmäßig gerichtlich überprüft werden. Daraus kann sich eine Mehrbelastung oder ein Mehreinkommen ergeben.",
    erklaerungText:
      "bezogener Unterhalt erhöht ebenfalls die Einnahmen auf privater Seite",
  },
  PrivatMietzins: {
    kurzName: "Miet-/Pachtzins",
    erklaerungHeader: "Einkommen aus Vermietung und Verpachtung",
    erklaerungTipp:
      "Mieteinnahmen könnten für die Rente später ein wichtiger Teil der Alterseinkünfte darstellen Unternehmer und Selbstständige sollten rechtzeitig sich mit dem Thema der Altersvorsorge befassen.",
    erklaerungText: "Mögliches Einkommen aus Mieten oder Pachten",
  },
  PrivateKapitalertraege: {
    kurzName: "Kapitalerträge",
    erklaerungHeader: "Einkommen aus Kapitalerträgen",
    erklaerungTipp:
      "Kapitalerträge könnten für die Rente später ein wichtiger Teil der Alterseinkünfte darstellen Unternehmer und Selbstständige sollten rechtzeitig sich mit dem Thema der Altersvorsorge befassen.",
    erklaerungText: "Mögliches Einkommen aus Kapitalanlagen",
  },
  PrivatSonstigeEinkuenfte: {
    kurzName: "Sonstige Einkünfte",
    erklaerungHeader: "Sonstige Einkünfte",
    erklaerungTipp:
      "Sonstige Einkünfte sind in der Regel nicht dauerhaft, und entfallen nach gewisser Zeit.",
    erklaerungText:
      "Sontige Einkünfte, z.B. familiäre Unterstützung, Stipendium o.ä.",
  },
  PrivatEinkommensteuerruecklage: {
    kurzName: "Einkommensteuerrücklage",
    erklaerungHeader:
      "Rücklage für Einkommensteuer inkl. Soli-Zuschlag und Kirchensteuer",
    erklaerungTipp:
      "Eine Rücklage für Steuerzahlungen sollte ggf. auch einem separaten Konto gebildet werden. Pünktliche Zahlungen an Krankenkassen und das Finanzamt sollten auf jeden Fall immer gewährleistet sein!",
    erklaerungText:
      "Irgendwann muss auch auf erzieltes Einkommen Steuer und Abgaben bezahlt werden, dieser Rücklage sollte daher laufend gebildet werden, um nicht von einer unerwarteten Steuerzahlung überrascht zu werden",
  },
  PrivatMiete: {
    kurzName: "Wohnen",
    erklaerungHeader: "private Wohnkosten wie Miete (inkl. Nebenkosten und Strom)",
    erklaerungTipp:
      "Manchmal kann, wenn z.B. ein Büro für das Unternehmen in den Privaträumen vorhanden ist, eine Miete dafür angesetzt werden, die die privaten Wohnkosten mindern könnte.",
    erklaerungText: "private Wohnkosten wie Miete plus aller Nebenkosten im Monat oder Hausgeld",
  },
  PrivatKrankenversicherung: {
    kurzName: "Kranken-/Pflegeversicherung",
    erklaerungHeader: "Kranken- und Pflegeversicherung",
    erklaerungTipp:
      "Die richtige Auswahl von Kranken- und Pflegeversicherung kann viel Geld sparen. Am besten setzt man sich mit einem neutralen Fachmann zusammen, der nicht an seiner eigenen Provision vorrangig interessiert ist.",
    erklaerungText: "Kosten für Kranken- und Pflegeversicherung im Monat",
  },
  PrivatAltersvorsorge: {
    kurzName: "Altersvorsorge",
    erklaerungHeader: "Rentenversicherung/Altersvorsorge",
    erklaerungTipp:
      "Auch ein Unternehmer benötigt früher oder später eine Rentenleistung. Diese sollte frühzeitig begonnen werden. Manchmal kann das Unternehmen mit Erreichen des Rentenalters verkauft werden, und der Verkaufserlös stellt eine Basis für die Rente dar.",
    erklaerungText: "Kosten für Altersvorsorge",
  },
  PrivatUnfallversicherung: {
    kurzName: "Unfallversicherung",
    erklaerungHeader: "Unfallversicherung (privat)",
    erklaerungTipp:
      "Eine Unfallversicherung ist oftmals sinnvoll.",
    erklaerungText: "Kosten für eine mögliche Unfallversicherung",
  },
  PrivatArbeitslosenversicherung: {
    kurzName: "Arbeitslosenversicherung",
    erklaerungHeader: "Arbeitslosenversicherung",
    erklaerungTipp:
      "Eine Arbeitsloenversicherung kann manchmal sinnvoll sein.",
    erklaerungText: "Kosten für die Arbeitslosenversicherung",
  },
  PrivatSonstigeVersicherungen: {
    kurzName: "Sonstige Versicherungen",
    erklaerungHeader:
      "Sonstige Versicherungen (Berufsunfähigkeit, Haftpflicht, Hausrat, etc.)",
    erklaerungTipp:
      "Die richtigen Versicherungen sind am besten mit einem neutralen, nicht an eignen Provisionserlösen interessierten Berater individuell zu klären.",
    erklaerungText: "Kosten für sonstige Versicherungen",
  },
  PrivatKfz: {
    kurzName: "Fahrzeuge",
    erklaerungHeader: "Private KfZ-Kosten",
    erklaerungTipp:
      "Betriebliche Fahrten können betrieblich abgesetzt werden, private Kfz-Kosten nur privat.",
    erklaerungText: "Kosten für private Kraftfahrzeuge",
  },
  PrivatKommunikation: {
    kurzName: "Kommunikation/Medien",
    erklaerungHeader: "Telefon/Fernsehen/Radio/Internet",
    erklaerungTipp:
      "Oft besteht hier großes Sparpotential für private Haushalte.",
    erklaerungText:
      "Rundfunkgebühren und sonstige Ausgaben dafür hier eintragen",
  },
  PrivatBeitraege: {
    kurzName: "Beiträge",
    erklaerungHeader: "Beiträge (Vereine etc.)",
    erklaerungTipp:
      "Vereine und Verbände stellen oftmals ein sehr gutes Netzwerk auch für berufliche Kontakte dar. Nutzen!",
    erklaerungText:
      "Beiträge zu Vereinen, oder sonstige private Mitgliedschaften",
  },
  PrivaterTaeglicherBedarf: {
    kurzName: "täglicher Bedarf",
    erklaerungHeader:
      "Kosten des täglichen privaten Bedarfs (Essen, Trinken, Kleidung etc.) im Monat",
    erklaerungTipp:
      "Ab und an macht es Sinn, die Kosten für die Dinge des täglichen Bedarfs aufzuschreiben, zu analysieren, und ggf. Teile davon durch bewussteres Einkaufen einzusparen.",
    erklaerungText:
      "Geschätzte durchschnittliche Kosten für Lebenshaltung (Essen, Trinken, Kleidung, sontiges) möglichst realistisch eintragen. Vielleicht auch mal eine Verbrauchsaufschreibung durchführen, das bringt oftmals unerwartete Erkenntnisse",
  },
  PrivateRuecklagen: {
    kurzName: "Rücklagen",
    erklaerungHeader:
      "Rücklagen für Neuanschaffungen, Urlaub und Ausbildung der Kinder",
    erklaerungTipp:
      "Reserven zu haben, hat sich insbesondere in schlechten wirtschaftlichen Zeiten bewährt.",
    erklaerungText: "Rücklagen für Urlaub, Ausbildungen, private Anschaffungen, etc.",
  },
  PrivateUnterhaltszahlungen: {
    kurzName: "Unterhaltszahlungen",
    erklaerungHeader: "Unterhaltszahlungen an Andere",
    erklaerungTipp:
      "Unterhaltszahlungen können immer wieder an die tatsächlichen Verhältnisse angepasst werden.",
    erklaerungText: "zu leistende Unterhaltszahlungen an andere",
  },
  PrivatKredite: {
    kurzName: "Kredite",
    erklaerungHeader: "Zinsen und Tilgung von privat aufgenommenen Krediten",
    erklaerungTipp:
      "Vergleiche vor Kreditaufnahme die Konditionen der verschiedenen Anbieter.",
    erklaerungText: "Zins und Tilgung privat aufgenommener Kredite und Verbindlichkeiten",
  },
  PrivatSonstiges: {
    kurzName: "Sonstiges",
    erklaerungHeader: "Sonstiges",
    erklaerungTipp:
      "Plane ruhig an verschiedenen Stellen mit zielgerichteten Reserven.",
    erklaerungText:
      "Sonstige Ausgaben, z.B. Unterstützungsleistungen für Familie oder allgemeine Reserve",
  },
  PrivatEinnahmenInsgesamt: {
    kurzName: "Einnahmen insgesamt",
    erklaerungHeader: "Einnahmen außerhalb der Selbständigkeit insgesamt",
    erklaerungTipp:
      "Sich Aktivitäten konkret vorzunehmen, wird viel Erfolg bringen. Dies gilt auch für zusätzliche Einnahmen, wenn diese möglich sind.",
    erklaerungText:
      "Summe aller privaten Einnahmen außerhalb der Unternehmung",
  },
  PrivatAusgabenInsgesamt: {
    kurzName: "Privatausgaben insgesamt",
    erklaerungHeader: "Privatausgaben insgesamt",
    erklaerungTipp:
      "Analysiere ab und an Deine private Ausgabenstruktur, damit Du mehr Geld als Reserve in Deinem Unternehmen hast.",
    erklaerungText: "Summe aller privaten Ausgaben außerhalb der Unternehmung.",
  },
  PrivatBenoetigtePrivatentnahme: {
    kurzName: "Benötigte Privatentnahme",
    erklaerungHeader: "Benötigte Privatentnahme aus der Unternehmung",
    erklaerungTipp:
      "Zum Leben brauchst Du normalerweise regelmäßige Privatentnahmen, die Du einplanen und auch nötigen solltest.",
    erklaerungText: "Benötigte Privatentnahme aus der Unternehmung insgesamt.",
  },
  RentabilitaetUmsatzerloese: {
    kurzName: "Umsatzerlöse",
    erklaerungHeader: "Umsatzerlöse",
    erklaerungTipp:
      "Steigende Rentabilität aus Deinen Umsätzen ist das Ziel. Die Basis dafür ist die tägliche Arbeit von Dir an Verbesserungen im Unternehmen!",
    erklaerungText: "Hier stehen die erzielten Umsätze",
  },
  RentabilitaetRohertrag: {
    kurzName: "Roherlös",
    erklaerungHeader: "Rohererlös",
    erklaerungTipp:
      "Steigenden Roherlöse sind ein wichtiger Schritt für Ergebnisverbesserungen!",
    erklaerungText: "Rohererlös = Umsatzerlöse - Projektbezogene Auszahlungen",
  },
  RentabilitaetAbschreibungen: {
    kurzName: "Abschreibungen",
    erklaerungHeader: "Abschreibungen",
    erklaerungTipp:
      "Abschreibungen entstehen durch Investitionen. Durch gezielte Investitionen kann sich die Struktur und damit das Ergebnispotential Deines Unternehmens verbessern!",
    erklaerungText: "Afa und Afa für geringwertige Wirtschaftsgüter",
  },
  RentabilitaetGWG: {
    kurzName: "GWG",
    erklaerungHeader: "Geringwertige Wirtschaftsgüter",
    erklaerungTipp:
      "Geringwertige Wirtschaftsgüter können bis 800 EUR netto sofort abgeschrieben werden",
    erklaerungText: "Werte für die Summe von geringwertigen Wirtschaftsgütern, die sofort abgeschrieben werden können",
  },
  RentabilitaetAufwand: {
    kurzName: "Aufwand insgesamt",
    erklaerungHeader: "Aufwand insgesamt",
    erklaerungTipp:
      "Erlöse erhöhen, Aufwände reduzieren erhöht den Gewinn. Das ist eine Daueraufgabe jedes Unternehmers",
    erklaerungText: "Summe des ermittelten Aufwandes",
  },
  Betriebsergebnis: {
    kurzName: "Betriebsergebnis",
    erklaerungHeader: "Betriebsergebnis",
    erklaerungTipp:
      "Das Betriebsergebnis ist ein Gradmesser Deines Erfolges als Unternehmer. Davon müssen Tilgungen, und der Unternehmerlohn noch im Regelfall bezahlt werden.",
    erklaerungText: "Das ist das anhand der getätigten Angaben ermittelte Betriebsergebnis",
  },
  "Neutrales Ergebnis": {
    kurzName: "Neutrales Ergebnis",
    erklaerungHeader: "Neutralesergebnis",
    erklaerungTipp:
      "Prüfe regelmäßig, welche Fördermöglichkeiten oder Zuschüssen es für Dein Unternehmen in Deinem Bundesland geben kann.",
    erklaerungText:
      "Das ist das anhand der getätigten Angaben ermittelte Betriebsergebnis",
  },
  Ergebnis: {
    kurzName: "Ergebnis",
    erklaerungHeader: "Ergebnis",
    erklaerungTipp:
      "Das Ergebnis ist ein Gradmesser Deines Erfolges als Unternehmer. Davon müssen Tilgungen, und der Unternehmerlohn noch im Regelfall bezahlt werden.",
    erklaerungText:
      "Das ist das anhand der getätigten Angaben ermittelte Betriebsergebnis",
  },
  TextteilIntro: {
    kurzName: "Intro/Einführung",
    erklaerungHeader: "Was ist meine Idee?",
  },
  TextteilTeam: {
    kurzName: "Team/Gründer",
    erklaerungHeader: "Wer ist bei meiner Idee dabei?",
  },
  TextteilProblem: {
    kurzName: "Herausforderung/Problem",
    erklaerungHeader: "Welches Problem löst meine Idee?",
  },
  TextteilLoesung: {
    kurzName: "Lösung",
    erklaerungHeader: "Wie löst meine Idee das Problem?",
  },
  TextteilProdukt: {
    kurzName: "Produktvorstellung",
    erklaerungHeader: "Wie sieht die Umsetzung meiner Idee aus?",
  },
  TextteilMarkt: {
    kurzName: "Marktanalyse/Wettbewerb",
    erklaerungHeader: "Gibt es bereits ähnliche Ideen oder gar Umsetzungen?",
  },
  TextteilUSP: {
    kurzName: "USP/Alleinstellungsmerkmal",
    erklaerungHeader: "Warum ist meine Idee besser?",
  },
  TextteilMachbarkeit: {
    kurzName: "Machbarkeit/PoC, Testimonials & bisherige Meilensteine",
    erklaerungHeader: "Funktioniert meine Idee?",
  },
  PitchDeckIntro: {
    kurzName: "Intro/Einführung",
    erklaerungHeader: "Was ist meine Idee?",
  },
  PitchDeckTeam: {
    kurzName: "Team/Gründer",
    erklaerungHeader: "Wer ist bei meiner Idee dabei?",
  },
  PitchDeckProblem: {
    kurzName: "Herausforderung/Problem",
    erklaerungHeader: "Welches Problem löst meine Idee?",
  },
  PitchDeckLoesung: {
    kurzName: "Lösung",
    erklaerungHeader: "Wie löst meine Idee das Problem?",
  },
  PitchDeckProdukt: {
    kurzName: "Produktvorstellung",
    erklaerungHeader: "Wie sieht die Umsetzung meiner Idee aus?",
  },
  PitchDeckMarkt: {
    kurzName: "Marktanalyse/Wettbewerb",
    erklaerungHeader: "Gibt es bereits ähnliche Ideen oder gar Umsetzungen?",
  },
  PitchDeckUSP: {
    kurzName: "USP/Alleinstellungsmerkmal",
    erklaerungHeader: "Warum ist meine Idee besser?",
  },
  PitchDeckMachbarkeit: {
    kurzName: "Machbarkeit/PoC, Testimonials & bisherige Meilensteine",
    erklaerungHeader: "Funktioniert meine Idee?",
  },
  unternehmenName: {
    kurzName: "Name der Unternehmung",
    erklaerungHeader: "Ideen-, Projekt-, Firmen- oder Unternehmensname",
  },
  image: {
    kurzName: "Logo",
    erklaerungHeader: "Logo des Unternehmens bzw. der Idee",
  },
  branche: {
    kurzName: "Branche",
    erklaerungHeader: "Branche",
  },
  unternehmenBereitsGegruendet: {
    kurzName: "Gründungsstatus",
    erklaerungHeader: "Besteht rechtlich bereits ein Unternehmen mit Rechtsform und Adresse?",
  },
  unternehmenGruendungsdatum: {
    kurzName: "(geplantes) Gründungsdatum",
    erklaerungHeader: "geplantes oder wirkliches Gründungsdatum",
  },
  adresse: {
    kurzName: "Straße und Hausnummer",
    erklaerungHeader: "Straße und Hausnummer",
  },
  plz: {
    kurzName: "PLZ",
    erklaerungHeader: "Postleitzahl",
  },
  ort: {
    kurzName: "Ort",
    erklaerungHeader: "Ort",
  },
  bundesland: {
    kurzName: "Bundesland",
    erklaerungHeader: "Bundesland",
  },
  rechtsform: {
    kurzName: "Rechtsform",
    erklaerungHeader: "Rechtsform",
  },
  url: {
    kurzName: "Internetadresse",
    erklaerungHeader: "Internetadresse des Unternehmens/der Idee",
  },
  telefon: {
    kurzName: "Telefon",
    erklaerungHeader: "Kontakt-Telefonnummer des Unternehmens/der Idee",
  },
  email: {
    kurzName: "E-Mail",
    erklaerungHeader: "Kontakt-E-Mail des Unternehmens/der Idee",
  },
  gesuchtWird: {
    kurzName: "Gesucht wird",
    erklaerungHeader: "In welchen Bereichen suchst du Unterstützung für dieses Unternehmen/diese Idee?",
  },
};

export default (erklaerungsTexte);
