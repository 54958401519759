const SteckbriefShowSchritte = {
  1: {
    PitchDeckIntro: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
    PitchDeckTeam: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
    PitchDeckProblem: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
    PitchDeckLoesung: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
    PitchDeckProdukt: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
    PitchDeckMarkt: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
    PitchDeckUSP: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
    PitchDeckMachbarkeit: {
      typ: "multiline",
      datenQuelle: "businessplan",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  }
};

export default SteckbriefShowSchritte;
