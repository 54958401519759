import React from "react";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import MoneyIcon from "@material-ui/icons/Money";
import DateRangeIcon from "@material-ui/icons/DateRange";
import RestoreIcon from "@material-ui/icons/Restore";
import AvTimerIcon from "@material-ui/icons/AvTimer"
import BookIcon from "@material-ui/icons/Book";
import ArticleIcon from '@mui/icons-material/Article';
import TableViewIcon from "@mui/icons-material/TableView";
import CalculateIcon from '@mui/icons-material/Calculate';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

const erklaerungsUeberschriften = {
  Geschaeftsdaten: {
    Stundensatz: {
      url: "stundensatz",
      icon: <AvTimerIcon/>,
      key: 1,
      name: "Stundensatz",
      erklaerungHeader:
        "Stundensatz",
      erklaerungText: "Hier kannst Du Deinen Stundensatz berechnen.",
      Schritte: {
        1: {
          erklaerungHeader: "Jahr und Bundesland",
          erklaerungText: 'Durch Deine Auswahl wird die Zahl der Arbeitstage in Deinem ausgewählten Bundesland automatisch herausgesucht.',
        },
        2: {
          erklaerungHeader: "Grundlegende Angaben",
          erklaerungText: 'Grundlegende Angaben sind Angaben, ohne die keine Rechenfunktion möglich ist. Notfalls solltest Du die Werte bitte schätzen!',
        },
        3: {
          erklaerungHeader: "Durchschnittliche Fehlzeiten",
          erklaerungText: 'Für eine richtige Kalkulation ist es wichtig, auch Fehlzeiten, mit denen man als Unternehmer rechnen musst, mit einzukalkulieren.',
        },
        4: {
          erklaerungHeader: "Arbeitsstunden",
          erklaerungText: 'Das ist die Zahl der vereinbarten Arbeitsstunden.',
        },
        5: {
          erklaerungHeader: "Produktivierbare Zeiten",
          erklaerungText: 'Das ist die Summe der produktiven Arbeitszeiten. Hier werden Wartezeiten oder Ähnliches abgezogen, da nur mit einer im wirtschaftlichen Sinne produktiven Arbeitszeit kalkuliert werden sollte.',
        },
        6: {
          erklaerungHeader: "Kosten",
          erklaerungText: 'Die Kosten müssen ebenfalls auf die Zahl der produktiven Stunden verteilt werden, um zu einem Stundensatz zu kommen.',
        },
        7: {
          erklaerungHeader: "Stundensatz",
          erklaerungText: 'Stundensatz sind die Kosten pro Stunde, die Du als Unternehmer mindestens pro Stunde erwirtschaften musst, um nicht im Minus zu sein.',
        },
      },
    },
    Bwa: {
      url: "bwa",
      icon: <BookIcon/>,
      key: 2,
      name: "Bwa",
      erklaerungHeader:
        "BWA-Eingabe",
      erklaerungText: "Hier kannst Du Deine BWA eingeben.",
      Schritte: {
        1: {
          erklaerungHeader: "Monat und Jahr der BWA wählen",
          erklaerungText:
            'Wähle hier den Monat und das Jahr aus, für die Du die BWA eingeben willst. Mit dem Schalter für "Planung" können auch direkt Planungsdaten eingegeben werden. Alternativ lassen sich die Planungsdaten über die Businessplan-Funktionen generieren.',
        },
        2: {
          erklaerungHeader: "Gesamtleistung",
          erklaerungText: "Erlöse und Bestandsveränderung als Summe.",
        },
        3: {
          erklaerungHeader: "Rohertrag",
          erklaerungText: "Rohertrag sind die Erlöse abzüglich der Materialkosten.",
        },
        4: {
          erklaerungHeader: "Kosten",
          erklaerungText: "Kosten ist der Sammelbegriff für Aufwendungen für die Erzielung der Betriebsleistung.",
        },
        5: {
          erklaerungHeader: "Neutrales Ergebnis",
          erklaerungText: "Ein neutrales Ergebnis entsteht durch Aufwendungen, die nicht in direktem Zusammenhang mit der eigentlichen Leistungserstellung stehen.",
        },
        6: {
          erklaerungHeader: "Ergebnisberechnung",
          erklaerungText: "Hierbei wird anhand der bisher getätigten Angaben das Ergebnis berechnet.",
        },
      },
      Funktionen: {
        "Auswertung": "",
      },
    },
    BwaAusgabe: {
      url: "bwaausgabe",
      icon: <TableViewIcon/>,
      key: 3,
      name: "BwaAusgabe",
      erklaerungHeader:
        "BWA-Ausgabe",
      erklaerungText: "Hier kannst Du eine Jahresübersicht über Deine BWA ausgeben lassen.",
      Schritte: {
        1: {
          erklaerungHeader: "Jahr der BWA wählen",
          erklaerungText: "Wähle hier das Jahr aus, für das Du die BWA ausgeben willst.",
        },
        2: {
          erklaerungHeader: "Auswertung",
          erklaerungText: "Hier ist Deine Jahresübersicht.",
        },
      },
      Funktionen: {
        "Auswertung": "",
      },
    },
  },
  Steckbrief: {
    Steckbrief: {
      url: "steckbrieftext",
      icon: <ArticleIcon/>,
      key: 1,
      name: "Steckbrief",
      erklaerungHeader: "Stichpunkte für den Steckbrief",
      erklaerungText: "Im Folgenden kannst Du Deine Idee über ein Produkt bzw. eine Dienstleistung darstellen.",
      Schritte: {
        1: {
          erklaerungHeader:
            "Was ist meine Idee?",
          erklaerungText: "Die Geschäftsidee als kurze Zusammenfassung.",
        },
        2: {
          erklaerungHeader:
            "Wer ist bei meiner Idee dabei?",
          erklaerungText: "Vorstellung des Gründerteams.",
        },
        3: {
          erklaerungHeader: "Welches Problem löst meine Idee?",
          erklaerungText: "Beschreibung der Herausforderung bzw. des Problems, dass durch Deine Geschäftsidee gelöst wird.",
        },
        4: {
          erklaerungHeader: "Wie löst meine Idee das Problem? ",
          erklaerungText: "An dieser Stelle stellst Du die Lösung dar.",
        },
        5: {
          erklaerungHeader: "Wie sieht die Umsetzung meiner Idee aus?",
          erklaerungText: "Vorstellung Deines Produktes bzw. Deiner Dienstleistung, das/die verkauft werden soll.",
        },
        6: {
          erklaerungHeader: "Gibt es bereits ähnliche Ideen oder gar Umsetzungen?",
          erklaerungText: "Marktanalyse/Wettbewerb",
        },
        7: {
          erklaerungHeader: "Warum ist meine Idee besser?",
          erklaerungText: "Beschreibung der Unique Selling Proposition (USP/Alleinstellungsmerkmal)",
        },
        8: {
          erklaerungHeader: "Funktioniert meine Idee?",
          erklaerungText: "Ist Deine Idee umsetzbar? Gibt es bereits ein Proof of Concept (PoC), bisherige Meilensteine oder Testimonials von Testkunden?",
        },
      },
    },
    SteckbriefShow: {
      url: "steckbriefshare",
      icon: <ArticleIcon/>,
      key: 2,
      name: "SteckbriefShow",
      erklaerungHeader: "Steckbriefeinstellungen",
      erklaerungText:
        "Hier kannst du Deinen erstellten Steckbrief ansehen und Einstellungen vornehmen.",
      Schritte: {
        1: {
          erklaerungHeader:
            "Freigabe des Steckbriefs bearbeiten",
          erklaerungText:
            "Hier siehst Du noch einmal Deine eingegebenen Daten und kannst Deinen Steckbrief freigeben bzw. Deinen Steckbrief wieder für die Öffentlichkeit sperren.",
        },
      },
    },
  },
  Businessplan: {
    PrivaterFinanzbedarf: {
      url: "privatfinanzen",
      icon: <EuroSymbolIcon/>,
      key: 1,
      name: "PrivaterFinanzbedarf",
      erklaerungHeader:
        "privater Finanzbedarf",
      erklaerungText: "Für die Betrachtung der Gesamtsituation ist der private Finanzbedarf sehr wichtig, denn mit dem wirtschaftlichen Ergebnis der Unternehmung sollte mindestens der private Finanzbedarf abgedeckt werden.",
      Schritte: {
        1: {
          erklaerungHeader: "Einnahmen außerhalb der Selbständigkeit",
          erklaerungText: "Das sind zusätzliche Einnahmen, z.B. durch einen Nebenjob oder sonstige Einkommen.",
        },
        2: {
          erklaerungHeader: "Privatausgaben",
          erklaerungText: "Privatausgaben sind Geldausgaben für private Zwecke.",
        },
        // 3: {
        //   erklaerungHeader: "Benötigte Privatentnahme",
        //   erklaerungText: "Benötigte Privatentnahme aus der Unternehmung, insbesondere bei Einzelunternehmen, bei denen der Unternehmer kein Geschäftsführergehalt bezieht",
        // }
      },
      Funktionen: {
        "Zahlweise": "",
      },
    },
    KaptialUndFinanzierung: {
      url: "kapitalundfinanzierung",
      icon: <MoneyIcon/>,
      key: 2,
      name: "KaptialUndFinanzierung",
      erklaerungHeader: "Kapitalbedarf und Finanzierung",
      erklaerungText:
        "Im Folgenden wird der Kapitalbedarf des gewählten Unternehmens ermittelt und die Finanzierung geplant.",
      Schritte: {
        1: {
          erklaerungHeader: "Sachinvestitionen",
          erklaerungText: "Sachinvestitionen sind werthaltige Ausgaben für Sachanlagen. Diese werden über die steuerlich festgelegte Nutzungsdauer abgeschrieben.",
        },
        2: {
          erklaerungHeader: "Gründungsnebenkosten",
          erklaerungText: "Das sind die Nebenkosten der Gründung, wie z.B. Notarkosten, oder die Kosten für die Erstellung eines Unternehmensvertrages.",
        },
        3: {
          erklaerungHeader: "Bürgschaften, Betriebsmittel und Finanzierung",
          erklaerungText: "Das sind Wirtschaftsgüter und Bürgschaften Dritter. Finanzierung ist dabei die Mittelbeschaffung für das Unternehmen.",
        },
      },
    },
    TypischerMonat: {
      url: "typischermonat",
      icon: <DateRangeIcon/>,
      key: 3,
      name: "TypischerMonat",
      erklaerungHeader: "typischer Monat",
      erklaerungText:
        "Der typische Monat ist ein Hilfsmittel aus der Praxis, um den Businessplan schnellstmöglich mit Beratungsheld erstellen zu können. Dieser Monat ist der erste Monat nach dem Anlaufen der Gründung, der einen relativ normalen Geschäftsverlauf widerspiegelt. Dieser belegt alle Planungsmonate automatisch vor, sodass man nur noch spezielle Monate anpassen muss und schon viele Eingabewerte hat. Dies spart viel Arbeit und Zeit. Anstatt mit der Eingabe von Zahlen beschäftigt zu sein, kann man sich stattdessen auf konkrete Planungsüberlegungen konzentrieren.",
      Schritte: {
        1: {
          erklaerungHeader: "Umsatz und Rohertrag",
          erklaerungText: "Das sind die Umsatzerlöse und der daraus resultierende Rohertrag.",
        },
        2: {
          erklaerungHeader: "Aufwand",
          erklaerungText: "Der Aufwand ist die Summe der betrieblichen Aufwendungen.",
        },
      },
    },
    // Zwischenzustand: {
    //   url: "zwischenzustand",
    //   icon: <TableChartIcon/>,
    //   key: 4,
    //   erklaerungHeader: "Zwischenergbnis anzeigen",
    //   erklaerungText:
    //     "Zum Testen wird hier der Finanzteil des Businessplans auf Grundlage des typischen Monats berechnet.",
    //   Schritte: {},
    // },
    SpezifischerMonat: {
      url: "spezifischermonat",
      icon: <RestoreIcon/>,
      key: 4,
      name: "SpezifischerMonat",
      erklaerungHeader: "Anpassung der Planung für Monat",
      erklaerungText:
        "Mit Hilfe des spezifischen Monats wird die Detailplanung für den betrachteten Monat angepasst, so dass dieser in der Planung realistisch ist.",
      Schritte: {
        1: {
          erklaerungHeader:
            "Monat und Jahr für die Planung des anzupassenden Monats wählen",
          erklaerungText: "Dies ist der Monat im ausgewählten Jahr, für den die Werte angepasst werden müssen, z.B. wegen saisonaler Schwankungen im Geschäftsverlauf.",
        },
        2: {
          erklaerungHeader: "Umsatz und Rohertrag",
          erklaerungText: "Das sind die Umsatzerlöse und der Rohertrag des anzupassenden Monats.",
        },
        3: {
          erklaerungHeader: "Aufwand",
          erklaerungText: "Das ist der Aufwand des anzupassenden Monats, der individuell anhand der Planungseinschätzungen geplant werden soll.",
        },
      },
    },
    Finanzteil: {
      url: "finanzteil",
      icon: <CalculateIcon/>,
      key: 5,
      name: "Finanzteil",
      erklaerungHeader: "Finanzteil berechnen",
      erklaerungText:
        "Hier wird der Finanzteil des Businessplans berechnet.",
      Schritte: {
        1: {
          erklaerungHeader:
            "Berechnung starten",
          erklaerungText: "Durch Klicken auf weiter wird der Finanzteil des Businessplans generiert. Achtung, dies berechnet alle Planungsdaten aufgrund der Eingaben neu und löscht dabei vorhandene BWA-Soll-Daten!",
        },
        2: {
          erklaerungHeader: "Berechnung erfolgreich",
          erklaerungText: "Hier kannst Du die Tabellen sehen.",
        },
      },
    },
    Textteil: {
      url: "textteil",
      icon: <ArticleIcon/>,
      key: 6,
      name: "Textteil",
      erklaerungHeader: "Textteil des Businessplans",
      erklaerungText: "Im Folgenden kannst Du die Idee über ein Produkt bzw. eine Dienstleistung beschreiben.",
      Schritte: {
        1: {
          erklaerungHeader:
            "Was ist meine Idee?",
          erklaerungText: "Die Geschäftsidee als kurze Zusammenfassung.",
        },
        2: {
          erklaerungHeader:
            "Wer ist bei meiner Idee dabei?",
          erklaerungText: "Vorstellung des Gründerteams.",
        },
        3: {
          erklaerungHeader: "Welches Problem löst meine Idee?",
          erklaerungText: "Beschreibung der Herausforderung bzw. des Problems.",
        },
        4: {
          erklaerungHeader: "Wie löst meine Idee das Problem? ",
          erklaerungText: "Die Lösung der Herausforderung bzw. des Problems.",
        },
        5: {
          erklaerungHeader: "Wie sieht die Umsetzung meiner Idee aus?",
          erklaerungText: "Vorstellung Deines Produktes bzw. Deiner Dienstleistung.",
        },
        6: {
          erklaerungHeader: "Gibt es bereits ähnliche Ideen oder gar Umsetzungen?",
          erklaerungText: "Marktanalyse/Wettbewerb",
        },
        7: {
          erklaerungHeader: "Warum ist meine Idee besser?",
          erklaerungText: "Beschreibung der Unique Selling Proposition (USP/Alleinstellungsmerkmal).",
        },
        8: {
          erklaerungHeader: "Funktioniert meine Idee?",
          erklaerungText: "Ist Deine Idee umsetzbar? Gibt es bereits ein Proof of Concept (PoC), bisherige Meilensteine oder Testimonials von Testkunden?",
        },
      },
    },
    Endergebnis: {
      url: "endergebnis",
      icon: <AutoStoriesIcon/>,
      key: 7,
      name: "Endergebnis",
      erklaerungHeader: "Businessplan erzeugen",
      erklaerungText:
        "Hier wird der Businessplan erzeugt.",
      Schritte: {
        1: {
          erklaerungHeader:
            "Generierung starten",
          erklaerungText: 'Durch Klicken auf "PDF herunterladen" wird Dein Businessplans unter Berücksichtigung aller Deiner Eingaben generiert.',
        }
      },
    },
  },
  Public: {
    SteckbriefBoard: {
      url: "public/businessforum",
      icon: <ArticleIcon/>,
      key: 1,
      name: "SteckbriefBoard",
      erklaerungHeader: "Business Forum",
      erklaerungText:
        "...",
      Schritte: {
        1: {
          erklaerungHeader:
            "Steckbrief Board",
          erklaerungText: "...",
        },
      },
    },
  },
  Unternehmen: {
    ErsteSchritte: {
      url: "ersteschritte",
      icon: <ArticleIcon/>,
      key: 1,
      name: "ErsteSchritte",
      erklaerungHeader: "Neues Unternehmens bzw. neue Geschäftsidee anlegen",
      erklaerungText:
        "...",
      Schritte: {
        1: {
          erklaerungHeader: "Wie heißt das neues Unternehmen bzw. die neue Geschäftsidee?",
          erklaerungText:
            "",
        },
        2: {
          erklaerungHeader: "Logo/Symbolbild",
          erklaerungText:
            "",
        },
        3: {
          erklaerungHeader: "Branche",
          erklaerungText:
            "",
        },
        4: {
          erklaerungHeader: "Gründung",
          erklaerungText:
            "",
        },
        5: {
          erklaerungHeader: "Adresse",
          erklaerungText:
            "",
        },
        6: {
          erklaerungHeader: "Unterstützungsbedarf",
          erklaerungText:
            "",
        }
      },
    },
  }
};

export default erklaerungsUeberschriften;
