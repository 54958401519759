import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import styles from "../styles";

import UnternehmensListe from "../Unternehmen/UnternehmenenListe";
import Funktionsleiste from "./Funktionsleiste";
import {AppContext} from "../AppContext";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button";

class TopAreaDual extends React.Component {
  static contextType = AppContext;

  state = {
    isLoaded: false,
    chatAssitentActivated: true,
    benutzerAnchorEl: null,
    activeStep: 0,
    items: {
      id: "",
      Vorname: "",
      Nachname: "",
      aktuellesUnternehmen: {unternehmenName: ""},
      alleUnternehmen: [],
    },
  };

  handleBenutzerClick = (e) => {
    this.setState({benutzerAnchorEl: e.currentTarget});
  };

  handleBenutzerClose = () => {
    this.setState({benutzerAnchorEl: null});
  };

  logout = async () => {
    this.context.logOut().then(() => window.location.replace("/"));
    // this.context.logOut().then(() => window.location.reload());
  }

  componentDidMount() {
    if (this.context.isLoggedIn) {
      this.context.refreshUnternehmenListe();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.context.isLoggedIn) {
      return (
        this.state.items.aktuellesUnternehmen._id !== nextProps.unternehmenId ||
        this.state.benutzerAnchorEl !== nextState.benutzerAnchorEl ||
        this.state.isLoaded !== nextState.isLoaded ||
        this.state.chatAssitentActivated !== nextState.chatAssitentActivated ||
        this.state.activeStep !== nextState.activeStep
      );
    } else {
      return false;
    }
  }

  render() {
    const {classes} = this.props;
    const {benutzerAnchorEl} = this.state;

    if (this.context.isLoggedIn) {

      return (
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.appBar}>
            <div className={classes.logo}>
              <img src="/beratungsheld-logo.png" height={60}/>
            </div>

            <div className={classes.topMenu}>
              <Funktionsleiste
                userId={this.props.userId}
                unternehmenId={this.props.unternehmenId}
              />
            </div>

            <UnternehmensListe
              userId={this.props.userId}
              unternehmenId={this.props.unternehmenId}
              history={this.props.history}
            />
            <ListItemIcon>
              <Fragment>
                <AccountCircleIcon
                  fontSize="large"
                  color="secondary"
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={this.handleBenutzerClick}
                />
                <Menu
                  className={classes.profileMenu}
                  elevation={2}
                  getContentAnchorEl={null}
                  anchorEl={benutzerAnchorEl}
                  keepMounted
                  open={Boolean(benutzerAnchorEl)}
                  onClose={this.handleBenutzerClose}
                >
                  <div>
                    {" "}
                    <MenuItem
                      className={classes.benutzerBereichInfo + " Mui-disabled"}
                    >
                      <ListItemIcon>
                        <PersonIcon fontSize="small"/>
                      </ListItemIcon>
                      <ListItemText>
                        {this.context.firstname}{" "}
                        {this.context.lastname}
                      </ListItemText>
                    </MenuItem>
                    {/*<MenuItem*/}
                    {/*  className={classes.benutzerBereichInfo}*/}
                    {/*  component={Link}*/}
                    {/*  to={`/meinunternehmen`}*/}
                    {/*  onClick={this.handleBenutzerClose}*/}
                    {/*>*/}
                    {/*  <ListItemIcon>*/}
                    {/*    <BusinessIcon fontSize="small"/>*/}
                    {/*  </ListItemIcon>*/}
                    {/*  <ListItemText primary="Unternehmen verwalten"/>*/}
                    {/*</MenuItem>*/}
                    <MenuItem
                      className={classes.benutzerBereichInfo}
                      // component={Link}
                      onClick={this.logout}
                      to="/login"
                    >
                      <ListItemIcon>
                        <ExitToAppIcon fontSize="small"/>
                      </ListItemIcon>
                      <ListItemText primary="Abmelden"/>
                    </MenuItem>
                  </div>
                </Menu>
              </Fragment>
            </ListItemIcon>
          </Toolbar>
        </AppBar>
      );
    } else {
      return (
        <Fragment>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar /*className={classes.toolbar}*/
              className={classes.appBar}
            >
              <div className={classes.logo}>
                <img src="/beratungsheld-logo.png" height={60}/>
              </div>

              <Typography variant="body1" component="p">
                Bereits bei Beratungsheld registriert?
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                href="/"
              >
                zum Login
              </Button>
            </Toolbar>
          </AppBar>
        </Fragment>
      );
    }
  }
}

export default withStyles(styles)(TopAreaDual);
