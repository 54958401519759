import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Typography from "@material-ui/core/Typography/Typography";
import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core";
import styles from "../styles";
import Button from "@material-ui/core/Button";

class TopAreaBlank extends React.Component {
  render() {
    const {classes} = this.props;

    return (
      <Fragment>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar /*className={classes.toolbar}*/
            className={classes.appBar}
          >
            <div className={classes.logo}>
              <img src="/beratungsheld-logo.png" height={60}/>
            </div>

            <Typography variant="body1" component="p">
              Bereits bei Beratungsheld registriert?
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              href="/"
            >
              zum Login
            </Button>
          </Toolbar>
        </AppBar>
      </Fragment>
    );
  }
}

export default withStyles(styles)(TopAreaBlank);
