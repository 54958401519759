import React from "react";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import RestoreIcon from "@material-ui/icons/Restore";
import BookIcon from "@material-ui/icons/Book";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import LayersIcon from "@material-ui/icons/Layers";
import CoPresentIcon from '@mui/icons-material/CoPresent';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
//import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import MoneyIcon from "@material-ui/icons/Money";
import DateRangeIcon from "@material-ui/icons/DateRange";
import TableViewIcon from '@mui/icons-material/TableView';
import ArticleIcon from '@mui/icons-material/Article';
import {withStyles} from "@material-ui/core/styles";
import {NavLink} from "react-router-dom";
import styles from "../styles";
import Button from "@material-ui/core/Button";
import {ClickAwayListener, Grow, MenuList, Paper, Popper} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import CalculateIcon from "@mui/icons-material/Calculate";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

class Funktionsleiste extends React.Component {
  state = {
    businessplanAnchorEl: null,
    geschaeftsdatenAnchorEl: null,
    SteckbriefAnchorEl: null,
  };

  handleSteckbriefClick = (event) => {
    this.setState({
      SteckbriefAnchorEl: event.currentTarget
    })
  };

  handleSteckbriefClose = () => {
    this.setState({
      SteckbriefAnchorEl: null
    })
  };

  handleBusinessplanClick = (event) => {
    this.setState({
      businessplanAnchorEl: event.currentTarget
    })
  };

  handleBusinessplanClose = () => {
    this.setState({
      businessplanAnchorEl: null
    })
  };

  handleGeschaeftsdatenClick = (event) => {
    this.setState({
      geschaeftsdatenAnchorEl: event.currentTarget
    })
  };

  handleGeschaeftsdatenClose = () => {
    this.setState({
      geschaeftsdatenAnchorEl: null
    })
  };

  render() {
    const {classes} = this.props;

    return (
      <div>
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          component={NavLink}
          to={`/dashboard`}
          startIcon={<DashboardIcon/>}>
          Cockpit
        </Button>

        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          aria-controls="geschaeftsdaten-menu"
          aria-haspopup="true"
          onClick={this.handleGeschaeftsdatenClick}
          startIcon={<LayersIcon/>}
        >
          Geschäftsdaten
        </Button>
        <Popper open={Boolean(this.state.geschaeftsdatenAnchorEl)}
                anchorEl={this.state.geschaeftsdatenAnchorEl}
                role={undefined}
                transition
                disablePortal>
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
            >
              <Paper className={classes.menuPaper}>
                <ClickAwayListener onClickAway={this.handleGeschaeftsdatenClose}>
                  <MenuList>
                    <MenuItem
                      onClick={this.handleGeschaeftsdatenClose}
                      component={NavLink}
                      to={`/stundensatz`}
                      activeClassName={classes.menuItemActive}
                    >
                      <ListItemIcon className={classes.menuIcon}>
                        <AvTimerIcon/>
                      </ListItemIcon>
                      <ListItemText primary="Stundensatz"/>
                    </MenuItem>
                    {/*<MenuItem*/}
                    {/*  onClick={this.handleGeschaeftsdatenClose}*/}
                    {/*  component={NavLink}*/}
                    {/*  to={`/stammdaten`}*/}
                    {/*  activeClassName={classes.menuItemActive}*/}
                    {/*>*/}
                    {/*  <ListItemIcon className={classes.menuIcon}>*/}
                    {/*    <PeopleIcon/>*/}
                    {/*  </ListItemIcon>*/}
                    {/*  <ListItemText primary="Stammdaten"/>*/}
                    {/*</MenuItem>*/}
                    <MenuItem
                      onClick={this.handleGeschaeftsdatenClose}
                      component={NavLink}
                      to={`/bwa`}
                      activeClassName={classes.menuItemActive}
                    >
                      <ListItemIcon className={classes.menuIcon}>
                        <BookIcon/>
                      </ListItemIcon>
                      <ListItemText primary="BWA-Eingabe"/>
                    </MenuItem>
                    <MenuItem
                      onClick={this.handleGeschaeftsdatenClose}
                      component={NavLink}
                      to={`/bwaausgabe`}
                      activeClassName={classes.menuItemActive}
                    >
                      <ListItemIcon className={classes.menuIcon}>
                        <TableViewIcon/>
                      </ListItemIcon>
                      <ListItemText primary="BWA-Ausgabe"/>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          aria-controls="Steckbrief-menu"
          aria-haspopup="true"
          onClick={this.handleSteckbriefClick}
          startIcon={<LayersIcon/>}
        >
          Steckbrief
        </Button>
        <Popper open={Boolean(this.state.SteckbriefAnchorEl)}
                anchorEl={this.state.SteckbriefAnchorEl}
                role={undefined}
                transition
                disablePortal>
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
            >
              <Paper className={classes.menuPaper}>
                <ClickAwayListener onClickAway={this.handleSteckbriefClose}>
                  <MenuList>
                    <MenuItem
                      onClick={this.handleSteckbriefClose}
                      component={NavLink}
                      to={`/steckbrieftext`}
                      activeClassName={classes.menuItemActive}
                    >
                      <ListItemIcon className={classes.menuIcon}>
                        <CoPresentIcon/>
                      </ListItemIcon>
                      <ListItemText primary="neuen Steckbrief anlegen bzw. vorhandenen Steckbrief ändern"/>
                    </MenuItem>
                    <MenuItem
                      onClick={this.handleSteckbriefClose}
                      component={NavLink}
                      to={`/steckbriefshare`}
                      activeClassName={classes.menuItemActive}
                    >
                      <ListItemIcon className={classes.menuIcon}>
                        <PresentToAllIcon/>
                      </ListItemIcon>
                      <ListItemText primary="Steckbrief ansehen und Freigabeeinstellungen anpassen"/>
                    </MenuItem>
                    <MenuItem
                      onClick={this.handleSteckbriefClose}
                      component={NavLink}
                      to={`/public/businessforum`}
                      activeClassName={classes.menuItemActive}
                    >
                      <ListItemIcon className={classes.menuIcon}>
                        <ViewAgendaIcon/>
                      </ListItemIcon>
                      <ListItemText primary="Gehe zum Business Forum und schaue dir alle Steckbriefe an"/>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          aria-controls="businessplan-menu"
          aria-haspopup="true"
          onClick={this.handleBusinessplanClick}
          startIcon={<LayersIcon/>}
        >
          Businessplan
        </Button>
        <Popper open={Boolean(this.state.businessplanAnchorEl)}
                anchorEl={this.state.businessplanAnchorEl}
                role={undefined}
                transition
                disablePortal>
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
            >
              <Paper className={classes.menuPaper}>
                <ClickAwayListener onClickAway={this.handleBusinessplanClose}>
                  <MenuList>
                    <MenuItem
                      onClick={this.handleBusinessplanClose}
                      component={NavLink}
                      to={`/privatfinanzen`}
                      activeClassName={classes.menuItemActive}
                    >
                      <ListItemIcon className={classes.menuIcon}>
                        <EuroSymbolIcon/>
                      </ListItemIcon>
                      <ListItemText primary="privater Finanzbedarf"/>
                    </MenuItem>
                    <MenuItem
                      onClick={this.handleBusinessplanClose}
                      component={NavLink}
                      to={`/kapitalundfinanzierung`}
                      activeClassName={classes.menuItemActive}
                    >
                      <ListItemIcon className={classes.menuIcon}>
                        <MoneyIcon/>
                      </ListItemIcon>
                      <ListItemText primary="Kapitalbedarf und Finanzierung"/>
                    </MenuItem>
                    <MenuItem
                      onClick={this.handleBusinessplanClose}
                      component={NavLink}
                      to={`/typischermonat`}
                      activeClassName={classes.menuItemActive}
                    >
                      <ListItemIcon className={classes.menuIcon}>
                        <DateRangeIcon/>
                      </ListItemIcon>
                      <ListItemText primary="typischer Monat"/>
                    </MenuItem>
                    <MenuItem
                      onClick={this.handleBusinessplanClose}
                      component={NavLink}
                      to={`/spezifischermonat`}
                      activeClassName={classes.menuItemActive}
                    >
                      <ListItemIcon className={classes.menuIcon}>
                        <RestoreIcon/>
                      </ListItemIcon>
                      <ListItemText primary="Anpassung der Planung für Monat"/>
                    </MenuItem>
                    <MenuItem
                      onClick={this.handleBusinessplanClose}
                      component={NavLink}
                      to={`/finanzteil`}
                      activeClassName={classes.menuItemActive}
                    >
                      <ListItemIcon className={classes.menuIcon}>
                        <CalculateIcon/>
                      </ListItemIcon>
                      <ListItemText primary="Finanzteil berechnen"/>
                    </MenuItem>
                    <MenuItem
                      onClick={this.handleBusinessplanClose}
                      component={NavLink}
                      to={`/textteil`}
                      activeClassName={classes.menuItemActive}
                    >
                      <ListItemIcon className={classes.menuIcon}>
                        <ArticleIcon/>
                      </ListItemIcon>
                      <ListItemText primary="Textteil Businessplan"/>
                    </MenuItem>
                    <MenuItem
                      onClick={this.handleBusinessplanClose}
                      component={NavLink}
                      to={`/endergebnis`}
                      activeClassName={classes.menuItemActive}
                    >
                      <ListItemIcon className={classes.menuIcon}>
                        <AutoStoriesIcon/>
                      </ListItemIcon>
                      <ListItemText primary="Businessplan erzeugen"/>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

export default withStyles(styles)(Funktionsleiste);
