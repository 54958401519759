import ChatFunktionen from "./ChatFunktionen";

const privaterFinanzBedarfSchritte = {
  1: {
    PrivatGehaltLebenspartner: {
      typ: "input",
      teilDerSummen: ["PrivatEinnahmenInsgesamt"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Zahlweise],
    },
    PrivatKindergeld: {
      typ: "input",
      teilDerSummen: ["PrivatEinnahmenInsgesamt"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Zahlweise],
    },
    PrivatErziehungsgeld: {
      typ: "input",
      teilDerSummen: ["PrivatEinnahmenInsgesamt"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Zahlweise],
    },
    PrivatUnterhalt: {
      typ: "input",
      teilDerSummen: ["PrivatEinnahmenInsgesamt"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Zahlweise],
    },
    PrivatMietzins: {
      typ: "input",
      teilDerSummen: ["PrivatEinnahmenInsgesamt"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Zahlweise],
    },
    PrivateKapitalertraege: {
      typ: "input",
      teilDerSummen: ["PrivatEinnahmenInsgesamt"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Zahlweise],
    },
    PrivatSonstigeEinkuenfte: {
      typ: "input",
      teilDerSummen: ["PrivatEinnahmenInsgesamt"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Zahlweise],
    },
    PrivatEinnahmenInsgesamt: {
      class: "summe",
      typ: "readOnly",
      teilDerSummen: ["PrivatBenoetigtePrivatentnahme"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
    },
  },
  2: {
    PrivatEinkommensteuerruecklage: {
      typ: "input",
      teilDerSummen: ["PrivatAusgabenInsgesamt"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Zahlweise],
    },
    PrivatMiete: {
      typ: "input",
      teilDerSummen: ["PrivatAusgabenInsgesamt"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Zahlweise],
    },
    PrivatKrankenversicherung: {
      typ: "input",
      teilDerSummen: ["PrivatAusgabenInsgesamt"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Zahlweise],
    },
    PrivatAltersvorsorge: {
      typ: "input",
      teilDerSummen: ["PrivatAusgabenInsgesamt"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Zahlweise],
    },
    PrivatUnfallversicherung: {
      typ: "input",
      teilDerSummen: ["PrivatAusgabenInsgesamt"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Zahlweise],
    },
    PrivatArbeitslosenversicherung: {
      typ: "input",
      teilDerSummen: ["PrivatAusgabenInsgesamt"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Zahlweise],
    },
    PrivatSonstigeVersicherungen: {
      typ: "input",
      teilDerSummen: ["PrivatAusgabenInsgesamt"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Zahlweise],
    },
    PrivatKfz: {
      typ: "input",
      teilDerSummen: ["PrivatAusgabenInsgesamt"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Zahlweise],
    },
    PrivatKommunikation: {
      typ: "input",
      teilDerSummen: ["PrivatAusgabenInsgesamt"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Zahlweise],
    },
    PrivatBeitraege: {
      typ: "input",
      teilDerSummen: ["PrivatAusgabenInsgesamt"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Zahlweise],
    },
    PrivaterTaeglicherBedarf: {
      typ: "input",
      teilDerSummen: ["PrivatAusgabenInsgesamt"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Zahlweise],
    },
    PrivateRuecklagen: {
      typ: "input",
      teilDerSummen: ["PrivatAusgabenInsgesamt"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Zahlweise],
    },
    PrivatKredite: {
      typ: "input",
      teilDerSummen: ["PrivatAusgabenInsgesamt"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Zahlweise],
    },
    PrivatSonstiges: {
      typ: "input",
      teilDerSummen: ["PrivatAusgabenInsgesamt"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Zahlweise],
    },
    PrivatAusgabenInsgesamt: {
      class: "summe",
      typ: "readOnly",
      teilDerSummen: [],
      subtrahendVon: ["PrivatBenoetigtePrivatentnahme"],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
    },
    PrivatBenoetigtePrivatentnahme: {
      typ: "readOnly",
      teilDerSummen: [],
      datenQuelle: "privatFinanzen",
      inAusgabe: true,
      inEingabe: true,
    },
  },
};

export default privaterFinanzBedarfSchritte;
