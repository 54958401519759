import React from 'react';

export const AppContext = React.createContext({
  //AuthContext
  apiToken: false,
  isLoggedIn: false,
  userId: "",
  unternehmenId: "",
  unternehmenName: "",
  unternehmenListe: [],
  error: false,
  firstname: "",
  lastname: "",
  noUnternehmenRedirect: false,
  logIn: () => {
  },
  logInByToken: () => {
  },
  logOut: () => {
  },
  changeUnternehmen: () => {
  },
  getUnternehmenDataFromApi: () => {
  },
  postUnternehmenDataToApi: () => {
  },
  refreshUnternehmenListe: () => {
  },
  getOtherUnternehmenData: () => {
  },
  postOtherUnternehmenData: () => {
  },
  saveStandardunternehmen: () => {
  },
  unternehmenData: {},

  //ChatContext
  chatColumnOpen: false,
  toggleChatColumn: () => {
  },
  chatSendOpen: false,
  toggleChatSend: () => {
  },
  chatMenuOpen: false,
  toggleChatMenu: () => {
  },
  chatFunctionsOpen: false,
  toggleChatFunctions: () => {
  },
  savePossible: false,
  setSavePossible: () => {

  },
  feld: {},
  setFeld: () => {
  },
  schritt: {
    key: 0
  },
  setSchritt: () => {
  },
  funktion: {
    key: 0
  },
  setFunktion: () => {
  },
  bereich: {
    name: "Businessplan"
  },
  setBereich: () => {
  },
  messages: [],
  postMessage: () => {
  },
  clearMessages: () => {
  },
  setPreviousSchritt: () => {
  },
  getChatDataFromCockpitApi: () => {
  },
  returnFeldDataFromCockpitApi: () => {
  },
});
