import Typography from "@material-ui/core/Typography/Typography";
import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import CustomTextField from "../CustomTags/CustomTextField";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import erklaerungsTexte from "../LogicData/erklaerungsTexte";
import liquiditaetsSchritte from "../LogicData/Liquiditaetsschritte";
import WeiterleitungsModal from "../CustomTags/Weiterleitungsmodal";
import styles from "../styles";
import {AppContext} from "../AppContext";

class Liquiditaet extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.erklaerungen = {
      1: {
        erklaerungHeader: "Monat und Jahr für Liquiditätsplanung wählen",
        erklaerungText: "...",
      },
      2: {
        erklaerungHeader: "Einzahlungen",
        erklaerungText: "...",
      },
      3: {
        erklaerungHeader: "Fremdkosten",
        erklaerungText: "...",
      },
      4: {
        erklaerungHeader: "Fixkosten",
        erklaerungText: "...",
      },
      ...erklaerungsTexte,
    };

    var allEingabeItems = [];

    Object.keys(liquiditaetsSchritte).forEach(function (outerKey) {
      Object.keys(liquiditaetsSchritte[outerKey]).forEach(function (innerKey) {
        if (liquiditaetsSchritte[outerKey][innerKey]["inEingabe"] === true) {
          allEingabeItems = [...allEingabeItems, innerKey];
        } else {
          delete liquiditaetsSchritte[outerKey][innerKey];
        }
      });
    });

    this.schritte = liquiditaetsSchritte;

    this.allItems = allEingabeItems;

    this.allItemsReversed = {};

    this.allItems.map((element, key) => {
      this.allItemsReversed[element] = 0;
    });

    this.getRumpfjahrFromApi();

    this.state = {
      step: 1,
      isLoaded: false,
      tutorialEnabled: true,
      ergebnisVorhanden: false,
      modalOpen: false,
      monat: "",
      jahr: "",
      rumpfjahr: "",
      erklaerungHeader: this.erklaerungen[1]["erklaerungHeader"],
      erklaerungText: this.erklaerungen[1]["erklaerungText"],
      items: this.allItemsReversed,
    };
  }

  getClearData = () => {
    console.log("getClearData");

    return this.allItemsReversed;
  };
  error = (error) => {
    this.setState({
      isLoaded: false,
      error: error,
    });
  };

  getRumpfjahrFromApi = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/v1/unternehmendata/${this.props.userId}/${this.props.unternehmenId}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.rumpfjahr && result.rumpfjahr > 2015) {
            console.log("Rumpfjahr: " + result.rumpfjahr);
            this.setState({
              rumpfjahr: result.rumpfjahr,
              modalOpen: false,
            });
          } else {
            console.log("NO Rumpfjahr!");
            this.setState({
              rumpfjahr: "",
              modalOpen: true,
            });
          }
        },
        (error) => this.error(error)
      );
  };

  getDataFromApi = async (jahr, monat) => {
    if (jahr === "" || monat === "") {
      this.setState({
        isLoaded: false,
        jahr: "",
        monat: "",
        items: this.getClearData(),
      });
    } else {
      let result = await this.context.getUnternehmenDataFromApi("bwa");

      if (result.bwa) {
        if (result.bwa[jahr]) {
          if (result.bwa[jahr][monat]) {
            if (result.bwa[jahr][monat]["Soll"]) {
              console.log(
                "Setting State from API Readings for Soll " +
                monat +
                "." +
                jahr
              );
              if (
                result.bwa[jahr][monat]["Soll"]["Betriebsergebnis"] &&
                0 !==
                result.bwa[jahr][monat]["Soll"][
                  "Betriebsergebnis"
                  ].toString().length
              ) {
                this.setState({
                  isLoaded: true,
                  jahr: jahr,
                  monat: monat,
                  ergebnisVorhanden: true,
                  items: {
                    ...this.getClearData(),
                    ...result.bwa[jahr][monat]["Soll"],
                  },
                });
              } else {
                this.setState({
                  isLoaded: true,
                  jahr: jahr,
                  monat: monat,
                  ergebnisVorhanden: false,
                  items: {
                    ...this.getClearData(),
                    ...result.bwa[jahr][monat]["Soll"],
                  },
                });
              }
              return;
            }
          }
        }
      }
      console.log(
        "Setting State from Sample Data (1) for " + monat + " " + jahr
      );
      this.setState({
        isLoaded: false,
        jahr: jahr,
        monat: monat,
        ergebnisVorhanden: false,
        items: this.getClearData(),
      });
    }
  }

  getDataFromApi = (jahr, monat) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/v1/unternehmendata/${this.props.userId}/${this.props.unternehmenId}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (jahr === "" || monat === "") {
            this.setState({
              isLoaded: false,
              jahr: "",
              monat: "",
              items: this.getClearData(),
            });
          } else {
            if (result.bwa) {
              if (result.bwa[jahr]) {
                if (result.bwa[jahr][monat]) {
                  if (result.bwa[jahr][monat]["Soll"]) {
                    console.log(
                      "Setting State from API Readings for Soll " +
                      monat +
                      "." +
                      jahr
                    );
                    if (
                      result.bwa[jahr][monat]["Soll"]["Betriebsergebnis"] &&
                      0 !==
                      result.bwa[jahr][monat]["Soll"][
                        "Betriebsergebnis"
                        ].toString().length
                    ) {
                      this.setState({
                        isLoaded: true,
                        jahr: jahr,
                        monat: monat,
                        ergebnisVorhanden: true,
                        items: {
                          ...this.getClearData(),
                          ...result.bwa[jahr][monat]["Soll"],
                        },
                      });
                    } else {
                      this.setState({
                        isLoaded: true,
                        jahr: jahr,
                        monat: monat,
                        ergebnisVorhanden: false,
                        items: {
                          ...this.getClearData(),
                          ...result.bwa[jahr][monat]["Soll"],
                        },
                      });
                    }
                    return;
                  }
                }
              }
            }
            console.log(
              "Setting State from Sample Data (1) for " + monat + " " + jahr
            );
            this.setState({
              isLoaded: false,
              jahr: jahr,
              monat: monat,
              ergebnisVorhanden: false,
              items: this.getClearData(),
            });
          }
        },
        (error) => this.error(error)
      );
  };

  continuePossible = (step) => {
    console.log("continuePossible für Step " + step + " aufgerufen.");

    const {jahr, monat, rumpfjahr} = this.state;

    switch (step) {
      case "rumpfjahr":
        return rumpfjahr > 2015;
      case 1:
        return (
          jahr !== undefined &&
          0 !== jahr.toString().length &&
          monat !== undefined &&
          0 !== monat.toString().length
        );
      case 2:
        return true;
      case 3:
        return true;
      case 4:
        return true;
      default:
        return true;
    }
  };

  handleSnackbarClose = (event, reason) => {
    this.setState({
      saveSuccessful: false,
    });
  };

  handleChange = (name) => ({target: {value, checked}}) => {
    console.log("handleChange aufgerufen für: " + name);
    const {items, jahr, monat} = this.state;

    if (name === "Jahr") {
      console.log("handleChange is jahr!");
      if (monat !== "" && value !== "" && jahr !== value) {
        console.log("jahr is set (monat was already set)!");
        this.getDataFromApi(value, monat);
      } else {
        this.setState({
          jahr: value,
        });
      }
    } else if (name === "Monat") {
      console.log("handleChange is monat!");
      if (jahr !== "" && value !== "" && jahr !== value) {
        console.log("monat is set (jahr was already set)!");
        this.getDataFromApi(jahr, value);
      } else {
        console.log("monat is " + value + " (jahr ist nor set)!");
        this.setState({
          monat: value,
        });
      }
    } else {
      if (value === "") {
        value = 0;
      } else {
        value = parseFloat(value);
      }

      let summen = {};
      const steps = Object.keys(this.schritte);

      this.allItems.map((item) => {
        steps.map((schritt) => {
          let itemsOfCurrentStep = Object.keys(this.schritte[schritt]);
          itemsOfCurrentStep.map((feld) => {
            if (this.schritte[schritt][feld]["typ"] === "monthYearSelect") {
              console.log("skipping " + feld);
            } else {
              if (
                this.schritte[schritt][feld]["teilDerSummen"].includes(item)
              ) {
                if (typeof summen[item] === "undefined") {
                  console.log("Reset of Summe of " + item);
                  summen[item] = 0;
                }
                if (feld === name) {
                  summen[item] += value;
                } else {
                  summen[item] += items[feld];
                }
              }
              if (
                typeof this.schritte[schritt][feld]["subtrahendVon"] !==
                "undefined" &&
                this.schritte[schritt][feld]["subtrahendVon"].includes(item)
              ) {
                if (typeof summen[item] === "undefined") {
                  console.log("Reset of Summe of " + item);
                  summen[item] = 0;
                }
                if (feld === name) {
                  summen[item] -= value;
                } else {
                  summen[item] -= items[feld];
                }
              }
            }
          });
        });
      });

      console.log("Final setState...");
      console.log(this.summen);

      this.setState({
        items: {
          ...items,
          ...summen,
          [name]: value,
        },
      });
    }
  };

  handleFocus = (name) => {
    this.setState({
      erklaerungHeader: this.erklaerungen[name].erklaerungHeader,
      erklaerungText: this.erklaerungen[name].erklaerungText,
    });
  };

  handleFocusEvent = (name, event) => {
    this.setState({
      erklaerungHeader: this.erklaerungen[name].erklaerungHeader,
      erklaerungText: this.erklaerungen[name].erklaerungText,
    });
    event.target.select();
  };

  handleContinue = () => {
    const {step} = this.state;
    this.setState({
      step: step + 1,
      erklaerungHeader: this.erklaerungen[step + 1].erklaerungHeader,
      erklaerungText: this.erklaerungen[step + 1].erklaerungText,
    });
  };

  handleBack = () => {
    const {step} = this.state;
    if (step > 1) {
      this.setState({
        step: step - 1,
        erklaerungHeader: this.erklaerungen[step - 1].erklaerungHeader,
        erklaerungText: this.erklaerungen[step - 1].erklaerungText,
      });
    }
  };

  handleNextMonth = () => {
    const {jahr, monat} = this.state;

    let monatneu = (monat + 1) % 12;
    let jahrneu = jahr;
    if (monatneu === 0) {
      monatneu = 12;
    } else if (monatneu === 1) {
      jahrneu = jahr + 1;
    }

    this.setState({
      step: 2,
      ergebnisVorhanden: false,
      erklaerungHeader: this.erklaerungen[2].erklaerungHeader,
      erklaerungText: this.erklaerungen[2].erklaerungText,
      isLoaded: false,
      jahr: jahrneu,
      monat: monatneu,
      saveSuccessful: false,
    });

    this.getDataFromApi(jahrneu, monatneu);
  };

  handleSubmit = () => {
    // TODO: Validate

    const {items, jahr, monat} = this.state;

    fetch(
      `${process.env.REACT_APP_API_URL}/v1/unternehmendata/${this.props.userId}/${this.props.unternehmenId}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          bwa: {
            [jahr]: {
              [monat]: {
                ["Soll"]: {
                  ...items,
                },
              },
            },
          },
        }),
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.kapitalUndFinanzierung,
            saveSuccessful: true,
          });
        },
        (error) => this.error(error)
      );
  };

  onExit = () => {
    this.setState({
      tutorialEnabled: false,
    });
  };

  createField = (name, properties) => {
    const {classes} = this.props;
    const {items, jahr, rumpfjahr, monat, ergebnisVorhanden} = this.state;
    if (properties.typ === "readOnly") {
      console.log("generating Field " + name);
      return (
        <CustomTextField
          id={"edit-liquiditaet-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.textField}
          value={items[name]}
          onChange={this.handleChange(name)}
          margin="normal"
          isCurrency={true}
          isReadOnly={true}
          unit={"€"}
          onFocus={() => this.handleFocus(name)}
        />
      );
    } else if (properties.typ === "input") {
      return (
        <CustomTextField
          id={"edit-liquiditaet-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.textField}
          value={items[name]}
          onChange={this.handleChange(name)}
          margin="normal"
          variant="outlined"
          isCurrency={true}
          isReadOnly={false}
          unit={"€"}
          onFocus={(event) => this.handleFocusEvent(name, event)}
        />
      );
    } else {
      return (
        <Fragment key={properties.key}>
          <FormControl
            className={classes.formControl}
            id={"edit-liquiditaet-jahr-control"}
          >
            <InputLabel htmlFor="edit-liquiditaet-jahr">Jahr</InputLabel>
            <Select
              value={jahr}
              onChange={this.handleChange("Jahr")}
              inputProps={{
                name: "jahr",
                id: "edit-liquiditaet-jahr",
              }}
            >
              <MenuItem value="">
                <em>Bitte wählen</em>
              </MenuItem>
              <MenuItem value={rumpfjahr}>Rumpfgeschäftsjahr</MenuItem>
              <MenuItem value={rumpfjahr + 1}>erstes Jahr</MenuItem>
              <MenuItem value={rumpfjahr + 2}>zweites Jahr</MenuItem>
              <MenuItem value={rumpfjahr + 3}>drittes Jahr</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            className={classes.formControl}
            id={"edit-liquiditaet-monat-control"}
          >
            <InputLabel htmlFor="edit-liquiditaet-monat">Monat</InputLabel>
            <Select
              value={monat}
              onChange={this.handleChange("Monat")}
              inputProps={{
                name: "monat",
                id: "edit-liquiditaet-monat",
              }}
            >
              <MenuItem value="">
                <em>Bitte wählen</em>
              </MenuItem>
              <MenuItem value={1}>Januar</MenuItem>
              <MenuItem value={2}>Februar</MenuItem>
              <MenuItem value={3}>März</MenuItem>
              <MenuItem value={4}>April</MenuItem>
              <MenuItem value={5}>Mai</MenuItem>
              <MenuItem value={6}>Juni</MenuItem>
              <MenuItem value={7}>Juli</MenuItem>
              <MenuItem value={8}>August</MenuItem>
              <MenuItem value={9}>September</MenuItem>
              <MenuItem value={10}>Oktober</MenuItem>
              <MenuItem value={11}>November</MenuItem>
              <MenuItem value={12}>Dezember</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            className={classes.formControl}
            id={"edit-liquiditaet-ergebnisVorhanden-control"}
          >
            <FormControlLabel
              control={
                <Checkbox
                  disabled
                  checked={ergebnisVorhanden}
                  onChange={this.handleChange("ergebnisVorhanden")}
                  value="ergebnisVorhanden"
                  color="primary"
                />
              }
              label="bereits eingegeben"
            />
          </FormControl>
        </Fragment>
      );
    }
  };

  createInputField = (name) => {
    const {classes} = this.props;
    const {items} = this.state;
    return (
      <CustomTextField
        id={"edit-liquiditaet-" + name}
        label={this.erklaerungen[name]["kurzName"]}
        className={classes.textField}
        value={items[name]}
        onChange={this.handleChange(name)}
        margin="normal"
        isCurrency={true}
        isReadOnly={false}
        unit={"€"}
        onFocus={(event) => this.handleFocusEvent(name, event)}
      />
    );
  };

  createReadOnlyField = (name) => {
    const {classes} = this.props;
    const {items} = this.state;
    return (
      <CustomTextField
        id={"edit-liquiditaet-" + name}
        label={this.erklaerungen[name]["kurzName"]}
        className={classes.textField}
        value={items[name]}
        onChange={this.handleChange(name)}
        margin="normal"
        isCurrency={true}
        isReadOnly={true}
        unit={"€"}
        onFocus={() => this.handleFocus(name)}
      />
    );
  };

  createStepCard = (step) => {
    const {classes} = this.props;
    return (
      <Card className={classes.card}>
        <CardHeader title={this.erklaerungen[step]["erklaerungHeader"]}/>
        <CardContent>
          {Object.keys(this.schritte[step]).map((feld) => {
            return this.createField(feld, this.schritte[step][feld]);
          })}
        </CardContent>
      </Card>
    );
  };

  render() {
    const {classes} = this.props;
    const {
      step,
      modalOpen,
      saveSuccessful,
      monat,
      jahr,
      rumpfjahr,
      erklaerungText,
      erklaerungHeader,
    } = this.state;

    return (
      <Fragment>
        <div className={classes.appBarSpacer}/>

        <WeiterleitungsModal
          userId={this.props.userId}
          unternehmenId={this.props.unternehmenId}
          modalOpen={modalOpen}
          script="Liquiditaet"
        />

        <Typography variant="h4" gutterBottom component="h2">
          Liquiditätsplanung
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={8}>
            <form className={classes.container} noValidate autoComplete="off">
              {this.createStepCard(step)}

              {step === 1 ? (
                <Button
                  disabled
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                >
                  Zurück
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={this.handleBack}
                >
                  Zurück
                </Button>
              )}
              {step === Object.keys(this.schritte).length ? (
                <Button
                  disabled={!this.continuePossible(step)}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleSubmit}
                >
                  Speichern
                </Button>
              ) : (
                <Button
                  disabled={!this.continuePossible(step)}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleContinue}
                >
                  Weiter
                </Button>
              )}
              {saveSuccessful ? (
                <Button
                  disabled={false}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleNextMonth}
                >
                  nächster Monat
                </Button>
              ) : (
                <Button
                  disabled
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  nächster Monat
                </Button>
              )}
            </form>
          </Grid>
          <Grid item md={4}>
            <Card className={classes.rightcard}>
              <CardHeader title={erklaerungHeader}/>
              <CardContent>
                <Typography variant={"body1"} paragraph={true}>
                  {erklaerungText}
                </Typography>
                <Typography variant={"body2"}>
                  Als Rumpfgeschäftsjahr wurde folgendes Jahr festgelegt:{" "}
                  {rumpfjahr}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={saveSuccessful}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">Daten erfolgreich gespeichert!</span>}
        ></Snackbar>

        <Card className={classes.card}>
          <CardHeader title="Fortschritt"/>
          <CardContent>
            <LinearProgress
              variant="determinate"
              value={
                ((step - 1) / (Object.keys(this.schritte).length - 1)) * 100
              }
            />
            {jahr !== "" && monat !== "" && (
              <Typography variant="body1" gutterBottom>
                Gewählter Zeitraum: {monat}/{jahr}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Liquiditaet);
