// import 'zingchart/es6';
import zingchart from 'zingchart/es6';
import Typography from "@material-ui/core/Typography/Typography";
import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import ZingChart from "zingchart-react";
import CustomTextField from "../CustomTags/CustomTextField";
import erklaerungsTexte from "../LogicData/erklaerungsTexte";
import styles from "../styles";
import {AppContext} from "../AppContext";
import erklaerungsUeberschriften from "../LogicData/erklaerungsUeberschriften";
import locale from "../CustomTags/zingchart-i18n-de-de";


class Bwa extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    zingchart.i18n.de_de = locale;

    this.erklaerungen = {
      ...erklaerungsUeberschriften.Geschaeftsdaten.Bwa,
      ...erklaerungsTexte,
    };

    this.state = {
      step: 1,
      isLoaded: false,
      tutorialEnabled: true,
      jahr: "",
      monat: "",
      focus: "",
      planung: false,
      ergebnisVorhanden: false,
      items: {
        Erloese7: 0,
        Erloese19: 0,
        Umsatzerloese: 0,
        UnfertigeErzeugnisse: 0,
        Bestandsveraenderung: 0,
        Gesamtleistung: 0,
        Wareneinkauf: 0,
        Fremdleistungen: 0,
        SonstigeErloese: 0,
        Rohertrag: 0,
        Personalkosten: 0,
        Raumkosten: 0,
        Steuern: 0,
        Versicherungen: 0,
        "Kfz-Kosten": 0,
        "Werbe-Reisekosten": 0,
        "Kosten der Warenabgabe": 0,
        Afa: 0,
        Reparaturen: 0,
        Instandhaltung: 0,
        "Sonstige Kosten": 0,
        Gesamtkosten: 0,
        Betriebsergebnis: 0,
        Zinsaufwand: 0,
        "So neutraler Aufwand": 0,
        "Neutraler Aufwand": 0,
        Zinsertrag: 0,
        SoNeutralerErtrag: 0,
        "Neutraler Ertrag": 0,
        "Neutrales Ergebnis": 0,
        Ergebnis: "",
      },
      vergleichsjahr: {
        vergleichsmonat: {
          Erloese7: "",
          Erloese19: "",
          Umsatzerloese: "",
          UnfertigeErzeugnisse: "",
          Bestandsveraenderung: "",
          Gesamtleistung: "",
          Wareneinkauf: "",
          Fremdleistungen: "",
          SonstigeErloese: "",
          Rohertrag: "",
          Personalkosten: "",
          Raumkosten: "",
          Steuern: "",
          Versicherungen: "",
          "Kfz-Kosten": "",
          "Werbe-Reisekosten": "",
          "Kosten der Warenabgabe": "",
          Afa: "",
          Reparaturen: "",
          Instandhaltung: "",
          "Sonstige Kosten": "",
          Gesamtkosten: "",
          Betriebsergebnis: "",
          Zinsaufwand: "",
          "So neutraler Aufwand": "",
          "Neutraler Aufwand": "",
          Zinsertrag: "",
          SoNeutralerErtrag: "",
          "Neutraler Ertrag": "",
          "Neutrales Ergebnis": "",
          Ergebnis: "",
        },
        vergleichstext: "",
      },
      chartData: {
        values: {
          Soll: {},
          Ist: {}
        }
      },
      visualisierungsdaten: {
        locale: 'de_de',
        type: "line",
        plot: {
          stacked: true,
        },
        "scale-x": {
          label: {
            /* Scale Title */
            text: "Monat",
          },
          labels: [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ] /* Scale Labels */,
        },
        tooltip: {
          text: "%t %negation%v €",
          decimals: 2,
          align: "left",
          borderRadius: 3,
          fontColor: "#ffffff",
          negation: "currency",
        },
        series: [
          {
            values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            stack: 1,
            text: "Ist",
            backgroundColor: "#0066AA",
          },
          {
            values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            stack: 2,
            text: "Planung",
            backgroundColor: "#E52B52",
          },
        ],
      },
    };
    this.handleFocus = this.handleFocus.bind(this);
  }

  componentDidMount() {
    this.context.setFunktion({
      key: this.erklaerungen.key,
      name: this.erklaerungen.name,
      erklaerungHeader: this.erklaerungen.erklaerungHeader,
      erklaerungText: this.erklaerungen.erklaerungText,
      continuePossible: false,
      backPossible: false,
      totalSteps: Object.keys(this.erklaerungen["Schritte"]).length,
      handleSubmit: () => this.handleSubmit(),
      resetToStepOne: () => this.resetToStepOne(),
    });
    this.context.setBereich({
      name: "Geschaeftsdaten"
    });
    this.resetToStepOne();
  }

  resetToStepOne = () => {
    this.context.setSchritt({
      key: 1,
      erklaerungHeader: this.erklaerungen["Schritte"][1]
        .erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][1].erklaerungText,
      continuePossible: false,
      backPossible: false,
      totalSteps: Object.keys(this.erklaerungen["Schritte"]).length,
    });
    this.context.setSavePossible(false);
  }

  componentDidUpdate() {
    if (this.state.step !== this.context.schritt.key) {
      this.handleStepChange();
    }
  }

  handleFocus = (name) => {
    this.setState({
      focus: name,
      visualisierungsdaten: {
        ...this.state.visualisierungsdaten,
        series: [
          {
            values: this.state.chartData["Ist"][name],
            stack: 1,
            text: "Ist",
            backgroundColor: "#0066AA",
          },
          {
            values: this.state.chartData["Soll"][name],
            stack: 2,
            text: "Planung",
            backgroundColor: "#E52B52",
          }],
      }
    });

    this.context.setFeld({
      key: name,
      // ...this.erklaerungen[name],
      // ...this.erklaerungen["Schritte"][this.state.step][name],
    });
    // this.handleChart(name);
  };

  handleFocusEvent = (name, event) => {
    event.target.select();
    // this.context.setFeld({
    //   key: name,
    //   // ...this.erklaerungen[name],
    //   // ...this.schritte[this.state.step][name],
    // });
    this.handleFocus(name);
  };

  handleStepChange = () => {
    const newStep = this.context.schritt.key;
    this.setState({
      step: newStep,
    });

    if (newStep > 1) {
      this.context.setSchritt({
        key: newStep,
        continuePossible: this.continuePossible(newStep),
        backPossible: true,
      });
    } else {
      this.context.setSchritt({
        key: newStep,
        continuePossible: this.continuePossible(newStep),
        backPossible: false,
      });
    }
  };

  // handleFocus = name => {
  // handleChart = (name) => {
  //   console.log("HandleChart!");
  //   const {classes} = this.props;
  //   const {jahr, monat} = this.state;
  //   const monate = {
  //     0: "Jan",
  //     1: "Feb",
  //     2: "Mär",
  //     3: "Apr",
  //     4: "Mai",
  //     5: "Jun",
  //     6: "Jul",
  //     7: "Aug",
  //     8: "Sep",
  //     9: "Okt",
  //     10: "Nov",
  //     11: "Dez",
  //   };
  //
  //   let historyJahr = jahr;
  //   let historyMonat = monat;
  //
  //   let historyData = {
  //     "scale-x": {
  //       label: {
  //         /* Scale Title */
  //         text: "Monat",
  //       },
  //       labels: {}
  //     }
  //   };
  //
  //   for (var i = 0; i < 12; i++) {
  //
  //     // Für jeden der letzten 12 Monate
  //     historyMonat = (monat - i + 11) % 12;
  //     if (monat - i === 0) {
  //       historyJahr--;
  //     }
  //     historyData["scale-x"]["labels"][i] = monate[historyMonat] + " " + historyJahr;
  //   }
  //
  //   console.log(historyData);
  //
  //   // this.setState({
  //   let visualisierungsdaten = {
  //     type: "bar",
  //     plot: {
  //       stacked: true,
  //     },
  //     "scale-x": historyData["scale-x"],
  //     // },
  //     tooltip: {
  //       text: "%t %negation%v €",
  //       decimals: 0,
  //       align: "left",
  //       borderRadius: 3,
  //       fontColor: "#ffffff",
  //       negation: "currency",
  //       "thousands-separator": ".",
  //     },
  //     series: [
  //       {
  //         // values: result.series[0].values,
  //         stack: 1,
  //         text: this.erklaerungen[name]["erklaerungHeader"] + " Ist",
  //         backgroundColor: "#0066AA",
  //       },
  //       {
  //         // values: result.series[1].values,
  //         stack: 2,
  //         text: this.erklaerungen[name]["erklaerungHeader"] + " Planung",
  //         backgroundColor: "#E52B52",
  //       },
  //     ],
  //     // });
  //   };
  //   // .then(
  //   // ReactDOM.render(
  //   //   <Card><CardHeader
  //   //     title={
  //   //       "Grafische Auswertung von " +
  //   //       this.erklaerungen[name]["erklaerungHeader"]
  //   //     }
  //   //     id="grafische-auswertung-header"
  //   //   />
  //   //     <CardContent>
  //   //       <ZingChart
  //   //         id="bwa-visualisierung"
  //   //         data={visualisierungsdaten}
  //   //         className={classes.chart}
  //   //         width={"100%"}
  //   //       />
  //   //       {/*Muh!*/}
  //   //     </CardContent>
  //   //   </Card>,
  //   //   document.getElementById("grafische-auswertung")
  //   // );
  // };


  getClearData = () => {
    console.log("getClearData");

    return {
      Erloese7: 0,
      Erloese19: 0,
      Umsatzerloese: 0,
      UnfertigeErzeugnisse: 0,
      Bestandsveraenderung: 0,
      Gesamtleistung: 0,
      Wareneinkauf: 0,
      Fremdleistungen: 0,
      SonstigeErloese: 0,
      Rohertrag: 0,
      Personalkosten: 0,
      Raumkosten: 0,
      Steuern: 0,
      Versicherungen: 0,
      "Kfz-Kosten": 0,
      "Werbe-Reisekosten": 0,
      "Kosten der Warenabgabe": 0,
      Afa: 0,
      Reparaturen: 0,
      Instandhaltung: 0,
      "Sonstige Kosten": 0,
      Gesamtkosten: 0,
      Betriebsergebnis: 0,
      Zinsaufwand: 0,
      "So neutraler Aufwand": 0,
      "Neutraler Aufwand": 0,
      Zinsertrag: 0,
      SoNeutralerErtrag: 0,
      "Neutraler Ertrag": 0,
      "Neutrales Ergebnis": 0,
      Ergebnis: "",
    };
  };

  getDataFromApi = async (jahr, monat, planung) => {
    let result = await this.context.getUnternehmenDataFromApi("all");
    this.dataImport(result, jahr, monat, planung);
    this.context.setFunktion({
      key: this.erklaerungen.key,
      name: this.erklaerungen.name,
      erklaerungHeader: this.erklaerungen.erklaerungHeader,
      erklaerungText: this.erklaerungen.erklaerungText,
      continuePossible: false,
      backPossible: false,
      totalSteps: Object.keys(this.erklaerungen["Schritte"]).length,
      month: monat,
      year: jahr,
      handleSubmit: () => this.handleSubmit(),
      resetToStepOne: () => this.resetToStepOne(),
    });
  }

  loadChartData = (result, jahr, monat, planung) => {
    const keys = Object.keys(this.state.items);
    console.log(keys);
    let laufendesJahr = jahr;
    if (monat !== 12) {
      laufendesJahr--;
    }
    let laufenderMonat = monat - 1;
    let labels = [];
    let values = {
      Soll: {},
      Ist: {}
    };
    keys.forEach(function (key) {
      values["Soll"][key] = [];
      values["Ist"][key] = [];
    });

    for (let i = 0; i < 12; i++) {
      laufenderMonat++;
      if (laufenderMonat >= 12) {
        laufenderMonat = laufenderMonat - 12;
        laufendesJahr++;
      }
      labels = [...labels, laufenderMonat + 1 + "/" + laufendesJahr];
      keys.forEach(function (key) {
        values["Soll"][key] = [...values["Soll"][key], (((((result.bwa || {})[laufendesJahr] || {})[laufenderMonat + 1] || {})["Soll"] || {})[key] || 0)];
        values["Ist"][key] = [...values["Ist"][key], (((((result.bwa || {})[laufendesJahr] || {})[laufenderMonat + 1] || {})["Ist"] || {})[key] || 0)];
      });
      // values =
      // console.log(laufenderMonat+1 + "/" + laufendesJahr)
    }
    this.setState({
      visualisierungsdaten: {
        ...this.state.visualisierungsdaten,
        "scale-x":
          {
            label: {
              text: "Monat",
            },
            labels: labels
          }
      },
      chartData: values
    });

    console.log(labels);
    console.log(values);
    // let chartData = (((result.bwa || {})[laufendesJahr] || {})[laufenderMonat] || {})[key];
    // if (chartData !== undefined) {
    //   console.log("chartData");
    //   console.log(chartData);
    // } else {
    //   console.log("keine chartData");
    // }
  };

  dataImport = (result, jahr, monat, planung) => {
    if (jahr === "" || monat === "") {
      this.context.setSavePossible(false);
      this.handleStepChange();
      this.setState({
        isLoaded: false,
        jahr: "",
        monat: "",
        planung: planung,
        items: this.getClearData(),
      });
    } else {
      this.context.setSavePossible(true);
      this.handleStepChange();
      this.loadChartData(result, jahr, monat, planung);
      let vergleich = false;
      let vergleichsjahr = jahr;
      let vergleichsmonat = monat;
      let vergleichstyp = "Ist";
      let vergleichstext = "";
      if (result.bwa) {
        vergleich = true;
        if (
          result.bwa[jahr - 1] &&
          result.bwa[jahr - 1][monat] &&
          result.bwa[jahr - 1][monat]["Ist"] &&
          result.bwa[jahr - 1][monat]["Ist"]["Ergebnis"] &&
          0 !==
          result.bwa[jahr - 1][monat]["Ist"]["Ergebnis"].toString()
            .length
        ) {
          vergleichsjahr = jahr - 1;
          vergleichstext = " (Vorjahresmonat)";
          console.log("Vergleiche mit Ist vom Vorjahresmonat");
        } else {
          if (
            result.bwa[jahr] &&
            result.bwa[jahr][monat - 1] &&
            result.bwa[jahr][monat - 1]["Ist"] &&
            result.bwa[jahr][monat - 1]["Ist"]["Ergebnis"] &&
            0 !==
            result.bwa[jahr][monat - 1]["Ist"]["Ergebnis"].toString()
              .length
          ) {
            vergleichsmonat = monat - 1;
            vergleichstext = " (Vormonat)";
            console.log("Vergleiche mit Ist vom Vormonat");
          } else {
            console.log("Kein Vergleich...");
            vergleich = false;
            vergleichstext = "*";
          }
        }
        if (result.bwa[jahr]) {
          if (result.bwa[jahr][monat]) {
            if (planung) {
              if (result.bwa[jahr][monat]["Soll"]) {
                console.log(
                  "Setting State from API Readings for Soll " +
                  monat +
                  "." +
                  jahr
                );
                if (
                  result.bwa[jahr][monat]["Soll"]["Ergebnis"] &&
                  0 !==
                  result.bwa[jahr][monat]["Soll"]["Ergebnis"].toString()
                    .length
                ) {
                  if (vergleich) {
                    this.setState({
                      isLoaded: true,
                      jahr: jahr,
                      monat: monat,
                      planung: planung,
                      ergebnisVorhanden: true,
                      items: {
                        ...this.getClearData(),
                        ...result.bwa[jahr][monat]["Soll"],
                      },
                      vergleichsjahr: {
                        vergleichsmonat: {
                          ...result.bwa[vergleichsjahr][vergleichsmonat][
                            vergleichstyp
                            ],
                        },
                        vergleichstext: vergleichstext,
                      },
                    });
                  } else {
                    this.setState({
                      isLoaded: true,
                      jahr: jahr,
                      monat: monat,
                      planung: planung,
                      ergebnisVorhanden: true,
                      items: {
                        ...this.getClearData(),
                        ...result.bwa[jahr][monat]["Soll"],
                      },
                      vergleichsjahr: {
                        vergleichsmonat: {
                          ...this.getClearData(),
                        },
                        vergleichstext: vergleichstext,
                      },
                    });
                  }
                } else {
                  if (vergleich) {
                    this.setState({
                      isLoaded: true,
                      jahr: jahr,
                      monat: monat,
                      planung: planung,
                      ergebnisVorhanden: false,
                      items: {
                        ...this.getClearData(),
                        ...result.bwa[jahr][monat]["Soll"],
                      },
                      vergleichsjahr: {
                        vergleichsmonat: {
                          ...result.bwa[jahr][monat]["Ist"],
                        },
                        vergleichstext: vergleichstext,
                      },
                    });
                  } else {
                    this.setState({
                      isLoaded: true,
                      jahr: jahr,
                      monat: monat,
                      planung: planung,
                      ergebnisVorhanden: false,
                      items: {
                        ...this.getClearData(),
                        ...result.bwa[jahr][monat]["Soll"],
                      },
                      vergleichsjahr: {
                        vergleichsmonat: {
                          ...this.getClearData(),
                        },
                        vergleichstext: vergleichstext,
                      },
                    });
                  }
                }
                return;
              }
            } else {
              console.log(
                "Setting State from API Readings for Soll2 " +
                monat +
                "." +
                jahr
              );
              console.log(result.bwa[jahr][monat]["Soll"])
              if (
                result.bwa[jahr][monat]["Soll"] &&
                result.bwa[jahr][monat]["Soll"]["Ergebnis"] &&
                0 !==
                result.bwa[jahr][monat]["Soll"]["Ergebnis"].toString()
                  .length
              ) {
                vergleichsjahr = jahr;
                vergleichstyp = "Soll";
                vergleichstext = " (Planung)";
                console.log("Vergleiche mit Planung für aktuellen Monat");
              }
              if (result.bwa[jahr][monat]["Ist"]) {
                console.log(
                  "Setting State from API Readings for Ist " +
                  monat +
                  "." +
                  jahr
                );
                if (
                  result.bwa[jahr][monat]["Ist"]["Ergebnis"] &&
                  0 !==
                  result.bwa[jahr][monat]["Ist"]["Ergebnis"].toString()
                    .length
                ) {
                  if (vergleich) {
                    this.setState({
                      isLoaded: true,
                      jahr: jahr,
                      monat: monat,
                      planung: planung,
                      ergebnisVorhanden: true,
                      items: {
                        ...this.getClearData(),
                        ...result.bwa[jahr][monat]["Ist"],
                      },
                      vergleichsjahr: {
                        vergleichsmonat: {
                          ...result.bwa[vergleichsjahr][vergleichsmonat][
                            vergleichstyp
                            ],
                        },
                        vergleichstext: vergleichstext,
                      },
                    });
                  } else {
                    this.setState({
                      isLoaded: true,
                      jahr: jahr,
                      monat: monat,
                      planung: planung,
                      ergebnisVorhanden: true,
                      items: {
                        ...this.getClearData(),
                        ...result.bwa[jahr][monat]["Ist"],
                      },
                      vergleichsjahr: {
                        vergleichsmonat: {
                          ...this.getClearData(),
                        },
                        vergleichstext: vergleichstext,
                      },
                    });
                  }
                } else {
                  if (vergleich) {
                    this.setState({
                      isLoaded: true,
                      jahr: jahr,
                      monat: monat,
                      planung: planung,
                      ergebnisVorhanden: false,
                      items: {
                        ...this.getClearData(),
                        ...result.bwa[jahr][monat]["Ist"],
                      },
                      vergleichsjahr: {
                        vergleichsmonat: {
                          ...result.bwa[vergleichsjahr][vergleichsmonat][
                            vergleichstyp
                            ],
                        },
                        vergleichstext: vergleichstext,
                      },
                    });
                  } else {
                    this.setState({
                      isLoaded: true,
                      jahr: jahr,
                      monat: monat,
                      planung: planung,
                      ergebnisVorhanden: false,
                      items: {
                        ...this.getClearData(),
                        ...result.bwa[jahr][monat]["Ist"],
                      },
                      vergleichsjahr: {
                        vergleichsmonat: {
                          ...this.getClearData(),
                        },
                        vergleichstext: vergleichstext,
                      },
                    });
                  }
                }
                return;
              }
            }
          }
        }
      }
      if (!planung) {
        if (
          result.bwa &&
          result.bwa[jahr] &&
          result.bwa[jahr][monat] &&
          result.bwa[jahr][monat]["Soll"] &&
          result.bwa[jahr][monat]["Soll"]["Ergebnis"] &&
          0 !==
          result.bwa[jahr][monat]["Soll"]["Ergebnis"].toString().length
        ) {
          vergleich = true;
          vergleichsjahr = jahr;
          vergleichstyp = "Soll";
          vergleichstext = " (Planung)";
          console.log("Vergleiche mit Planung für aktuellen Monat");
        }
      }
      if (vergleich) {
        this.setState({
          isLoaded: false,
          jahr: jahr,
          monat: monat,
          planung: planung,
          ergebnisVorhanden: false,
          items: this.getClearData(),
          vergleichsjahr: {
            vergleichsmonat: {
              ...result.bwa[vergleichsjahr][vergleichsmonat][
                vergleichstyp
                ],
            },
            vergleichstext: vergleichstext,
          },
        });
      } else {
        console.log(
          "Setting State from Sample Data (1) for " + monat + " " + jahr
        );
        this.setState({
          isLoaded: false,
          jahr: jahr,
          monat: monat,
          planung: planung,
          ergebnisVorhanden: false,
          items: this.getClearData(),
          vergleichsjahr: {
            vergleichsmonat: {
              ...this.getClearData(),
            },
            vergleichstext: vergleichstext,
          },
        });
      }
    }
  };

  continuePossible = (step) => {
    console.log("continuePossible für Step " + step + " aufgerufen.");

    const {jahr, monat} = this.state;

    switch (step) {
      case 1:
        console.log("yaeh!")
        // return (
        //   jahr &&
        //   0 !== jahr.toString().length &&
        //   monat &&
        //   0 !== monat.toString().length
        // );
        return true;
      case 2:
        // return (items["Gesamtleistung"] && 0 !== items["Gesamtleistung"].toString().length);
        return true;
      case 3:
        // return (items["Rohertrag"] && 0 !== items["Rohertrag"].toString().length);
        return true;
      case 4:
        // return (items["Gesamtkosten"] && 0 !== items["Gesamtkosten"].toString().length);
        return true;
      case 5:
        // return (items["Ergebnis"] && 0 !== items["Ergebnis"].toString().length);
        return true;
      default:
        // return (items["Ergebnis"] && 0 !== items["Ergebnis"].toString().length);
        return false;
    }
  };

  handleSnackbarClose = () => {
    this.setState({
      saveSuccessful: false,
    });
  };

  handleChange = (name) => ({target: {value, checked}}) => {
    console.log("handleChange aufgerufen für: " + name);
    const {jahr, monat, planung} = this.state;

    if (name === "Jahr") {
      console.log("handleChange is jahr!");
      if (monat !== "" && value !== "" && jahr !== value) {
        console.log("jahr is set (monat was already set)!");
        this.getDataFromApi(value, monat, planung);
      } else {
        this.setState({
          jahr: value,
        });
      }
    } else if (name === "Monat") {
      console.log("handleChange is monat!");
      if (jahr !== "" && value !== "" && jahr !== value) {
        console.log("monat is set (jahr was already set)!");
        this.getDataFromApi(jahr, value, planung);
      } else {
        console.log("monat is " + value + " (jahr ist nor set)!");
        this.setState({
          monat: value,
        });
      }
    } else if (name === "Planung") {
      console.log("handleChange is planung!");
      console.log("value=" + value);
      console.log("checked=" + checked);
      if (jahr !== "" && value !== "") {
        this.getDataFromApi(jahr, monat, checked);
      } else {
        this.setState({
          planung: checked,
        });
      }
    } else {
      value = parseFloat(value);

      let items = this.state.items;
      let umsatzerloese = 0;
      let gesamtleistung = 0;
      let rohertrag = 0;
      let gesamtkosten = 0;
      let betriebsergebnis = 0;
      let neutralerAufwand = 0;
      let neutralerErtrag = 0;
      let neutralesErgebnis = 0;
      let ergebnis = 0;

      //Second row
      switch (name) {
        case "Erloese7":
          umsatzerloese =
            value +
            parseFloat(items["Erloese19"]);
          gesamtleistung = umsatzerloese +
            parseFloat(items["Bestandsveraenderung"]);
          break;
        case "Erloese19":
          umsatzerloese =
            value +
            parseFloat(items["Erloese7"]);
          gesamtleistung = umsatzerloese +
            parseFloat(items["Bestandsveraenderung"]);
          break;
        case "Umsatzerloese":
          umsatzerloese =
            parseFloat(items["Erloese19"]) +
            parseFloat(items["Erloese7"]);
          gesamtleistung = umsatzerloese +
            parseFloat(items["Bestandsveraenderung"]) +
            parseFloat(items["UnfertigeErzeugnisse"]);
          break;
        case "UnfertigeErzeugnisse":
          gesamtleistung = umsatzerloese +
            parseFloat(items["Bestandsveraenderung"]) +
            value;
          break;
        case "Bestandsveraenderung":
          gesamtleistung = umsatzerloese +
            parseFloat(items["UnfertigeErzeugnisse"]) +
            value;
          break;
        default:
          // if (items["Gesamtleistung"]) {
          umsatzerloese =
            parseFloat(items["Erloese19"]) +
            parseFloat(items["Erloese7"]);
          gesamtleistung = umsatzerloese +
            parseFloat(items["Bestandsveraenderung"]) +
            parseFloat(items["UnfertigeErzeugnisse"]);
          // }
          break;
      }

      //Third row
      switch (name) {
        case "Wareneinkauf":
          rohertrag =
            gesamtleistung -
            (value +
              items["Fremdleistungen"] +
              items["SonstigeErloese"]);
          break;
        case "Fremdleistungen":
          rohertrag =
            gesamtleistung -
            (items["Wareneinkauf"] +
              value +
              items["SonstigeErloese"]);
          break;
        case "SonstigeErloese":
          rohertrag =
            gesamtleistung -
            (items["Wareneinkauf"] +
              items["Fremdleistungen"] +
              value);
          break;
        default:
          rohertrag =
            gesamtleistung -
            (items["Wareneinkauf"] +
              items["Fremdleistungen"] +
              items["SonstigeErloese"]);
          // if (items["Rohertrag"]) {
          //   rohertrag = parseFloat(items["Rohertrag"]);
          // }
          break;
      }

      console.log("Rohertrag = " + rohertrag);

      //Forth row
      switch (name) {
        case "Personalkosten":
        case "Raumkosten":
        case "Steuern":
        case "Versicherungen":
        case "Kfz-Kosten":
        case "Werbe-Reisekosten":
        case "Kosten der Warenabgabe":
        case "Afa":
        case "Reparaturen":
        case "Instandhaltung":
        case "Sonstige Kosten":
          items[name] = parseFloat(value);
          gesamtkosten =
            items["Personalkosten"] +
            items["Raumkosten"] +
            items["Steuern"] +
            items["Versicherungen"] +
            items["Kfz-Kosten"] +
            items["Werbe-Reisekosten"] +
            items["Kosten der Warenabgabe"] +
            items["Afa"] +
            items["Reparaturen"] +
            items["Instandhaltung"] +
            items["Sonstige Kosten"];
          betriebsergebnis = rohertrag - gesamtkosten;
          break;
        default:
          if (items["Gesamtkosten"]) {
            gesamtkosten = parseFloat(items["Gesamtkosten"]);
            betriebsergebnis = parseFloat(items["Betriebsergebnis"]);
          } else {
            gesamtkosten =
              items["Personalkosten"] +
              items["Raumkosten"] +
              items["Steuern"] +
              items["Versicherungen"] +
              items["Kfz-Kosten"] +
              items["Werbe-Reisekosten"] +
              items["Kosten der Warenabgabe"] +
              items["Afa"] +
              items["Reparaturen"] +
              items["Instandhaltung"] +
              items["Sonstige Kosten"];
            betriebsergebnis = rohertrag - gesamtkosten;
          }
          break;
      }

      console.log("Gesamtkosten = " + items["Gesamtkosten"]);
      console.log("Betriebsergebnis = " + items["Betriebsergebnis"]);

      //Fifth row
      switch (name) {
        case "Zinsaufwand":
        case "So neutraler Aufwand":
          console.log("neutraler Aufwand changed!");
          items[name] = parseFloat(value);
          neutralerAufwand =
            items["Zinsaufwand"] + items["So neutraler Aufwand"];
          if (items["Neutraler Ertrag"] || items["Neutraler Ertrag"] === 0.0) {
            neutralerErtrag = parseFloat(items["Neutraler Ertrag"]);
            neutralesErgebnis = neutralerErtrag - neutralerAufwand;
            ergebnis = betriebsergebnis + neutralesErgebnis;
          }
          break;
        case "Zinsertrag":
        case "SoNeutralerErtrag":
          console.log("neutraler Ertrag changed!");
          items[name] = parseFloat(value);
          neutralerErtrag = items["Zinsertrag"] + items["SoNeutralerErtrag"];
          if (
            items["Neutraler Aufwand"] ||
            items["Neutraler Aufwand"] === 0.0
          ) {
            neutralerAufwand = parseFloat(items["Neutraler Aufwand"]);
            neutralesErgebnis = neutralerErtrag - neutralerAufwand;
            ergebnis = betriebsergebnis + neutralesErgebnis;
          }
          break;
        case "Neutraler Aufwand":
          neutralerAufwand = parseFloat(value);
          if (items["Neutraler Ertrag"] || items["Neutraler Ertrag"] === 0.0) {
            neutralerErtrag = parseFloat(items["Neutraler Ertrag"]);
            neutralesErgebnis = neutralerErtrag - neutralerAufwand;
            ergebnis = betriebsergebnis + neutralesErgebnis;
          }
          console.log("neutraler Aufwand directly changed! value=" + value);
          break;
        case "Neutraler Ertrag":
          neutralerErtrag = parseFloat(value);
          if (
            items["Neutraler Aufwand"] ||
            items["Neutraler Aufwand"] === 0.0
          ) {
            neutralerAufwand = parseFloat(items["Neutraler Aufwand"]);
            neutralesErgebnis = neutralerErtrag - neutralerAufwand;
            ergebnis = betriebsergebnis + neutralesErgebnis;
          }
          console.log("neutraler Ertrag directly changed! value=" + value);
          break;
        default:
          console.log("neutrales Ergebnis unchanged, da name=" + name);
          if (
            items["Neutraler Aufwand"] ||
            items["Neutraler Aufwand"] === 0.0
          ) {
            neutralerAufwand = parseFloat(items["Neutraler Aufwand"]);
          }
          if (items["Neutraler Ertrag"] || items["Neutraler Ertrag"] === 0.0) {
            neutralerErtrag = parseFloat(items["Neutraler Ertrag"]);
            if (
              items["Neutraler Aufwand"] ||
              items["Neutraler Aufwand"] === 0.0
            ) {
              neutralesErgebnis = neutralerErtrag - neutralerAufwand;
              ergebnis = betriebsergebnis + neutralesErgebnis;
            }
          }
          break;
      }

      console.log("neutraler Aufwand = " + items["Neutraler Aufwand"]);
      console.log("neutraler Ertrag = " + items["Neutraler Ertrag"]);
      console.log("neutrales Ergebnis = " + items["Neutrales Ergebnis"]);
      console.log("neutraler Aufwand = " + neutralerAufwand);
      console.log("neutraler Ertrag = " + neutralerErtrag);
      console.log("neutrales Ergebnis = " + neutralesErgebnis);

      console.log("Final setState...");

      let chartData = {};
      let visualisierungsdaten = {};
      if (this.state.focus === name) {
        if (planung) {
          chartData = {
            ...this.state.chartData,
            Soll: {
              ...this.state.chartData["Soll"],
              [name]: [
                this.state.chartData["Soll"][name][0],
                this.state.chartData["Soll"][name][1],
                this.state.chartData["Soll"][name][2],
                this.state.chartData["Soll"][name][3],
                this.state.chartData["Soll"][name][4],
                this.state.chartData["Soll"][name][5],
                this.state.chartData["Soll"][name][6],
                this.state.chartData["Soll"][name][7],
                this.state.chartData["Soll"][name][8],
                this.state.chartData["Soll"][name][9],
                this.state.chartData["Soll"][name][10],
                value,
              ]
            }
          };
          visualisierungsdaten = {
            ...this.state.visualisierungsdaten,
            series: [
              {
                values: this.state.chartData["Ist"][name],
                stack: 1,
                text: "Ist",
                backgroundColor: "#0066AA",
              },
              {
                values: [
                  this.state.chartData["Soll"][name][0],
                  this.state.chartData["Soll"][name][1],
                  this.state.chartData["Soll"][name][2],
                  this.state.chartData["Soll"][name][3],
                  this.state.chartData["Soll"][name][4],
                  this.state.chartData["Soll"][name][5],
                  this.state.chartData["Soll"][name][6],
                  this.state.chartData["Soll"][name][7],
                  this.state.chartData["Soll"][name][8],
                  this.state.chartData["Soll"][name][9],
                  this.state.chartData["Soll"][name][10],
                  value,
                ],
                stack: 2,
                text: "Planung",
                backgroundColor: "#E52B52",
              }],
          }
        } else {
          chartData = {
            Soll:
              {...this.state.chartData["Soll"]},
            Ist: {
              ...this.state.chartData["Ist"],
              [name]: [
                this.state.chartData["Ist"][name][0],
                this.state.chartData["Ist"][name][1],
                this.state.chartData["Ist"][name][2],
                this.state.chartData["Ist"][name][3],
                this.state.chartData["Ist"][name][4],
                this.state.chartData["Ist"][name][5],
                this.state.chartData["Ist"][name][6],
                this.state.chartData["Ist"][name][7],
                this.state.chartData["Ist"][name][8],
                this.state.chartData["Ist"][name][9],
                this.state.chartData["Ist"][name][10],
                value,
              ]
            }
          };
          visualisierungsdaten = {
            ...this.state.visualisierungsdaten,
            series: [
              {
                values:
                  chartData["Ist"][name],
                stack: 1,
                text: "Ist",
                backgroundColor: "#0066AA",
              },
              {
                values: chartData["Soll"][name],
                stack: 2,
                text: "Planung",
                backgroundColor: "#E52B52",
              }],
          }
          console.log(chartData);
          console.log(visualisierungsdaten);
        }
        this.setState({
          items: {
            ...items,
            [name]: value,
            Gesamtleistung: gesamtleistung,
            Rohertrag: rohertrag,
            Umsatzerloese: umsatzerloese,
            Gesamtkosten: gesamtkosten,
            Betriebsergebnis: betriebsergebnis,
            "Neutraler Aufwand": neutralerAufwand,
            "Neutraler Ertrag": neutralerErtrag,
            "Neutrales Ergebnis": neutralesErgebnis,
            Ergebnis: ergebnis,
          },
          chartData: chartData,
          visualisierungsdaten: visualisierungsdaten
        });
      } else {
        this.setState({
          items: {
            ...items,
            [name]: value,
            Gesamtleistung: gesamtleistung,
            Rohertrag: rohertrag,
            Umsatzerloese: umsatzerloese,
            Gesamtkosten: gesamtkosten,
            Betriebsergebnis: betriebsergebnis,
            "Neutraler Aufwand": neutralerAufwand,
            "Neutraler Ertrag": neutralerErtrag,
            "Neutrales Ergebnis": neutralesErgebnis,
            Ergebnis: ergebnis,
          }
        });
      }
    }
  };

  handleNextMonth = () => {
    const { jahr, monat, planung } = this.state;

    let monatneu = (monat + 1) % 12;
    let jahrneu = jahr;
    if (monatneu === 0) {
      monatneu = 12;
    } else if (monatneu === 1) {
      jahrneu = jahr + 1;
    }

    this.setState({
      step: 2,
      ergebnisVorhanden: false,
      isLoaded: false,
      jahr: jahrneu,
      monat: monatneu,
      saveSuccessful: false,
    });

    this.getDataFromApi(jahrneu, monatneu, planung);
  };

  handleSubmit = async () => {
    // TODO: Validate
    const {items, jahr, monat, planung} = this.state;

    let istSoll = "Ist";

    if (planung) {
      istSoll = "Soll";
    }

    var submitdata = {
      [jahr]: {
        [monat]: {
          [istSoll]: {
            ...items,
          },
        },
      },
    };

    let result = await this.context.postUnternehmenDataToApi("bwa", submitdata);

    this.setState({
      ergebnisVorhanden: true,
      isLoaded: true,
      jahr: jahr,
      monat: monat,
      items: result.bwa[jahr][monat][istSoll],
      saveSuccessful: true,
    });
  };

// handleSubmit = () => {
//   // TODO: Validate
//
//   const { items, jahr, monat, planung } = this.state;
//
//   let istSoll = "Ist";
//
//   if (planung) {
//     istSoll = "Soll";
//   }
//
//   fetch(
//     `${process.env.REACT_APP_API_URL}/v1/unternehmendata/${this.props.userId}/${this.props.unternehmenId}`,
//     {
//       method: "PUT",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         bwa: {
//           [jahr]: {
//             [monat]: {
//               [istSoll]: {
//                 ...items,
//               },
//             },
//           },
//         },
//       }),
//     }
//   )
//     .then((res) => res.json())
//     .then(
//       (result) => {
//         this.setState({
//           isLoaded: true,
//           jahr: jahr,
//           monat: monat,
//           items: result.bwa[jahr][monat][istSoll],
//           saveSuccessful: true,
//         });
//       },
//       (error) => {
//         this.setState({
//           isLoaded: false,
//           error: error,
//         });
//       }
//     );
// };

  onExit = () => {
    this.setState({
      tutorialEnabled: false,
    });
  };

  render() {
    const {classes} = this.props;
    const {
      planung,
      jahr,
      monat,
      vergleichsjahr,
      ergebnisVorhanden,
      items,
    } = this.state;
    const STEPS = {
      1: (
        <Card className={classes.card}>
          <CardHeader title="Monat und Jahr"/>
          <CardContent>
            <FormControl
              className={classes.formControl}
              id={"edit-bwa-jahr-control"}
            >
              <InputLabel htmlFor="edit-bwa-bundesland">Jahr</InputLabel>
              <Select
                value={jahr}
                onChange={this.handleChange("Jahr")}
                inputProps={{
                  name: "jahr",
                  id: "edit-bwa-jahr",
                }}
              >
                {/*<MenuItem value="">*/}
                {/*  <em>Bitte wählen</em>*/}
                {/*</MenuItem>*/}
                <MenuItem value={2016}>2016</MenuItem>
                <MenuItem value={2017}>2017</MenuItem>
                <MenuItem value={2018}>2018</MenuItem>
                <MenuItem value={2019}>2019</MenuItem>
                <MenuItem value={2020}>2020</MenuItem>
                <MenuItem value={2021}>2021</MenuItem>
                <MenuItem value={2022}>2022</MenuItem>
                <MenuItem value={2023}>2023</MenuItem>
                <MenuItem value={2024}>2024</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="edit-bwa-monat">Monat</InputLabel>
              <Select
                value={monat}
                onChange={this.handleChange("Monat")}
                inputProps={{
                  name: "monat",
                  id: "edit-bwa-monat",
                }}
              >
                <MenuItem value={1}>Januar</MenuItem>
                <MenuItem value={2}>Februar</MenuItem>
                <MenuItem value={3}>März</MenuItem>
                <MenuItem value={4}>April</MenuItem>
                <MenuItem value={5}>Mai</MenuItem>
                <MenuItem value={6}>Juni</MenuItem>
                <MenuItem value={7}>Juli</MenuItem>
                <MenuItem value={8}>August</MenuItem>
                <MenuItem value={9}>September</MenuItem>
                <MenuItem value={10}>Oktober</MenuItem>
                <MenuItem value={11}>November</MenuItem>
                <MenuItem value={12}>Dezember</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <Switch
                    checked={planung}
                    onChange={this.handleChange("Planung")}
                    value="Planung"
                    color="primary"
                  />
                }
                label="Planung"
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={ergebnisVorhanden}
                    value="ergebnisVorhanden"
                    color="primary"
                  />
                }
                label="bereits eingegeben"
              />
            </FormControl>
          </CardContent>
        </Card>
      ),
      2: (
        <Card className={classes.card}>
          <CardHeader title="Gesamtleistung"/>
          <CardContent>
            <CustomTextField
              id="edit-bwa-erloese-7"
              label="Erlöse 7% MwSt"
              className={classes.textField}
              value={items["Erloese7"]}
              onChange={this.handleChange("Erloese7")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Erloese7"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("Erloese7", event)}
            />
            <CustomTextField
              id="edit-bwa-erloese-19"
              label="Erlöse 19% MwSt"
              className={classes.textField}
              value={items["Erloese19"]}
              onChange={this.handleChange("Erloese19")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Erloese19"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("Erloese19", event)}
            />
            <CustomTextField
              id="edit-bwa-umsatzerloese"
              label="Umsatzerlöse"
              className={classes.textField}
              value={items["Umsatzerloese"]}
              onChange={this.handleChange("Umsatzerloese")}
              margin="normal"
              isCurrency={true}
              isReadOnly={true}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Umsatzerloese"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={() => this.handleFocus("RentabilitaetUmsatzerloese")}
            />
            <CustomTextField
              id="edit-bwa-unfertige-erzeugnisse"
              label="Unfertige Erzeugnisse"
              className={classes.textField}
              value={items["UnfertigeErzeugnisse"]}
              onChange={this.handleChange("UnfertigeErzeugnisse")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["UnfertigeErzeugnisse"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("UnfertigeErzeugnisse", event)}
            />
            <CustomTextField
              id="edit-bwa-bestandsveraenderung"
              label={this.erklaerungen.Bestandsveraenderung.kurzName}
              className={classes.textField}
              value={items["Bestandsveraenderung"]}
              onChange={this.handleChange("Bestandsveraenderung")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Bestandsveraenderung"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("Bestandsveraenderung", event)}
            />
            <CustomTextField
              id="edit-bwa-gesamtleistung"
              label="Gesamtleistung"
              className={classes.textField}
              value={items["Gesamtleistung"]}
              onChange={this.handleChange("Gesamtleistung")}
              margin="normal"
              isCurrency={true}
              isReadOnly={true}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Gesamtleistung"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={() => this.handleFocus("Gesamtleistung")}
            />
            {vergleichsjahr.vergleichstext === "*" &&
            <Typography variant="caption" display="block">*keine Daten aus Planung, Vormonat oder Vorjahresmonat
              vorhanden</Typography>}
          </CardContent>
        </Card>
      ),
      3: (
        <Card className={classes.card}>
          <CardHeader title="Rohertrag"/>
          <CardContent>
            <CustomTextField
              id="edit-bwa-wareneinkauf"
              label="Wareneinkauf"
              className={classes.textField}
              value={items["Wareneinkauf"]}
              onChange={this.handleChange("Wareneinkauf")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Wareneinkauf"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("Wareneinkauf", event)}
            />
            <CustomTextField
              id="edit-bwa-Fremdleistungen"
              label="Fremdleistungen"
              className={classes.textField}
              value={items["Fremdleistungen"]}
              onChange={this.handleChange("Fremdleistungen")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Fremdleistungen"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("Fremdleistungen", event)}
            />
            <CustomTextField
              id="edit-bwa-soerloese"
              label={this.erklaerungen.SonstigeErloese.kurzName}
              className={classes.textField}
              value={items["SonstigeErloese"]}
              onChange={this.handleChange("SonstigeErloese")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["SonstigeErloese"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("SonstigeErloese", event)}
            />
            <CustomTextField
              id="edit-bwa-rohertrag"
              label="Rohertrag"
              className={classes.textField}
              value={items["Rohertrag"]}
              onChange={this.handleChange("Rohertrag")}
              margin="normal"
              isCurrency={true}
              isReadOnly={true}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Rohertrag"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={() => this.handleFocus("Rohertrag")}
            />
            {vergleichsjahr.vergleichstext === "*" &&
            <Typography variant="caption" display="block">*keine Daten aus Planung, Vormonat oder Vorjahresmonat
              vorhanden</Typography>}
          </CardContent>
        </Card>
      ),
      4: (
        <Card className={classes.card}>
          <CardHeader title="Kosten"/>
          <CardContent>
            <CustomTextField
              id="edit-bwa-Personalkosten"
              label="Personalkosten"
              className={classes.textField}
              value={items["Personalkosten"]}
              onChange={this.handleChange("Personalkosten")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Personalkosten"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("Personalkosten", event)}
            />
            <CustomTextField
              id="edit-bwa-raumkosten"
              label="Raumkosten"
              className={classes.textField}
              value={items["Raumkosten"]}
              onChange={this.handleChange("Raumkosten")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Raumkosten"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("Raumkosten", event)}
            />
            <CustomTextField
              id="edit-bwa-steuern"
              label="Steuern"
              className={classes.textField}
              value={items["Steuern"]}
              onChange={this.handleChange("Steuern")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Steuern"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("Steuern", event)}
            />
            <CustomTextField
              id="edit-bwa-versicherungen"
              label="Versicherungen"
              className={classes.textField}
              value={items["Versicherungen"]}
              onChange={this.handleChange("Versicherungen")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Versicherungen"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("Versicherungen", event)}
            />
            <CustomTextField
              id="edit-bwa-kfz-kosten"
              label="Kfz-Kosten"
              className={classes.textField}
              value={items["Kfz-Kosten"]}
              onChange={this.handleChange("Kfz-Kosten")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Kfz-Kosten"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("Kfz-Kosten", event)}
            />
            <CustomTextField
              id="edit-bwa-werbe-reisekosten"
              label="Werbe/Reisekosten"
              className={classes.textField}
              value={items["Werbe-Reisekosten"]}
              onChange={this.handleChange("Werbe-Reisekosten")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Werbe-Reisekosten"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("Werbe-Reisekosten", event)}
            />
            <CustomTextField
              id="edit-bwa-warenabgabekosten"
              label="Kosten der Warenabgabe"
              className={classes.textField}
              value={items["Kosten der Warenabgabe"]}
              onChange={this.handleChange("Kosten der Warenabgabe")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Kosten der Warenabgabe"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("Kosten der Warenabgabe", event)}
            />
            <CustomTextField
              id="edit-bwa-afa"
              label="Afa"
              className={classes.textField}
              value={items["Afa"]}
              onChange={this.handleChange("Afa")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Afa"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("Afa", event)}
            />
            <CustomTextField
              id="edit-bwa-reparaturen"
              label="Reparaturen"
              className={classes.textField}
              value={items["Reparaturen"]}
              onChange={this.handleChange("Reparaturen")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Reparaturen"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("Reparaturen", event)}
            />
            <CustomTextField
              id="edit-bwa-instandhaltung"
              label="Instandhaltung"
              className={classes.textField}
              value={items["Instandhaltung"]}
              onChange={this.handleChange("Instandhaltung")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Instandhaltung"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("Instandhaltung", event)}
            />
            <CustomTextField
              id="edit-bwa-sokosten"
              label="Sonstige Kosten"
              className={classes.textField}
              value={items["Sonstige Kosten"]}
              onChange={this.handleChange("Sonstige Kosten")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Sonstige Kosten"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("Sonstige Kosten", event)}
            />
            <CustomTextField
              id="edit-bwa-gesamtkosten"
              label="Gesamtkosten"
              className={classes.textField}
              value={items["Gesamtkosten"]}
              onChange={this.handleChange("Gesamtkosten")}
              margin="normal"
              isCurrency={true}
              isReadOnly={true}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Gesamtkosten"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={() => this.handleFocus("Gesamtkosten")}
            />
            <CustomTextField
              id="edit-bwa-betriebsergebnis"
              label="Betriebsergebnis"
              className={classes.textField}
              value={items["Betriebsergebnis"]}
              onChange={this.handleChange("Betriebsergebnis")}
              margin="normal"
              isCurrency={true}
              isReadOnly={true}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Betriebsergebnis"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={() => this.handleFocus("Betriebsergebnis")}
            />
            {vergleichsjahr.vergleichstext === "*" &&
            <Typography variant="caption" display="block">*keine Daten aus Planung, Vormonat oder Vorjahresmonat
              vorhanden</Typography>}
          </CardContent>
        </Card>
      ),
      5: (
        <Card className={classes.card}>
          <CardHeader title="Neutrales Ergebnis"/>
          <CardContent>
            <CustomTextField
              id="edit-bwa-zinsaufwand"
              label="Zinsaufwand"
              className={classes.textField}
              value={items["Zinsaufwand"]}
              onChange={this.handleChange("Zinsaufwand")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Zinsaufwand"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("Zinsaufwand", event)}
            />
            <CustomTextField
              id="edit-bwa-so-neutraler-aufwand"
              label="Sonstiger neutraler Aufwand"
              className={classes.textField}
              value={items["So neutraler Aufwand"]}
              onChange={this.handleChange("So neutraler Aufwand")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["So neutraler Aufwand"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("So neutraler Aufwand", event)}
            />
            <CustomTextField
              id="edit-bwa-neutraler-aufwand"
              label="Summe neutraler Aufwand"
              className={classes.textField}
              value={items["Neutraler Aufwand"]}
              onChange={this.handleChange("Neutraler Aufwand")}
              margin="normal"
              isCurrency={true}
              isReadOnly={true}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Neutraler Aufwand"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={() => this.handleFocus("Neutraler Aufwand")}
            />
            <CustomTextField
              id="edit-bwa-zinsertrag"
              label={this.erklaerungen.Zinsertrag.kurzName}
              className={classes.textField}
              value={items["Zinsertrag"]}
              onChange={this.handleChange("Zinsertrag")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Zinsertrag"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("Zinsertrag", event)}
            />
            <CustomTextField
              id="edit-bwa-so-neutraler-Ertrag"
              label={this.erklaerungen.SoNeutralerErtrag.kurzName}
              className={classes.textField}
              value={items["SoNeutralerErtrag"]}
              onChange={this.handleChange("SoNeutralerErtrag")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["SoNeutralerErtrag"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={(event) => this.handleFocusEvent("SoNeutralerErtrag", event)}
            />
            <CustomTextField
              id="edit-bwa-neutraler-ertrag"
              label="Summe neutraler Ertrag"
              className={classes.textField}
              value={items["Neutraler Ertrag"]}
              onChange={this.handleChange("Neutraler Ertrag")}
              margin="normal"
              isCurrency={true}
              isReadOnly={true}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Neutraler Ertrag"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={() => this.handleFocus("Neutraler Ertrag")}
            />
            {vergleichsjahr.vergleichstext === "*" &&
            <Typography variant="caption" display="block">*keine Daten aus Planung, Vormonat oder Vorjahresmonat
              vorhanden</Typography>}
          </CardContent>
        </Card>
      ),
      6: (
        <Card className={classes.card}>
          <CardHeader title="Ergebnisberechnung"/>
          <CardContent>
            <CustomTextField
              id="edit-bwa-betriebsergebnis"
              label="Betriebsergebnis"
              className={classes.textField}
              value={items["Betriebsergebnis"]}
              onChange={this.handleChange("Betriebsergebnis")}
              margin="normal"
              isCurrency={true}
              isReadOnly={true}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Betriebsergebnis"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={() => this.handleFocus("Betriebsergebnis")}
            />
            <CustomTextField
              id="edit-bwa-neutrales-ergebnis"
              label="Neutrales Ergebnis"
              className={classes.textField}
              value={items["Neutrales Ergebnis"]}
              onChange={this.handleChange("Neutrales Ergebnis")}
              margin="normal"
              isCurrency={true}
              isReadOnly={true}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Neutrales Ergebnis"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={() => this.handleFocus("Neutrales Ergebnis")}
            />
            <CustomTextField
              id="edit-bwa-ergebnis"
              label="Ergebnis"
              className={classes.textField}
              value={items["Ergebnis"]}
              onChange={this.handleChange("Ergebnis")}
              margin="normal"
              isCurrency={true}
              isReadOnly={true}
              unit={"€"}
              helperText={
                "Vergleichswert: " +
                vergleichsjahr.vergleichsmonat["Ergebnis"] +
                vergleichsjahr.vergleichstext
              }
              onFocus={() => this.handleFocus("Ergebnis")}
            />
            {vergleichsjahr.vergleichstext === "*" &&
            <Typography variant="caption" display="block">*keine Daten aus Planung, Vormonat oder Vorjahresmonat
              vorhanden</Typography>}
          </CardContent>
        </Card>
      ),
    };

    const {
      step,
      visualisierungsdaten,
    } = this.state;

    return (
      <Fragment>
        <div className={classes.appBarSpacer}/>
        <Typography variant="h4" gutterBottom component="h2">
          BWA-Eingabe
          {jahr !== "" && monat !== "" && (
            <Fragment>
              &nbsp;für {monat}/{jahr} {planung && " (Planung)"}
            </Fragment>
          )}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <form className={classes.container} noValidate autoComplete="off">
              {STEPS[step]}
            </form>
          </Grid>
          <Grid item md={12}>
            {/*<Card className={classes.rightcard}>*/}
            {/*  <CardHeader title={erklaerungHeader} />*/}
            {/*  <CardContent>*/}
            {/*    <Typography variant={"body1"}>{erklaerungText}</Typography>*/}
            {/*  </CardContent>*/}
            {/*</Card>*/}
            {step == 1 ? (
              <Card className={classes.card}>
                {/*<CardHeader*/}
                {/*  title="grafische Auswertung"*/}
                {/*  id="grafische-auswertung-header"*/}
                {/*/>*/}
                <CardContent>
                </CardContent>
              </Card>
            ) : (
              <Card className={classes.chartCard}>
                <CardHeader
                  title={"grafische Auswertung von " + this.context.feld.kurzName}
                  className={classes.chartCardHeader}
                />
                <CardContent className={classes.chartCardContent}>
                  <ZingChart
                    id="bwa-visualisierung"
                    data={visualisierungsdaten}
                    className={classes.chart}
                    width={"100%"}
                    height={200}
                  />
                </CardContent>
              </Card>
            )}
            {/*</Grid>*/}
            {/*<Grid item md={4}>*/}
          </Grid>
          {/*<Grid item md={12}>*/}
          {/*  <Card className={classes.progressCard}>*/}
          {/*    <CardHeader title="Fortschritt"/>*/}
          {/*    <CardContent>*/}
          {/*      <LinearProgress*/}
          {/*        variant="determinate"*/}
          {/*        value={((step - 1) / (Object.keys(this.erklaerungen["Schritte"]).length - 1)) * 100}*/}
          {/*      />*/}
          {/*      <Typography variant="body1">*/}
          {/*        {this.erklaerungen.erklaerungHeader} &mdash; Schritt {step} von {(Object.keys(this.erklaerungen["Schritte"]).length)}: {this.context.schritt.erklaerungHeader}*/}
          {/*      </Typography>*/}
          {/*    </CardContent>*/}
          {/*  </Card>*/}
          {/*</Grid>*/}
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Bwa);
