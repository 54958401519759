import Typography from "@material-ui/core/Typography/Typography";
import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import CustomTextField from "../CustomTags/CustomTextField";
import styles from "../styles";
import {AppContext} from "../AppContext";
import erklaerungsUeberschriften from "../LogicData/erklaerungsUeberschriften";
import erklaerungsTexte from "../LogicData/erklaerungsTexte";

class Stundensatz extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.erklaerungen = {
      ...erklaerungsUeberschriften.Geschaeftsdaten.Stundensatz,
      ...erklaerungsTexte,
    };

    this.state = {
      step: 1,
      isLoaded: false,
      tutorialEnabled: true,
      jahr: "",
      items: {
        Kalendertage: "",
        "Samstage/Sonntage": "",
        Feiertage: "",
        Vertragsarbeitszeit: "",
        Bundesland: "",
        Urlaub: "",
        Krankheit: "",
        Weiterbildung: "",
        Anwesenheitstage: "",
        "ArbeitsstundenTag": "",
        "ArbeitsstundenJahr": "",
        "MehrarbeitJahr": "",
        "AnwesenheitBrutto": "",
        "unproduktive Prozente": "",
        "unproduktive Stunden": "",
        "produktivierbare Zeiten": "",
        "Personalaufwand Gesamt": "",
        "RisikozuschlagProzent": "",
        "RisikozuschlagEuro": "",
        Gemeinkosten: "",
        StundensatzGesamtkosten: "",
        "Anzahl Mitarbeiter": "",
        Produktivzeit: "",
        Stundenverrechnungssatz: "",
      },
    };

    this.handleFocus = this.handleFocus.bind(this);
  }

  componentDidMount() {
    this.context.setFunktion({
      key: this.erklaerungen.key,
      name: this.erklaerungen.name,
      erklaerungHeader: this.erklaerungen.erklaerungHeader,
      erklaerungText: this.erklaerungen.erklaerungText,
      continuePossible: false,
      backPossible: false,
      totalSteps: Object.keys(this.erklaerungen["Schritte"]).length,
      handleSubmit: () => this.handleSubmit(),
      resetToStepOne: () => this.resetToStepOne(),
    });
    this.context.setBereich({
      name: "Geschaeftsdaten"
    });
    this.resetToStepOne();
  }

  resetToStepOne = () => {
    this.context.setSchritt({
      key: 1,
      erklaerungHeader: this.erklaerungen["Schritte"][1]
        .erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][1].erklaerungText,
      continuePossible: false,
      backPossible: false,
    });
    this.context.setSavePossible(false);
  }

  componentDidUpdate() {
    if (this.state.step !== this.context.schritt.key) {
      this.handleStepChange();
    }
  }

  handleFocus = (name) => {
    // this.setState({
    //   erklaerungHeader: this.erklaerungen[name].erklaerungHeader,
    //   erklaerungText: this.erklaerungen[name].erklaerungText,
    // });
    this.context.setFeld({
      key: name,
      // ...this.erklaerungen[name],
      // ...this.erklaerungen["Schritte"][this.state.step][name],
    });
  };

  handleFocusEvent = (name, event) => {
    this.setState({
      erklaerungHeader: this.erklaerungen[name].erklaerungHeader,
      erklaerungText: this.erklaerungen[name].erklaerungText,
    });
    event.target.select();
    this.context.setFeld({
      key: name,
      // ...this.erklaerungen[name],
      // ...this.schritte[this.state.step][name],
    });
  };

  handleFirstStepChange = (complete) => {
    this.context.setSchritt({
      key: 1,
      erklaerungHeader: this.erklaerungen["Schritte"][1]
        .erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][1].erklaerungText,
      continuePossible: complete,
      backPossible: false,
    });
  }

  handleStepChange = () => {
    const newStep = this.context.schritt.key;
    this.setState({
      step: newStep,
      erklaerungHeader: this.erklaerungen["Schritte"][newStep]
        .erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
    });

    if (newStep > 1) {
      this.context.setSchritt({
        key: newStep,
        erklaerungHeader: this.erklaerungen["Schritte"][newStep]
          .erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
        continuePossible: this.continuePossible(newStep),
        backPossible: true,
      });
    } else {
      this.context.setSchritt({
        key: newStep,
        erklaerungHeader: this.erklaerungen["Schritte"][newStep]
          .erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
        continuePossible: this.continuePossible(newStep),
        backPossible: false,
      });
    }
  };

  // componentDidMount() {
  //     this.getDataFromApi(false)
  // }

  getYearData = (jahr, bundesland = this.state.items.Bundesland) => {
    let kalendertage;
    let samstage;
    let sonntage;
    let feiertage;

    //Nur Feiertage anpassen
    if (bundesland === "Baden-Wuerttemberg") {
      switch (jahr) {
        case 2020:
          kalendertage = 366;
          samstage = 52;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2021:
          kalendertage = 365;
          samstage = 53;
          sonntage = 53;
          feiertage = 15;
          break;
        case 2022:
          kalendertage = 365;
          samstage = 53;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2023:
          kalendertage = 365;
          samstage = 52;
          sonntage = 53;
          feiertage = 15;
          break;
        default:
          kalendertage = 365;
          samstage = 52;
          sonntage = 52;
          feiertage = 19;
          break;
      }
    } else if (bundesland === "Bayern") {
      switch (jahr) {
        case 2020:
          kalendertage = 366;
          samstage = 52;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2021:
          kalendertage = 365;
          samstage = 53;
          sonntage = 53;
          feiertage = 15;
          break;
        case 2022:
          kalendertage = 365;
          samstage = 53;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2023:
          kalendertage = 365;
          samstage = 52;
          sonntage = 53;
          feiertage = 15;
          break;
        default:
          kalendertage = 365;
          samstage = 52;
          sonntage = 52;
          feiertage = 19;
          break;
      }
    } else if (bundesland === "Berlin") {
      switch (jahr) {
        case 2020:
          kalendertage = 366;
          samstage = 52;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2021:
          kalendertage = 365;
          samstage = 53;
          sonntage = 53;
          feiertage = 15;
          break;
        case 2022:
          kalendertage = 365;
          samstage = 53;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2023:
          kalendertage = 365;
          samstage = 52;
          sonntage = 53;
          feiertage = 15;
          break;
        default:
          kalendertage = 365;
          samstage = 52;
          sonntage = 52;
          feiertage = 19;
          break;
      }
    } else if (bundesland === "Brandenburg") {
      switch (jahr) {
        case 2020:
          kalendertage = 366;
          samstage = 52;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2021:
          kalendertage = 365;
          samstage = 53;
          sonntage = 53;
          feiertage = 15;
          break;
        case 2022:
          kalendertage = 365;
          samstage = 53;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2023:
          kalendertage = 365;
          samstage = 52;
          sonntage = 53;
          feiertage = 15;
          break;
        default:
          kalendertage = 365;
          samstage = 52;
          sonntage = 52;
          feiertage = 19;
          break;
      }
    } else if (bundesland === "Bremen") {
      switch (jahr) {
        case 2020:
          kalendertage = 366;
          samstage = 52;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2021:
          kalendertage = 365;
          samstage = 53;
          sonntage = 53;
          feiertage = 15;
          break;
        case 2022:
          kalendertage = 365;
          samstage = 53;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2023:
          kalendertage = 365;
          samstage = 52;
          sonntage = 53;
          feiertage = 15;
          break;
        default:
          kalendertage = 365;
          samstage = 52;
          sonntage = 52;
          feiertage = 19;
          break;
      }
    } else if (bundesland === "Hamburg") {
      switch (jahr) {
        case 2020:
          kalendertage = 366;
          samstage = 52;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2021:
          kalendertage = 365;
          samstage = 53;
          sonntage = 53;
          feiertage = 15;
          break;
        case 2022:
          kalendertage = 365;
          samstage = 53;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2023:
          kalendertage = 365;
          samstage = 52;
          sonntage = 53;
          feiertage = 15;
          break;
        default:
          kalendertage = 365;
          samstage = 52;
          sonntage = 52;
          feiertage = 19;
          break;
      }
    } else if (bundesland === "Hessen") {
      switch (jahr) {
        case 2020:
          kalendertage = 366;
          samstage = 52;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2021:
          kalendertage = 365;
          samstage = 53;
          sonntage = 53;
          feiertage = 15;
          break;
        case 2022:
          kalendertage = 365;
          samstage = 53;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2023:
          kalendertage = 365;
          samstage = 52;
          sonntage = 53;
          feiertage = 15;
          break;
        default:
          kalendertage = 365;
          samstage = 52;
          sonntage = 52;
          feiertage = 19;
          break;
      }
    } else if (bundesland === "Mecklenburg-Vorpommern") {
      switch (jahr) {
        case 2020:
          kalendertage = 366;
          samstage = 52;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2021:
          kalendertage = 365;
          samstage = 53;
          sonntage = 53;
          feiertage = 15;
          break;
        case 2022:
          kalendertage = 365;
          samstage = 53;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2023:
          kalendertage = 365;
          samstage = 52;
          sonntage = 53;
          feiertage = 15;
          break;
        default:
          kalendertage = 365;
          samstage = 52;
          sonntage = 52;
          feiertage = 19;
          break;
      }
    } else if (bundesland === "Niedersachsen") {
      switch (jahr) {
        case 2020:
          kalendertage = 366;
          samstage = 52;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2021:
          kalendertage = 365;
          samstage = 53;
          sonntage = 53;
          feiertage = 15;
          break;
        case 2022:
          kalendertage = 365;
          samstage = 53;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2023:
          kalendertage = 365;
          samstage = 52;
          sonntage = 53;
          feiertage = 15;
          break;
        default:
          kalendertage = 365;
          samstage = 52;
          sonntage = 52;
          feiertage = 19;
          break;
      }
    } else if (bundesland === "Nordrhein-Westfalen") {
      switch (jahr) {
        case 2020:
          kalendertage = 366;
          samstage = 52;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2021:
          kalendertage = 365;
          samstage = 53;
          sonntage = 53;
          feiertage = 15;
          break;
        case 2022:
          kalendertage = 365;
          samstage = 53;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2023:
          kalendertage = 365;
          samstage = 52;
          sonntage = 53;
          feiertage = 15;
          break;
        default:
          kalendertage = 365;
          samstage = 52;
          sonntage = 52;
          feiertage = 19;
          break;
      }
    } else if (bundesland === "Rheinland-Pfalz") {
      switch (jahr) {
        case 2020:
          kalendertage = 366;
          samstage = 52;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2021:
          kalendertage = 365;
          samstage = 53;
          sonntage = 53;
          feiertage = 15;
          break;
        case 2022:
          kalendertage = 365;
          samstage = 53;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2023:
          kalendertage = 365;
          samstage = 52;
          sonntage = 53;
          feiertage = 15;
          break;
        default:
          kalendertage = 365;
          samstage = 52;
          sonntage = 52;
          feiertage = 19;
          break;
      }
    } else if (bundesland === "Saarland") {
      switch (jahr) {
        case 2020:
          kalendertage = 366;
          samstage = 52;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2021:
          kalendertage = 365;
          samstage = 53;
          sonntage = 53;
          feiertage = 15;
          break;
        case 2022:
          kalendertage = 365;
          samstage = 53;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2023:
          kalendertage = 365;
          samstage = 52;
          sonntage = 53;
          feiertage = 15;
          break;
        default:
          kalendertage = 365;
          samstage = 52;
          sonntage = 52;
          feiertage = 19;
          break;
      }
    } else if (bundesland === "Sachsen") {
      switch (jahr) {
        case 2020:
          kalendertage = 366;
          samstage = 52;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2021:
          kalendertage = 365;
          samstage = 53;
          sonntage = 53;
          feiertage = 15;
          break;
        case 2022:
          kalendertage = 365;
          samstage = 53;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2023:
          kalendertage = 365;
          samstage = 52;
          sonntage = 53;
          feiertage = 15;
          break;
        default:
          kalendertage = 365;
          samstage = 52;
          sonntage = 52;
          feiertage = 19;
          break;
      }
    } else if ((bundesland = "Sachsen-Anhalt")) {
      switch (jahr) {
        case 2020:
          kalendertage = 366;
          samstage = 52;
          sonntage = 52;
          feiertage = 10;
          break;
        case 2021:
          kalendertage = 365;
          samstage = 53;
          sonntage = 53;
          feiertage = 15;
          break;
        case 2022:
          kalendertage = 365;
          samstage = 53;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2023:
          kalendertage = 365;
          samstage = 52;
          sonntage = 53;
          feiertage = 15;
          break;
        default:
          kalendertage = 365;
          samstage = 52;
          sonntage = 52;
          feiertage = 10;
          break;
      }
    } else if (bundesland === "Schleswig-Holstein") {
      switch (jahr) {
        case 2020:
          kalendertage = 366;
          samstage = 52;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2021:
          kalendertage = 365;
          samstage = 53;
          sonntage = 53;
          feiertage = 15;
          break;
        case 2022:
          kalendertage = 365;
          samstage = 53;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2023:
          kalendertage = 365;
          samstage = 52;
          sonntage = 53;
          feiertage = 15;
          break;
        default:
          kalendertage = 365;
          samstage = 52;
          sonntage = 52;
          feiertage = 19;
          break;
      }
    } else if (bundesland === "Thüringen") {
      switch (jahr) {
        case 2020:
          kalendertage = 366;
          samstage = 52;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2021:
          kalendertage = 365;
          samstage = 53;
          sonntage = 53;
          feiertage = 15;
          break;
        case 2022:
          kalendertage = 365;
          samstage = 53;
          sonntage = 52;
          feiertage = 15;
          break;
        case 2023:
          kalendertage = 365;
          samstage = 52;
          sonntage = 53;
          feiertage = 15;
          break;
        default:
          kalendertage = 365;
          samstage = 52;
          sonntage = 52;
          feiertage = 19;
          break;
      }
    }
    return {
      // Bundesland: bundesland,
      Kalendertage: kalendertage,
      "Samstage/Sonntage": samstage + sonntage,
      Feiertage: feiertage,
      Vertragsarbeitszeit: kalendertage - samstage - sonntage - feiertage,
    };
  };

  setSampleData = (jahr, gemeinkosten, personalaufwand) => {
    console.log("setSampleData for " + jahr);

    this.setState({
      isLoaded: true,
      jahr: jahr,
      items: {
        ...this.getClearData(),
        ...this.getYearData(jahr),
        // Gemeinkosten: gemeinkosten,
        // "Personalaufwand Gesamt": personalaufwand,
      },
    }, () => {
      this.context.setSavePossible(false);
      this.handleFirstStepChange(false);
    });
  };

  getClearData = () => {
    console.log("getClearData");

    return {
      Urlaub: "",
      Krankheit: "",
      Weiterbildung: "",
      Anwesenheitstage: "",
      "ArbeitsstundenTag": "",
      "ArbeitsstundenJahr": "",
      "MehrarbeitJahr": "",
      "AnwesenheitBrutto": "",
      "unproduktive Prozente": "",
      "unproduktive Stunden": "",
      "produktivierbare Zeiten": "",
      "Personalaufwand Gesamt": "",
      "RisikozuschlagProzent": "",
      "RisikozuschlagEuro": "",
      Gemeinkosten: "",
      StundensatzGesamtkosten: "",
      "Anzahl Mitarbeiter": "",
      Produktivzeit: "",
      Stundenverrechnungssatz: "",
    };
  };

  getDataFromApi = async (jahr) => {
    let result = await this.context.getUnternehmenDataFromApi("stundensatz");
    this.dataImport(result, jahr);
  }

  dataImport = (result, jahr) => {
    if (jahr === false) {
      this.setState({
        isLoaded: false,
        jahr: "",
        items: this.getClearData(),
      });
      this.context.setSavePossible(false);
      this.handleFirstStepChange(false);
    } else {
      var gemeinkosten = "";
      var personalaufwand = "";
      var risikoprozent = "";
      var risikoeuro = "";
      if (result.stundensatz) {
        if (result.stundensatz[jahr]) {
          console.log("Setting State from API Readings for " + jahr);
          if (result.stundensatz[jahr][
            "RisikozuschlagProzent"
            ]) {
            console.log("loading Risiko");
            risikoprozent =
              result.stundensatz[jahr][
                "RisikozuschlagProzent"
                ];
            risikoeuro =
              (parseFloat(risikoprozent) * parseFloat(personalaufwand)) /
              100;
            console.log(
              "Risiko %:" + risikoprozent + " and €:" + risikoeuro
            );
          }
          this.setState({
            isLoaded: true,
            jahr: jahr,
            items: {
              ...this.getClearData(),
              ...result.stundensatz[jahr],
              "RisikozuschlagProzent": risikoprozent,
              "RisikozuschlagEuro": risikoeuro,
            },
          }, () => {
            if (this.state.items.Bundesland !== "") {
              this.context.setSavePossible(true);
              this.handleFirstStepChange(true);
            } else {
              this.context.setSavePossible(false);
              this.handleFirstStepChange(false);
            }
          });
        } else {
          console.log("Setting State from Sample Data (1) for " + jahr);
          this.setSampleData(jahr, gemeinkosten, personalaufwand);
        }
      } else {
        console.log("Setting State from Sample Data (2) for " + jahr);
        this.setSampleData(jahr, gemeinkosten, personalaufwand);
      }
    }
  };

  continuePossible = (step) => {
    console.log("continuePossible für Step " + step + " aufgerufen.");

    const {items} = this.state;

    switch (step) {
      default:
      case 1:
        return false;
      // console.log("Step: " + step)
      // console.log("Bundesland: " + items.Bundesland)
      // return (items.Bundesland && (0 !== items.Bundesland.toString().length));
      case 2:
        return true;
      case 3:
        return true;
        return (
          items.Anwesenheitstage &&
          0 !== items.Anwesenheitstage.toString().length
        );
      case 4:
        return true;
        return (
          items["AnwesenheitBrutto"] &&
          0 !== items["AnwesenheitBrutto"].toString().length
        );
      case 5:
        return true;
        return (
          items["produktivierbare Zeiten"] &&
          0 !== items["produktivierbare Zeiten"].toString().length
        );
      case 6:
        return true;
        return (
          items["RisikozuschlagEuro"] &&
          0 !==
          items["RisikozuschlagEuro"].toString().length
        );
      case 7:
        // return (
        //   items["Stundenverrechnungssatz"] &&
        //   0 !== items["Stundenverrechnungssatz"].toString().length
        // );
        return false;
    }
  };

  handleSnackbarClose = () => {
    this.setState({
      saveSuccessful: false,
    });
  };

  handleBundeslandChange = () => {
    console.log("handleBundeslandChange aufgerufen.");

    const {items} = this.state;

    let vertragsarbeitszeit = "";
    let anwesenheitstage = "";
    let stundenprojahr = "";
    let anwesenheitbrutto = "";
    let unproduktiv = "";
    let risikozuschlag = "";
    let produktivzeit = "";

    vertragsarbeitszeit = parseFloat(items["Vertragsarbeitszeit"]);

    anwesenheitstage =
      vertragsarbeitszeit -
      parseFloat(items["Urlaub"]) -
      parseFloat(items["Krankheit"]) -
      parseFloat(items["Weiterbildung"]);

    console.log("Anwesenheitstage = " + anwesenheitstage);

    stundenprojahr =
      parseFloat(items["ArbeitsstundenTag"]) * anwesenheitstage;

    anwesenheitbrutto =
      parseFloat(items["MehrarbeitJahr"]) + parseFloat(stundenprojahr);

    unproduktiv =
      Math.round(
        (parseFloat(items["unproduktive Prozente"]) / 10) * anwesenheitbrutto
      ) / 10;

    let produktivierbar = anwesenheitbrutto - unproduktiv;

    risikozuschlag =
      parseFloat(items["RisikozuschlagProzent"]) +
      parseFloat(items["Personalaufwand Gesamt"]);

    let gesamtkosten =
      parseFloat(items["Personalaufwand Gesamt"]) +
      risikozuschlag +
      parseFloat(items["Gemeinkosten"]);

    produktivzeit = parseFloat(items["Anzahl Mitarbeiter"]) * produktivierbar;

    let stundenverrechnungssatz = gesamtkosten / produktivzeit;

    console.log("Final setState handleBundeslandChange...");

    this.setState({
      items: {
        ...items,
        Vertragsarbeitszeit: vertragsarbeitszeit,
        Anwesenheitstage: anwesenheitstage,
        "ArbeitsstundenJahr": stundenprojahr,
        "AnwesenheitBrutto": anwesenheitbrutto,
        "unproduktive Stunden": unproduktiv,
        "produktivierbare Zeiten": produktivierbar,
        "RisikozuschlagEuro": risikozuschlag,
        StundensatzGesamtkosten: gesamtkosten,
        Produktivzeit: produktivzeit,
        Stundenverrechnungssatz: stundenverrechnungssatz,
      },
    });
  };

  handleChange = (name) => ({target: {value}}) => {
    const {jahr} = this.state;
    console.log("handleChange aufgerufen für: " + name);
    const {items} = this.state;

    if (name === "Jahr") {
      console.log("handleChange is jahr!");
      if (value !== "" && jahr !== value) {
        console.log("jahr is set!");

        this.getDataFromApi(value);
      }
    } else {
      let vertragsarbeitszeit = "";
      let anwesenheitstage = "";
      let stundenprojahr = "";
      let anwesenheitbrutto = "";
      let unproduktiv = "";
      let risikozuschlag = "";
      let produktivzeit = "";

      //First row
      if (name === "Bundesland") {
        this.setState(
          {
            items: {
              ...items,
              [name]: value,
              ...this.getYearData(jahr, value),
            },
          },
          () => {
            this.handleBundeslandChange();
            if (this.state.jahr !== "") {
              this.context.setSavePossible(true);
              this.handleFirstStepChange(true);
            } else {
              this.context.setSavePossible(false);
              this.handleFirstStepChange(false);
            }
          }
        );
        return;
      }

      value = parseFloat(value);
      //Second row
      switch (name) {
        case "Samstage/Sonntage":
          vertragsarbeitszeit =
            parseFloat(items["Kalendertage"]) -
            value -
            parseFloat(items["Feiertage"]);
          break;
        case "Feiertage":
          vertragsarbeitszeit =
            parseFloat(items["Kalendertage"]) -
            parseFloat(items["Samstage/Sonntage"]) -
            value;
          break;
        case "all":
          vertragsarbeitszeit =
            parseFloat(items["Kalendertage"]) -
            parseFloat(items["Samstage/Sonntage"]) -
            parseFloat(items["Feiertage"]);
          break;
        default:
          // vertragsarbeitszeit = items["Kalendertage"] - items["Samstage/Sonntage"] - items["Feiertage"];
          vertragsarbeitszeit = parseFloat(items["Vertragsarbeitszeit"]);
          break;
      }

      //Third row
      switch (name) {
        case "Urlaub":
          anwesenheitstage =
            vertragsarbeitszeit -
            value -
            items["Krankheit"] -
            items["Weiterbildung"];
          break;
        case "Krankheit":
          anwesenheitstage =
            vertragsarbeitszeit -
            items["Urlaub"] -
            value -
            items["Weiterbildung"];
          break;
        case "Weiterbildung":
          anwesenheitstage =
            vertragsarbeitszeit - items["Urlaub"] - items["Krankheit"] - value;
          break;
        default:
          anwesenheitstage =
            vertragsarbeitszeit -
            items["Urlaub"] -
            items["Krankheit"] -
            items["Weiterbildung"];
          break;
      }

      console.log("Anwesenheitstage = " + anwesenheitstage);

      //Forth row
      switch (name) {
        case "ArbeitsstundenTag":
          stundenprojahr = parseFloat(value) * anwesenheitstage;
          break;
        default:
          stundenprojahr =
            parseFloat(items["ArbeitsstundenTag"]) * anwesenheitstage;
          break;
      }

      console.log(
        "Arbeitsstunden pro Tag = " + items["ArbeitsstundenTag"]
      );

      //Forth row: scond calculation
      switch (name) {
        case "MehrarbeitJahr":
          anwesenheitbrutto = parseFloat(value) + parseFloat(stundenprojahr);
          break;
        default:
          anwesenheitbrutto =
            parseFloat(items["MehrarbeitJahr"]) +
            parseFloat(stundenprojahr);
          break;
      }

      //Fifth row
      switch (name) {
        case "unproduktive Prozente":
          unproduktiv =
            Math.round((parseFloat(value) / 10) * anwesenheitbrutto) / 10;
          break;
        default:
          unproduktiv =
            Math.round(
              (parseFloat(items["unproduktive Prozente"]) / 10) *
              anwesenheitbrutto
            ) / 10;
          break;
      }

      let produktivierbar = anwesenheitbrutto - unproduktiv;

      //Sixth row
      switch (name) {
        case "RisikozuschlagProzent":
          risikozuschlag =
            (parseFloat(value) / 100) *
            parseFloat(items["Personalaufwand Gesamt"]);
          break;
        default:
          risikozuschlag =
            (parseFloat(items["RisikozuschlagProzent"]) /
              100) *
            parseFloat(items["Personalaufwand Gesamt"]);
          break;
      }

      let gesamtkosten =
        parseFloat(items["Personalaufwand Gesamt"]) +
        risikozuschlag +
        parseFloat(items["Gemeinkosten"]);

      // Seventh row
      switch (name) {
        case "Anzahl Mitarbeiter":
          produktivzeit = parseFloat(value) * produktivierbar;
          break;
        default:
          produktivzeit =
            parseFloat(items["Anzahl Mitarbeiter"]) * produktivierbar;
          break;
      }

      let stundenverrechnungssatz = gesamtkosten / produktivzeit;

      console.log("Final setState...");

      this.setState({
        items: {
          ...items,
          [name]: value,
          Vertragsarbeitszeit: vertragsarbeitszeit,
          Anwesenheitstage: anwesenheitstage,
          "ArbeitsstundenJahr": stundenprojahr,
          "AnwesenheitBrutto": anwesenheitbrutto,
          "unproduktive Stunden": unproduktiv,
          "produktivierbare Zeiten": produktivierbar,
          "RisikozuschlagEuro": risikozuschlag,
          StundensatzGesamtkosten: gesamtkosten,
          Produktivzeit: produktivzeit,
          Stundenverrechnungssatz: stundenverrechnungssatz,
        },
      });
    }
  };

  // handleContinue = () => {
  //   const {step} = this.state;
  //   this.setState({
  //     step: step + 1,
  //   });
  // };
  //
  // handleBack = () => {
  //   const {step} = this.state;
  //   if (step > 1) {
  //     this.setState({
  //       step: step - 1,
  //     });
  //   }
  // };

  handleSubmit = async () => {
    // TODO: Validate
    const {items, jahr} = this.state;

    var submitdata = {
      [jahr]: {
        ...items,
      },
    };

    let result = await this.context.postUnternehmenDataToApi("stundensatz", submitdata);

    this.setState({
      isLoaded: true,
      jahr: jahr,
      items: result.stundensatz[jahr],
      saveSuccessful: true,
    });
  };

  // handleSubmit = () => {
  //   // TODO: Validate
  //
  //   const {items, jahr} = this.state;
  //
  //   fetch(
  //     `${process.env.REACT_APP_API_URL}/v1/unternehmendata/${this.props.userId}/${this.props.unternehmenId}`,
  //     {
  //       method: "PUT",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         stundensatz: {
  //           [jahr]: {
  //             ...items,
  //           },
  //         },
  //       }),
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         this.setState({
  //           isLoaded: true,
  //           jahr: jahr,
  //           items: result.stundensatz[jahr],
  //           saveSuccessful: true,
  //         });
  //       },
  //       (error) => {
  //         this.setState({
  //           isLoaded: false,
  //           error: error,
  //         });
  //       }
  //     );
  // };

  onExit = () => {
    this.setState({
      tutorialEnabled: false,
    });
  };

  render() {
    const {classes} = this.props;
    const {jahr, items, saveSuccessful, step} = this.state;
    const STEPS = {
      1: (
        <Card className={classes.card}>
          <CardHeader title="Jahr und Bundesland"/>
          <CardContent>
            <FormControl
              className={classes.formControl}
              id={"edit-bwa-jahr-control"}
            >
              <InputLabel htmlFor="edit-bwa-bundesland">Jahr</InputLabel>
              <Select
                value={jahr}
                onChange={this.handleChange("Jahr")}
                inputProps={{
                  name: "jahr",
                  id: "edit-bwa-jahr",
                }}
              >
                <MenuItem value="">
                  <em>Bitte wählen</em>
                </MenuItem>
                <MenuItem value={2020}>2020</MenuItem>
                <MenuItem value={2021}>2021</MenuItem>
                <MenuItem value={2022}>2022</MenuItem>
                <MenuItem value={2023}>2023</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="edit-bwa-bundesland">Bundesland</InputLabel>
              <Select
                value={items["Bundesland"]}
                onChange={this.handleChange("Bundesland")}
                inputProps={{
                  name: "bundesland",
                  id: "edit-bwa-bundesland",
                }}
                // onFocus={() => this.handleFocus("bundesland")}
              >
                <MenuItem value="Baden-Wuerttemberg">
                  Baden-Württemberg
                </MenuItem>
                <MenuItem value="Bayern">Bayern</MenuItem>
                <MenuItem value="Berlin">Berlin</MenuItem>
                <MenuItem value="Brandenburg">Brandenburg</MenuItem>
                <MenuItem value="Bremen">Bremen</MenuItem>
                <MenuItem value="Hamburg">Hamburg</MenuItem>
                <MenuItem value="Hessen">Hessen</MenuItem>
                <MenuItem value="Mecklenburg-Vorpommern">
                  Mecklenburg-Vorpommern
                </MenuItem>
                <MenuItem value="Niedersachsen">Niedersachsen</MenuItem>
                <MenuItem value="Nordrhein-Westfalen">
                  Nordrhein-Westfalen
                </MenuItem>
                <MenuItem value="Rheinland-Pfalz">Rheinland-Pfalz</MenuItem>
                <MenuItem value="Saarland">Saarland</MenuItem>
                <MenuItem value="Sachsen">Sachsen</MenuItem>
                <MenuItem value="Sachsen-Anhalt">Sachsen-Anhalt</MenuItem>
                <MenuItem value="Schleswig-Holstein">
                  Schleswig-Holstein
                </MenuItem>
                <MenuItem value="Thüringen">Thüringen</MenuItem>
              </Select>
            </FormControl>
          </CardContent>
        </Card>
      ),
      2: (
        <Card className={classes.card}>
          <CardHeader title="Grundlegende Angaben"/>
          <CardContent>
            <CustomTextField
              id="edit-bwa-kalendertage"
              label="Kalendertage"
              className={classes.textField}
              value={items["Kalendertage"]}
              onChange={this.handleChange("Kalendertage")}
              margin="normal"
              variant="outlined"
              isCurrency={false}
              isReadOnly={false}
              unit={"Tage"}
              onFocus={() => this.handleFocus("Kalendertage")}
            />
            <CustomTextField
              id="edit-bwa-samstage"
              label="Samstage/Sonntage"
              className={classes.textField}
              value={items["Samstage/Sonntage"]}
              onChange={this.handleChange("Samstage/Sonntage")}
              margin="normal"
              variant="outlined"
              isCurrency={false}
              isReadOnly={false}
              unit={"Tage"}
              onFocus={() => this.handleFocus("Samstage/Sonntage")}
            />
            <CustomTextField
              id="edit-bwa-feiertage"
              label="Feiertage"
              className={classes.textField}
              value={items["Feiertage"]}
              onChange={this.handleChange("Feiertage")}
              margin="normal"
              variant="outlined"
              isCurrency={false}
              isReadOnly={false}
              unit={"Tage"}
              onFocus={() => this.handleFocus("Feiertage")}
            />
            <CustomTextField
              id="edit-bwa-vertragsarbeitszeit"
              label="Vertragsarbeitszeit pro Jahr"
              className={classes.textField}
              value={items["Vertragsarbeitszeit"]}
              margin="normal"
              isCurrency={false}
              isReadOnly={true}
              unit={"Tage"}
              onFocus={() => this.handleFocus("Vertragsarbeitszeit")}
            />
          </CardContent>
        </Card>
      ),
      3: (
        <Card className={classes.card}>
          <CardHeader title="Durchschnittliche Fehlzeiten"/>
          <CardContent>
            <CustomTextField
              id="edit-bwa-urlaub"
              label="Urlaub"
              className={classes.textField}
              value={items["Urlaub"]}
              onChange={this.handleChange("Urlaub")}
              margin="normal"
              variant="outlined"
              isCurrency={false}
              isReadOnly={false}
              unit={"Tage"}
              onFocus={() => this.handleFocus("Urlaub")}
            />
            <CustomTextField
              id="edit-bwa-krankheit"
              label="Krankheit"
              className={classes.textField}
              value={items["Krankheit"]}
              onChange={this.handleChange("Krankheit")}
              margin="normal"
              variant="outlined"
              isCurrency={false}
              isReadOnly={false}
              unit={"Tage"}
              onFocus={() => this.handleFocus("Krankheit")}
            />
            <CustomTextField
              id="edit-bwa-weiterbildung"
              label="Weiterbildung"
              className={classes.textField}
              value={items["Weiterbildung"]}
              onChange={this.handleChange("Weiterbildung")}
              margin="normal"
              variant="outlined"
              isCurrency={false}
              isReadOnly={false}
              unit={"Tage"}
              onFocus={() => this.handleFocus("Weiterbildung")}
            />
            <CustomTextField
              id="edit-bwa-anwesenheitstage"
              label="Anwesenheitstage"
              className={classes.textField}
              value={items["Anwesenheitstage"]}
              onChange={this.handleChange("Anwesenheitstage")}
              margin="normal"
              isCurrency={false}
              isReadOnly={true}
              unit={"Tage"}
              onFocus={() => this.handleFocus("Anwesenheitstage")}
            />
          </CardContent>
        </Card>
      ),
      4: (
        <Card className={classes.card}>
          <CardHeader title="ArbeitsstundenTag"/>
          <CardContent>
            <CustomTextField
              id="edit-bwa-pro-tag"
              label="Arbeitsstunden pro Tag"
              className={classes.textField}
              value={items["ArbeitsstundenTag"]}
              onChange={this.handleChange("ArbeitsstundenTag")}
              margin="normal"
              variant="outlined"
              isCurrency={false}
              isReadOnly={false}
              unit={"Stunden"}
              onFocus={() => this.handleFocus("ArbeitsstundenTag")}
            />
            <CustomTextField
              id="edit-bwa-pro-jahr"
              label="Arbeitsstunden pro Jahr"
              className={classes.textField}
              value={items["ArbeitsstundenJahr"]}
              onChange={this.handleChange("ArbeitsstundenJahr")}
              margin="normal"
              isCurrency={false}
              isReadOnly={true}
              unit={"Stunden"}
              onFocus={() => this.handleFocus("ArbeitsstundenJahr")}
            />
            <CustomTextField
              id="edit-bwa-mehrarbeit"
              label="Mehrarbeit pro Jahr"
              className={classes.textField}
              value={items["MehrarbeitJahr"]}
              onChange={this.handleChange("MehrarbeitJahr")}
              margin="normal"
              variant="outlined"
              isCurrency={false}
              isReadOnly={false}
              unit={"Stunden"}
              onFocus={() => this.handleFocus("MehrarbeitJahr")}
            />
            <CustomTextField
              id="edit-bwa-anwesenheit-brutto"
              label="Anwesenheit brutto"
              className={classes.textField}
              value={items["AnwesenheitBrutto"]}
              onChange={this.handleChange("AnwesenheitBrutto")}
              margin="normal"
              isCurrency={false}
              isReadOnly={true}
              unit={"Stunden"}
              onFocus={() => this.handleFocus("AnwesenheitBrutto")}
            />
          </CardContent>
        </Card>
      ),
      5: (
        <Card className={classes.card}>
          <CardHeader title="Produktivierbare Zeiten"/>
          <CardContent>
            <CustomTextField
              id="edit-bwa-unproduktiv-prozent"
              label="unproduktive Arbeitszeiten"
              className={classes.textField}
              value={items["unproduktive Prozente"]}
              onChange={this.handleChange("unproduktive Prozente")}
              margin="normal"
              variant="outlined"
              isCurrency={false}
              isReadOnly={false}
              unit={"%"}
              onFocus={() => this.handleFocus("unproduktive Prozente")}
            />
            <CustomTextField
              id="edit-bwa-unproduktiv-stunden"
              label="unproduktive Arbeitszeiten"
              className={classes.textField}
              value={items["unproduktive Stunden"]}
              onChange={this.handleChange("unproduktive Stunden")}
              margin="normal"
              isCurrency={false}
              isReadOnly={true}
              unit={"Stunden"}
              onFocus={() => this.handleFocus("unproduktive Stunden")}
            />
            <CustomTextField
              id="edit-bwa-produktivierbar"
              label="produktivierbare Zeiten"
              className={classes.textField}
              value={items["produktivierbare Zeiten"]}
              onChange={this.handleChange("produktivierbare Zeiten")}
              margin="normal"
              isCurrency={false}
              isReadOnly={true}
              unit={"Stunden"}
              onFocus={() => this.handleFocus("produktivierbare Zeiten")}
            />
          </CardContent>
        </Card>
      ),
      6: (
        <Card className={classes.card}>
          <CardHeader title="Kosten"/>
          <CardContent>
            <CustomTextField
              id="edit-bwa-personalaufwand"
              label="Personalaufwand Gesamt"
              className={classes.textField}
              value={items["Personalaufwand Gesamt"]}
              onChange={this.handleChange("Personalaufwand Gesamt")}
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              onFocus={() => this.handleFocus("Personalaufwand Gesamt")}
            />
            <CustomTextField
              id="edit-bwa-risikozuschlag-prozent"
              label="kalkulatorischer Risikozuschlag"
              className={classes.textField}
              value={items["RisikozuschlagProzent"]}
              onChange={this.handleChange(
                "RisikozuschlagProzent"
              )}
              variant="outlined"
              isCurrency={false}
              isReadOnly={false}
              unit={"%"}
              onFocus={() => this.handleFocus("RisikozuschlagProzent")}
            />
            <CustomTextField
              id="edit-bwa-risikozuschlag-euro"
              label="kalkulatorischer Risikozuschlag"
              className={classes.textField}
              value={items["RisikozuschlagEuro"]}
              onChange={this.handleChange("RisikozuschlagEuro")}
              margin="normal"
              isCurrency={true}
              isReadOnly={true}
              unit={"€"}
              onFocus={() => this.handleFocus("RisikozuschlagEuro")}
            />
            <CustomTextField
              id="edit-bwa-gemeinkosten"
              label="Gemeinkosten"
              className={classes.textField}
              value={items["Gemeinkosten"]}
              onChange={this.handleChange("Gemeinkosten")}
              margin="normal"
              variant="outlined"
              isCurrency={true}
              isReadOnly={false}
              unit={"€"}
              onFocus={() => this.handleFocus("Gemeinkosten")}
            />
          </CardContent>
        </Card>
      ),
      7: (
        <Card className={classes.card}>
          <CardHeader title="Stundensatz"/>
          <CardContent>
            <CustomTextField
              id="edit-bwa-gesamtkosten"
              label="Basis Stundensatzberechnung"
              className={classes.textField}
              value={items["StundensatzGesamtkosten"]}
              onChange={this.handleChange("StundensatzGesamtkosten")}
              margin="normal"
              isCurrency={true}
              isReadOnly={true}
              unit={"€"}
              onFocus={() => this.handleFocus("StundensatzGesamtkosten")}
            />
            <CustomTextField
              id="edit-bwa-mitarbeiteranzahl"
              label="Anzahl Mitarbeiter"
              className={classes.textField}
              value={items["Anzahl Mitarbeiter"]}
              onChange={this.handleChange("Anzahl Mitarbeiter")}
              variant="outlined"
              isCurrency={false}
              isReadOnly={false}
              unit={""}
              onFocus={() => this.handleFocus("Anzahl Mitarbeiter")}
            />
            <CustomTextField
              id="edit-bwa-produktivzeit"
              label="Produktivzeit"
              className={classes.textField}
              value={items["Produktivzeit"]}
              onChange={this.handleChange("Produktivzeit")}
              margin="normal"
              isCurrency={false}
              isReadOnly={true}
              unit={"Stunden"}
              onFocus={() => this.handleFocus("Produktivzeit")}
            />
            <CustomTextField
              id="edit-bwa-stundenverrechnungssatz"
              label="Stundenverrechnungssatz"
              className={classes.textField}
              value={items["Stundenverrechnungssatz"]}
              onChange={this.handleChange("Stundenverrechnungssatz")}
              margin="normal"
              isCurrency={true}
              isReadOnly={true}
              unit={"€"}
              onFocus={() => this.handleFocus("")}
            />
          </CardContent>
        </Card>
      ),
    };

    return (
      <Fragment>
        <div className={classes.appBarSpacer}/>
        <Typography variant="h4" gutterBottom component="h2">
          Stundenverrechnungssatz berechnen
        </Typography>
        <form className={classes.container} noValidate autoComplete="off">
          {STEPS[step]}

          {/*{step === 1 ? (*/}
          {/*  <Button*/}
          {/*    disabled*/}
          {/*    variant="contained"*/}
          {/*    color="secondary"*/}
          {/*    className={classes.button}*/}
          {/*  >*/}
          {/*    Zurück*/}
          {/*  </Button>*/}
          {/*) : (*/}
          {/*  <Button*/}
          {/*    variant="contained"*/}
          {/*    color="secondary"*/}
          {/*    className={classes.button}*/}
          {/*    onClick={this.handleBack}*/}
          {/*  >*/}
          {/*    Zurück*/}
          {/*  </Button>*/}
          {/*)}*/}
          {/*{step === 7 ? (*/}
          {/*  <Button*/}
          {/*    disabled={!this.continuePossible(step)}*/}
          {/*    variant="contained"*/}
          {/*    color="primary"*/}
          {/*    className={classes.button}*/}
          {/*    onClick={this.handleSubmit}*/}
          {/*  >*/}
          {/*    Speichern*/}
          {/*  </Button>*/}
          {/*) : (*/}
          {/*  <Button*/}
          {/*    disabled={!this.continuePossible(step)}*/}
          {/*    variant="contained"*/}
          {/*    color="primary"*/}
          {/*    className={classes.button}*/}
          {/*    onClick={this.handleContinue}*/}
          {/*  >*/}
          {/*    Weiter*/}
          {/*  </Button>*/}
          {/*)}*/}

          {/*<Snackbar*/}
          {/*  anchorOrigin={{*/}
          {/*    vertical: "bottom",*/}
          {/*    horizontal: "left",*/}
          {/*  }}*/}
          {/*  open={saveSuccessful}*/}
          {/*  autoHideDuration={6000}*/}
          {/*  onClose={this.handleSnackbarClose}*/}
          {/*  ContentProps={{*/}
          {/*    "aria-describedby": "message-id",*/}
          {/*  }}*/}
          {/*  message={*/}
          {/*    <span id="message-id">Daten erfolgreich gespeichert!</span>*/}
          {/*  }*/}
          {/*/>*/}
        </form>

        {/*<Card className={classes.progressCard}>*/}
        {/*  <CardHeader title="Fortschritt"/>*/}
        {/*  <CardContent>*/}
        {/*    <LinearProgress*/}
        {/*      variant="determinate"*/}
        {/*      value={((step - 1) / (Object.keys(this.erklaerungen["Schritte"]).length - 1)) * 100}*/}
        {/*    />*/}
        {/*    <Typography variant="body1">*/}
        {/*      {this.erklaerungen.erklaerungHeader} &mdash; Schritt {step} von {(Object.keys(this.erklaerungen["Schritte"]).length)}: {this.context.schritt.erklaerungHeader}*/}
        {/*    </Typography>*/}
        {/*  </CardContent>*/}
        {/*</Card>*/}
      </Fragment>
    );
  }
}

export default withStyles(styles)(Stundensatz);
