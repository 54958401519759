import React from "react";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import InputAdornment from "@material-ui/core/InputAdornment";
import {withStyles} from "@material-ui/styles";
import styles from "../styles";

function NumberFormatCustom(props) {
  const {inputRef, onChange, ...other} = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      decimalSeparator=","
      thousandSeparator="."
      allowNegative={false}
    />
  );
}

function NumberFormatCustomCurrency(props) {
  const {inputRef, onChange, ...other} = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      decimalSeparator=","
      thousandSeparator="."
      allowNegative={true}
      fixedDecimalScale={true}
      decimalScale={2}
    />
  );
}

class CustomTextField extends React.Component {
  state = {
    step: 1,
    isLoaded: false,
    tutorialEnabled: true,
    jahr: "",
  };

  render() {
    var {classes, isText, isCurrency, unit, isReadOnly, ...other} = this.props;

    if (isText) {
      if (isReadOnly) {
        return (
          <TextField
            {...other}
            InputProps={{
              readOnly: true,
            }}
          />
        );
      } else {
        return (
          <TextField
            {...other}
            InputProps={{}}
          />
        );
      }
    } else {
      if (isCurrency) {
        if (isReadOnly) {
          return (
            <TextField

              {...other}
              InputProps={{
                inputComponent: NumberFormatCustomCurrency,
                endAdornment: (
                  <InputAdornment position="end">{unit}</InputAdornment>
                ),
                readOnly: true,
              }}
            />
          );
        } else {
          return (
            <TextField
              {...other}
              InputProps={{
                inputComponent: NumberFormatCustomCurrency,
                endAdornment: (
                  <InputAdornment position="end">{unit}</InputAdornment>
                ),
                classes: {
                  input: classes.sizeUp,
                },
              }}
            />
          );
        }
      } else {
        if (isReadOnly) {
          return (
            <TextField
              {...other}
              InputProps={{
                inputComponent: NumberFormatCustom,
                endAdornment: (
                  <InputAdornment position="end">{unit}</InputAdornment>
                ),
                readOnly: true,
              }}
            />
          );
        } else {
          return (
            <TextField
              {...other}
              InputProps={{
                inputComponent: NumberFormatCustom,
                endAdornment: (
                  <InputAdornment position="end">{unit}</InputAdornment>
                ),
              }}
            />
          );
        }
      }
    }
  }
}

export default withStyles(styles)(CustomTextField);
