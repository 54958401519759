import ChatFunktionen from "./ChatFunktionen";

const TypischerMonatSchritte = {
  1: {
    LiquiditaetVormonat: {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetVerfuegbar", "LiquiditaetMonatsende"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: false,
      funktionen: [ChatFunktionen.Bewertung],
    },
    Erloese7: {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["RentabilitaetUmsatzerloese", "LiquiditaetEinzahlungen"],
      funktionen: [ChatFunktionen.Bewertung],
    },
    Erloese19: {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["RentabilitaetUmsatzerloese", "LiquiditaetEinzahlungen"],
      funktionen: [ChatFunktionen.Bewertung],
    },
    // SonstigeErloese: {
    //   typ: "input",
    //   datenQuelle: "bwa",
    //   datenTyp: "monthly",
    //   inAusgabe: true,
    //   inEingabe: true,
    //   teilDerSummen: ["LiquiditaetEinzahlungen"],
    //   funktionen: [ChatFunktionen.Bewertung],
    // },
    RentabilitaetUmsatzerloese: {
      typ: "readOnly",
      teilDerSummen: ["RentabilitaetRohertrag"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Bewertung],
    },
    Zinsertrag: {
      typ: "input",
      teilDerSummen: ["LiquiditaetEinzahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Bewertung],
    },
    SoNeutralerErtrag: {
      typ: "input",
      teilDerSummen: ["LiquiditaetEinzahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Bewertung],
    },
    LiquiditaetSonstigeEinzahlungen: {
      typ: "input",
      teilDerSummen: ["LiquiditaetEinzahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: false,
      funktionen: [ChatFunktionen.Bewertung],
    },
    LiquiditaetEinzahlungen: {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetRoherloes", "LiquiditaetVerfuegbar"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Bewertung],
    },
    LiquiditaetVerfuegbar: {
      class: "summe",
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetRoherloes"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: false,
      funktionen: [ChatFunktionen.Bewertung],
    },
    Wareneinkauf: {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["LiquiditaetProjektbezogeneAuszahlungen"],
      subtrahendVon: [],
      funktionen: [ChatFunktionen.Bewertung],
    },
    Fremdleistungen: {
      typ: "input",
      teilDerSummen: ["LiquiditaetProjektbezogeneAuszahlungen"],
      subtrahendVon: [],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Bewertung],
    },
    LiquiditaetProjektbezogeneAuszahlungen: {
      typ: "readOnly",
      teilDerSummen: [],
      subtrahendVon: ["LiquiditaetRoherloes", "RentabilitaetRohertrag"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Bewertung],
    },
    RentabilitaetRohertrag: {
      typ: "readOnly",
      teilDerSummen: ["Betriebsergebnis"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: false,
      funktionen: [ChatFunktionen.Bewertung],
    },
    LiquiditaetRoherloes: {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetUeberdeckung"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: false,
      funktionen: [ChatFunktionen.Bewertung],
    },
  },
  2: {
    Personalkosten: {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: [
        "RentabilitaetAufwand",
        "LiquiditaetLaufendeAuszahlungen",
      ],
      funktionen: [ChatFunktionen.Bewertung],
    },
    Raumkosten: {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: [
        "RentabilitaetAufwand",
        "LiquiditaetLaufendeAuszahlungen",
      ],
      funktionen: [ChatFunktionen.Bewertung],
    },
    Steuern: {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: [
        "RentabilitaetAufwand",
        "LiquiditaetLaufendeAuszahlungen",
      ],
      funktionen: [ChatFunktionen.Bewertung],
    },
    Versicherungen: {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: [
        "RentabilitaetAufwand",
        "LiquiditaetLaufendeAuszahlungen",
      ],
      funktionen: [ChatFunktionen.Bewertung],
    },
    "Kfz-Kosten": {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: [
        "RentabilitaetAufwand",
        "LiquiditaetLaufendeAuszahlungen",
      ],
      funktionen: [ChatFunktionen.Bewertung],
    },
    "Werbe-Reisekosten": {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: [
        "RentabilitaetAufwand",
        "LiquiditaetLaufendeAuszahlungen",
      ],
      funktionen: [ChatFunktionen.Bewertung],
    },
    "Kosten der Warenabgabe": {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: [
        "RentabilitaetAufwand",
        "LiquiditaetLaufendeAuszahlungen",
      ],
      funktionen: [ChatFunktionen.Bewertung],
    },
    Reparaturen: {
      typ: "input",
      teilDerSummen: [
        "RentabilitaetAufwand",
        "LiquiditaetLaufendeAuszahlungen",
      ],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Bewertung],
    },
    "Sonstige Kosten": {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: [
        "RentabilitaetAufwand",
        "LiquiditaetLaufendeAuszahlungen",
      ],
      funktionen: [ChatFunktionen.Bewertung],
    },
    RentabilitaetGWG: {
      typ: "input",
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      teilDerSummen: ["RentabilitaetAufwand"],
      funktionen: [ChatFunktionen.Bewertung],
    },
    // Afa: {
    //   typ: "input",
    //   datenQuelle: "bwa",
    //   datenTyp: "monthly",
    //   inAusgabe: true,
    //   inEingabe: true,
    //   teilDerSummen: ["RentabilitaetAbschreibungen"],
    //   funktionen: [ChatFunktionen.Bewertung],
    // },
    // RentabilitaetAbschreibungen: {
    //   typ: "readOnly",
    //   datenQuelle: "bwa",
    //   datenTyp: "monthly",
    //   inAusgabe: true,
    //   inEingabe: true,
    //   teilDerSummen: ["RentabilitaetAufwand"],
    //   funktionen: [ChatFunktionen.Bewertung],
    // },
    // RentabilitaetAufwand: {
    //   typ: "readOnly",
    //   datenQuelle: "bwa",
    //   datenTyp: "monthly",
    //   inAusgabe: true,
    //   inEingabe: true,
    //   teilDerSummen: [],
    //   subtrahendVon: ["Betriebsergebnis"],
    //   funktionen: [ChatFunktionen.Bewertung],
    // },
    // Betriebsergebnis: {
    //   typ: "readOnly",
    //   datenQuelle: "bwa",
    //   datenTyp: "monthly",
    //   inAusgabe: true,
    //   inEingabe: true,
    //   teilDerSummen: [],
    //   funktionen: [ChatFunktionen.Bewertung],
    // },
    Zinsaufwand: {
      typ: "input",
      teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Bewertung],
    },
    "So neutraler Aufwand": {
      typ: "input",
      teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Bewertung],
    },
    LiquiditaetTilgungen: {
      typ: "input",
      teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [ChatFunktionen.Bewertung],
    },
    // LiquiditaetSteuernAbgaben: {
    //   typ: "input",
    //   teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
    //   datenQuelle: "bwa",
    //   datenTyp: "monthly",
    //   inAusgabe: true,
    //   inEingabe: true,
    //   funktionen: [ChatFunktionen.Bewertung],
    // },
    LiquiditaetInvestitionen: {
      typ: "input",
      inAusgabe: true,
      inEingabe: false,
      teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
      funktionen: [ChatFunktionen.Bewertung],
    },
    LiquiditaetUmsatzsteuersaldo: {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetLaufendeAuszahlungen"],
      prozentualeAnrechnungDerSumme: true,
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: false,
      funktionen: [ChatFunktionen.Bewertung],
    },
    LiquiditaetLaufendeAuszahlungen: {
      typ: "readOnly",
      teilDerSummen: [],
      subtrahendVon: ["LiquiditaetUeberdeckung"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: false,
      funktionen: [ChatFunktionen.Bewertung],
    },
    LiquiditaetUeberdeckung: {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetUeberdeckungNachEntnahme"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: false,
      funktionen: [ChatFunktionen.Bewertung],
    },
    PrivatBenoetigtePrivatentnahme: {
      typ: "readOnly",
      teilDerSummen: [],
      subtrahendVon: ["LiquiditaetMonatsende"],
      datenQuelle: "PrivatFinanzen",
      datenTyp: "static",
      inAusgabe: true,
      inEingabe: false,
      funktionen: [ChatFunktionen.Bewertung],
    },
    LiquiditaetUeberdeckungNachEntnahme: {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetMonatsende"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: false,
      funktionen: [ChatFunktionen.Bewertung],
    },
    LiquiditaetMonatsende: {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetMonatsendeMitKontokorrentrahmen"],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: false,
      funktionen: [ChatFunktionen.Bewertung],
    },
    FinanzierungKontokorrrentkredit: {
      typ: "readOnly",
      teilDerSummen: ["LiquiditaetMonatsendeMitKontokorrentrahmen"],
      datenQuelle: "kapitalUndFinanzierung",
      datenTyp: "static",
      inAusgabe: true,
      inEingabe: false,
      funktionen: [ChatFunktionen.Bewertung],
    },
    LiquiditaetMonatsendeMitKontokorrentrahmen: {
      typ: "readOnly",
      teilDerSummen: [],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: false,
      funktionen: [ChatFunktionen.Bewertung],
    },
    "Neutrales Ergebnis": {
      typ: "readOnly",
      teilDerSummen: [],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: false,
    },
    "Ergebnis": {
      typ: "readOnly",
      teilDerSummen: [],
      datenQuelle: "bwa",
      datenTyp: "monthly",
      inAusgabe: true,
      inEingabe: false,
    },
  },
};

export default TypischerMonatSchritte;
